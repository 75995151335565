import React from "react";
import styled from "styled-components";

declare global {
  interface Window {
    Trustpilot: any;
  }
}

const TrustPilotWrapper = styled.div`
  text-align: left;
  @media (max-width: 436px) {
    display: grid;
    place-items: center;
  }
`;

window.Trustpilot = window.Trustpilot || {};

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot && window.Trustpilot.loadFromElement) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, [window.Trustpilot]);
  return (
    <TrustPilotWrapper
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className="trustpilot-widget" // Renamed this to className.
      // [ long list of data attributes...]
      data-locale="en-GB"
      data-template-id="53aa8912dec7e10d38f59f36"
      data-businessunit-id="5c8b865b17f74e00010409c9"
      data-style-height="140px"
      data-style-width="300px"
      data-theme="light"
      data-stars="4,5"
      data-review-languages="en"
      data-text-color="#082842"
    >
      <a
        href="https://uk.trustpilot.com/review/vouch.co.uk"
        target="_blank"
        rel="noreferrer"
      >
        {" "}
        Trustpilot
      </a>
    </TrustPilotWrapper>
  );
};
export default TrustBox;
