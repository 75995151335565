import React, { useRef, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import ProfilePassport from "../components/ProfilePassport";
import { NavLink } from "react-router-dom";
import { PassportUser } from "../types/Person";
import AlertCard from "../components/Input/AlertCard";
interface Props {
  open: boolean;
  setOpen: Function;
  passportUser: PassportUser | null;
  handleLogout: Function;
}

export const slideInTop = keyframes`
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
`;

export const fadeInModal = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.45);
  }
`;

const ModalBackDrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  display: grid;
  place-items: center;
  animation: ${fadeInModal} 0.35s ease-in-out 0s forwards 1;
`;

const ModalBodyContainer = styled.div`
  background: linear-gradient(168deg, #fff 33%, #e0ecf5);
  padding: 1rem 0 1rem 0;
  animation: ${slideInTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  max-width: 85%;
  box-shadow: 0 0 8px 2px rgba(100, 100, 100, 0.25);
  height: 100%;
  position: relative;
  width: 100%;
  min-height: 50vh;
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 0;
  align-items: center;
  color: white;
  text-align: center;
`;

const ShopSwitcher = styled.div`
  border-radius: 10px;
  border: 2px solid #f2f2f2;
  max-width: 800px;
  width: 90%;
  margin: 0 auto;
  display: flex;
`;

const ShopToggle = styled(NavLink)<{ active: boolean }>`
  background: ${(props) => (props.active ? "white" : "#f2f2f2")};
  width: 100%;
  font-size: 1.25rem;
  padding: 1rem;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
`;

export default function Sidebar({
  setOpen,
  open,
  passportUser,
  handleLogout,
}: Props) {
  const modalRef = useRef(null);
  const [shop, setShop] = useState<"landlord" | "tenant" | "">(getUrl());
  const [cardTitle, setCardTitle] = useState<string>("");

  function getUrl(): "landlord" | "tenant" | "" {
    if (window.location.pathname.includes("landlord")) {
      return "landlord";
    } else if (window.location.pathname.includes("tenant")) {
      return "tenant";
    }
    return "";
  }

  useOutsideAlerter(modalRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      function handleKeyPressOutside(event: any) {
        if (event.key === "Escape") {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyPressOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyPressOutside);
        setOpen(false);
      };
    }, [ref]);
  }

  if (!open) {
    return null;
  }

  return (
    <ModalBackDrop>
      <AlertCard
        title={cardTitle}
        desc="Your store has been switched and you may close the sidebar when you're ready to view it."
      />
      <ModalBodyContainer ref={modalRef}>
        <ShopSwitcher>
          <ShopToggle
            onClick={() => {
              setShop("tenant");
              setCardTitle("Switched to Tenant store!");
            }}
            to="/tenant"
            active={shop === "tenant"}
          >
            👪 Tenants
          </ShopToggle>
          <ShopToggle
            onClick={() => {
              setShop("landlord");
              setCardTitle("Switched to Landlord store!");
            }}
            to="/landlord"
            active={shop === "landlord"}
          >
            🏠 Landlords
          </ShopToggle>
          <hr
            style={{
              borderBottom: "2px solid #f2f2f2",
            }}
          />
        </ShopSwitcher>
        <ProfilePassport
          passportUser={passportUser}
          handleLogout={handleLogout}
        />
      </ModalBodyContainer>
    </ModalBackDrop>
  );
}
