import React, { useState } from "react";
import styled from "styled-components";
import { Tenant } from "../../types/Void";
import { TextInput } from "vouch.ui.react.components";
import SingleTenant from "./SingleTenant";
import { Button } from "../../common/styles";
import DropdownSelector from "../ScottishPower/DropdownSelector";
import { titleOptions } from "../../helpers/ScottishPowerConstants";

interface Props {
  tenantList: Tenant[] | undefined;
  moveInOrOut: string;
  getUpdatedTenants: (TenantsList: Tenant[]) => void;
  setRemoveAllTenants: (tenantsToBeRemoved:boolean) => void;
  submitTenants: () => void;
  setFormError: (isFormWithoutErrors:boolean) => void;
  formError: boolean;
}

const Backwards = styled.div`
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  z-index: 1;
  font-size: 1rem;
  padding: 20px 0;
  align-items: flex-end;
`;

const TenantStepContainer = styled.div`
  .addressPeople {
    align-items: flex-end !important;
  }

  .addressPeople li {
    padding: 0;
  }

  #dropdownSelector {
    max-width:502px;
    margin-left:5px;
  }
`;

const Forwards = styled(Backwards)`
  img {
    transform: rotate(180deg);
  }
`;

const UpdateConfirm = styled.p`
  color: #34bebd;
  text-align: right;
  color: #34bebd;
  font-weight: 700;
  padding: 0 10px;
  margin-top: 0;
  height:30px;
`;

const StepContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

const TenantStepper: React.FC<Props> = ({
  tenantList,
  moveInOrOut,
  getUpdatedTenants,
  setRemoveAllTenants,
  submitTenants,
  setFormError,
  formError
}) => {
  const [voidInTenant, setVoidInTenant] = useState({
    title: "",
    forename: "",
    surname: "",
    email: "",
    phone: "",
  });

  const [voidOutTenant, setVoidOutTenant] = useState({
    title: "",
    forename: "",
    surname: "",
    email: "",
    phone: "",
  });

  const [tenantForm, setTenantForm] = useState({
    contactDetails: {
      title: "",
      forename: "",
      surname: "",
      email: "",
      phone: "",
    },
    id: "",
    vchNumber: "",
    isLeadTenant: false,
  });

  const [isListUpdated, setIsListUpdated] = useState<boolean>(false);

  const [step, setStep] = useState(0);
  const [tenantData, setTenantFormData] = useState<any>(tenantList);
  let updatedStuff: any[] = [];
  function onFormSubmit(formData: any) {
    if(formData.length > 1) {
      updatedStuff = formData.map((form: any) => {
        let contactDetails = {};
        if (form.myFullForm) {
             // many edits
          contactDetails = {
            email:
              form.myFullForm.contactDetails.email || form.contactDetails.email,
            title:
              form.myFullForm.contactDetails.title || form.contactDetails.title,
            forename:
              form.myFullForm.contactDetails.forename ||
              form.contactDetails.forename,
            surname:
              form.myFullForm.contactDetails.surname ||
              form.contactDetails.surname,
            phone:
              form.myFullForm.contactDetails.mobilePhone ||
              form.contactDetails.mobilePhone,
  
          };
        } else {
          contactDetails = {
            email: form.contactDetails.email,
            title: form.contactDetails.title,
            forename: form.contactDetails.forename,
            surname: form.contactDetails.surname,
            phone: form.contactDetails.mobilePhone,
          };
        }

        form = { ...form, contactDetails: contactDetails };
        delete form.email;
        delete form.forename;
        delete form.surname;
        delete form.title;
        delete form.phone;
        delete form.myFullForm;
        return form;
      });

      updatedStuff = formData
    }

    if(formData.length >= 1) {
      updatedStuff = formData
    }
    
     else {

      // solo edit
      updatedStuff = formData[0]
    }

    
    getUpdatedTenants(updatedStuff);
    return updatedStuff;
  }

  function addTenant() {
    if (tenantList !== undefined) {
      const newTenant = {
        contactDetails: {
          email: "",
          forename: "",
          housePhone: "",
          mobilePhone: "",
          surname: "",
          title: ""
        },
        isLeadTenant: false,
        vchNumber:"",
        id:""
      }
      tenantList.push(newTenant)
      setStep(tenantList.length-1)
    }    
  }
  
  function removeTenant(step:number) {
    if (tenantList !== undefined) {
      if(tenantList.length === 1) {
        setRemoveAllTenants(true);
        getUpdatedTenants([]);
        tenantList.length = 0;
      } else {
        const newTenantList = tenantList;
        newTenantList.splice(step, 1);
        setStep(tenantList.length-1)
        getUpdatedTenants(newTenantList);
      }
    }
  }

  function goNext() {
    if (tenantList === undefined) {
      return;
    }
    
    if (tenantList.length === 0) {
      let updatedForm;
        
      if (moveInOrOut === "outTenant") {
        updatedForm = {
          contactDetails: voidOutTenant,
          isLeadTenant: false,
          vchNumber:"",
          id:""
        };
        setTenantFormData(updatedForm);
        onFormSubmit([updatedForm]);
      }

      else if (moveInOrOut === "inTenant") {
        updatedForm = {
          contactDetails: voidInTenant,
          isLeadTenant: false,
          vchNumber:"",
          id:""
        };
        setTenantFormData(updatedForm);
        onFormSubmit([updatedForm]);
      }
      return;
    }

    const userInfo = tenantList;
    userInfo[step] = tenantForm;
    setTenantFormData(userInfo);

    if (step === tenantList?.length - 1) {
      onFormSubmit(tenantData);
    } else {
      const newStep = step + 1;
      setStep(newStep);
    }
  }

  function goForward() {
    if (tenantList === undefined) {
      return;
    }
    if (step === tenantList?.length - 1) {
      onFormSubmit(tenantData);
    } else {
      const newStep = step + 1;
      setStep(newStep);
    }
  }

  function goBack() {
    if (step !== 0) {
      const newStep = step - 1;
      setStep(newStep);
    }
  }

  function handleEditedFields(
    evt: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    setIsListUpdated(false)
    if (type === "inTenant") {
      setVoidInTenant({
        ...voidInTenant,
        [evt.target.name]: evt.target.value,
      });
    }

    if (type === "outTenant") {
      setVoidOutTenant({
        ...voidOutTenant,
        [evt.target.name]: evt.target.value,
      });
    }
  }

  function getTitle(_: number, actualTitle: string): void {
    if(moveInOrOut === "inTenant") {
      setVoidInTenant({ ...voidInTenant, title:actualTitle });
    } else {
      setVoidOutTenant({ ...voidOutTenant, title:actualTitle });
    }
}

function submitTenantList() {
  goNext()
  setIsListUpdated(true)
}
  return (
    <TenantStepContainer>
    {
      tenantList !== undefined && tenantList.length > 0 && (
        <>
        <h5>{step+1} of {tenantList.length}</h5>
        <Button onClick={() => addTenant()}>Add Tenant</Button>
        <Button onClick={() => removeTenant(step)}>Remove Tenant</Button>
        </>
      )
    }
      {tenantList !== null &&
      tenantList !== undefined &&
      tenantList.length > 0 ? (
        tenantList.map((tenant: Tenant, index: number) => {
          if (step === index) {
            return (
              <SingleTenant
                key={step}
                step={step}
                item={tenantList[step]}
                inputtedForm={tenantForm}
                setTenantForm={setTenantForm}
                users={tenantList}
                setIsListUpdated={setIsListUpdated}
                setFormError={setFormError}
                formError={formError}
              ></SingleTenant>
            );
          }
        })
      ) : (
        <>
          <div className="addressPeople">
            <ul>
              <div id="dropdownSelector">
              <DropdownSelector
                introText={""}
                listType={"Title"}
                getGeneralValue={getTitle}
                options={titleOptions}
                ></DropdownSelector>
                </div>
              <TextInput
                type="text"
                name="forename"
                label="Tenant Forename"
                maxLength={50}
                regex={/^[a-zA-Z0-9\s-]+$/}
                value={voidOutTenant.forename || voidInTenant.forename || ""}
                onChange={(e: any) => handleEditedFields(e, moveInOrOut)}
              />
              <TextInput
                type="text"
                name="surname"
                label="Tenant Surname"
                maxLength={50}
                regex={/^[a-zA-Z0-9\s-]+$/}
                value={voidOutTenant.surname ||voidInTenant.surname || ""}
                onChange={(e: any) => handleEditedFields(e, moveInOrOut)}
              />
            </ul>
            <ul>
              <li>
                <TextInput
                  type="text"
                  name="email"
                  label="Tenant Email"
                  maxLength={50}
                  regex={/\S+@\S+\.\S+/}
                  value={voidOutTenant.email || voidInTenant.email || ""}
                  onChange={(e: any) => handleEditedFields(e, moveInOrOut)}
                />

                <TextInput
                  type="text"
                  name="phone"
                  label="Tenant Phone"
                  maxLength={11}
                  regex={/^[0-9\s ]+$/}
                  value={
                    voidOutTenant.phone || voidInTenant.phone || ""
                  }
                  onChange={(e: any) => handleEditedFields(e, moveInOrOut)}
                />
              </li>
            </ul>
          </div>
        </>
      )}
      <UpdateConfirm>{isListUpdated ? "Tenants list updated!" : ""}</UpdateConfirm>
      <StepContainer>
        {tenantList !== undefined && tenantList?.length > 1 && step !== 0 && (
          <Backwards onClick={() => goBack()}>
            <img
              src="/assets/svg/back.svg"
              alt="a black arrow pointing leftwards"
            /> previous
          </Backwards>
        )}
        {tenantList !== undefined && (
          <>
            {tenantList?.length === step + 1 ? (
              <>
              <Button onClick={() => submitTenantList()}>Update Tenants</Button>
              </>
              ) : tenantList.length === 0 ? null : (
                <>
              <Forwards onClick={() => goForward()}>
                next<img
                  src="/assets/svg/back.svg"
                  alt="a black arrow pointing leftwards"
                />
              </Forwards>
                <Button onClick={() => submitTenantList()}>Update Tenants</Button>      
              </>
            )}
          </>
        )}
        {tenantList !== undefined && tenantList?.length === 0 && (
          <Button onClick={() => submitTenantList()}>Update Tenants</Button>
        )}
      </StepContainer>
    </TenantStepContainer>
  );
};

export default TenantStepper;
