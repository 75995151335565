import React, { useState, useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { PageWrapper, Colours } from "../../common/styles";
// types
import { Store } from "../../types/Store";
import Person from "../../types/Person";
import { useAuthenticated } from "../../hooks/hooks";
// components
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import UserHandler from "../../components/modals/UserHandler";
import ExistingUser from "../../components/modals/ExistingUser";

interface Props {
  store: Store[];
}

const StoreContainer = styled.div<{ bannerUrl: string }>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &:after {
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    position: fixed;
    background-position: center;
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

const TenantsInsurancePageWrapper = styled(PageWrapper)`
  padding: 0;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const Important = styled.h1`
  color: white;
  text-align: center;
`;

const MainStoreBanner = styled.div`
  margin-bottom: 100px;

  h1 {
    color: white;
    padding: 25px;
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    width: fit-content;
    line-height: 3rem;
  }

  h4 {
    color: white;
    background: ${Colours.teal};
    padding: 15px;
    font-weight: 100;
    font-size: 1.5rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    width: fit-content;
  }

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

interface KeyAndValue {
  key: string;
  value: string;
}

export interface Quote {
  tenantFee: number | null;
  totalFee: number | null;
  headCount: number | null;
  ppm: number | null;
  savings: number | null;
}

export interface QuotePricing {
  charges: KeyAndValue[];
  finalPrice: string;
}

const BackArrow = styled.img`
  max-width: 25px;
  min-width: 25px;
  margin: 0 20px;
`;

const TitleContainer = styled.div`
  justify-content: stretch;
  text-align: left;
  width: 100%;

  h1 {
    text-align: left;
    background: ${Colours.blue};
    color: white;
  }
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: "Montserrat", sans-serif;

  img:hover {
    cursor: pointer;
  }
`;

const TextContainer = styled.div`
  background: ${Colours.blue};
  display: grid;
  grid-gap: 20px;
  padding: 10px;
`;

const TextHeaderContainer = styled(TextContainer)`
  color: white;
  display: table-column;
  text-align: center;

  a {
    color: #f45372;
    font-weight: bold;
  }
  a:hover {
    cursor: pointer;
  }
`;

const Blurb = styled.div`
  h1 {
    font-weight: bold;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 0px 30px;
  }
`;

const BlurbContainer = styled.div`
  display: grid;
  align-items: flex-end;
  justify-content: center;
  margin: 0 25px;

  img {
    max-width: 100%;
  }
`;

const CTA = styled.div`
  margin: 25px auto;
  color: white;
  background: #f45372;
  padding: 15px 20px;
  font-weight: bold;

  &:hover {
    cursor: pointer;
  }
  img {
    cursor: pointer;
    height: auto;
    padding: 0 20px;
  }
`;

const CheckMarkPoint = styled.div`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  color: #f2f2f2;
  font-family: "Lato", Helvetica, sans-serif;
  h4 {
    font-weight: normal;
  }

  img {
    margin-right: 10px;
  }

  span,
  h1 {
    font-weight: bold;
    color: white;
    font-family: "Montserrat", sans-serif;
  }
`;

const ZeroDepositPage: React.FC<Props> = ({ store }) => {
  const shopContext = useContext(ctx);
  const typeOfAuth = useAuthenticated();
  const [open, setOpen] = useState(false);
  const [applicant, setApplicant] = useState<Person | null>({
    firstname: "",
    lastname: "",
    agencyName: "",
    email: "",
    hasVisitedBefore: false,
    applicationStatus: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      nameOrNumber: "",
      postcode: "",
    },
  }); // store applicant meta
  let history = useHistory();
  if (!store) {
    return <div>Loading...</div>;
  }
  const zeroDeposit = store[0];

  if (!zeroDeposit) {
    return <div>Loading...</div>;
  }

  const depositProtection = zeroDeposit.departments.filter(
    ({ departmentName }) => {
      return departmentName.toLowerCase() === "deposit protection";
    }
  )[0];

  return (
    <>
      {open && (
        <Modal setOpen={setOpen} open={open}>
          {typeOfAuth === "vch" ? (
            <ExistingUser
              integration="zeroDeposit"
              setOpen={setOpen}
              deal={{
                id: "zero-deposit",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Zero Depsoit",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: "",
              }}
              email=""
              storeName="Zero Deposit"
              dealType="CALLBACK"
            />
          ) : (
            <UserHandler
              deal={{
                id: "zero-deposit",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Zero Depsoit",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: "",
              }}
              integration="zeroDeposit"
              setOpen={setOpen}
              storeName="Zero Deposit"
              dealType="CALLBACK"
            />
          )}
        </Modal>
      )}
      <StoreContainer bannerUrl={zeroDeposit.smallBanner}>
        <MainStoreBanner>
          <TitleContainer>
            <h1>{zeroDeposit.storeName}</h1>

            <h4>{depositProtection.departmentShortDesc}</h4>
            <div style={{ gridArea: "blank" }}>&nbsp;</div>
            <ControlsContainer>
              {window.location.pathname !== "/" && (
                <BackArrow
                  src="/assets/svg/back.svg"
                  onClick={() => history.goBack()}
                />
              )}
            </ControlsContainer>
          </TitleContainer>
        </MainStoreBanner>
        <TenantsInsurancePageWrapper center>
          <BlurbContainer>
            <TextHeaderContainer>
              <h1>Get Deposit Freedom</h1>
              <p>
                Say no to expensive cash deposits, and yes to spending money on
                the things you love. Get started today at{" "}
                <a target="_blank" href="https://www.zerodeposit.com" rel="noreferrer">
                  www.zerodeposit.com
                </a>
              </p>
            </TextHeaderContainer>
            <TextContainer>
              <Blurb>
                <h1>How it works</h1>
                <CheckMarkPoint>
                  <h4>
                    <span>1. Getting started</span> <br /> Ask your letting
                    agent to refer you to Zero Deposit™ (they will have to
                    confirm that the landlord is happy to proceed).
                  </h4>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <h4>
                    <span>
                      2. Purchasing a Guarantee - See how much you could save
                    </span>
                    <br />
                    You’ll be offered the option to purchase a Zero Deposit™️
                    Guarantee, which replaces a cash deposit. Just sign up to
                    find out how much you could save.
                    <br />
                    This makes moving home more affordable, giving you the
                    financial freedom to spend your money on what you want - and
                    give your landlord peace of mind with the same fair if there
                    is a claim
                  </h4>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <h4>
                    <span>3. The tenancy ends</span> <br /> If there are no
                    damages or unpaid rent at the end of your tenancy, then
                    theres no further action required
                  </h4>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <h4>
                    <span>4. Disputes and claims</span> <br /> If there are any
                    damages or unpaid rent at the end of the tenancy, you’ll
                    still be liable to pay for them. Any disputes that cannot be
                    resolved with your landlord will be referred to TDS for
                    adjudication. If your landlord makes a fair claim, we pay
                    them and then collect the payment from you see important
                    stuff below).
                  </h4>
                </CheckMarkPoint>
              </Blurb>

              <Important>The important stuff</Important>

              <CheckMarkPoint>
                <h4>
                  <span>Your responsibility</span> - The Zero Deposit™ Guarantee
                  provides your landlord with protection if you do not pay them
                  directly for the cost of any financial loss or damage due.
                  It’s offered to you as a choice, which means you can still
                  provide a traditional cash deposit if you prefer.
                </h4>
              </CheckMarkPoint>

              <CheckMarkPoint>
                <h4>
                  <span>Disputes and claims</span> - If your landlord makes a
                  successful claim, we will pay them before seeking
                  reimbursement from you. Failure to pay at this stage will
                  result in us passing your debt to a debt collection agency,
                  which could mean further costs for you and may even affect
                  your credit rating
                </h4>
              </CheckMarkPoint>

              <CheckMarkPoint>
                <h4>
                  <span>Costs and fees</span> - The cost of the Zero Deposit
                  Guarantee is not returned to you at the end of your tenancy.
                  There is also an annual admin fee per tenancy, payable after
                  your first 12 months.
                </h4>
              </CheckMarkPoint>
            </TextContainer>
            <CTA onClick={() => setOpen(true)}>Find Out More</CTA>
          </BlurbContainer>
          <Footer>
            {zeroDeposit.storeTermsAndCond && (
              <SmallPrint>{zeroDeposit.storeTermsAndCond}</SmallPrint>
            )}
          </Footer>
        </TenantsInsurancePageWrapper>
      </StoreContainer>
    </>
  );
};

export default /* withAITracking(reactPlugin,  */ ZeroDepositPage;
