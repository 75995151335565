import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Mixpanel } from '../../insights/mixpanel';
// context
import ctx, { ctxType } from "../../context/ShopContext";
// components
import NewUser from "./NewUser";
import ExistingUser from "./ExistingUser";
import MoreInfoUser from "./MoreInfoUser";
import TextInput from "../Input/TextInput";
import { Deal } from "../../types/Store";
import { Button } from "../../common/styles";
import { returningUser } from "../../hooks/hooks";
interface Props {
  deal?: Deal;
  integration: string;
  setOpen: Function;
  storeName: string;
  dealType: "CALLBACK" | "CALLNOW" | "GETNOW" | null;
  phoneNumber?: string | null;
  setRevealNumber?: Function;
}

const UserHandlerContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  text-align: left;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const EnterEmail = styled.div`
  max-width: 450px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  button {
    align-self: flex-end;
  }
`;

const UserHandler: React.FC<Props> = ({
  deal,
  integration,
  setOpen,
  storeName,
  dealType,
  phoneNumber,
  setRevealNumber,
}) => {
  const [screen, setScreen] = useState<"new" | "existing" | "moreinfo" | "">(
    ""
  );
  const shopCtx: ctxType = useContext(ctx);
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  useEffect(() => {
    if (
      email.split("").length > 5 &&
      email.includes("@") &&
      email.includes(".")
    ) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [email]);

  useEffect(() => {
    if (
      shopCtx.emailAddr &&
      shopCtx.emailAddr?.trim() !== "" &&
      integration !== "tcli"
    ) {
      setEmail(shopCtx.emailAddr);
      setScreen("existing");
      Mixpanel.identify(shopCtx.emailAddr);
      Mixpanel.people = {
        $email: shopCtx.emailAddr
      };
    }
  }, []);

  function goBack() {
    setScreen("");
  }

  async function handleSubmit(emailParam = email) {
    Mixpanel.people = {
      $email: shopCtx.emailAddr
    };
    const res: any = await returningUser(emailParam);
    if (res.data.getReturningUser.email) {
      if (!res.data.getReturningUser.dob && integration === "tcli") {
        setScreen("moreinfo");
        return;
      }
      setScreen("existing");
    } else {
      setScreen("new");
    }
  }

  function handleInputChange(e: React.FormEvent<EventTarget>) {
    const { value, name } = e.target as HTMLInputElement;
    switch (name) {
      case "email":
        setEmail(value);
        break;
    }
  }
  return (
    <UserHandlerContainer>
      {screen === "" && (
        <EnterEmail>
          <div>
            <h2>We just need a few details</h2>
            <TextInput
              label="Email Address"
              onChange={(e: any) => handleInputChange(e)}
              type="email"
              value={email}
              name="email"
            />
          </div>
          <Button disabled={!validEmail} onClick={() => {
            handleSubmit();
            if (deal?.url && dealType === "GETNOW") {
              window.open(deal.url);
            }
          }
          }>
            Submit
          </Button>
        </EnterEmail>
      )}
      {screen === "existing" && (
        <ExistingUser
          goBack={() => goBack()}
          integration={integration}
          setOpen={setOpen}
          deal={deal}
          setRevealNumber={setRevealNumber}
          email={email}
          storeName={storeName}
          dealType={dealType}
          phoneNumber={phoneNumber}
        />
      )}
      {screen === "new" && (
        <NewUser
          integration={integration}
          setOpen={setOpen}
          deal={deal}
          storeName={storeName}
          goBack={() => goBack()}
          emailProp={email}
          dealType={dealType}
          phoneNumber={phoneNumber}
          setRevealNumber={setRevealNumber}
        />
      )}
      {screen === "moreinfo" && (
        <MoreInfoUser
          integration={integration}
          setOpen={setOpen}
          deal={deal}
          storeName={storeName}
          goBack={() => goBack()}
          emailProp={email}
          dealType={dealType}
        />
      )}
    </UserHandlerContainer>
  );
};

export default UserHandler;
