import React from "react";
import styled from "styled-components";
import {  Void} from "../../types/Void";
import {  formatVoidPortalEnums, getProgressColour } from "../../helpers/voidPortalHelpers";

interface Props {
  voidMove: Void['moveIn'] | Void['moveOut'] ;
  isReadRequired: boolean;
  voidMoveNotifications:Void['moveOut' | 'moveIn']['notificationsToDisplay'] | undefined;
  voidMoveNotificationsFuel:Void['moveOut' | 'moveIn']['notificationsToDisplay']['council' | 'gas' | 'water' | 'electricity'] | undefined;
  toggleMeterReadEntryPanel: () => void;
}

 


const UtilityStatusPill = styled.div<{ switchStatus: string }>`
  margin-left: auto;
  cursor: pointer;
  width: 120px;
  border-radius: 10px;
  background: #34bebd;
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
  cursor: auto;
  margin-left: auto;
  cursor: pointer;
  width: auto;
  border-radius: 5px;
  background: #34bebd;
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  padding: 0px 10px;
  margin-bottom: 20px;
  margin-right:20px;
  background: ${(props) =>
    getProgressColour(props.switchStatus)};
`;

const VoidUtilityStatus: React.FC<Props> = ({
  voidMove,
  isReadRequired,
  voidMoveNotifications,
  voidMoveNotificationsFuel,
  toggleMeterReadEntryPanel,
}) => {
  
  return (
    <>
    {
      voidMove !== null &&
        voidMove !== undefined &&
        voidMoveNotifications !== null &&
        voidMoveNotificationsFuel !== undefined && 
      <UtilityStatusPill
        onClick={() => toggleMeterReadEntryPanel()}
        switchStatus={
            voidMoveNotificationsFuel.status !==
            "cancelled" &&
          isReadRequired ? "Action Required"
          : voidMoveNotificationsFuel?.status
        }
      >
        {
        voidMoveNotificationsFuel?.status !==
          "cancelled" &&
        isReadRequired
          ? "Action Required"
          :  formatVoidPortalEnums(
            voidMoveNotificationsFuel.status,
              "Switch"
            )
         }
      </UtilityStatusPill>
    } 
    </>
   );
};

export default VoidUtilityStatus;
