import styled, { keyframes } from "styled-components";

export interface IBannerBackground {
  bannerUrl: string;
}

export const PageWrapper = styled.div<{ center?: boolean }>`
  max-width: 1200px;
  margin: ${(props) => (props.center === true ? "0 auto" : "0")};
  text-align: left;
  padding: 0 20px;
  width: 100%;
`;

export const FontSizes = {
  extraLarge: "2.5em",
  large: "1.5em",
  regular: "1em",
  small: "0.8em"
}

export const Colours = {
  teal: "#34bebd",
  blue: "#082842",
  red: "#ED1C24",
  orange: "#ED941E",
  darkGrey: "#BCBEC0",
  lightGrey: "#F2F2F2",
  white: "#ffffff"
};

/* ANIMATIONS */
export const wiggle = keyframes`
    0% { transform: rotate(0deg); }
   80% { transform: rotate(0deg); }
   85% { transform: rotate(5deg); }
   95% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
`;

export const fadeIn = keyframes`
  from {ha
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const slideInTop = keyframes`
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

export const fadeInModal = keyframes`
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.45);
  }
`;

export const BannerBackground = styled.div<IBannerBackground>`
  &:after {
    content: "";
    // prettier-ignore
    // -webkit-mask-image: linear-gradient( rgba(0,0,0,1) 25%,rgba(0,0,0,0.8) 70%, rgba(0,0,0,0) 100%);
    // mask-image: linear-gradient(
    //   rgba(0, 0, 0, 1) 25%,
    //   rgba(0, 0, 0, 0.8) 70%,
    //   rgba(0, 0, 0, 0) 100%
    // );
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p: IBannerBackground) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    position: fixed;
    background-position: top center;

    @media (max-width: 320px) {
      height: 300px;
    }
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

export const ButtonGroup = styled.div`
  display:flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
max-width: 600px; 

@media(max-width:450px){

  label {
    margin: 5px 0;
  }
  }
`
  ;

interface IToggleButton {
  isActive: boolean;
}

export const ToggleButton = styled.label<IToggleButton>`
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  background: ${(p: IToggleButton) => (p.isActive ? (props) => props.theme.color.primaryActive : "white")};
  
  span {
    color: ${(p: IToggleButton) => (p.isActive ? (props) => props.theme.color.highlightBodyText : (props) => props.theme.color.mainBodyText)}
  }
`
  ;

export const Button = styled.button<IButton>`
  background-color: ${(props: any) => props.theme.color.mainHighlight};
  margin: 10px;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid ${(props: any) => props.theme.color.mainHighlight};
  width: 150px;
  height: 60px;
  letter-spacing: 0.23px;
  color: ${(props: any) => props.theme.color.highlightBodyText};
  font: bold 15px Lato, sans-serif;
  outline: none;

  &:disabled {
    background: ${Colours.darkGrey};
    border-color: ${(props: any) => props.theme.color.primaryDisabled};
    color: ${(props: any) => props.theme.color.disabledBodyText};
    pointer-events: none;
  }
`;

export const AlternateButton = styled(Button)`
  background: none;
  border-color: #082842;
  color: #082842;
`;

export const TertiaryButton = styled(Button)`
  background-color: ${(props: any) => props.theme.color.tertiaryActive};
  color: ${Colours.white};
  border: 1px solid;
  height: auto;
  max-height: 50px;
  padding: 10px;
  margin: auto;
  max-width: 100px;
  font-size: 0.8em;
`;


export const CTA = styled(Button)`
  background: none;
  border-color: white;
  color: white;
`;

export const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${(p: IButton) =>
    p.disabled
      ? (props) => props.theme.color.primaryDisabled
      : p.alternate
        ? (props) => props.theme.color.highlightBodyText
        : (props) => props.theme.color.mainBodyText};
  ${(p: IButton) =>
    p.disabled
      ? (props: any) => `1px solid ${props.theme.color.primaryDisabled}`
      : p.alternate
        ? (props: any) => `1px solid ${props.theme.color.primaryActive}`
        : (props: any) => `1px solid ${props.theme.color.secondaryActive}`};
`;

export const InputWrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  display: block;
`;

interface IButton {
  disabled?: boolean;
  alternate?: boolean;
  alternateInverse?: boolean;
}

/* CUSTOM COMPONENT FORM STYLES */

export const FormHeading = styled.h1`
  color: ${Colours.blue}!important;
  text-align: center;
  @media (max-width: 650px) {
    padding: 30px 0 10px 0;
    font-size: 1.75em;
    margin:0;
  }
`;

export const FormFooter = styled.div`
  p{
    padding: 0;
  }
`;

export const ActiveLabel = styled.label`
  background: #34bebd;
  color: white;
  `
  ;

export const FormGuide = styled.p`
  text-align:left;
  padding: 10px 0;
  max-width:600px;
  width: 100%;
  margin: 0 auto;

  span {
    color: #34bebd;
  }

  @media (max-width: 450px) {
    padding: 5px;
  }

  `;

export const FormContainer = styled.div`
  text-align: left;

  p {
    padding-bottom:50px;
  }

  label {
    max-width: 600px; 
    padding: 20px;
    margin: 5px 20px;
    border: 1px solid ${(props) => props.theme.color.alternateBackground};
    border-radius: 5px;

    input {
      margin-left: 10px;
    }
  }
  
  button {
    border-radius: 10px;
    display:flex;
    flex-flow: row;
    
    span {
      margin: auto;
    }
  }

  @media (max-width: 450px) {
    label {
      margin: 10px 5px;
      font-size: .8rem;
      line-height: normal;
    }

    p {
      padding-bottom: 5px;
    }
  }

  `;