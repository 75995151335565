import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Void, PropertyInfo } from "../../types/Void";
import SubmitMeterRead from "./SubmitMeterRead";
import VoidMoveStatusComponent from "./VoidMoveStatusComponent";
import SubmitMeterErrorBoundary from "./ErrorScreen";
import VoidErrorBoundary from "./ErrorScreen";
import MoveInEditPanel from "./MoveInEditPanel";
import LandlordEditPanel from "./LandlordEditPanel";
import { Button, AlternateButton } from "../../common/styles";
import ctx, { ctxType } from "../../context/VoidContext";
import BillPayerEditPanel from "./BillPayerEditPanel";
import { Link, useParams } from "react-router-dom";
import { moveHasBeenSentOrHasReading, getProgressColour } from "../../helpers/voidPortalHelpers";
import PortalMenu from "./PortalMenu";
import MainHeader from "../../components/MainHeader";
import { useLocation } from "react-router-dom";
import Loading from "../Loading";
import { format, formatISO } from "date-fns";
import AlertMessage from "./AlertMessage";
import { ToggleSwitch } from "vouch.ui.react.components";
import SVG from "react-inlinesvg";

interface Props {}

const VoidAddressDetailContainer = styled.div`
  > div:nth-child(2) {
    display: flexbox;
    flex-flow: row wrap;
    flex-flow: column;
    justify-content: space-between;
    display: flex;
    grid-gap: 5%;
  }

  #editButtonsContainer {
    .editButton {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer !important;
    }
    display: flex;
    justify-content: space-between;
    grid-gap: 5%;
    > div:nth-child(1) {
      display: flex;
      justify-content: end;
      flex: 1 1 45%;
      text-align: right;
      button:nth-child(1) {
        align-self: flex-start;
        margin-right: 0;
        border-color: transparent;
      }
      button:nth-child(2) {
        margin-right: 0;
      }
    }
    > div:nth-child(2) {
      flex: 1 1 50%;
    }
  }
`;

const SwitchStatus = styled.div<{ status: string }>`
  border-radius: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  padding: 0px 10px;
  border: 2px solid ${(props) => props.theme.buttonOutline};
  background: ${(props) =>
    props.status === "In Progress"
      ? "#082842"
      : props.status === "Action Required"
      ? "#ed941e"
      : props.status === "Complete"
      ? "#34bebd"
      : props.status === "Cancelled"
      ? "#ED1C24"
      : "#BCBEC0"};
  color: white;
  font-size: 0.8rem;
  font-size: 10px;
  cursor: auto;
`;

const Address = styled.span`
  text-transform: capitalize;
  display: flex;
  grid-gap: 50px;
  li {
    display:block;
  }
    .normal {
    text-transform: none;
  }
`;

const AddressMetaSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-flow: column;
    flex: 0 1 65%;

    span {
      display: inline;
      line-height: 60px;
      font-family: "Montserrat";
      font-weight: 700;
      border-radius: 10px;
    }
  }
`;

const CancelPrompt = styled.div`
  label {
    display: flex;
    align-items: center;
  }

  #cancelButtonContainer {
    display: flex;
    align-items: center;

    > button:nth-child(1) {
      background: red;
      border: red;
      
      &:disabled {
        background: #BCBEC0;
        border: #BCBEC0;
      }
    }

    > button:nth-child(2) {
      padding: 0 !important;
    }
  }

  > div:nth-child(1) {
    border: 3px solid orange;
    border-radius: 10px;
    padding: 40px 80px;
    margin: 40px 0;
  }

  display: flex !important;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0 !important;

  #address {
    span {
span {

  font-size: 1rem !important;
}
      font-size: 1rem !important;
    }
}
`;

const TenancyDetailsSection = styled.div`
  border-radius: 10px;
  padding: 0 40px 40px 40px;
  align-self: baseline;
  flex: 1;
  width: 100%;

  .city {
    text-transform: capitalize;
  }

  .UtilityStatusPillContainer {
    flex: 1;
  }

  #cancelContainer {
    display: flex;
    justify-content: right;
    button {
      margin-right: 0;
    }
  }

  .summaryHeading {
    color: #34bebd;
    padding: 0 20px;
    font-size: 1.4rem;
  }
  > div {
    display: flex;
  }

  .introHeading {
    margin-top: 18px;
  }

  .subHeading {
    display: flex;
    grid-gap: 20px;
    cursor: pointer;
  }

  #voidOwnerTitle {
    border-top: 1px solid #f2f2f2;
    padding-top: 30px;
    margin-top: 30px;
    column-gap: 0px;

    .grouped {
      > div {
        flex: 1;
      }
    }
  }

  #voidOwnerAlt {
    border-top: 1px solid #f2f2f2;
    padding: 20px 20px 0px 20px;
    .grouped {
      > div {
        flex: 0 1 auto;
      }
    }
  }

  .grouped {
    display: flex;
    flex-flow: row;
    align-items: flex-start;
    column-gap: 150px;
    img {
      max-width: 125px;
      object-fit: contain;
    }
  }

  small {
    display: block;
  }

  span {
    font-size: 0.8rem;
  }

  > div:nth-child(1) {
    display: flex;
    column-gap: 20px;
    flex-flow: row;

    > div {
      flex: 1 1 100px;
    }
  }
  .addressPeople {
    display: flex;
    flex-flow: column wrap;
    li {
      padding: 5px 0;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .utilityList {
    img {
      width: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      max-width: 350px;
      padding: 5px 0;
    }
  }
`;

const Back = styled.div`
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  z-index: 10;
  font-size: 1rem;
`;

const HeadingContainer = styled.div<{ isEditFormOpen: boolean }>`
  display: flex;
  grid-gap: 20px;
  margin-left: 20px;
  display: ${(props) => (props.isEditFormOpen ? "none" : "flex")};
  align-items: center;
`;

const Meters = styled.div<{ moveOutSelectedNotMoveIn: boolean }>`
  background: ${(props) => props.theme.accentBackground};
  padding: 30px 40px 20px 40px;
  border-radius: ${(props) =>
    props.moveOutSelectedNotMoveIn ? "0 10px 10px 10px" : "10px 0 10px 10px"};
  display: flex;
  flex-flow: column;
  > div {
    > div {
      margin-bottom: 20px;
    }
  }
`;

const Notifications = styled.div<{ selected: boolean }>`
  background: ${(props) => props.theme.accentBackground};
  padding: 20px 40px;
  display: flex;
  flex-flow: column;
  border-radius: 0 0 10px 10px;
  padding-bottom: 30px;

  .notificationsHeading {
    font-size: 16px;
    color: #34bebd;
    font-weight: 700;
  }

  span {
    display: flex;
    align-items: center;
    flex-flow: column;
    width: 150px;
    text-align: center;
    img:nth-child(2) {
      width: 20px;
    }
  }
  > div {
    background: ${(props) => props.theme.background};
    color: #082842;

    img {
      height: 64px;
      width: 64px;
    }
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
`;

const Overview = styled.div<{ selected: boolean; moveOutSelectedNotMoveIn: boolean }>`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  background: ${(props) => props.theme.accentBackground};
  color: ${(props) => props.theme.text};
  padding: 20px 40px;
  position: relative;
  border-radius: ${(props) =>
    props.moveOutSelectedNotMoveIn ? "0 10px 0 0" : "10px 0 0 0"};

  .UtilityStatusPillContainer {
    div {
      margin: 0;
      cursor: pointer;
    }
    width: 120px;
  }

  svg {
    path {
      fill: ${(props) => props.theme.text};
    }
  }

  #overview {
    display: flex;
    width: auto;
    cursor: pointer;
    grid-gap: 20px;
    align-items: center;
    > span {
      > span {
        display: flex;
      }
    }
    span {
      display: inline-block;
    }
  }
  #tenantSubTitle {
    color: ${(props) => props.theme.text};
    font-size: 1rem;
    grid-gap: 20px;
    display: flex;
    font-family: "Montserrat", sans-serif;
  }

  #editButton {
  }

  > div {
    width: calc(50% - 25px);
    display: flex;
    width: 100%;
    grid-gap: 150px;
  }
  #switchDates {
    display: flex;
    flex-flow: column;

    > span {
      display: flex;
      grid-gap: 20px;
      justify-content: space-between;
    }
  }
  #tenantsList {
    display: flex;
    flex-flow: column;
  }
`;

const DetailContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background:${(props) => props.theme.background};

#headerWrapper {
  padding-top: 30px;
}
`;

const UtilityStatusPill = styled.div<{ switchStatus: string }>`
  cursor: auto;
  margin-left: auto;
  max-width: 120px;
  border-radius: 5px;
  background: #34bebd;
  color: #fff;
  text-align: center;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: ${(props) =>
    getProgressColour(props.switchStatus)};
`;

const MoveOutDate = styled.div<{ selected: boolean; moveOutSelectedNotMoveIn: boolean }>`
  display: flex;
  align-items: center;
  grid-gap: 20px;
  background: ${(props) => (props.moveOutSelectedNotMoveIn ? props.theme.accentBackground : props.theme.background)};
  padding: 20px 40px;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100px;
  justify-content: space-between;
  cursor: pointer;
  color: ${(props) => props.theme.text};
 h3 {
    font-weight: ${(props) => (!props.moveOutSelectedNotMoveIn ? "normal" : "700")};
  }
  `;

const MoveInDate = styled(MoveOutDate)<{
  selected: boolean;
  moveOutSelectedNotMoveIn: boolean;
}>`
h3 {
  font-weight: ${(props) => (!props.moveOutSelectedNotMoveIn ? "700" : "normal")};
}
  background: ${(props) => (props.moveOutSelectedNotMoveIn ? props.theme.background : props.theme.accentBackground)};
`;

const ContentWrapper = styled.div`
padding: 15px 35px; 
`;

const VoidAddressDetailScreen: React.FC<Props> = () => {
  const [moveOutSelectedNotMoveIn, setSelectedOption] = useState<boolean>(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [singleVoid, setSingleVoid] = useState<Void>();
  const [fuelType, setFuelType] = useState<string>("");
  const [isEditFormOpen, setEditFormOpen] = useState<boolean>(false);
  const [readSubmitted, setReadSubmitted] = useState<boolean>(false);
  const [cancelPromptOpen, setCancelPromptOpen] = useState<boolean>(false);
  const [propertyInfo, setPropertyInfo] = useState<PropertyInfo>({
    gasMeterRead: "",
    waterMeterRead: "",
    electricityDayMeterRead: "",
    electricityNightMeterRead: "",
    submittedMoveOutDate: formatISO(new Date()),
    submittedMoveOutDateMeta: [],
    submittedMoveInDate: formatISO(new Date()),
    submittedMoveInDateMeta: [],
  });

  const { moveIn, moveOut }: ctxType = useContext(ctx);
  const [sendingData, setSendingData] = useState(false);
  const [isActionRequired, setIsActionRequired] = useState(false);

  const [moveOutElecReadRequired, setMoveOutElecReadRequired ] = useState(true); 
  const [moveOutGasReadRequired, setMoveOutGasReadRequired ] = useState(true); 
  const [moveOutWaterReadRequired, setMoveOutWaterReadRequired ] = useState(true); 
  const [moveInWaterReadRequired, setMoveInWaterReadRequired ] = useState(true);
  const [moveInElecReadRequired, setMoveInElecReadRequired ] = useState(true);
  const [moveInGasReadRequired, setMoveInGasReadRequired ] = useState(true);

  const [moveInEdit, setMoveInEdit] = useState(false);
  const [landlordEdit, setLandlordEdit] = useState(false);
  const [billPayerEdit, setBillPayerEdit] = useState(false);
  const routePath = useLocation();

  const { voidId } = useParams<{ voidId: string }>();
  const [confirmMoveCancel, setConfirmMoveCancel] = useState(false);

  function toggleEdit() {
    setEditFormOpen(!isEditFormOpen);
  }

  function toggleMoveInEdit() {
    setMoveInEdit(true);
    toggleEdit();
    setLandlordEdit(false);
    setBillPayerEdit(false);
  }

  function toggleLandlordEdit() {
    setLandlordEdit(true);
    toggleEdit();
    setMoveInEdit(false);
    setBillPayerEdit(false);
  }

  function toggleBillPayerEdit() {
    setBillPayerEdit(true);
    toggleEdit();
    setLandlordEdit(false);
    setMoveInEdit(false);
  }

  function cancelPrompt() {
    setCancelPromptOpen(!cancelPromptOpen);
  }

  function resetMove(moveOutSelectedNotMoveIn: boolean) {
    let move;
    moveOutSelectedNotMoveIn ? (move = "Out") : (move = "In");
    setCancelPromptOpen(false);
    resetMoveData(move);
  }

  function moveToggle(boolean: boolean) {
    setCancelPromptOpen(false);
    setSelectedOption(boolean);
  }

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  async function cancelVoid(
    voidCancelObj: any,
    moveType: string,
    id: string,
    customerIdentifier: string
  ) {
    setSendingData(true);
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/cancelNotifications/${moveType}/${id}/${customerIdentifier}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(voidCancelObj),
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      window.location.reload();
      console.log(data);
    }
    setSendingData(false);
  }

  function resetMoveData(type: string) {
    let cancelObj = {
      cancelGas: true,
      cancelElectricity: true,
      cancelWater: true,
      cancelCouncil: true,
    };

    if (singleVoid?.id !== undefined) {
      if (type === "In") {
        cancelVoid(
          cancelObj,
          "move-in",
          singleVoid?.id,
          singleVoid?.customerIdentifier
        );
      } else {
        cancelVoid(
          cancelObj,
          "move-out",
          singleVoid?.id,
          singleVoid?.customerIdentifier
        );
      }
    }
  }


  function checkReadRequired(singleVoid: Void) {
   
    if(singleVoid === undefined || singleVoid === null ) {
      return
    }

    if(singleVoid?.moveOut === undefined || singleVoid?.moveOut === null) {
      return
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveOut.podioNotifications, singleVoid?.moveOut.waterMeterReading, "water")) {
      setMoveOutWaterReadRequired(false)
    } else {
      setMoveOutWaterReadRequired(true)
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveOut.podioNotifications, singleVoid?.moveOut.electricityDayMeterReading, "electricity",singleVoid?.moveOut.internalScottishPowerElectricityStatus)) {
      setMoveOutElecReadRequired(false)
    } else {
      setMoveOutElecReadRequired(true)
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveOut.podioNotifications, singleVoid?.moveOut.gasMeterReading, "gas",singleVoid?.moveOut.internalScottishPowerGasStatus)) {
      setMoveOutGasReadRequired(false)
    } else {
      setMoveOutGasReadRequired(true)
    }

    if(singleVoid?.moveIn === undefined || singleVoid?.moveIn === null) {
      return
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveIn.podioNotifications, singleVoid?.moveIn.waterMeterReading, "water")) {
      setMoveInWaterReadRequired(false)
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveIn.podioNotifications, singleVoid?.moveIn.gasMeterReading, "gas")) {
      setMoveInGasReadRequired(false)
    }

    if(moveHasBeenSentOrHasReading(singleVoid?.moveIn.podioNotifications, singleVoid?.moveIn.electricityDayMeterReading, "electricity")) {
      setMoveInElecReadRequired(false)
    }
  }

    

  async function fetchASingleAgentVoid(customerId: string | null, voidId: string | null) {
    setSendingData(true)
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/${customerId}/${voidId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "GET",
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      checkReadRequired(data)
      setSingleVoid(data);
    }
    setSendingData(false)
  }

  
  const customerIdentifier: string|null = sessionStorage.getItem("voidCustomerIdentifier");
  const voidJWT: string|null = sessionStorage.getItem("voidJWT");


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routePath]);


  useEffect(() => {
      fetchASingleAgentVoid(customerIdentifier, voidId)
        return
  }, []);

  useEffect(() => {
    if (
      (singleVoid?.landlordContactDetails === null &&
      singleVoid?.agentContactDetails === null) ||

      (singleVoid?.landlordContactDetails !== null &&
      singleVoid?.agentContactDetails !== null && singleVoid?.landlordContactDetails?.forwardingAddress === null && singleVoid?.agentContactDetails?.forwardingAddress === null)
    ) {
      setIsActionRequired(true)
    } else {
      setIsActionRequired(false)
    }
  },[singleVoid?.landlordContactDetails, singleVoid?.agentContactDetails ])

return (
    <>
    { sendingData ? <Loading/> : (

        <>
<PortalMenu toggleMenu={toggleMenu} menuOpen={menuOpen}></PortalMenu>
          <DetailContainer>
            <div id="headerWrapper">
<MainHeader shopType={"portal"} />
            </div>

<ContentWrapper>
            <HeadingContainer isEditFormOpen={isEditFormOpen}>
              <Link to={`/utility/voids/dashboard/${voidJWT}`}>
              <Back>
                <img
                  src="/assets/svg/back.svg"
                  alt="a black arrow pointing leftwards"
                />
              </Back>
              </Link>
              <AddressMetaSection>
                <div>
                  <span>
                    {singleVoid?.propertyAddress?.line1
                      ? `${singleVoid?.propertyAddress?.line1.toLowerCase()},`
                      : ""}{" "}
                    {singleVoid?.propertyAddress?.line2
                      ? `${singleVoid?.propertyAddress?.line2.toLowerCase()},`
                      : ""}{" "}
                    {singleVoid?.propertyAddress?.city
                      ? `${singleVoid?.propertyAddress?.city.toLowerCase()},`
                      : ""}{" "}
                    {singleVoid?.propertyAddress?.postcode
                      ? `${singleVoid?.propertyAddress?.postcode.toUpperCase()}`
                      : ""}
                  </span>
                </div>
              </AddressMetaSection>
            </HeadingContainer>
            <VoidAddressDetailContainer>
              <div>
                {isEditFormOpen && singleVoid !== undefined ? (
                  <>
                    {moveInEdit && (
                      <VoidErrorBoundary>
                        <MoveInEditPanel
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                          singleVoid={singleVoid}
                          isEditFormOpen={isEditFormOpen}
                          toggleEdit={toggleMoveInEdit}
                          moveToggle={moveToggle}
                        ></MoveInEditPanel>
                      </VoidErrorBoundary>
                    )}
                    {landlordEdit && (
                      <VoidErrorBoundary>
                        <LandlordEditPanel
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                          singleVoid={singleVoid}
                          isEditFormOpen={isEditFormOpen}
                          toggleEdit={toggleLandlordEdit}
                          moveToggle={moveToggle}
                          setSelectedOption={setSelectedOption}
                        ></LandlordEditPanel>
                      </VoidErrorBoundary>
                    )}
                    {billPayerEdit && (
                      <VoidErrorBoundary>
                        <BillPayerEditPanel
                          singleVoid={singleVoid}
                          isEditFormOpen={isEditFormOpen}
                          toggleEdit={toggleLandlordEdit}
                          isActionRequired={isActionRequired}
                        ></BillPayerEditPanel>
                      </VoidErrorBoundary>
                    )}
                  </>
                ) : (
                  <>
                    <TenancyDetailsSection>
                      <div>
                        <h3 className="summaryHeading">Summary</h3>
                      </div>

                      <div className="grouped" id="voidOwnerAlt">
                        <div>
                          <h3
                            onClick={() => toggleLandlordEdit()}
                            className="subHeading"
                          >
                            Landlord{" "}
                            <span>
                              <SVG
                                src="/assets/svg/edit.svg"
                                title="icon of a pen"
                              />
                            </span>
                          </h3>

                          {singleVoid?.landlordContactDetails !== null ? (
                            <Address className="">
                              <ul>
                              <li>
                                {singleVoid?.landlordContactDetails?.title !==
                                  null &&
                                singleVoid?.landlordContactDetails?.title ? (
                                  <span>
                                    {singleVoid?.landlordContactDetails?.title.toLocaleLowerCase()}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}

                                {singleVoid?.landlordContactDetails
                                  ?.forename ? (
                                  <span>
                                    {`${singleVoid?.landlordContactDetails?.forename.toLocaleLowerCase()}`}{" "}
                                  </span>
                                ) : (
                                  ""
                                )}

                                {singleVoid?.landlordContactDetails?.surname ? (
                                  <span>
                                    {`${singleVoid?.landlordContactDetails?.surname.toLocaleLowerCase()}`}
                                    <br />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {singleVoid?.landlordContactDetails?.email !==
                                  null &&
                                singleVoid?.landlordContactDetails?.email ? (
                                  <span className="normal">
                                    {singleVoid?.landlordContactDetails?.email}
                                    <br />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                {singleVoid?.landlordContactDetails?.companyName !==
                                  null &&
                                singleVoid?.landlordContactDetails?.companyName ? (
                                  <span>
                                    {singleVoid?.landlordContactDetails?.companyName.toLocaleLowerCase()}
                                    <br />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </li>
                              <li>
                                <span>
                                  {singleVoid?.landlordContactDetails?.housePhone
                                    ? singleVoid?.landlordContactDetails?.housePhone
                                    : singleVoid?.landlordContactDetails?.mobilePhone ? singleVoid?.landlordContactDetails?.mobilePhone : ""}
                                </span>
                              </li>
                              </ul>
                              <ul>
                              <li>
                                {singleVoid?.landlordContactDetails
                                  ?.forwardingAddress !== null &&
                                singleVoid?.landlordContactDetails
                                  ?.forwardingAddress !== undefined ? (
                                  <div>
                                    <span>
                                    {singleVoid.landlordContactDetails
                                            .forwardingAddress?.line1 ? (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.line1?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.houseNumber?.toLowerCase()}{" "}
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.street?.toLowerCase()}
                                              <br />
                                            </span>
                                          )}
                                          {singleVoid.landlordContactDetails
                                            .forwardingAddress?.line2 ? (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.line2?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                            {singleVoid.landlordContactDetails?.forwardingAddress?.city?.toLowerCase()}
                                            <br />
                                          </span>
                                          )}
                                    </span>
                                    <span>
                                      {singleVoid?.landlordContactDetails
                                        ?.forwardingAddress.postcode ? (
                                        <>
                                          {singleVoid?.landlordContactDetails?.forwardingAddress.postcode.toUpperCase()}
                                          <br />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                ) : null}
                              </li>
                              </ul>
                            </Address>
                          ) : (
                            <span>No Landlord details provided</span>
                          )}
                        </div>
                        <div>
                          <h3
                            onClick={() => toggleBillPayerEdit()}
                            className="subHeading"
                          >
                            Bill Payer
                            <span>
                              <SVG
                                src="/assets/svg/edit.svg"
                                title="icon of a pen"
                              />
                            </span>
                          </h3>

                            {singleVoid?.billOwner.toLowerCase() ===
                            "landlord" ? (
                              <>
                                {singleVoid.landlordContactDetails !==
                                  undefined &&
                                singleVoid.landlordContactDetails !== null ? (
                                  <Address>
                                    {singleVoid?.landlordContactDetails
                                      ?.forename !== "" &&
                                    singleVoid?.landlordContactDetails
                                      ?.surname !== "" &&
                                    singleVoid?.landlordContactDetails
                                      ?.email !== "" ? (<>
                                      <ul className="">
                                        <li>
                                          {singleVoid?.landlordContactDetails
                                            ?.title !== null &&
                                          singleVoid?.landlordContactDetails
                                            ?.title ? (
                                            <span>
                                              {
                                                singleVoid
                                                  ?.landlordContactDetails
                                                  ?.title
                                              }{" "}
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          {singleVoid?.landlordContactDetails
                                            ?.forename ? (
                                            <span>
                                              {`${singleVoid?.landlordContactDetails?.forename}`}{" "}
                                            </span>
                                          ) : (
                                            ""
                                          )}

                                          {singleVoid?.landlordContactDetails
                                            ?.surname ? (
                                            <span>
                                              {`${singleVoid?.landlordContactDetails?.surname}`}
                                              <br />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                        <li>
                                          {singleVoid?.landlordContactDetails
                                            ?.email !== null &&
                                          singleVoid?.landlordContactDetails
                                            ?.email ? (
                                            <span className="normal">
                                              {
                                                singleVoid
                                                  ?.landlordContactDetails
                                                  ?.email
                                              }
                                              <br />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                        <li>
                                          {singleVoid?.landlordContactDetails
                                            ?.companyName !== null &&
                                          singleVoid?.landlordContactDetails
                                            ?.companyName ? (
                                            <span>
                                              {
                                                singleVoid
                                                  ?.landlordContactDetails
                                                  ?.companyName
                                              }
                                              <br />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                        <li>
                                          <span>
                                  {singleVoid?.landlordContactDetails?.housePhone
                                    ? singleVoid?.landlordContactDetails?.housePhone
                                    : singleVoid?.landlordContactDetails?.mobilePhone ? singleVoid?.landlordContactDetails?.mobilePhone : ""}
                                          </span>
                                        </li>
                                  
                                  </ul>
                                  <ul>
                                        <li>
                                          {singleVoid?.landlordContactDetails
                                            ?.forwardingAddress !== null &&
                                          singleVoid?.landlordContactDetails
                                            ?.forwardingAddress !==
                                            undefined ? (
                                            <div>
                                              {singleVoid.landlordContactDetails
                                            .forwardingAddress?.line1 ? (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.line1?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.houseNumber?.toLowerCase()}{" "}
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.street?.toLowerCase()}
                                              <br />
                                            </span>
                                          )}
                                          {singleVoid.landlordContactDetails
                                            .forwardingAddress?.line2 ? (
                                            <span>
                                              {singleVoid.landlordContactDetails?.forwardingAddress?.line2?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                            {singleVoid.landlordContactDetails?.forwardingAddress?.city?.toLowerCase()}
                                            <br />
                                          </span>
                                          )}
                                              <span>
                                                {singleVoid
                                                  ?.landlordContactDetails
                                                  ?.forwardingAddress
                                                  .postcode ? (
                                                  <>
                                                    {singleVoid?.landlordContactDetails?.forwardingAddress.postcode.toUpperCase()}
                                                    <br />
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </span>
                                            </div>
                                          ) : null}
                                        </li>
                                      </ul>
                                      </>
                                    ) : (
                                      <span>No Landlord details provided</span>
                                    )}
                                  </Address>
                                ) : (
                                  <span>No Bill Payer details provided</span>
                                )}
                              </>
                            ) : singleVoid?.billOwner.toLowerCase() ===
                              "agency" ? (
                              <>
                                {singleVoid.agentContactDetails !== undefined &&
                                singleVoid.agentContactDetails !== null ? (
                                  <Address>
                                    <ul>
                                    {singleVoid.agentContactDetails
                                      .agencyName !== undefined ? (
                                      <span>
                                        {singleVoid.agentContactDetails.agencyName?.toLowerCase()}
                                        <br />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    <br/>
                                    {singleVoid.agentContactDetails?.title ? (
                                      <span>
                                        {singleVoid.agentContactDetails?.title.toLowerCase()}{" "}
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                    {singleVoid.agentContactDetails?.forename &&
                                    singleVoid.agentContactDetails?.surname ? (
                                      <span>
                                        {singleVoid.agentContactDetails?.forename.toLowerCase()}{" "}
                                        {singleVoid.agentContactDetails?.surname.toLowerCase()}
                                        <br />
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                     {singleVoid.agentContactDetails?.email ? (
                                      <span className="normal">
                                        {singleVoid.agentContactDetails?.email.toLowerCase()}
                                        <br />
                                      </span>
                                    ) : (
                                      ""
                                    )}

                                    {singleVoid.agentContactDetails?.mobilePhone && (
                                      <span>
                                        {singleVoid.agentContactDetails?.mobilePhone}
                                        <br />
                                      </span>
                                    )}
                                    {singleVoid.agentContactDetails?.housePhone && (
                                      <span>
                                        {singleVoid.agentContactDetails?.housePhone}
                                        <br />
                                      </span>
                                    )}
                                    </ul>
                                    <ul>

                                    {singleVoid.agentContactDetails !==
                                      undefined &&
                                      singleVoid.agentContactDetails !== null &&
                                      singleVoid.agentContactDetails
                                        ?.forwardingAddress && (
                                        <>
                                          {singleVoid.agentContactDetails
                                            .forwardingAddress?.line1 ? (
                                            <span>
                                              {singleVoid.agentContactDetails?.forwardingAddress?.line1?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                              {singleVoid.agentContactDetails?.forwardingAddress?.houseNumber?.toLowerCase()}{" "}
                                              {singleVoid.agentContactDetails?.forwardingAddress?.street?.toLowerCase()}
                                              <br />
                                            </span>
                                          )}
                                          {singleVoid.agentContactDetails
                                            .forwardingAddress?.line2 ? (
                                            <span>
                                              {singleVoid.agentContactDetails?.forwardingAddress?.line2?.toLowerCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            <span>
                                            {singleVoid.agentContactDetails?.forwardingAddress?.city?.toLowerCase()}
                                            <br />
                                          </span>
                                          )}
                                  
                                          {singleVoid.agentContactDetails
                                            .forwardingAddress?.postcode ? (
                                            <span>
                                              {singleVoid.agentContactDetails?.forwardingAddress?.postcode?.toUpperCase()}
                                              <br />
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          <br/>
                                        </>
                                      )}
                                    </ul>
                                  </Address>
                                ) : (
                                  <span>No Bill Payer details provided</span>
                                )}
                              </>
                            ) : (
                              <span>No Bill Payer details provided</span>
                            )}
                        </div>
                        {(singleVoid?.agentContactDetails === undefined ||
                          singleVoid?.agentContactDetails === null) &&
                          (singleVoid?.landlordContactDetails === undefined ||
                            singleVoid?.landlordContactDetails === null) && (
                            <div className="UtilityStatusPillContainer">
                              <UtilityStatusPill
                                switchStatus={"Action Required"}>
                                {"Action Required"}
                              </UtilityStatusPill>
                            </div>
                          )}

                          {(singleVoid?.agentContactDetails !== undefined &&
                            singleVoid?.agentContactDetails !== null) &&
                            (singleVoid?.landlordContactDetails !== undefined &&
                              singleVoid?.landlordContactDetails !== null &&
                              ((singleVoid?.agentContactDetails?.forwardingAddress === null || singleVoid?.agentContactDetails?.forwardingAddress === undefined) &&
                              (singleVoid?.landlordContactDetails?.forwardingAddress === null || singleVoid?.landlordContactDetails?.forwardingAddress === undefined))                
                              ) && (
                                <div className="UtilityStatusPillContainerAction" onClick={() => toggleBillPayerEdit()}>
                                  <UtilityStatusPill
                                    switchStatus={"Action Required"}>
                                    {"Action Required"}
                                  </UtilityStatusPill>
                                  <AlertMessage message="No bill forwarding address provided" alertEvent={toggleBillPayerEdit}></AlertMessage>
                                </div>
                            )}
                      </div>
                      <div className="grouped" id="voidOwnerTitle">
                        <MoveOutDate
                          onClick={() => moveToggle(true)}
                          selected={moveOutSelectedNotMoveIn}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                        >
                          <h3>Move-Out Date</h3>
                          <SwitchStatus status={moveOut}>
                            {moveOut}
                          </SwitchStatus>
                        </MoveOutDate>
                        <MoveInDate
                          onClick={() => moveToggle(false)}
                          selected={moveOutSelectedNotMoveIn}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                        >
                          <h3>Move-In Date</h3>
                          <SwitchStatus status={moveIn}>{moveIn}</SwitchStatus>
                        </MoveInDate>
                      </div>
                      <Overview
                        selected={moveOutSelectedNotMoveIn}
                        moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      >
                        <>
                        {
                          sendingData ? <Loading/> : (
                            <>
                            {cancelPromptOpen ? (
                              <CancelPrompt>
                                <div>
                                  <>
                                    Are you sure you want to{" "}
                                    <strong>
                                      cancel this move-{moveOutSelectedNotMoveIn ? "out" : "in"}{" "}
                                      for{" "}
                                      <span id="address">
                                        {singleVoid?.propertyAddress?.line1 ? (
                                          <span>
                                            {singleVoid?.propertyAddress?.line1.toLowerCase()}
                                            ,
                                          </span>
                                        ) : (
                                          ""
                                        )}{" "}
                                        {singleVoid?.propertyAddress?.postcode ? (
                                          <span>
                                            {singleVoid?.propertyAddress?.postcode.toUpperCase()}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </strong>
                                    ? This action will clear details of your move-{moveOutSelectedNotMoveIn ? "out" : "in"}, pending utility notifications will
                                    not be sent and there will be no commissions earned.
                                  </>
                                  {!moveOutSelectedNotMoveIn && (
                                    <label>
                                        <p>Please note, <strong>cancelling this move-in will not make it available to link to a move out on the new Utility Management Dashboard.</strong> if this move-in is proceeding and you want to notify the utility providers automatically we recommend completing it here.</p>
                                    </label>
                                  )}
                                  <label> 
                                    <p><strong>Confirm cancellation of Move-{moveOutSelectedNotMoveIn ? "Out" : "In"}</strong></p>
                                    <ToggleSwitch
                                      toggle={() => setConfirmMoveCancel(!confirmMoveCancel)}
                                      initVal={confirmMoveCancel}>
                                    </ToggleSwitch>
                                  </label>
                                </div>
                                <div id="cancelButtonContainer">
                                  <Button disabled={!confirmMoveCancel} onClick={() => resetMove(moveOutSelectedNotMoveIn)}>
                                    Cancel Move-{moveOutSelectedNotMoveIn ? "Out" : "In"}
                                  </Button>
    
                                  <AlternateButton onClick={() => cancelPrompt()}>
                                    Back
                                  </AlternateButton>
                                </div>
                              </CancelPrompt>
                            ) :  
                              <>
                                <h3
                                  id="overview"
                                  onClick={() => toggleMoveInEdit()}
                                >
                                  Overview{" "}
                                  <span id="tenantSubTitle">
                                    <span>
                                      <SVG
                                        src="/assets/svg/edit.svg"
                                        title="icon of a pen"
                                      />
                                    </span>
                                  </span>
                                </h3>
    
                                <div>
                                  <div id="tenantsList">
                                    <span id="tenantSubTitle">
                                      <strong>
                                        {moveOutSelectedNotMoveIn
                                          ? "Outgoing Tenants"
                                          : "Incoming Tenants"}
                                      </strong>
                                    </span>
                                    {moveOutSelectedNotMoveIn ? (
                                      singleVoid?.tenantsMovingOut !== null &&
                                      singleVoid?.tenantsMovingOut !== undefined &&
                                      singleVoid?.tenantsMovingOut?.length > 0 ? (
                                        <div>
                                          {singleVoid?.tenantsMovingOut.map(
                                            (tenant, index: number) => {
                                              return (
                                                <div key={index}>
                                                  {" "}
                                                  {tenant.contactDetails !== null &&
                                                  tenant.contactDetails !==
                                                    undefined ? (
                                                    <span>
                                                      {tenant.contactDetails
                                                        .title !== null &&
                                                      tenant.contactDetails
                                                        .title !== undefined
                                                        ? `${tenant.contactDetails.title} `
                                                        : ""}{" "}
                                                      {tenant.contactDetails
                                                        .forename !== null &&
                                                      tenant.contactDetails
                                                        .forename !== undefined
                                                        ? `${tenant.contactDetails.forename} `
                                                        : ""}
                                                      {tenant.contactDetails
                                                        .surname !== null &&
                                                      tenant.contactDetails
                                                        .surname !== undefined
                                                        ? tenant.contactDetails
                                                            .surname
                                                        : ""}
                                                    </span>
                                                  ) : (
                                                    <strong>
                                                      <span>
                                                        No outgoing tenants provided
                                                      </span>
                                                    </strong>
                                                  )}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      ) : (
                                        <strong>
                                          <span>No outgoing tenants provided</span>
                                        </strong>
                                      )
                                    ) : singleVoid?.tenantsMovingIn !== null &&
                                      singleVoid?.tenantsMovingIn !== undefined &&
                                      singleVoid?.tenantsMovingIn?.length > 0 ? (
                                      <div>
                                        {singleVoid?.tenantsMovingIn.map(
                                          (tenant, index) => {
                                            return (
                                              <div key={index}>
                                                {" "}
                                                {tenant.contactDetails !== null &&
                                                  tenant.contactDetails !==
                                                    undefined && (
                                                    <span>
                                                      {tenant.contactDetails
                                                        .title !== null &&
                                                      tenant.contactDetails
                                                        .title !== undefined
                                                        ? `${tenant.contactDetails.title} `
                                                        : ""}{" "}
                                                      {tenant.contactDetails
                                                        .forename !== null &&
                                                      tenant.contactDetails
                                                        .forename !== undefined
                                                        ? `${tenant.contactDetails.forename} `
                                                        : ""}
                                                      {tenant.contactDetails
                                                        .surname !== null &&
                                                      tenant.contactDetails
                                                        .surname !== undefined
                                                        ? tenant.contactDetails
                                                            .surname
                                                        : ""}
                                                    </span>
                                                  )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      <strong>
                                        <span>No incoming tenants provided</span>
                                      </strong>
                                    )}
                                  </div>
                                  <div id="switchDates">
                                    <span>
                                      <span id="tenantSubTitle">
                                        <strong>
                                          {moveOutSelectedNotMoveIn
                                            ? "Vacant from"
                                            : "Occupied on"}
                                          :
                                        </strong>
                                      </span>
                                      {moveOutSelectedNotMoveIn ? (
                                        singleVoid?.moveOut?.moveDate &&
                                        singleVoid?.moveOut?.moveDate !==
                                          "1970-01-01T00:00:00+00:00" &&
                                        singleVoid?.moveOut?.moveDate !==
                                          "0001-01-01T00:00:00+00:00" ? (
                                          <span>
                                            {format(new Date(singleVoid?.moveOut?.moveDate), "PPPP")}
                                          </span>
                                        ) : (
                                          <>
                                            <AlertMessage alertEvent={toggleMoveInEdit} message={"No Move-Out Date provided"}></AlertMessage>
                                            <div className="UtilityStatusPillContainer">
                                              <UtilityStatusPill
                                                switchStatus={"Action Required"}
                                                onClick={() => {
                                                  toggleMoveInEdit();
                                                }}
                                              >
                                                {"Action Required"}
                                              </UtilityStatusPill>
                                            </div>
                                          </>
                                        )
                                      ) : singleVoid?.moveIn?.moveDate &&
                                        singleVoid?.moveIn?.moveDate !==
                                          "1970-01-01T00:00:00+00:00" &&
                                        singleVoid?.moveIn?.moveDate !==
                                          "0001-01-01T00:00:00+00:00" ? (
                                        <span>
                                          {format(new Date(singleVoid?.moveIn?.moveDate), "PPPP")}
                                        </span>
                                      ) : (
                                        <>
                                          <AlertMessage alertEvent={toggleMoveInEdit} message={"No Move-In Date provided"}></AlertMessage>
                                          <div className="UtilityStatusPillContainer">
                                            <UtilityStatusPill
                                              switchStatus={"Action Required"}
                                              onClick={() => {
                                                toggleMoveInEdit();
                                              }}
                                            >
                                              {"Action Required"}
                                            </UtilityStatusPill>
                                          </div>
                                        </>
                                      )}
                                    </span>
                                  </div>
                                  <div id="cancelContainer">
                                  {singleVoid?.moveIn !== null && !moveOutSelectedNotMoveIn && singleVoid?.moveIn.moveDate !== undefined && 
                                  singleVoid?.moveIn.moveDate !== null && singleVoid?.moveIn.moveDate !== "0001-01-01T00:00:00+00:00" && 
                                   (singleVoid?.moveIn.notificationsToDisplay.water.status === "notSent" || 
                                   singleVoid?.moveIn.notificationsToDisplay.water.status === "notRequired") && 
                                   (singleVoid?.moveIn.notificationsToDisplay.gas.status === "notSent" ||
                                   singleVoid?.moveIn.notificationsToDisplay.gas.status === "notRequired") && 
                                   (singleVoid?.moveIn.notificationsToDisplay.council.status === "notSent" ||
                                   singleVoid?.moveIn.notificationsToDisplay.council.status === "notRequired") && 
                                   (singleVoid?.moveIn.notificationsToDisplay.electricity.status === "notSent" ||
                                   singleVoid?.moveIn.notificationsToDisplay.electricity.status === "notRequired") &&
                                   (
                                    <>
                                    <AlternateButton onClick={() => cancelPrompt()}>
                                    Cancel Move-In
                                    </AlternateButton>
                                    </>
                                  )}
                                  {singleVoid?.moveOut !== null && moveOutSelectedNotMoveIn && singleVoid?.moveOut.moveDate !== undefined && 
                                  singleVoid?.moveOut.moveDate !== null && singleVoid?.moveOut.moveDate !== "0001-01-01T00:00:00+00:00" &&  
                                  (singleVoid?.moveOut.notificationsToDisplay.water.status === "notSent" || 
                                   singleVoid?.moveOut.notificationsToDisplay.water.status === "notRequired") && 
                                   (singleVoid?.moveOut.notificationsToDisplay.gas.status === "notSent" ||
                                   singleVoid?.moveOut.notificationsToDisplay.gas.status === "notRequired") && 
                                   (singleVoid?.moveOut.notificationsToDisplay.council.status === "notSent" ||
                                   singleVoid?.moveOut.notificationsToDisplay.council.status === "notRequired") && 
                                   (singleVoid?.moveOut.notificationsToDisplay.electricity.status === "notSent" ||
                                   singleVoid?.moveOut.notificationsToDisplay.electricity.status === "notRequired") && (
                                    <>
                                    <AlternateButton onClick={() => cancelPrompt()}>
                                      Cancel Move-Out
                                    </AlternateButton>
                                    </>
                                  )}
                                  </div>
                                </div>
                              </> 
                            }                 
                            </>
                          ) 
                        }
                        </>
                      </Overview>
                      <Notifications selected={moveOutSelectedNotMoveIn}>
                        <h3 className="notificationsHeading">
                          Notifications (Existing Supplier)
                        </h3>
                        {singleVoid !== undefined &&
                          (moveOutSelectedNotMoveIn ? (
                            <VoidMoveStatusComponent
                              type={"notification"}
                              showDate={false}
                              isMoveOut={moveOutSelectedNotMoveIn}
                              propertyMove={singleVoid.moveOut}
                              data-cy="moveOutComponent"
                            ></VoidMoveStatusComponent>
                          ) : (
                            <VoidMoveStatusComponent
                            type={"notification"}
                            showDate={false}
                            isMoveOut={moveOutSelectedNotMoveIn}
                            propertyMove={singleVoid.moveIn}
                            data-cy="moveOutComponent"
                          ></VoidMoveStatusComponent>
                          ))}
                      </Notifications>
                      <div className="grouped" id="voidOwnerTitle">
                        <MoveOutDate
                          onClick={() => moveToggle(true)}
                          selected={moveOutSelectedNotMoveIn}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                        >
                          <h3>Meter Readings - Move-Out</h3>
                          {((
                            singleVoid?.moveOut !== null &&
                            singleVoid?.moveOut !== undefined &&
                            (singleVoid?.moveOut.gasMeterReading === undefined ||
                            singleVoid?.moveOut.gasMeterReading === null) && singleVoid?.moveOut?.notificationsToDisplay !== null && singleVoid?.moveOut?.notificationsToDisplay
                              .gas.status !== "cancelled" && moveOutGasReadRequired) ||

                              (singleVoid?.moveOut !== null &&
                              singleVoid?.moveOut !== undefined &&
                              (singleVoid?.moveOut.electricityDayMeterReading === undefined || 
                              singleVoid?.moveOut.electricityDayMeterReading === null) && 
                              singleVoid?.moveOut?.notificationsToDisplay !== null && 
                              singleVoid?.moveOut?.notificationsToDisplay.electricity.status !== "cancelled" && moveOutElecReadRequired ))
                           && (
                            <div
                              className="UtilityStatusPillContainer"
                              id="test"
                            >
                              <UtilityStatusPill
                                switchStatus={"Action Required"}
                              >
                                {"Action Required"}
                              </UtilityStatusPill>
                            </div>
                          )}
                        </MoveOutDate>
                        <MoveInDate
                          onClick={() => moveToggle(false)}
                          selected={moveOutSelectedNotMoveIn}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                        >


                          <h3>Meter Readings - Move-In</h3>
                          {((
                            singleVoid?.moveIn !== null &&
                            singleVoid?.moveIn !== undefined &&
                            (singleVoid?.moveIn.gasMeterReading === undefined ||
                            singleVoid?.moveIn.gasMeterReading === null) && singleVoid?.moveIn?.notificationsToDisplay !== null && singleVoid?.moveIn?.notificationsToDisplay
                              .gas.status !== "cancelled" && moveInGasReadRequired) ||

                              (singleVoid?.moveIn !== null &&
                              singleVoid?.moveIn !== undefined &&
                              (singleVoid?.moveIn.electricityDayMeterReading === undefined || 
                              singleVoid?.moveIn.electricityDayMeterReading === null) && 
                              singleVoid?.moveIn?.notificationsToDisplay !== null && 
                              singleVoid?.moveIn?.notificationsToDisplay.electricity.status !== "cancelled" && moveInElecReadRequired ))
                          && (
                            <div className="UtilityStatusPillContainer">
                              <UtilityStatusPill
                                switchStatus={"Action Required"}
                              >
                                {"Action Required"}
                              </UtilityStatusPill>
                            </div>
                          )}
                        </MoveInDate>
                      </div>
                      <Meters id="test" moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                        <SubmitMeterErrorBoundary>
                          {!isEditFormOpen && (
                            <>
                              <SubmitMeterRead
                                singleVoid={singleVoid}
                                propertyInfo={propertyInfo}
                                fuelType={fuelType}
                                readSubmitted={readSubmitted}
                                setFuelType={setFuelType}
                                setReadSubmitted={setReadSubmitted}
                                setPropertyInfo={setPropertyInfo}
                                moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                                setSelectedOption={setSelectedOption}
                                moveToggle={moveToggle}
                                toggleMenu={toggleMenu}     
                                moveInElecReadRequired={moveInElecReadRequired}            
                                moveInGasReadRequired={moveInGasReadRequired}            
                                moveInWaterReadRequired={moveInWaterReadRequired}            
                                moveOutElecReadRequired={moveOutElecReadRequired}            
                                moveOutGasReadRequired={moveOutGasReadRequired}       
                                moveOutWaterReadRequired={moveOutWaterReadRequired}   
                              ></SubmitMeterRead>
                            </>
                          )}
                        </SubmitMeterErrorBoundary>
                      </Meters>
                    </TenancyDetailsSection>
                  </>
                )}
              </div>
            </VoidAddressDetailContainer>
            </ContentWrapper>
          </DetailContainer>
        </>
    )}
    </>
  )
};

export default VoidAddressDetailScreen;
