import { Price, Store, Department } from "../types/Store";

export function getImg(service: string = "placeholder"): string {
  service = service.toLowerCase();
  if (service.trim() === "") {
    service = "placeholder";
  }
  service = service.split(" ").join("-");
  return `/assets/svg/${service}.svg`;
}

export function formatCurrency(price: Price): string {
  if (!price.amount) return "";

  let symbol: string = "£";

  switch (price.currency) {
    case "":
      symbol = "";
      break;
    case "GBP":
      symbol = "£";
      break;
    case "USD":
      symbol = "$";
      break;
  }

  return `${symbol}${price.amount}`;
}

export function formatDate(date: string, dateFormat: string = "short"): string {
  const newDate = new Date(date);
  // @ts-ignore
  return `${new Intl.DateTimeFormat({ format: dateFormat }).format(newDate)}`;
}

export function isCustomShop(route: string) {
  let formattedRoute = "";
  if (!route.includes("-")) {
    formattedRoute = route.charAt(0).toUpperCase();
  } else {
    const parts = route.split("-");
    parts.map((el, index) => {
      parts[index] = parts[index].charAt(0).toUpperCase();
    });

    console.log(formattedRoute);
    return true;
  }
  const tryRequire = () => {
    try {
      return require(`../views/CustomStores/${formattedRoute}`);
    } catch (err) {
      return null;
    }
  };
}

export function updateFilteredStores(
  stores: Store[],
  setFilteredStores: Function,
  filterTerm: string,
  type: string | undefined
): void {
  let modifiedStores = [];
  if (!stores) return;
  // return everything

  if (type) {
    modifiedStores = stores.filter((str) => {
      if (str.type === type || !str.type) {
        return str;
      }
    });
  } else {
    modifiedStores = [...stores];
  }

  if (filterTerm === "all") {
    let tempStores: any = [];
    modifiedStores.forEach((str: Store, index: number) => {
      str.departments.forEach((dep: Department, i: number) => {
        const data = Object.assign(dep, str);
        tempStores.push(data);
      });
    });
    filterAndOrder(tempStores, setFilteredStores);
  } else {
    //  return only departmentment selected
    let tempStores: any = [];
    modifiedStores.forEach((str: Store, index: number) => {
      str.departments.forEach((dep: Department, i: number) => {
        const data = Object.assign(dep, str);
        tempStores.push(data);
      });
    });

    filterAndOrder(tempStores, setFilteredStores, filterTerm);
  }
}

function filterAndOrder(
  tempStores: Store[],
  setFilteredStores: Function,
  filterTerm?: string
) {
  tempStores.sort(function (a: Store, b: Store) {
    if (a.departmentName < b.departmentName) {
      return -1;
    }
    if (a.departmentName > b.departmentName) {
      return 1;
    }
    return 0;
  });

  tempStores = tempStores.reduce((r: any, a: any) => {
    r[a.departmentName] = [...(r[a.departmentName] || []), a];
    return r;
  }, {});
  if (filterTerm) {
    let key = filterTerm;
    key = key.charAt(0).toUpperCase() + key.slice(1).toLowerCase();
    // @ts-ignore
    setFilteredStores({ key: tempStores[key] });
  } else {
    setFilteredStores(tempStores);
  }
}
