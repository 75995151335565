import React from "react";
import styled from "../common/themes";
import TrustPilot from "../components/TrustPilotWidget";
interface Props {
  id?: string;
}

const FooterContainer = styled.div`
  position: relative;
  margin: 25px 0;
  z-index: 0 !important;
  height: ${(props) => (props.theme.name === "bot" ? "30px" : "")};
`;

const Title = styled.div`
  font-family: "Montserrat", sans-serif;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  display: ${(props) => (props.theme.name === "bot" ? "none" : "")};
  span {
    color: ${(props) => props.theme.color.mainHighlight};
  }
  h2 {
    color: ${(props) => props.theme.color.mainHighlight};
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const Emoji = styled.img`
  margin-left: 5px;
  max-width: 100px;
`;

const FooterChildren = styled.div``;

const TrustPilotAndLogoContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

const Footer: React.FC<Props> = ({ children }) => {
  return (
    <FooterContainer>
      <TrustPilotAndLogoContainer>
        <TrustPilot />
        <Title onClick={(e) => (window.location.href = "/")}>
          <h2>
            powered by
            <Emoji src="/assets/svg/vouchlogo.svg" />
          </h2>
        </Title>
      </TrustPilotAndLogoContainer>
      <FooterChildren>{children}</FooterChildren>
    </FooterContainer>
  );
};

export default Footer;
