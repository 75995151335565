import React from "react";
import styled from "styled-components";
import { QuotePricing } from "../Reposit";

interface Props {
  setOption: Function;
  option: string;
  optionNum: string;
  optionHeader: string;
  bill: QuotePricing;
}

const OptionSelected = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h3 {
    margin-right: 5px;
  }
`;

const BillBreakdownItem = styled.div<{ selected: boolean; right?: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding: 10px;
  justify-items: ${(props) => (props.right ? "flex-end" : "flex-start")};
  h3 {
    color: #4a4a4a;
    text-align: left;
    font-weight: 400;
  }

  #bill-key {
    font-weight: bold;
  }
`;

const RepositCardContainer = styled.div<{ selected: boolean }>`
  background: ${(props) => (props.selected ? "#0075dc" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "white" : "")};
  border: 7px solid;
  border-radius: 5px;
  border-color: ${(props) => (props.selected ? "#0075dc" : "#f2f2f2")};
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 20px rgb(0 0 0 / 10%);
  &:hover {
    cursor: pointer;
  }
`;

const RepositCardHeader = styled.div`
  padding: 10px;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 450px) {
    h1 {
      font-size: 1.5rem;
    }
  }
`;

const RepositBlub = styled.div<{ selected: boolean }>`
  background: white;

  h2 {
    color: ${(props) => (props.selected ? "#0075dc" : "#4a4a4a")};
    padding: 10px;
  }
`;

const RepositCard: React.FC<Props> = ({
  setOption,
  option,
  optionNum,
  optionHeader,
  bill,
}) => {
  return (
    <RepositCardContainer
      selected={option === optionNum}
      onClick={() => setOption(optionNum)}
    >
      <RepositCardHeader className="side-by-side-flex">
        <h1>Option {optionNum}</h1>
        {option === optionNum && (
          <OptionSelected>
            <h3>Selected</h3>
            <img alt="selected" src="/assets/svg/circle-tick.svg" />
          </OptionSelected>
        )}
      </RepositCardHeader>
      <RepositBlub selected={option === optionNum}>
        <h2>{optionHeader}</h2>
        {bill &&
          bill.charges.map((item, index) => (
            <BillBreakdownItem key={index} selected={option === optionNum}>
              <h3 id="bill-key">{item.key}</h3>
              <h3>
                {item.value.includes("-") ? "" : "£"}
                {item.value.replace("-", "- £")}
              </h3>
            </BillBreakdownItem>
          ))}

        {bill?.finalPrice && (
          <BillBreakdownItem selected={option === optionNum} right>
            <h3 id="bill-key">Total</h3>
            <h3>£ {bill.finalPrice}</h3>
          </BillBreakdownItem>
        )}
      </RepositBlub>
    </RepositCardContainer>
  );
};

export default RepositCard;
