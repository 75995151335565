import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "../../common/styles";
import ctx, { ctxType } from "../../context/ShopContext";
import { Void } from "../../types/Void";
import AddLandlordStep from "./AddLandlordStep";
// import AddMeterStep from "./AddMeterStep";
import AddNotificationsStep from "./AddNotificationsStep";
// import AddPropertyStep from "./AddPropertyStep";
import AddTenants from "./AddTenantsStep";
// import SummaryStep from "./SummaryStep";

interface Props {
  setIsAddVoidOpen: Function;
}

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
`;

const ReturnText = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: #34bebd;
    margin-right: auto;
    display:block;
    text-align: right;
`;

const VoidPropertyCreator: React.FC<Props> = ({ setIsAddVoidOpen }) => {
  const [step, setStep] = useState(1);
  const [validInput, setValidInput] = useState(false);
  const [propertyDetails, setPropertyDetails] = useState({
    city: "",
    flatNumber: "",
    formattedAddress: "",
    houseName: "",
    houseNumber: "",
    id: "",
    postCode: "",
    street: "",
  });
  const [notificationDetails, setNotificationDetails] = useState({
water:true,
gas:true,
councilTax:true,
electric:true
  });
  const [tenantDetails, setTenantDetails] = useState({
    firstName: "" ,
      lastName: "" ,
      email: "" ,
      moveOutDate: "", 
      moveInDate: ""
  });
  const [landlordDetails, setLandlordDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    companyName: "",
    phoneNumber: "",
  });

  function updateStep(e: any): void {
    if (step < 10) {
      setStep(step + 1);
      setValidInput(false);
    } else {
      setStep(step - 1);
    }
  }


  function getPropertyStepDetails(info: any) {
    setPropertyDetails(info);
  }

  function getLandlordStepDetails(info: any) {
    setLandlordDetails(info);
  }

  function getTenantStepDetails(info: any) {
    setTenantDetails(info);
  }

  function getNotificationStepDetails(info: any) {
    setNotificationDetails(info);
  }


  // const finalThing = {
  //   customerIdentifier:
  //   propertyAddress: {
  //     isFlat: false,
  //     houseNumber: propertyDetails?.houseNumber,
  //     street: propertyDetails?.street,
  //     city: propertyDetails?.city,
  //     addressType: "current",
  //     normalizedLine1: propertyDetails?.formattedAddress,
  //     line1: `${propertyDetails?.houseNumber} ${propertyDetails?.street}`,
  //     line2: propertyDetails?.city,
  //     postcode: propertyDetails?.postCode,
  //     latitude: 0,
  //     longitude: 0,
  //   },
  //   landlordContactDetails: {
  //     companyName: landlordDetails.companyName,
  //     email: landlordDetails.email,
  //     title: landlordDetails.title,
  //     forename: landlordDetails.firstName,
  //     surname: landlordDetails.lastName,
  //     phoneNumber: landlordDetails.phoneNumber,
  //     forwardingAddress: {
  //       addressType: "",
  //       city: "",
  //       id: "",
  //       line1: "",
  //       line2: "",
  //       name: "",
  //       normalizedLine1: "",
  //       postcode: "",
  //       udprn: "",
  //     },
  //   },
  //   agentContactDetails: {
  //     agencyName: agencyData && agencyData.name ? agencyData.name : "",
  //     email: agencyData && agencyData.email ? agencyData.email : "",
  //     title: "",
  //     forename: "",
  //     surname: "",
  //     phoneNumber: agencyData && agencyData.phone ? agencyData.phone : "",
  //     forwardingAddress: {
  //       addressType: "",
  //       city: "",
  //       id: "",
  //       line1: "",
  //       line2: "",
  //       name: "",
  //       normalizedLine1: "",
  //       postcode: "",
  //       udprn: "",
  //     },
  //   },
  //   billingDetails: {
  //     billingAddress: {
  //       addressType: "",
  //       city: "",
  //       id: "",
  //       line1: "",
  //       line2: "",
  //       name: "",
  //       normalizedLine1: "",
  //       postcode: "",
  //       udprn: "",
  //     },
  //     isLandlordResponsibility: false,
  //   },
  //   agencyId: agencyData && agencyData.agencyId ? agencyData.agencyId : "",
  //   holder: {
  //     email: "",
  //     title: "",
  //     forename: "",
  //     surname: "",
  //     name: "",
  //     phoneNumber: "",
  //     forwardingAddress: {
  //       addressType: "",
  //       city: "",
  //       id: "",
  //       line1: "",
  //       line2: "",
  //       name: "",
  //       normalizedLine1: "",
  //       postcode: "",
  //       udprn: "",
  //     },
  //   },
  //   updatedAt: new Date(Date.now()).toISOString().slice(0, 19),
  //   id: `${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 10000)}-${Math.floor(Math.random() * 10000)}`,
  //   meterDetails: {
  //     mpan: "",
  //     mprn: "",
  //     economy7: false,
  //     electricityPrepayed: false,
  //     gasPrepayed: false,
  //     electricityReadings: [],
  //     gasReadings: [],
  //   },
  //   milestones: "",
  //   moveIn: {
  //     cotId:"",
  //     commissionId:"",
  //     notifications: {
  //       gas: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:""
  //       },
  //       water: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:""
  //       },
  //       council: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:""
  //       },
  //       electricity: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:""
  //       },
  //     },
  //     moveDate: tenantDetails.moveInDate !== "" ? tenantDetails.moveInDate : new Date(+0).toISOString().slice(0, 19),
  //     sentToProviderDate: new Date(Date.now()).toISOString().slice(0, 19),
  //     sentToPodioDate: new Date(Date.now()).toISOString().slice(0, 19),
  //     podioId: "",
  //     gasSwitchStatus:{
  //       isGain: isGainType,
  //       isSupplied: isOnSupply,
  //       switchStatus: "pending"
  //     }, 
  //     electricSwitchStatus:{
  //       isGain: isGainType,
  //       isSupplied: isOnSupply,
  //       switchStatus: "pending"
  //     }
  //   },
  //   moveOut: {
  //     combinedId:"", 
  //     electricityId:"", 
  //     gasId:"", 
  //     commissionId:"",
  //     notifications: {
  //       gas: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:"pending"
  //       },
  //       water: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:"pending"
  //       },
  //       council: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:"pending"
  //       },
  //       electricity: {
  //         lastUpdatedDateTime:"",
  //         provider:"",
  //         status:"pending"
  //       },
  //     },
  //     moveDate: tenantDetails.moveOutDate !== "" ? tenantDetails.moveOutDate : new Date(+0).toISOString().slice(0, 19),
  //     sentToProviderDate: new Date(Date.now()).toISOString().slice(0, 19),
  //     sentToPodioDate: new Date(Date.now()).toISOString().slice(0, 19),
  //     podioId: "",
  //     gasSwitchStatus:{
  //       isGain: isGainType,
  //       isSupplied: isOnSupply,
  //       switchStatus: "pending"
  //     }, 
  //     electricSwitchStatus:{
  //       isGain: isGainType,
  //       isSupplied: isOnSupply,
  //       switchStatus: "pending"
  //     }
  //   },
  //   notificationStatus: {
  //     gas: {
  //       lastUpdatedDateTime:"",
  //       provider:"",
  //       status:"pending"
  //     },
  //     water: {
  //       lastUpdatedDateTime:"",
  //       provider:"",
  //       status:"pending"
  //     },
  //     council: {
  //       lastUpdatedDateTime:"",
  //       provider:"",
  //       status:"pending"
  //     },
  //     electricity: {
  //       lastUpdatedDateTime:"",
  //       provider:"",
  //       status:"pending"
  //     },

  //   },
  //   provider: "scottishPower",
  //   fuelStatus: "",
  //   switchStatus: "pending",
  //   version: 0,
  //   foundMatchedAddress:true, 
  //   addressIsSupportedByScottishPower:true, 
  //   etag:""
  // };



  async function createVoid(voidDetails: Void) {
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(voidDetails),
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      console.log(data);
    }
  }
 

  return (
    <Container>
      {/* <AddPropertyStep
        // getPropertyStepDetails={getPropertyStepDetails}
        setValidInput={setValidInput}
        step={step}
      /> */}
      <AddLandlordStep setLandlordDetails={getLandlordStepDetails} setValidInput={setValidInput} step={step} />
      <AddTenants getTenantStepDetails={getTenantStepDetails} setValidInput={setValidInput} step={step} />
      {/* <AddMeterStep setValidInput={setValidInput} step={step} /> */}
      <AddNotificationsStep setNotificationDetails={getNotificationStepDetails} setValidInput={setValidInput} step={step} />
      {/* <SummaryStep submitVoid={createVoid} createdVoid={finalThing} setValidInput={setValidInput} step={step} /> */}

      <div>
        {step === 5 ? null :
          <Button
            name="continue"
            id="continue"
            type="button"
            disabled={!validInput}
            onClick={updateStep}
          >
            Continue
          </Button>
        }
      </div>
      <ReturnText
        onClick={() => {
          setIsAddVoidOpen(false);
        }}
      >
        Return to Void Portal
      </ReturnText>
    </Container>
  );
};

export default VoidPropertyCreator;
