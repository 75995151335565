import React, { useMemo } from "react";
import ShopCard from "../components/ShopCard";
import DealCard from "../components/DealCard";
import { Store, Deal } from "../types/Store";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../common/styles";
import { Mixpanel } from "../insights/mixpanel";

interface Props {
  stores: Store[];
  category?: string;
  isDealBreakdown?: boolean;
}

const ShopCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: flex-end;
  grid-gap: 20px;

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const StoreLogo = styled.img`
  max-height: 50px;
  margin: 10px 0;
`;

const NoShopItems = styled.div`
  text-align: center;
  margin: 50px;

  img {
    max-width: 250px;
  }
`;

const CTAContainer = styled.div`
  text-align: center;
  margin: 0 auto;

  p {
    text-align: left;
    font-size: 10px;
    padding: 20px;
    margin: 0;
    color: white;
    text-align: justify;
    padding-top: 0;
  }
`;

const CardPanel: React.FC<Props> = ({
  stores = [],
  category,
  isDealBreakdown = false,
}) => {
  const numStoresUnlocked = stores.filter(({ locked }) => locked !== true);

  const allDeals = useMemo(() => {
    let foundDeals: Deal[] = [];
    stores.forEach((store) => {
      if (store.deals) {
        foundDeals = foundDeals.concat([...store.deals]);
      }
    });
    return foundDeals;
  }, [stores]);

  const tag = useMemo(() => {
    if (!stores || stores.length === 0) return null;
    return stores[0]?.departmentName;
  }, [stores]);

  function getQuote() {
    Mixpanel.track("ScottishPower.StartJourney");
  }

  if (numStoresUnlocked.length === 0) {
    return null;
  }

  if (!stores) {
    return (
      <NoShopItems>
        <img src="/assets/svg/shop.svg" alt="" />
        <h3>Vouch {category} stores coming soon!</h3>
      </NoShopItems>
    );
  }

  if (isDealBreakdown && allDeals.length !== 0) {
    return (
      <>
        <h2>Popular {tag} Deals</h2>
        {stores.map(
          ({ deals, departmentName, storeName, departments, logoUrl }) => (
            <>
              <StoreLogo src={logoUrl} alt="" />
              <ShopCardsContainer>
                {deals &&
                  deals.map((deal, index) => (
                    <DealCard key={index}
                      deal={deal}
                      department={tag || departmentName}
                      store={storeName}
                      departmentObj={departments[0]}
                    >
                      {storeName.toLowerCase() === "scottish power" && (
                        <CTAContainer>
                          <Link to="/scottish-power/energy/quote">
                            <Button onClick={() => getQuote()}>
                              Get a quote
                            </Button>
                          </Link>
                          <div>
                            <p>
                              We&apos;ve teamed up with ScottishPower and can set up
                              your new home on a fixed price tariff that could
                              also provide 100% green electricity.
                            </p>
                          </div>
                        </CTAContainer>
                      )}
                    </DealCard>
                  ))}
              </ShopCardsContainer>
            </>
          )
        )}
      </>
    );
  }

  return (
    <>
      <h2 id="capitalise">{tag}</h2>
      <ShopCardsContainer key={tag}>
        {stores.map((str: Store, index: number) =>
          str.locked ? null : (
            <ShopCard
              store={str}
              key={index}
              fullWidth={stores.length === 1 ? true : false}
            />
          )
        )}
      </ShopCardsContainer>
    </>
  );
};

export default CardPanel;
