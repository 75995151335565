import React, { useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { BannerBackground, PageWrapper } from "../../common/styles";
// types
import { Store } from "../../types/Store";
// components
import Footer from "../../components/Footer";
import StoreHeader from "../../components/StoreHeader";
import { useParams } from "react-router-dom";
import RPIForm from "./RPIForm";

interface Props {
  store: Store[];
}

const StoreContainer = styled(BannerBackground)`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  h1 {
    color: white;
    padding: 20px;
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

const MainPanel = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 15px;
`;

const GoodlordRPI: React.FC<Props> = ({ store }) => {
  const { setAgencyData, setPropertyData } = useContext(ctx);
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (!token) return;
    const reqBody = {
      query: `query($token: String!){
        getStoreConfig(token: $token){
            agencyData{
              customerResourceId
              name
              email
              phone
            }
            propertyData{
              name
              line1
              line2
              city
              postcode
              moveIn
              monthlyRent
            } 
              }
            }`,
      variables: {
        token,
      },
    };

    console.log("calling api");

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {        
        const { agencyData, propertyData } = resData.data.getStoreConfig;

        console.log(resData);
        if (agencyData) {
          setAgencyData(agencyData);
        }

        if (propertyData) {
          setPropertyData(propertyData);
        }
      })
      .catch((err) => console.log(err));
  }, [token]);

  if (!store) {
    return <div>Loading...</div>;
  }
 
  return (
    <PageWrapper center>
      <StoreContainer
        bannerUrl={"https://cdn-v2.vouch.co.uk/tcli/department-banner.jpg"}
      >
        <MainStoreBanner>
          <StoreHeader
            storeLogo={
              "https://jobs.mindtheproduct.com/wp-content/uploads/job-manager-uploads/company_logo/2020/06/Goodlord_Logo_green_square-1.png"
            }
            department={"Rent Protection Insurance"}
          />
        </MainStoreBanner>
        <MainPanel>
          <RPIForm></RPIForm>
        </MainPanel>
        <Footer></Footer>
      </StoreContainer>
    </PageWrapper>
  );
};

export default GoodlordRPI;
