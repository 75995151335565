import React, { useState, useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  PageWrapper,
  Button,
  Colours,
} from "../../common/styles";
// types
import { Store, Department } from "../../types/Store";
// hooks
import { useAuthenticated } from "../../hooks/hooks";
// components
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import UserHandler from "../../components/modals/UserHandler";
import ExistingUser from "../../components/modals/ExistingUser";
import StoreHeader from "../../components/StoreHeader";

interface Props {
  stores: Store[];
}

const StoreContainer = styled.div<{ bannerUrl: string }>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &:after {
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 700px;
    position: fixed;
    background-position: center;

    @media (max-width: 320px) {
      height: 500px;
    }
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

const TenantsInsurancePageWrapper = styled(PageWrapper)`
  padding: 0;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  margin-bottom: 100px;

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

interface KeyAndValue {
  key: string;
  value: string;
}

export interface Quote {
  tenantFee: number | null;
  totalFee: number | null;
  headCount: number | null;
  ppm: number | null;
  savings: number | null;
}

export interface QuotePricing {
  charges: KeyAndValue[];
  finalPrice: string;
}

const TitleContainer = styled.div`
justify-content: stretch;
text-align: left;
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;

#storeName {
  background: ${Colours.teal};
  color: white;
  padding: 20px;
  max-width: 80%;
  margin-bottom: 20px;
}
#departmentDescription{
  padding: 20px;
  background-color: ${Colours.blue};
  max-width: 80%;
  h2{
    color: ${Colours.white};
  }
}
`;

const Blurb = styled.div`
  h1 {
    color: white;
  }
`;
const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 10px;
`;

const BlurbContainer = styled.div`
  padding: 40px;
  border-radius: 20px;
  background: ${Colours.blue};
  display: grid;
  align-items: flex-end;
  justify-content: center;

  img {
    max-width: 100%;
  }
  @media (min-width: 375px){
    padding: 20px;
    margin: 0 20px;
  }
`;

const CTAWrapper = styled.div`
  margin: 0 auto;
  padding: 20px 0;
`;

const CheckMarkPoint = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${Colours.white};

  img {
    padding: 5px;
    margin: 1em 5px 0 0;
  }

  span {
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
`;

const LBCIPage: React.FC<Props> = ({ stores }) => {
  const shopContext = useContext(ctx);
  const [open, setOpen] = useState(false);
  const [updatedStore, setUpdatedStore] = useState<Store | null>(null);
  const [updatedDepartment, setUpdatedDepartment] = useState<Department | null>(
    null
  );

  useEffect(() => {
    if (!stores || stores.length === 0) return;
    const lbci = stores[0].departments.filter(({ departmentName }) => {
      return departmentName.toLowerCase() === "insurance";
    })[0];

    const tempStore = [...stores][0];
    const newCallBackUrl = lbci.callbackUrl.replace(
      "{AGENT_ID}",
      shopContext.config?.customer?.alanBoswellCode || "2716"
    );

    tempStore.departments[0].callbackUrl = newCallBackUrl;

    console.log(newCallBackUrl);

    setUpdatedStore({ ...tempStore });
    setUpdatedDepartment(lbci);
  }, [stores]);

  const typeOfAuth = useAuthenticated();
  let history = useHistory();
  if (!stores) {
    return <div>Loading...</div>;
  }

  if (!updatedStore || !updatedDepartment) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {open && (
        <Modal setOpen={setOpen} open={open}>
          {typeOfAuth === "vch" ? (
            <ExistingUser
              integration="lbci"
              setOpen={setOpen}
              deal={{
                id: "landlords-building-contents-insurance",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Landlords Building & Contents Insurance",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: updatedDepartment.callbackUrl,
              }}
              email=""
              storeName="Landlords Building & Contents Insurance"
              dealType="GETNOW"
            />
          ) : (
            <UserHandler
              deal={{
                id: "landlords-building-contents-insurance",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Landlords Building Contents Insurance",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: updatedDepartment.callbackUrl,
              }}
              integration="lbci"
              setOpen={setOpen}
              storeName="Landlords Building & Contents Insurance"
              dealType="GETNOW"
            />
          )}
        </Modal>
      )}
      <StoreContainer bannerUrl={updatedDepartment.departmentBanner}>
        <MainStoreBanner>
          <StoreHeader storeLogo={updatedStore.logoUrl} />
          <TitleContainer>
            <div id="storeName"><h1>{updatedStore.storeName}</h1></div>
            <div id="departmentDescription"><h2>{updatedDepartment.departmentShortDesc}</h2></div>
            <div style={{ gridArea: "blank" }}>&nbsp;</div>
          </TitleContainer>
        </MainStoreBanner>
        <TenantsInsurancePageWrapper center>
          <BlurbContainer>
            <h1>What&apos;s covered?</h1>
            <TextContainer>
              <Blurb>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick"/>
                  <p>
                    <span>Domestic furniture and furnishings</span> - This
                    provides cover for the furniture left in the property for
                    tenants, premium starts from £56 per year for a minimum sum
                    insured of £10,000.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Public liability cover</span> - Our property owner&apos;s
                    public liability cover covers loss or damage to material
                    property or bodily injury (including death, illness or
                    disease) for which you are held responsible and proven
                    negligent in relation to members of the public.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Employer&apos;s liability cover</span> - Protection for
                    claims made by your employees. Employees could be family
                    members who get paid to undertake work for you. Accidental
                    damage - this is where anyone residing or visiting the
                    property accidentally causes damage to your contents.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Lock replacement</span> - Covers the cost of changing
                    locks if the keys are lost from your residential or business
                    premises.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Loss of metered utility supplies</span> - Covers you
                    for the cost of metered water, gas, oil and electricity for
                    which you are legally responsible arising from damage
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Buildings</span> - Cover for rebuild or repair of your
                    building following an event e.g. flood or fire
                  </p>
                </CheckMarkPoint>
              </Blurb>
              <Blurb>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Loss of rent</span> - Loss of rent cover following
                    your rental property becoming uninhabitable after a claim OR
                    alternative accommodation costs for your tenants due to your
                    property becoming uninhabitable after a claim e.g. flood,
                    fire etc
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Property owners&apos; liability</span> - Protection against
                    legal liability for personal injury or property damage
                    suffered by third parties at your rental property.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Employers&apos; liability</span> - Employers&apos; liability
                    cover for cleaners, gardeners, caretakers and persons doing
                    minor maintenance and repairs.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Unoccupied property cover</span> - Full cover for up
                    to 90 days un-occupancy between tenancies.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Malicious damage by tenants</span> - Full cover for
                    malicious damage caused by your tenants to your property.
                  </p>
                </CheckMarkPoint>
              </Blurb>
            </TextContainer>

            <CTAWrapper>
              <Button onClick={() => setOpen(true)}>Get Now</Button>
            </CTAWrapper>
          </BlurbContainer>
          <Footer>
            {updatedStore.storeTermsAndCond && (
              <SmallPrint>{updatedStore.storeTermsAndCond}</SmallPrint>
            )}
          </Footer>
        </TenantsInsurancePageWrapper>
      </StoreContainer>
    </>
  );
};

export default /* withAITracking(reactPlugin,  */ LBCIPage;
