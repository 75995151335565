import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextInput } from "vouch.ui.react.components";
import { Button, Colours, AlternateButton } from "../common/styles";
import { PassportUser } from "../types/Person";
import { graphQLQuery } from "../hooks/hooks";

interface Props {
  passportUser: PassportUser | null;
  handleLogout: Function;
}

const Container = styled.div`
  margin-top: 60px;
`;

const BodyContainer = styled.div`
  display: grid;
  width: 100%;
  color: ${Colours.teal};
  max-width: 70%;
  margin: 20px auto;
`;

const GroupedInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const AuthContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  color: ${Colours.teal};
  max-width: 70%;
  margin: 20px auto;
`;

const Header = styled.div`
  color: ${Colours.blue};
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  #title {
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }

  h1 {
    font-family: "Montserrat", sans-serif;
  }

  h5 {
    text-align: left;
  }

  #new {
    position: absolute;
    top: -20px;
    right: -25px;
    font-weight: bold;
    background: ${Colours.teal};
    color: white;
    padding: 0px 5px;
    border-radius: 10px;
    transform: rotate(5deg);
    font-size: 0.8rem;
  }
`;

const ProfilePassport: React.FC<Props> = ({ passportUser, handleLogout }) => {
  const [email, setEmail] = useState("");
  const [vch, setVch] = useState("");
  const [valid, setValid] = useState(false);
  const [APIResponse, setAPIResponse] = useState<string>("");

  function handleSubmit() {
    setAPIResponse("");
    graphQLQuery(`query{
    sendMagicLink(email: "${email}"){
      status
      code
  }
  }`)
      .then((resData) => {
        return resData.json();
      })
      .then((data) => {
        if (data.data.sendMagicLink.code === 200) {
          setAPIResponse("Success! Check your email.");
        } else {
          setAPIResponse("Ooops, unable to find that email or vch number.");
        }
      });
  }

  useEffect(() => {
    if (
      (email.trim() !== "" &&
        email.length > 5 &&
        email.includes("@") &&
        email.includes(".")) ||
      (vch.length > 5 && vch.toLowerCase().includes("vch-"))
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [email, vch]);

  if (!passportUser) {
    return (
      <Container>
        <Header>
          <div id="title">
            <span id="new">NEW!</span>
            <h1>
              <span
                style={{
                  color: Colours.teal,
                }}
              >
                Vouch{" "}
              </span>
              Passport
            </h1>
          </div>
          <h5>
            Enter either your email address or VCH Number associated with your
            application and we will email you a magic link to avoid the hassle
            of entering your details to secure our fantastic deals.
          </h5>
        </Header>
        <AuthContainer>
          <div
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <TextInput
              value={email}
              label="Email Address"
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <h4>OR</h4>
            <TextInput
              value={vch}
              label="VCH Number"
              onChange={(e: any) => setVch(e.target.value)}
            />
            <Button disabled={!valid} onClick={() => handleSubmit()}>
              Submit
            </Button>
            <h5>{APIResponse}</h5>
          </div>
          <div
            style={{
              display: "grid",
              placeItems: "center",
            }}
          >
            <img
              alt="passport"
              src="/assets/svg/passport.svg"
              style={{
                maxWidth: 200,
              }}
            />
          </div>
        </AuthContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <div id="title">
          <span id="new">NEW!</span>
          <h1>
            <span
              style={{
                color: Colours.teal,
              }}
            >
              Vouch{" "}
            </span>
            Passport
          </h1>
        </div>
        <h5>
          Welcome back {passportUser.firstName}! Here are your passports
          details, feel free to ammend them and we will use them to fast track
          the next deal you secure.
        </h5>
      </Header>
      <BodyContainer>
        <h4>
          <span
            style={{
              color: Colours.blue,
            }}
          >
            Hi
          </span>{" "}
          {passportUser.email} 👋
        </h4>
        <GroupedInput>
          <TextInput
            label="First Name"
            onChange={() => {}}
            value={passportUser.firstName}
          />
          <TextInput
            label="Last Name"
            onChange={() => {}}
            value={passportUser.lastName}
          />
        </GroupedInput>
        <TextInput
          label="Phone"
          onChange={() => {}}
          value={passportUser.phone}
        />
        <div className="side-by-side-flex-end">
          <AlternateButton onClick={() => handleLogout()}>
            Not {passportUser.firstName}?
          </AlternateButton>{" "}
          <Button>Update Profile</Button>
        </div>
      </BodyContainer>
    </Container>
  );
};

export default ProfilePassport;
