import React, { useState } from "react";
import styled from "styled-components";
import { BannerBackground, PageWrapper } from "../../common/styles";
import { useParams } from "react-router";
// types
import { Store, Deal } from "../../types/Store";
import { useFindShop, useValidEmail } from "../../hooks/hooks";
// components
import DealCard from "../../components/DealCard";
import Footer from "../../components/Footer";
import StoreHeader from "../../components/StoreHeader";
import { Button } from "../../common/styles";
import { Link, useHistory } from "react-router-dom";

import { Mixpanel } from "../../insights/mixpanel";

interface Props {
  stores: Store[];
}

const CardFooter = styled.div`
  display: flex;
  flex-flow: row wrap;
  a {
    margin: 0 auto;
    height: auto;
  }
  p {
    color: #fff;
  }
`;

const StoreContainer = styled(BannerBackground)`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const FormIntro = styled.div`
  color: #fff;
  display: block;
`;
const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  @media (min-width: 320px) {
    margin-bottom: 0px;
  }
`;

const BlurbContainer = styled.div`
  width: auto;
`;

const CTAContainer = styled.div`
  text-align: center;
  margin: 0 auto;

  p {
    text-align: left;
    font-size: 10px;
    padding: 20px;
    margin: 0;
    color: white;
    text-align: justify;
    padding-top: 0;
  }
`;

const DealsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: flex-start;
  margin: 25px 0;
  grid-gap: 20px;

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const StorePage: React.FC<Props> = ({ stores }) => {
  const [store] = stores.filter(({ storeName }) => {
    return storeName.toLowerCase() === "scottish power";
  });

  if (!store) return <div>loading...</div>;

  function getQuote() {
    Mixpanel.track("ScottishPower.StartJourney");
  }

  return (
    <PageWrapper center>
      <StoreContainer bannerUrl={store.departments[0].departmentBanner}>
        <MainStoreBanner>
          <StoreHeader storeLogo={store.logoUrl} />
        </MainStoreBanner>
        <BlurbContainer>
          <DealsContainer>
            <DealCard
              store={store.storeName}
              department={store.departments[0].departmentName}
              deal={store.departments[0].deals[0]}
            >
              <CardFooter>
                <Link to="/scottish-power/energy/quote">
                  <Button onClick={() => getQuote()}>Get a quote</Button>
                </Link>
                <FormIntro>
                  <p><strong>Why ScottishPower?</strong></p>
                  <p> ScottishPower is at the forefront of building a sustainable future for everyone. They’re proud that all their domestic green tariffs provide 100% green electricity. They generate it themselves here in the UK, 100% from wind.</p>
                </FormIntro>
              </CardFooter>
            </DealCard>
          </DealsContainer>
        </BlurbContainer>
        <Footer>
          {store.storeTermsAndCond && (
            <SmallPrint>{store.storeTermsAndCond}</SmallPrint>
          )}
        </Footer>
      </StoreContainer>
    </PageWrapper>
  );
};

export default StorePage;
