import React from "react";
import styled from "styled-components";
import { Colours } from "../common/styles";
import Person from "../types/Person";
import { Customer } from "../types/Config";

interface Props {
  vchMeta: Person | null;
  customerMeta: Customer | null;
  executeScroll?: Function;
}

const FWContainer = styled.div`
  width: 100%;
`;

const FWBody = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  place-items: center;
  height: 100%;
`;

const FWBlurb = styled.div`
  width: 100%;
  h1 {
    text-align: left;
  }

  #highlight,
  #asset {
    color: ${Colours.teal};
  }
`;

const FullWidthBanner: React.FC<Props> = ({ vchMeta, customerMeta }) => {
  if (vchMeta) {
    return (
      <FWContainer>
        <FWBody>
          {vchMeta.applicationStatus === "accepted" && (
            <FWBlurb>
              <h1 id="highlight">
                Congratulations {vchMeta.firstname}! on your new home 🎉
              </h1>
              <p>
                Its fantastic news that you have passed your application.
                <br />
                <br />
                Let us help you get ready for the big move in day, check out the
                exciting offers from our fab partners!
              </p>
            </FWBlurb>
          )}
          {vchMeta.applicationStatus === "complete" && (
            <FWBlurb>
              <h1 id="highlight">
                Your application is all done {vchMeta.firstname}
              </h1>
              <p>
                {vchMeta.agencyName} are checking over your application and
                making sure everything is in order.
                <br />
                <br />
                in the meantime, why not check out the exciting offers from out
                partners.
              </p>
            </FWBlurb>
          )}
        </FWBody>
      </FWContainer>
    );
  }
  if (customerMeta) {
    return (
      <FWContainer>
        <FWBody>
          {customerMeta.name && (
            <FWBlurb>
              <h1 id="highlight">
                Vouch{" "}
                <span style={{ color: `${Colours.blue}` }}>
                  in partnership with
                </span>{" "}
                {customerMeta.name}💜
              </h1>
              <p>
                Here at Vouch, we have teamed up with{" "}
                <span
                  style={{
                    color: Colours.teal,
                    fontWeight: "bold",
                  }}
                >
                  {customerMeta.name}
                </span>{" "}
                to bring you the latest & greatest deals from all of the top
                suppliers that you could ever wish for. Why not treat yourself
                or your home!
              </p>
            </FWBlurb>
          )}
        </FWBody>
      </FWContainer>
    );
  }
  return null;
};

export default FullWidthBanner;
