import React, { useState, useContext, useEffect } from "react";
import ctx, { ctxType } from "../context/ShopContext";
import styled from "../common/themes";
import { Deal, Department } from "../types/Store";
import CTAButtons from "./CTAButtons";
// helpers
import { formatCurrency } from "../helpers/helpers";
// components
import Modal from "../components/Modal";
import UserHandler from "../components/modals/UserHandler";
import { useAuthenticated } from "../hooks/hooks";
import ExistingUser from "../components/modals/ExistingUser";

interface Props {
  deal: Deal;
  department: string;
  departmentObj?: Department;
  store: string;
  singleDeal?: boolean;
  storeId?: string;
  quote?: boolean;
}

const DealCardContainer = styled.div<{ singleDeal: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background: white;
  max-width: 500px;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  &:hover {
    cursor: ${(p) => (p.singleDeal ? "" : "pointer")};
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

const PriceAndTerm = styled.div`
  span :first {
    color: ${(props) => props.theme.color.mainHighlight};
    font-weight: bold;
    display: block;
  }

  span {
    color: ${(props) => props.theme.color.mainBodyText};
    font-weight: 600;
    display: block;
  }
`;

const SmallPrint = styled.p`
  font-size: 10px;
  padding: 20px;
  margin: 0;
  color: white;
  text-align: justify;
  padding-top: 0;
`;

const DealDesc = styled.p``;

const DealBody = styled.div`
  padding: 20px;
  height: 100%;
  display: grid;
  grid-auto-rows: max-content 1fr min-content;
  grid-auto-flow: row;
`;

const DealDetails = styled.div`
  display: grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  align-items: end;
`;

const DealTags = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 5px;
  justify-content: flex-end;
  align-items: flex-end;
`;

const DealTag = styled.span`
  border: 1px solid ${(props) => props.theme.color.mainHighlight};
  width: fit-content;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
  margin: 0;
  font-size: 0.8rem;
  color: ${(props) => props.theme.color.mainBodyText};
`;

const CTAAndSmallPrint = styled.div`
  background: ${(props) => props.theme.color.mainBodyText};
  border-radius: 0 0 10px 10px;
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;

const DealCard: React.FC<Props> = ({
  deal,
  store,
  departmentObj,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [dealType, setDealType] = useState<
    "CALLBACK" | "CALLNOW" | "GETNOW" | null | null
  >(null);
  const [revealNumber, setRevealNumber] = useState(false);
  const typeOfAuth = useAuthenticated();
  const shopCtx: ctxType = useContext(ctx);
  const person = shopCtx?.config?.applicant;

  useEffect(() => {
    if (!dealType) return;
    setOpen(true);
  }, [dealType]);

  return (
    <>
      {open && revealNumber && deal.phone && (
        <Modal setOpen={setOpen} open={open}>
          <ExistingUser
            integration={store.toLowerCase()}
            setOpen={setOpen}
            deal={deal}
            email=""
            phoneNumber={deal.phone}
            storeName={deal.dealName}
            dealType={dealType}
          />
        </Modal>
      )}
      {open && !revealNumber && (
        <Modal setOpen={setOpen} open={open}>
          {typeOfAuth === "vch" ? (
            <ExistingUser
              integration={store.toLowerCase()}
              setOpen={setOpen}
              setRevealNumber={setRevealNumber}
              deal={deal}
              email=""
              phoneNumber={deal.phone || null}
              storeName={deal.dealName}
              dealType={dealType}
            />
          ) : (
            <UserHandler
              deal={deal}
              integration={store.toLowerCase()}
              setOpen={setOpen}
              setRevealNumber={setRevealNumber}
              storeName={store}
              phoneNumber={deal.phone || null}
              dealType={dealType}
            />
          )}
        </Modal>
      )}
      <DealCardContainer id={deal.id} singleDeal>
        <DealBody id={"deal-body-" + deal.id}>
          <h2>{deal.dealName}</h2>
          {deal.description && <DealDesc>{deal.description}</DealDesc>}
          <DealDetails>
            <PriceAndTerm>
              {deal.price && (
                <PriceAndTerm>
                  <span>
                    {deal.price.currency
                      ? formatCurrency(deal.price)
                      : deal.price.amount}
                  </span>
                  <span>{deal.price.term}</span>
                </PriceAndTerm>
              )}
            </PriceAndTerm>
            <DealTags>
              {deal.tags.map((tag, index) => (
                <DealTag key={index}>{tag}</DealTag>
              ))}
            </DealTags>
          </DealDetails>
        </DealBody>
        <CTAAndSmallPrint>
          {departmentObj && deal && (
            <>
              <CTAButtons
                deal={deal}
                setDealType={setDealType}
                revealNumber={revealNumber}
                setOpen={setOpen}
                callbackUrl={departmentObj.callbackUrl}
              />
            </>
          )}
          {deal.smallPrint && <SmallPrint>{deal.smallPrint}</SmallPrint>}
          <div style={{ padding: 10 }}>{children}</div>
        </CTAAndSmallPrint>
      </DealCardContainer>
    </>
  );
};

export default DealCard;
