import React, { useEffect, useContext } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { Button } from "../../common/styles";
import { noHookCreateLead } from "../../hooks/hooks";
import Error from "../../components/Input/Error";
import { Mixpanel } from "../../insights/mixpanel";

import { Deal } from "../../types/Store";
interface Props {
  deal?: Deal;
  integration: string;
  setOpen?: Function;
  dealType: "CALLBACK" | "CALLNOW" | "GETNOW" | null;
  goBack?: Function;
  email: string;
  storeName: string;
  phoneNumber?: string | null;
  setRevealNumber?: Function;
}

const Wrapper = styled.div`
  grid-template-rows: 1fr 200px;
  display: grid;
`;

const SignUpBlurb = styled.div`
  margin: 10px;
`;

const SuccessBlurb = styled(SignUpBlurb)`
  text-align: center;

  #deal-highlight {
    color: #34bebd;
    font-weight: bold;
  }
`;

const CodeToQuote = styled.div`
  background: #f2f2f2;
  width: fit-content;
  padding: 15px;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.5rem;
`;

const TitleContainer = styled.h1`
  font-family: "Montserrat", sans-serif;
  color: #082842;
  text-align: left;
`;

const PhoneNumber = styled.div`
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ExistingUser: React.FC<Props> = ({
  email,
  dealType,
  deal,
  setOpen,
  integration,
  storeName,
  phoneNumber,
  setRevealNumber,
}) => {
  const ctxData = useContext(ctx);

  useEffect(() => {
    let data = {
      store: storeName,
      deal: deal,
      dealType: dealType,
    };
    const customTracks: { servicesId?: string } = {};

    if (dealType === "CALLNOW") {
      customTracks.servicesId = "Vouch Inbound";
    }
    let vch = undefined;
    if (ctxData.vchNumber) {
      vch = ctxData.vchNumber;
    }

    noHookCreateLead(
      integration,
      null,
      {
        store: storeName,
        deal: deal?.dealName || "<unable to link deal>",
      },
      customTracks,
      email,
      vch
    ).then((res) => {
      console.log(res);
    });

    if (deal?.url && dealType == "GETNOW") {
      window.open(deal.url);
    }

    if (dealType === "CALLBACK" || dealType === "GETNOW") {
      setTimeout(() => {
        if (setOpen) {
          setOpen(false);
        }
      }, 4000);
    }

    return () => {
      if (phoneNumber) {
        if (setRevealNumber) {
          setRevealNumber(true);
        }
      }
    };
  }, []);

  return (
    <Wrapper>
      <SuccessBlurb>
        <TitleContainer>Fantastic.</TitleContainer>
        <img
          src="/assets/svg/success.svg"
          style={{ maxWidth: 300, margin: "0 auto" }}
          alt="Success"
        />
        {deal?.url && dealType === "GETNOW" ? (
          <p>Just a moment, we are sending you through to {storeName} now.</p>
        ) : dealType === "CALLBACK" ? (
          <p>Thank you, we have registered your interest with {storeName}</p>
        ) : null}
        {phoneNumber && dealType === "CALLNOW" && (
          <div>
            <h3>
              Call now on the number below and quote the code when prompted.
            </h3>
            {deal?.dealCode && <CodeToQuote>{deal?.dealCode}</CodeToQuote>}
            {phoneNumber && (
              <PhoneNumber>
                <a href={`tel:${phoneNumber}`}>
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <img src="/assets/svg/phone.svg" alt="Phone" />{" "}
                    {phoneNumber}
                  </Button>
                </a>
              </PhoneNumber>
            )}
          </div>
        )}
      </SuccessBlurb>
    </Wrapper>
  );
};

export default ExistingUser;
