import React from "react";
import styled from "styled-components";
import { Void } from "../../types/Void";
import { format } from "date-fns";
import NotificationItem from "./NotificationItem";

interface Props {
  showDate: boolean;
  type:string;
  propertyMove: Void['moveIn' | 'moveOut'];
  isMoveOut: boolean;
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;


const MoveOutContainer = styled.div<{type: string}>`

.timeStamp{
  display: ${(props) => props.type === "notification" ? "block" : "none"};
  font-size: .6rem;
}
  flex: 1;
  margin: 10px 0 0 0;
  display: flex;
  position: relative;
  > div:nth-child(1) {
    z-index: 10;
    flex: 1;
    display: flex;
    justify-content: space-between;

    margin: ${(props) => props.type === "notification" ? "0 20px" : "0px"};
    > span {
      justify-content: flex-start;
      > div:nth-child(2) {
        height: 24px;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        
      }
    }
  }
  
  > div:nth-child(2) {
    hr {
      border: none;
      border-top: 3px dashed #34bebd;
      color: ${(props) => props.theme.background};
      opacity:0.3;
      background-color: ${(props) => props.theme.background};
      height: 10px;
      width: 100%;
    }
    position: absolute;
    z-index: 0;
    top: ${(props) => (props.type === "notification" ? "15px" : "25%")};
    left: ${(props) => (props.type === "notification" ? "10%" : "0")};
    width: ${(props) => props.type === "notification" ? "80%" : "100%"};
  }
`;


const VoidMoveStatusComponent: React.FC<Props> = ({
  showDate,
  type,
  isMoveOut,
  propertyMove
}) => { 

  return (
 
    <Container>
        <MoveOutContainer type={type}>
          <div>
            <NotificationItem notificationsFuel={propertyMove?.notificationsToDisplay?.gas} type={type} img={"/assets/svg/flame.svg"}></NotificationItem>
            <NotificationItem notificationsFuel={propertyMove?.notificationsToDisplay?.electricity} type={type} img={"/assets/svg/electricity.svg"}></NotificationItem>
            <NotificationItem notificationsFuel={propertyMove?.notificationsToDisplay?.water} type={type} img={"/assets/svg/tap.svg"}></NotificationItem>
            <NotificationItem notificationsFuel={propertyMove?.notificationsToDisplay?.council} type={type} img={"/assets/svg/council.svg"}></NotificationItem>
          </div>
          <div>
            <hr />
          </div>
        </MoveOutContainer>
        {showDate && (
          <span className="moveDate">
            <span>
              <strong>Move {isMoveOut ? "Out" : "In"}:  {propertyMove !== null &&
                propertyMove?.moveDate !== undefined &&
                propertyMove?.moveDate !== "1970-01-01T00:00:00+00:00" &&
                propertyMove?.moveDate !== "0001-01-01T00:00:00+00:00"
                  ? format(new Date(propertyMove?.moveDate), "dd/MM/yyyy")
                  : "No date provided"}</strong>
            </span>
          </span>
        )}
        </Container>
        
  );
};

export default VoidMoveStatusComponent;
