import React from "react";
import ctx from "../../context/VoidContext";
import styled from "styled-components";
import { PageWrapper } from "../../common/styles";
// types
import { Store } from "../../types/Store";
// components
import Footer from "../../components/Footer";
import VoidAddressesScreen from "../../components/VoidPortal/VoidAddressesScreen";
import MainHeader from "../../components/MainHeader";

interface Props {
  store: Store[];
}

const PortalContainer = styled.div`
  min-height: 100vh;
  margin-top:30px;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;



const MainPanel = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 15px;
`;

const VoidPortal: React.FC<Props> = () => {


  return (
    <PageWrapper center>
      <PortalContainer>
        <MainHeader shopType={"portal"} />

        <MainPanel>
          
 
            <VoidAddressesScreen/>
          
        </MainPanel>
        <Footer></Footer>
      </PortalContainer>
    </PageWrapper>
  );
};

export default VoidPortal;
