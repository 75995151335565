import React, { useContext, useEffect, useState } from "react";
import ctx, { ctxType } from "../../context/VoidContext";
import styled, { ThemeProvider } from "styled-components";
import { BannerBackground, PageWrapper } from "../../common/styles";
// types
import { Store } from "../../types/Store";
// components
import Footer from "../../components/Footer";
import StoreHeader from "../../components/StoreHeader";
import { useHistory, useParams } from "react-router-dom";
import VoidAddressesScreen from "../../components/VoidPortal/VoidAddressesScreen";
import MainHeader from "../../components/MainHeader";
import Loading from "../../components/Loading";
import {Colours} from '../../common/styles';



const PortalContainer = styled.div`
  min-height: 100vh;
  margin-top:30px;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;



const MainPanel = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 15px;
`;

const VoidPortal: React.FC = () => {
const { setVoidData, setDisplayDetail, darkModeEnabled }: ctxType = useContext(ctx);

const queryParams = new URLSearchParams(window.location.search);
const displayDetail = queryParams.get("display") || "";

const { token } = useParams<{ token: string }>();
const history = useHistory();

const voidDarkTheme = {
  background: Colours.blue,
  accentBackground: Colours.lightGrey,
  text: Colours.teal,
  accentText: Colours.white,
  noticeAccent: Colours.blue,
}

const voidMainTheme = {
  background: Colours.white,
  accentBackground: Colours.lightGrey,
  text: Colours.blue,
  accentText: Colours.teal,
  noticeAccent: Colours.orange,
}


  useEffect(() => {
    setDisplayDetail(displayDetail)
    if (!token) return;
    const reqBody = {
      query: `query($token: String!){
        getVoidConfig(token: $token){
            voidData{
              customerIdentifier
              name
              phone
              email
            }
              }
            }`,
      variables: {
        token,
      },
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        const { voidData } = resData.data.getVoidConfig;
        if (voidData) {
          setVoidData(voidData);
          sessionStorage.setItem("voidCustomerIdentifier", voidData.customerIdentifier);
        }
      })
      .catch((err) => {
        setVoidData({
          customerIdentifier: "",
          name: "",
          phone: "",
          email: ""
        });
        console.log(err);
      });
      
      sessionStorage.setItem("voidJWT", token);
 
      history.push('/utility/voids/agent/dashboard');
  }, [token]);

  return (
    <PageWrapper center>
      <ThemeProvider theme={darkModeEnabled ? voidDarkTheme : voidMainTheme}>
      <PortalContainer>
        <MainHeader shopType={"portal"} />
        <MainPanel>
            <Loading/>   
        </MainPanel>
        <Footer></Footer>
      </PortalContainer>
      </ThemeProvider>
    </PageWrapper>
  );
};

export default VoidPortal;
