import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import ctx, { ctxType } from "../../context/ShopContext";
import { Button, InputWrapper, AlternateButton } from "../../common/styles";
import TextInput from "../Input/TextInput";
import { SteppedInput } from "vouch.ui.react.components";
import AddressSelector from "../Input/AddressSelector";
import { noHookCreateLead } from "../../hooks/hooks";
import { Deal } from "../../types/Store";
import Error from "../../components/Input/Error";

interface Props {
  deal?: Deal;
  integration: string;
  setOpen: Function;
  goBack: Function;
  emailProp: string;
  storeName: string;
  dealType: "CALLBACK" | "CALLNOW" | "GETNOW" | null;
}

const NewUserContainer = styled.div`
  height: 100%;
  width: 100%;

  background-color: white;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-height: 70vh;
  margin: 0 auto;
  padding: 10px;
`;

const SignUpBlurb = styled.div`
`;

const SuccessBlurb = styled(SignUpBlurb)`
  text-align: center;

  #deal-highlight {
    color: #34bebd;
    font-weight: bold;
  }
`;

const ErrorList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;

    img {
      margin-right: 5px;
    }
  }
`;

const TitleContainer = styled.h1`
  font-family: "Montserrat", sans-serif;
  color: #082842;
  text-align: left;
`;

const DescriptionContainer = styled.p`
  color: #082842;
`;

const ChosenDeal = styled.h5`
  color: #34bebd;
`;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const GroupedInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
`;

const MoreInfoUser: React.FC<Props> = ({
  deal,
  integration,
  setOpen,
  goBack,
  emailProp,
  storeName,
  dealType,
}) => {
  const shopCtx: ctxType = useContext(ctx);
  const [success, setSuccess] = useState(false);
  const [posting, setPosting] = useState(false);
  const [validForm, setValidForm] = useState(false);

  /* 
    TCLI specific fields
  */
  const [dob, setDOB] = useState<string[]>([]);
  const [dobErrors, setDobErrors] = useState<string[]>([]);
  const [tenancyLength, setTenancyLength] = useState(12);
  const [startDateErrors, setStartDateErrors] = useState<string[]>([]);
  const [tenancyStartDate, setTenancyStartDate] = useState<string[]>([]);

  useEffect(() => {
    if (
      +tenancyLength !== 0 &&
      tenancyStartDate.join("").trim() !== "" &&
      dob.join("").trim() !== ""
    ) {
      if (integration === "tcli") {
        if (
          +tenancyLength !== 0 &&
          startDateErrors.length === 0 &&
          dobErrors.length === 0
        ) {
          setValidForm(true);
          return;
        } else {
          setValidForm(false);
          return;
        }
      }
      setValidForm(true);
      return;
    }
    setValidForm(false);
  }, [dobErrors, tenancyLength, startDateErrors]);

  async function handlePost() {
    setPosting(true);

    let customTracks = {};

    if (integration === "tcli") {
      const dobCopy = [...dob];
      const moveInCpy = [...tenancyStartDate];

      customTracks = {
        // format as MM DD YYYY for those bloody americans
        dob: new Date(
          [dobCopy[1], dobCopy[0], dobCopy[2]].join("-")
        ).toISOString(),
        moveIn: new Date(
          [moveInCpy[1], moveInCpy[0], moveInCpy[2]].join("-")
        ).toISOString(),
        duration: `${tenancyLength}`,
      };
    }

    const res = await noHookCreateLead(
      integration,
      {},
      {
        store: storeName,
        deal: deal?.dealName || "<unable to link deal>",
      },
      customTracks
    );

    console.log(res);

    if (res.status === 201 || 200) {
      setSuccess(true);
      // if (deal?.url && dealType === "GETNOW") {
      //   window.open(deal.url);
      // }
      setPosting(false);
    } else {
      setPosting(false);
    }
  }

  if (success) {
    return (
      <NewUserContainer>
        <SuccessBlurb>
          <TitleContainer>Your interest has been registered</TitleContainer>
          <img
            src="/assets/svg/success.svg"
            style={{ maxWidth: 300, margin: "0 auto" }}
            alt="success"
          />
          <p>
            Need help with anything else?<br/>
            Check out our other great offers.
          </p>
        </SuccessBlurb>
      </NewUserContainer>
    );
  }

  return (
    <>
      <NewUserContainer>
        <SignUpBlurb>
          <TitleContainer>Welcome back!</TitleContainer>
          <DescriptionContainer>
            We just need a few extra details
          </DescriptionContainer>
        </SignUpBlurb>
        {integration === "tcli" && (
          <>
            <h3>Tenancy Details</h3>
            <GroupedInput>
              <SteppedInput
                config={[
                  { label: "Day", length: 2, maxVal: 31, minVal: 1 },
                  {
                    label: "Month",
                    length: 2,
                    maxVal: 12,
                    minVal: 1,
                  },
                  {
                    label: "Year",
                    length: 4,
                    maxVal: 3000,
                    minVal: new Date().getFullYear() - 1,
                  },
                ]}
                isDate
                onError={setStartDateErrors}
                label="Start Date"
                onChange={setTenancyStartDate}
                values={tenancyStartDate}
                divider="/"
              />
              <TextInput
                label="Tenancy Length (Months)"
                onChange={(e: any) => setTenancyLength(e.target.value)}
                type="number"
                value={tenancyLength}
                name="TenancyLength"
              />
            </GroupedInput>
            <ErrorList>
              {startDateErrors.map((err, index) => (
                <Error message={err} key={index} />
              ))}
            </ErrorList>
            <h3>Your Details</h3>

            <SteppedInput
              config={[
                { label: "Day", length: 2, maxVal: 31, minVal: 1 },
                {
                  label: "Month",
                  length: 2,
                  maxVal: 12,
                  minVal: 1,
                },
                {
                  label: "Year",
                  length: 4,
                  maxVal: new Date().getFullYear() - 18,
                  minVal: 1900,
                },
              ]}
              isDate
              onError={setDobErrors}
              label="Date of Birth"
              onChange={setDOB}
              values={dob}
              divider="/"
            />
            <ErrorList>
              {dobErrors.map((err, index) => (
                <Error message={err} key={`dob${index}`}/>
              ))}
            </ErrorList>
          </>
        )}
      </NewUserContainer>
      <ControlsContainer>
        <AlternateButton onClick={() => goBack()}>Back</AlternateButton>
        <Button onClick={() => handlePost()} disabled={!validForm || posting}>
          Submit
        </Button>
      </ControlsContainer>
    </>
  );
};

export default MoreInfoUser;
