import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  Void,
  MeterOptions,
  PropertyInfo,
  moveInDTO,
  moveOutDTO,
  MetaMeterInfo,
} from "../../types/Void";

import VoidPropertyMeter from "./VoidPropertyMeter";
import { formatISO } from "date-fns";
import { checkReadIsInRange, handleInputtedDate, fuelNotificationSent, returnMeterIdentifier, returnNewMeterReading } from "../../helpers/voidPortalHelpers";
import MeterReadSubmitForm from "./MeterReadSubmitForm";

interface Props {
  setSelectedOption: (input: boolean) => void;
  singleVoid: Void | undefined;
  propertyInfo: PropertyInfo;
  fuelType: string;
  readSubmitted: boolean;
  moveOutSelectedNotMoveIn: boolean;
  setFuelType: (fuel: string) => void;
  setReadSubmitted: (read: boolean) => void;
  setPropertyInfo: (property: PropertyInfo) => void;
  moveToggle: (MoveInOrOut: boolean) => void;
  toggleMenu: () => void;
  moveOutElecReadRequired: boolean;
  moveOutGasReadRequired: boolean;
  moveOutWaterReadRequired: boolean;
  moveInGasReadRequired: boolean;
  moveInElecReadRequired: boolean;
  moveInWaterReadRequired: boolean;
}

const MeterContentSection = styled.div<{ isMeterReadOpen: boolean }>`
  grid-gap: 30px;
  display: ${(props) => (props.isMeterReadOpen ? "none" : "block")};
  display: flex;
  flex-flow: row;
`;


const MetersContainer = styled.div<{ isMeterReadOpen: boolean }>`
`;

const MeterReadSection = styled.div<{ isMeterReadOpen: boolean }>`
  border-radius: 10px;
  display: flex;
  width: 100%;
  flex-flow: column;
  flex: 1 1 40%;
  justify-content: space-between;

  #address {
    text-transform: capitalize;
    display: inline-block;
  }

  #chacha {
    padding: 40px !important;
    margin-bottom: 20px !important;
    background: #fff !important;
  }

  #dateContainer {
    display: flex;
    flex-flow: column;
    margin-top: 10px;
    label {
      display: flex;
      align-items: center;
    }
    > div:nth-child(2) {
      width: 100%;
      margin-right: 0;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  button {
    margin: 10px 0;
  }
  .meterIntro {
    margin-top: 40px;
  }

  #metersContainer {
    margin-bottom: 0;
    order: 1;
    flex: 1;
    max-height: ${props => props.isMeterReadOpen ? "720px" : "100%"};
    overflow-y: ${props => props.isMeterReadOpen ? "scroll" : "none"};
    padding-right: ${props => props.isMeterReadOpen ? "20px" : "0px"};
    flex: ${props => props.isMeterReadOpen ? ".7" : "1"};
    border-radius: 10px;
  }

  #meterReadSection {
    order: 0;
    padding: 40px;
    background: #fff;
    border-radius: 10px;
    height: auto;
  }
  .utilityList {
    display: flex;
    flex-flow: column wrap;
    padding: 0;
    img {
      width: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      max-width: 350px;
      padding: 5px 0;
      font-size: 0.8rem;
    }
  }

  #dateToggle {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;

    > div {
      padding: 1rem 0 1rem 1rem;
    }
  }

  small {
    display: flex;
    justify-content: space-between;
    text-align: right;
    width: 100%;
  }

  #meterSubmit {
    text-align: right;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-flow: column;
    align-items: center;

    button {
      margin-top: 30px;
    }

    > span {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      span {
        color: #34bebd;
        font-weight: 700;
      }
    }
  }

  #elecMeterMoveIn, #elecMeterMoveOut, #gasMeterMoveOut, #gasMeterMoveIn {
    margin-bottom: 30px;
    > div:nth-child(1) {
      padding: 0;
      grid-gap: 20px;
      > div:nth-child(1) {
        margin: 10px 0;
      }
    }
    > div {
      > span {
        align-items: center;
        img {
          width: 40px;
        }
      }
    }
  }
  #meterReadInput {
    > div {
      margin: 10px 0;
    }
  }
  ul {
    display: flex;
    flex-flow: column-wrap;
    li {
      display: flex;
      flex: 1 1 50%;
      column-gap: 20px;
      justify-content: space-between;
    }
  }
`;


const SubmitMeterRead: React.FC<Props> = ({
  singleVoid,
  fuelType,
  propertyInfo,
  readSubmitted,
  moveOutSelectedNotMoveIn,
  setFuelType,
  setReadSubmitted,
  setPropertyInfo,
  toggleMenu,
  moveOutElecReadRequired,
  moveOutGasReadRequired,
  moveInGasReadRequired,
  moveInElecReadRequired,
  moveInWaterReadRequired,
  moveOutWaterReadRequired
}) => {
  const [submitDay, setDay] = useState("");
  const [submitMonth, setMonth] = useState("");
  const [submitYear, setYear] = useState("");
  const [submitMoveInDay, setMoveInDay] = useState("");
  const [submitMoveInMonth, setMoveInMonth] = useState("");
  const [submitMoveInYear, setMoveInYear] = useState("");
  const [isReadTakenToday, setReadTakenToday] = useState(true);
  const [isMeterReadOpen, setIsMeterReadOpen] = useState(false);

  const [meterMessage, setMeterMessage] = useState("Submitting read...");
  const [hasAgentConfirmedMeterE7, setHasAgentConfirmedMeterE7] = useState(false);

  const elecMeter: MetaMeterInfo = {
    title: "Electricity",
    imageDesc: "Outline Illustration of a lightbulb",
    img: "/assets/svg/electric.svg"
  }

  const gasMeter: MetaMeterInfo = {
    title: "Gas",
    imageDesc: "Outline Illustration of a flame",
    img: "/assets/svg/gas.svg"
  }

  const waterMeter: MetaMeterInfo = {
    title: "Water",
    imageDesc: "Outline Illustration of a water tap",
    img: "/assets/svg/tap.svg"
  }

  const [isMoveOutMeterReadInRange, setIsMoveOutMeterReadInRange] =
    useState(true);
  const [isMoveInMeterReadInRange, setIsMoveInMeterReadInRange] =
    useState(true);

    async function findMeter(meterDetails: any) {
      const res = await fetch(
        `${process.env.REACT_APP_VOID_METER_SEARCH_ENDPOINT}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: JSON.stringify(meterDetails),
        }
      );
  
      if (res.ok) {
        console.log(res);
        window.location.reload();
      }
    }
    
  useEffect(() => {

    if (
      submitMoveInDay !== "" &&
      submitMoveInMonth !== "" &&
      submitMoveInYear !== ""
    ) {
      setPropertyInfo({
        ...propertyInfo,
        submittedMoveInDate: `${submitMoveInYear}-${submitMoveInMonth}-${submitMoveInDay}`,
      });
    }

     if (
        singleVoid?.moveIn !== undefined &&
        singleVoid?.moveIn !== null &&
        singleVoid?.moveIn.moveDate
      ) {
        checkReadIsInRange(singleVoid?.moveIn.moveDate, submitMoveInYear, submitMoveInMonth, submitMoveInDay, setIsMoveInMeterReadInRange,10, isReadTakenToday )
      }
   
  }, [submitMoveInDay, submitMoveInMonth, submitMoveInYear, isReadTakenToday]);


  useEffect(() => {

    if (
      submitDay !== "" &&
      submitMonth !== "" &&
      submitYear !== ""
    ) {
      setPropertyInfo({
        ...propertyInfo,
        submittedMoveOutDate: `${submitYear}-${submitMonth}-${submitDay}`,
      });
    }
    if (
       singleVoid?.moveOut !== undefined &&
       singleVoid?.moveOut !== null &&
       singleVoid?.moveOut.moveDate
     ) {
       checkReadIsInRange(singleVoid?.moveOut.moveDate, submitYear, submitMonth, submitDay, setIsMoveOutMeterReadInRange, 7, isReadTakenToday)
     }
  }, [submitDay, submitMonth, submitYear, isReadTakenToday]);

useEffect(() => {
  if(isReadTakenToday && moveOutSelectedNotMoveIn) {
    setPropertyInfo({
      ...propertyInfo, submittedMoveInDate: formatISO(new Date(), {representation: "date"})
    })
  }
  if(isReadTakenToday && !moveOutSelectedNotMoveIn) {
    setPropertyInfo({
      ...propertyInfo, submittedMoveOutDate: formatISO(new Date(), {representation: "date"})
    })
  }
},[isReadTakenToday, moveOutSelectedNotMoveIn])


  function handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value;
    setPropertyInfo({
      ...propertyInfo,
      [evt.target.name]: value,
    });
  }


  function submitAnotherRead(type: string, submittedMoveDate: string | undefined) {
    setFuelType(type);
    setReadSubmitted(false);
    setMeterMessage("")
    setPropertyInfo({
      waterMeterRead:"",
      electricityDayMeterRead: "",
      electricityNightMeterRead: "",
      gasMeterRead: "",
      submittedMoveOutDate: submittedMoveDate,
      submittedMoveOutDateMeta: [],
    });
  }

  function toggleMeterReadEntryOnGasPillOut() {
    if (
      !isMeterReadOpen &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut !== undefined &&
      singleVoid?.moveOut.gasMeterReading !== undefined &&
      singleVoid?.moveOut.gasMeterReading !== null &&
      singleVoid?.moveOut.moveDate &&
      singleVoid?.moveOut.moveDate !== "0001-01-01T00:00:00+00:00" &&
      moveOutGasReadRequired
      ) {
      setIsMeterReadOpen(true);
    }
  }
  
  function toggleMeterReadEntryOnElecPillOut() {
    if (
      !isMeterReadOpen &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut !== undefined &&
      singleVoid?.moveOut.electricityDayMeterReading !== undefined &&
      singleVoid?.moveOut.electricityDayMeterReading !== null &&
      singleVoid?.moveOut.moveDate &&
      singleVoid?.moveOut.moveDate !== "0001-01-01T00:00:00+00:00" &&
      moveOutElecReadRequired
    ) {
      setIsMeterReadOpen(true);
    }
  }

  function toggleMeterReadEntryOnGasPillIn() {
    if (
      !isMeterReadOpen &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn !== undefined &&
      singleVoid?.moveIn.gasMeterReading !== undefined &&
      singleVoid?.moveIn.moveDate &&
      singleVoid?.moveIn.moveDate !== "0001-01-01T00:00:00+00:00" &&
      moveInGasReadRequired
    ) {
      setIsMeterReadOpen(true);
    }
  }

  function toggleMeterReadEntryOnElecPillIn() {
    if (
      !isMeterReadOpen &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn !== undefined &&
      singleVoid?.moveIn.electricityDayMeterReading !== undefined &&
      singleVoid?.moveIn.electricityDayMeterReading !== null &&
      singleVoid?.moveIn.moveDate &&
      singleVoid?.moveIn.moveDate !== "0001-01-01T00:00:00+00:00" &&
      moveInElecReadRequired
    ) {
      setIsMeterReadOpen(true);
    }
  }

  function toggleMeterReadEntry() {
    setIsMeterReadOpen(!isMeterReadOpen);
  }

  const meterDetails = {
    customerIdentifier: singleVoid?.customerIdentifier,
    id: singleVoid?.id,
    meterDetailsState: singleVoid?.meterDetailsState,
    propertyAddress: { udprn: singleVoid?.propertyAddress.udprn },
  };

  async function sendMeterRead(id:string, voidMove: Void['moveIn'] | Void['moveOut'], submittedMoveDate: PropertyInfo['submittedMoveOutDate'] | PropertyInfo['submittedMoveInDate']) {

    if(singleVoid === undefined || voidMove === null || voidMove === undefined) {
      return 
    }

    let sentDTO

    const updatedMoveInDTO: moveInDTO = {
      voidId: singleVoid.id,
      customerIdentifier: singleVoid.customerIdentifier,
      moveIn: {
        newGasMeterReading: returnNewMeterReading(propertyInfo.gasMeterRead,isReadTakenToday,submittedMoveDate),
        newElectricityDayMeterReading: returnNewMeterReading(propertyInfo.electricityDayMeterRead,isReadTakenToday,submittedMoveDate),
        newElectricityNightMeterReading: returnNewMeterReading(propertyInfo.electricityNightMeterRead,isReadTakenToday,submittedMoveDate),
        electricityDayReadingSubmittedToScottishPowerDate: formatISO(new Date()),
        newWaterMeterReading: returnNewMeterReading(propertyInfo.waterMeterRead,isReadTakenToday,submittedMoveDate)      
      },
      electricityMeterDetails: {
        mpan:returnMeterIdentifier(singleVoid.electricMeterDetails, propertyInfo, "electricity")
      },
      gasMeterDetails: {
        mprn: returnMeterIdentifier(singleVoid.gasMeterDetails, propertyInfo, "gas")
      }
    }    
    
    const updatedMoveOutDTO: moveOutDTO = {
      voidId: singleVoid.id,
      customerIdentifier: singleVoid.customerIdentifier,
      moveOut: {
        newGasMeterReading: returnNewMeterReading(propertyInfo.gasMeterRead, isReadTakenToday,submittedMoveDate),
        newElectricityDayMeterReading: returnNewMeterReading(propertyInfo.electricityDayMeterRead,isReadTakenToday,submittedMoveDate),
        newElectricityNightMeterReading: returnNewMeterReading(propertyInfo.electricityNightMeterRead,isReadTakenToday,submittedMoveDate),
        electricityDayReadingSubmittedToScottishPowerDate: formatISO(new Date()),     
        newWaterMeterReading: returnNewMeterReading(propertyInfo.waterMeterRead,isReadTakenToday,submittedMoveDate)      
      },
      electricityMeterDetails: {
        mpan:returnMeterIdentifier(singleVoid.electricMeterDetails, propertyInfo, "electricity")
      },
      gasMeterDetails: {
        mprn: returnMeterIdentifier(singleVoid.gasMeterDetails, propertyInfo, "gas")
      }
    }    

    if(voidMove === singleVoid.moveIn) {
      if(updatedMoveInDTO.moveIn.newGasMeterReading === undefined || updatedMoveInDTO.moveIn.newGasMeterReading.value === "") {
        delete updatedMoveInDTO.moveIn.newGasMeterReading
        delete updatedMoveInDTO.moveIn.gasReadingSubmittedToScottishPowerDate
      }
  
      if(updatedMoveInDTO.moveIn.newElectricityDayMeterReading === undefined || updatedMoveInDTO.moveIn.newElectricityDayMeterReading.value === "") {
        delete updatedMoveInDTO.moveIn.newElectricityDayMeterReading
        delete updatedMoveInDTO.moveIn.electricityDayReadingSubmittedToScottishPowerDate
      }

      if(updatedMoveInDTO.moveIn.newElectricityNightMeterReading === undefined || updatedMoveInDTO.moveIn.newElectricityNightMeterReading.value === "") {
        delete updatedMoveInDTO.moveIn.newElectricityNightMeterReading
      }
  
      if(updatedMoveInDTO.moveIn.newWaterMeterReading === undefined || updatedMoveInDTO.moveIn.newWaterMeterReading.value === "") {
        delete updatedMoveInDTO.moveIn.newWaterMeterReading
      }

      if(updatedMoveInDTO.gasMeterDetails && updatedMoveInDTO.gasMeterDetails.mprn === undefined) {
        delete updatedMoveInDTO.gasMeterDetails
      }
      
      if(updatedMoveInDTO.electricityMeterDetails && updatedMoveInDTO.electricityMeterDetails.mpan === undefined) {
        delete updatedMoveInDTO.electricityMeterDetails
      }

      sentDTO = updatedMoveInDTO

    } else if(voidMove === singleVoid.moveOut){
      if(updatedMoveOutDTO.moveOut.newGasMeterReading === undefined || updatedMoveOutDTO.moveOut.newGasMeterReading.value === "") {
        delete updatedMoveOutDTO.moveOut.newGasMeterReading
        delete updatedMoveOutDTO.moveOut.gasReadingSubmittedToScottishPowerDate
      }
  
      if(updatedMoveOutDTO.moveOut.newElectricityDayMeterReading === undefined || updatedMoveOutDTO.moveOut.newElectricityDayMeterReading.value === "") {
        delete updatedMoveOutDTO.moveOut.newElectricityDayMeterReading
        delete updatedMoveOutDTO.moveOut.newElectricityNightMeterReading
        delete updatedMoveOutDTO.moveOut.electricityDayReadingSubmittedToScottishPowerDate
      }

      if(updatedMoveOutDTO.moveOut.newElectricityNightMeterReading === undefined || updatedMoveOutDTO.moveOut.newElectricityNightMeterReading.value === "") {
        delete updatedMoveOutDTO.moveOut.newElectricityNightMeterReading
      }

      if(updatedMoveOutDTO.moveOut.newWaterMeterReading === undefined || updatedMoveOutDTO.moveOut.newWaterMeterReading.value === "") {
        delete updatedMoveOutDTO.moveOut.newWaterMeterReading
      }

     if(updatedMoveOutDTO.gasMeterDetails && updatedMoveOutDTO.gasMeterDetails.mprn === undefined) {
        delete updatedMoveOutDTO.gasMeterDetails
      }
      
      if(updatedMoveOutDTO.electricityMeterDetails && updatedMoveOutDTO.electricityMeterDetails.mpan === undefined) {
        delete updatedMoveOutDTO.electricityMeterDetails
      }

      sentDTO = updatedMoveOutDTO
    }

    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/new/${id}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(sentDTO),
      }
    );

    if (res.ok) {
      console.log(res);
    }

    setMeterMessage("Confirming meter read...");

    if (res.status === 200) {
      setMeterMessage(`${voidMove === singleVoid.moveIn ? "Move In" : "Move Out"} Meter read submitted successfully!`);
      window.location.reload();
    } else {
      setMeterMessage("Error updating meter read for this fuel, please retry");
    }
}

  function handleMoveInReadSubmit() {    
    if(singleVoid === undefined) {
      return
    }

    if (propertyInfo.gasMeterRead || propertyInfo.electricityDayMeterRead || propertyInfo.waterMeterRead) {
      
      setReadSubmitted(true);
      sendMeterRead(singleVoid.id, singleVoid.moveIn, propertyInfo.submittedMoveInDate);
  
      setPropertyInfo({
        electricityDayMeterRead: "",
        electricityNightMeterRead: "",
        gasMeterRead: "",
        waterMeterRead: "",
        submittedMoveInDateMeta: [],
        submittedMoveInDate: propertyInfo?.submittedMoveInDate,
        submittedMoveOutDate: propertyInfo?.submittedMoveOutDate,
      });
    }
  }

  function handleMoveOutReadSubmit() {
    if(singleVoid === undefined) {
      return
    }

    if (propertyInfo.gasMeterRead || propertyInfo.electricityDayMeterRead || propertyInfo.waterMeterRead) {
      
      setReadSubmitted(true);
      sendMeterRead(singleVoid.id, singleVoid.moveOut, propertyInfo.submittedMoveOutDate);
  
      setPropertyInfo({
        electricityDayMeterRead: "",
        electricityNightMeterRead: "",
        gasMeterRead: "",
        waterMeterRead: "",
        submittedMoveOutDateMeta: [],
        submittedMoveOutDate: propertyInfo?.submittedMoveOutDate,
        submittedMoveInDate: propertyInfo?.submittedMoveInDate,
      });
    }
  }

  

  function getMeterRead(info: string) {
    if (info === "1") {
      setFuelType("gas");
      setReadSubmitted(false);
    }
    if (info === "2") {
      setFuelType("elec");
      setReadSubmitted(false);
    }
    if (info === "3") {
      setFuelType("water");
      setReadSubmitted(false);
    }
  }
  
  const displayMeters = () => {
    let gasMoveOutRead;
    let gasMoveInRead;
    let elecMoveInRead;
    let elecMoveOutRead;
    let elecNightMoveOutRead;
    let elecNightMoveInRead;
    let waterMoveInRead;
    let waterMoveOutRead;

    let meterOptions: MeterOptions[] = [];
    if (
      singleVoid &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn.electricityNightMeterReading
    ) {
      elecNightMoveInRead = singleVoid?.moveIn.electricityNightMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut.electricityNightMeterReading
    ) {
      elecNightMoveOutRead = singleVoid?.moveOut.electricityNightMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn.electricityDayMeterReading
    ) {
      elecMoveInRead = singleVoid?.moveIn.electricityDayMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut.electricityDayMeterReading
    ) {
      elecMoveOutRead = singleVoid?.moveOut.electricityDayMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut.gasMeterReading
    ) {
      gasMoveOutRead = singleVoid?.moveOut.gasMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn.gasMeterReading
    ) {
      gasMoveInRead = singleVoid?.moveIn.gasMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveOut !== null &&
      singleVoid?.moveOut.waterMeterReading
    ) {
      waterMoveOutRead = singleVoid?.moveOut.waterMeterReading;
    }

    if (
      singleVoid &&
      singleVoid?.moveIn !== null &&
      singleVoid?.moveIn.waterMeterReading
    ) {
      waterMoveInRead = singleVoid?.moveIn.waterMeterReading;
    }

  
    meterOptions = [
      { title: `Gas - MPRN - ${singleVoid?.gasMeterDetails?.mprn || "Unknown"}`, value: 1 },
      {
        title: `Electricity - MPAN - ${singleVoid?.electricMeterDetails?.mpan || "Unknown"}`,
        value: 2,
      },
      {
        title: `Water - Serial No. - Unknown`,
        value: 3,
      }
    ];

    const removeIndex = meterOptions.findIndex((item) => {
      if (item?.title.includes("null")) {
        return item;
      }
      if (item?.title.includes("undefined")) {
        return item;
      }
      return "";
    });

    if (removeIndex !== -1) meterOptions.splice(removeIndex, 1);

    return {
      meterOptions,
      elecMoveInRead,
      elecMoveOutRead,
      elecNightMoveInRead,
      elecNightMoveOutRead,
      gasMoveOutRead,
      gasMoveInRead,
      waterMoveInRead,
      waterMoveOutRead
    };
  };

  const {
    meterOptions,
    elecMoveInRead,
    elecMoveOutRead,
    elecNightMoveInRead,
    elecNightMoveOutRead,
    gasMoveOutRead,
    gasMoveInRead,
    waterMoveInRead,
    waterMoveOutRead
  } = displayMeters();

  useEffect(() => {
    displayMeters();
  }, []);
 
  return (

    <>
    {singleVoid !== undefined && 
    <>
    {moveOutSelectedNotMoveIn && singleVoid.moveOut !== null && (
        <>
          <MeterReadSection isMeterReadOpen={isMeterReadOpen}>
            <MeterContentSection id="moveOut" isMeterReadOpen={isMeterReadOpen}>
              <MetersContainer
                id="metersContainer"
                isMeterReadOpen={isMeterReadOpen}
              >
                {
                  singleVoid.moveOut.notificationsToDisplay.water !== null && 
                  singleVoid.moveOut.notificationsToDisplay.water.status !== "notRequired" && (
                    <VoidPropertyMeter 
                      voidMoveNotifications={singleVoid.moveOut.notificationsToDisplay}
                      voidMeterDetails={null}
                      meterReading={waterMoveOutRead} 
                      meterNightReading={undefined} 
                      isReadRequired={moveOutWaterReadRequired} 
                      voidMove={singleVoid.moveOut} 
                      voidAddress={singleVoid.propertyAddress} 
                      toggleMeterReadEntryPanel={toggleMeterReadEntryOnGasPillIn}
                      toggleMeterRead={toggleMeterReadEntry} 
                      isMeterReadOpen={isMeterReadOpen}
                      voidMoveNotificationsFuel={singleVoid.moveOut.notificationsToDisplay.water}
                      voidMoveInternalSPFuelStatus={undefined}
                      voidPodioNotificationFuel={singleVoid.moveOut.podioNotifications.water}
                      meterDetails={meterDetails}
                      findMeter={findMeter}
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      meterType={waterMeter}>
                    </VoidPropertyMeter> 
                  )
                }
                {
                  singleVoid.moveOut.notificationsToDisplay.gas !== null && 
                  singleVoid.moveOut.notificationsToDisplay.gas.status !== "notRequired" && (
                    <VoidPropertyMeter 
                      voidMeterDetails={singleVoid.gasMeterDetails}
                      meterReading={gasMoveOutRead} 
                      meterNightReading={undefined} 
                      isReadRequired={moveOutGasReadRequired} 
                      voidMove={singleVoid.moveOut} 
                      voidMoveNotifications={singleVoid.moveOut.notificationsToDisplay}
                      voidAddress={singleVoid.propertyAddress} 
                      toggleMeterReadEntryPanel={toggleMeterReadEntryOnGasPillOut}
                      toggleMeterRead={toggleMeterReadEntry} 
                      isMeterReadOpen={isMeterReadOpen}
                      meterType={gasMeter}
                      meterDetails={meterDetails}
                      findMeter={findMeter}
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      voidMoveInternalSPFuelStatus={singleVoid.moveOut.internalScottishPowerGasStatus}
                      voidMoveNotificationsFuel={singleVoid.moveOut.notificationsToDisplay.gas}
                      voidPodioNotificationFuel={singleVoid.moveOut.podioNotifications.gas}
                      >
                    </VoidPropertyMeter>  
                  )
                }
                {
                  singleVoid.moveOut.notificationsToDisplay.electricity !== null && 
                  singleVoid.moveOut.notificationsToDisplay.electricity.status !== "notRequired" && (
                    <VoidPropertyMeter 
                      voidMeterDetails={singleVoid.electricMeterDetails}
                      meterReading={elecMoveOutRead} 
                      meterNightReading={elecNightMoveOutRead} 
                      isReadRequired={moveOutElecReadRequired} 
                      voidMove={singleVoid.moveOut} 
                      voidMoveNotifications={singleVoid.moveOut.notificationsToDisplay}
                      voidAddress={singleVoid.propertyAddress} 
                      toggleMeterReadEntryPanel={toggleMeterReadEntryOnElecPillOut}
                      toggleMeterRead={toggleMeterReadEntry} 
                      isMeterReadOpen={isMeterReadOpen}
                      voidMoveInternalSPFuelStatus={singleVoid.moveOut.internalScottishPowerElectricityStatus}
                      voidMoveNotificationsFuel={singleVoid.moveOut.notificationsToDisplay.electricity}
                      voidPodioNotificationFuel={singleVoid.moveOut.podioNotifications.electricity}
                      meterDetails={meterDetails}
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      findMeter={findMeter}
                      meterType={elecMeter}>   
                    </VoidPropertyMeter> 
                  )
                }               
              </MetersContainer>
              {isMeterReadOpen && (
                <>
              <MeterReadSubmitForm
                 moveOutGasReadRequired={moveOutGasReadRequired}
                 fuelNotificationSent={fuelNotificationSent}
                 singleVoid={singleVoid}
                 propertyInfo={propertyInfo}
                 handleInputChange={handleInputChange}
                 getMeterRead={getMeterRead}
                 meterOptions={meterOptions}
                 isMeterReadOpen={isMeterReadOpen}
                 fuelType={fuelType}
                 hasAgentConfirmedMeterE7={hasAgentConfirmedMeterE7}
                 setHasAgentConfirmedMeterE7={setHasAgentConfirmedMeterE7}
                 setReadTakenToday={setReadTakenToday}
                 isReadTakenToday={isReadTakenToday}
                 setDay={setDay}
                 setMonth={setMonth}
                 setYear={setYear}
                 handleInputtedDate={handleInputtedDate}
                 readSubmitted={readSubmitted}
                 handleMoveOutReadSubmit={handleMoveOutReadSubmit}
                 isMoveOutMeterReadInRange={isMoveOutMeterReadInRange}
                 meterMessage={meterMessage}
                 submitAnotherRead={submitAnotherRead}
                 voidMove={singleVoid.moveOut} 
                 isMoveOut={moveOutSelectedNotMoveIn}
                 propertyInfoSubmittedMoveDate={propertyInfo.submittedMoveOutDate}
                 propertyInfoMoveDateMeta={propertyInfo.submittedMoveOutDateMeta}
              ></MeterReadSubmitForm>
              </>
              )}
            </MeterContentSection>
                                  
          </MeterReadSection>
        </>
      )
    }

    {
      !moveOutSelectedNotMoveIn && singleVoid.moveIn !== null && (
        <>
        <MeterReadSection isMeterReadOpen={isMeterReadOpen}>
          <MeterContentSection id="moveIn" isMeterReadOpen={isMeterReadOpen}>
            <MetersContainer
              id="metersContainer"
              isMeterReadOpen={isMeterReadOpen}>
              {
                singleVoid.moveIn.notificationsToDisplay !== null &&
                singleVoid.moveIn.notificationsToDisplay.water !== null && 
                singleVoid.moveIn.notificationsToDisplay.water.status !== "notRequired" && (
                    <VoidPropertyMeter 
                      voidMoveNotifications={singleVoid.moveIn.notificationsToDisplay}
                      voidMeterDetails={null}
                      meterReading={waterMoveInRead} 
                      meterNightReading={undefined} 
                      isReadRequired={moveInWaterReadRequired} 
                      voidMove={singleVoid.moveIn} 
                      voidAddress={singleVoid.propertyAddress} 
                      toggleMeterReadEntryPanel={toggleMeterReadEntryOnGasPillIn}
                      toggleMeterRead={toggleMeterReadEntry} 
                      isMeterReadOpen={isMeterReadOpen}
                      voidMoveNotificationsFuel={singleVoid.moveIn.notificationsToDisplay.water}
                      voidMoveInternalSPFuelStatus={undefined}
                      voidPodioNotificationFuel={singleVoid.moveIn.podioNotifications.water}
                      meterDetails={meterDetails}
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      findMeter={findMeter}
                      meterType={waterMeter}>
                      </VoidPropertyMeter> 
                      )
                    }
                    {                   
                      singleVoid.moveIn.notificationsToDisplay !== null &&
                      singleVoid.moveIn.notificationsToDisplay.gas !== null && 
                      singleVoid.moveIn.notificationsToDisplay.gas.status !== "notRequired" && (
                        <VoidPropertyMeter 
                          voidMoveNotifications={singleVoid.moveIn.notificationsToDisplay}
                          voidMeterDetails={singleVoid.gasMeterDetails}
                          meterReading={gasMoveInRead} 
                          meterNightReading={undefined} 
                          isReadRequired={moveInGasReadRequired} 
                          voidMove={singleVoid.moveIn} 
                          voidAddress={singleVoid.propertyAddress} 
                          toggleMeterReadEntryPanel={toggleMeterReadEntryOnGasPillIn}
                          toggleMeterRead={toggleMeterReadEntry} 
                          isMeterReadOpen={isMeterReadOpen}
                          voidMoveNotificationsFuel={singleVoid.moveIn.notificationsToDisplay.gas}
                          voidMoveInternalSPFuelStatus={singleVoid.moveIn.internalScottishPowerGasStatus}
                          voidPodioNotificationFuel={singleVoid.moveIn.podioNotifications.gas}
                          meterDetails={meterDetails}
                          findMeter={findMeter}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                          meterType={gasMeter}>
                        </VoidPropertyMeter> 
                      )
                    }
                    {
                      singleVoid.moveIn.notificationsToDisplay !== null &&
                      singleVoid.moveIn.notificationsToDisplay.electricity !== null && 
                      singleVoid.moveIn.notificationsToDisplay.electricity.status !== "notRequired" && (
                        <VoidPropertyMeter 
                          voidMeterDetails={singleVoid.electricMeterDetails}
                          meterReading={elecMoveInRead} 
                          meterNightReading={elecNightMoveInRead} 
                          isReadRequired={moveInElecReadRequired} 
                          voidMove={singleVoid.moveIn} 
                          voidMoveNotifications={singleVoid.moveIn.notificationsToDisplay}
                          voidAddress={singleVoid.propertyAddress} 
                          toggleMeterReadEntryPanel={toggleMeterReadEntryOnElecPillIn}
                          toggleMeterRead={toggleMeterReadEntry} 
                          isMeterReadOpen={isMeterReadOpen}
                          voidMoveNotificationsFuel={singleVoid.moveIn.notificationsToDisplay.electricity}
                          voidMoveInternalSPFuelStatus={singleVoid.moveIn.internalScottishPowerElectricityStatus}
                          voidPodioNotificationFuel={singleVoid.moveIn.podioNotifications.electricity}
                          meterDetails={meterDetails}
                          moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                          findMeter={findMeter}
                          meterType={elecMeter}>
                        </VoidPropertyMeter> 
                      )
                    }
            </MetersContainer>

            {isMeterReadOpen && (
              <MeterReadSubmitForm
                moveOutGasReadRequired={moveInGasReadRequired}
                fuelNotificationSent={fuelNotificationSent}
                singleVoid={singleVoid}
                propertyInfo={propertyInfo}
                handleInputChange={handleInputChange}
                getMeterRead={getMeterRead}
                meterOptions={meterOptions}
                isMeterReadOpen={isMeterReadOpen}
                fuelType={fuelType}
                hasAgentConfirmedMeterE7={hasAgentConfirmedMeterE7}
                setHasAgentConfirmedMeterE7={setHasAgentConfirmedMeterE7}
                setReadTakenToday={setReadTakenToday}
                isReadTakenToday={isReadTakenToday}
                setDay={setMoveInDay}
                setMonth={setMoveInMonth}
                setYear={setMoveInYear}
                handleInputtedDate={handleInputtedDate}
                readSubmitted={readSubmitted}
                handleMoveOutReadSubmit={handleMoveInReadSubmit}
                isMoveOutMeterReadInRange={isMoveInMeterReadInRange}
                meterMessage={meterMessage}
                submitAnotherRead={submitAnotherRead}
                voidMove={singleVoid.moveIn} 
                isMoveOut={moveOutSelectedNotMoveIn}
                propertyInfoSubmittedMoveDate={propertyInfo.submittedMoveInDate}
                propertyInfoMoveDateMeta={propertyInfo.submittedMoveInDateMeta}
              ></MeterReadSubmitForm>
            )}
          </MeterContentSection>
        </MeterReadSection>
      </>
      )
    }
    </>
    }
    </>
  );
};

export default SubmitMeterRead;
