import React from "react";
import styled from "styled-components";
import DropdownSelector from "../ScottishPower/DropdownSelector";
import {
  SteppedInput,
  TextInput,
  ToggleSwitch,
} from "vouch.ui.react.components";
import { Button } from "../../common/styles";
import { format } from "date-fns";
import { MeterOptions, PropertyInfo, Void } from "../../types/Void";



interface Props {
    moveOutGasReadRequired:boolean;
    fuelNotificationSent:(propertyMove:Void['moveIn' | 'moveOut']) => boolean;
    singleVoid:Void;
    propertyInfo: PropertyInfo;
    handleInputChange:(evt: React.ChangeEvent<HTMLInputElement>) => void;
    getMeterRead:(info: string) => void;
    meterOptions:MeterOptions[];
    isMeterReadOpen:boolean;
    fuelType:string;
    hasAgentConfirmedMeterE7:boolean;
    setHasAgentConfirmedMeterE7:(hasAgentConfirmedMeterE7:boolean) => void;
    setReadTakenToday:(isReadTakenToday:boolean) => void;
    isReadTakenToday:boolean;
    setDay:(day: string) => void;
    setMonth:(month: string) => void;
    setYear:(year: string) => void;
    handleInputtedDate:(e: Array<string>, setTheDay: (day: string) => void, setTheMonth: (month: string) => void, setTheYear: (year: string) => void) => void;
    readSubmitted:boolean;
    handleMoveOutReadSubmit:(fuelType:string) => void;
    isMoveOutMeterReadInRange:boolean;
    meterMessage:string;
    submitAnotherRead: (type: string, submittedMoveDate: string | undefined) => void;
    voidMove:Void['moveIn' | 'moveOut'];
    isMoveOut:boolean;
    propertyInfoSubmittedMoveDate:string | undefined;
    propertyInfoMoveDateMeta:string[] | undefined;
}

const MeterReadSectionInner = styled.div<{ isMeterReadOpen: boolean }>`
  position:relative;
  flex: 1;
`;

const MeterReadAdvice = styled.div`
  padding: 20px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  color: #34bebd;
  background: #fff;

  p {
    color: #ed941e;
  }
`;

const ErrorMessage = styled.span<{noErrors?: boolean}>`
  border-radius: 10px;
  padding: ${(props) => (props.noErrors ? "0px" : "20px")}; 
  border: 2px solid ${(props) => (props.noErrors ? "#fff" : "#ED1C24")}; 
  color: #ED1C24;
  font-size: 1rem;
  margin: 0 auto;
  width: 100% !important;
  text-align: center !important;
  display: inline-block !important;
    ul {
      padding: 0 20px !important;
    list-style-position: outside;
    display: list-item !important;
    li {
      list-style-type:disc;
      text-align: left;
      display: list-item !important;
    }

  }

`;

const ExpiredReadMessage = styled(ErrorMessage) `
  margin: 20px 0 0;
`

const MeterReadSubmitForm: React.FC<Props> = ({
  moveOutGasReadRequired,
  fuelNotificationSent,
  singleVoid,
  propertyInfo,
  handleInputChange,
  getMeterRead,
  meterOptions,
  isMeterReadOpen,
  fuelType,
  hasAgentConfirmedMeterE7,
  setHasAgentConfirmedMeterE7,
  setReadTakenToday,
  isReadTakenToday,
  setDay,
  setMonth,
  setYear,
  handleInputtedDate,
  readSubmitted,
  handleMoveOutReadSubmit,
  isMoveOutMeterReadInRange,
  meterMessage,
  submitAnotherRead,
  voidMove, 
  isMoveOut,
  propertyInfoSubmittedMoveDate,
  propertyInfoMoveDateMeta
}) => {
 

  return (
    <MeterReadSectionInner isMeterReadOpen={isMeterReadOpen}>  
    <div id="meterReadSection">

          <MeterReadAdvice>
            To get an accurate meter read, note the numbers from left
            to right including any leading 0s in your meter reading,
            for example <strong>002364</strong>. You can ignore the
            numbers after the decimal place and any numbers in red.
          </MeterReadAdvice>
        
      {
        !moveOutGasReadRequired &&
        fuelNotificationSent(voidMove) ? 
        <>
        {singleVoid &&
                voidMove &&
                voidMove.moveDate !== undefined &&
                voidMove.moveDate !==
                  "0001-01-01T00:00:00+00:00" ?
        <ExpiredReadMessage>
                    The meter read submission window has now closed for this notification request, and the reading for this meter may be estimated by the utility provider.
                  </ExpiredReadMessage>
        :
          <ExpiredReadMessage>
          Please add a move {isMoveOut ? "out" : "in"} date
        </ExpiredReadMessage> 
        }
        </>

                  : 
      <>
      <DropdownSelector
        introText={""}
        listType={"Meter Type"}
        getGeneralValue={getMeterRead}
        options={meterOptions}>
      </DropdownSelector>

      <div id="meterReadInput">
        {fuelType === "water" && (
          <TextInput
            value={propertyInfo?.waterMeterRead}
            onChange={(e: any) => handleInputChange(e)}
            type="text"
            data-cy="water-meter-input"
            label="Enter Water Meter Read"
            name={"waterMeterRead"}
          />
        )}
        {fuelType === "gas" && (
          <>
            <TextInput
              value={propertyInfo?.gasMeterRead}
              onChange={(e: any) => handleInputChange(e)}
              type="text"
              data-cy="gas-meter-input"
              label="Enter Gas Meter Read"
              name={"gasMeterRead"}
            />
            {
              singleVoid?.gasMeterDetails === null &&
                <TextInput
                value={propertyInfo?.gasMPRN}
                onChange={(e: any) => handleInputChange(e)}
                type="text"
                data-cy="gas-mprn-meter-input"
                label="Enter MPRN Number"
                name={"gasMPRN"}
                />
            }
          </>
        )}
    
        {fuelType === "elec" && (
          <>
            {singleVoid?.electricMeterDetails?.isEconomy7 || hasAgentConfirmedMeterE7 ? (
              <>
                <TextInput
                  value={propertyInfo?.electricityDayMeterRead}
                  onChange={(e: any) => handleInputChange(e)}
                  type="text"
                  data-cy="elec-meter-input"
                  label="Enter Day Electricity Read"
                  name={"electricityDayMeterRead"}
                />
                <TextInput
                  value={propertyInfo?.electricityNightMeterRead}
                  onChange={(e: any) => handleInputChange(e)}
                  type="text"
                  data-cy="elec-meter-input"
                  label="Enter Night Electricity Read"
                  name={"electricityNightMeterRead"}
                />
              </>
            ) : (
              <TextInput
                value={propertyInfo?.electricityDayMeterRead}
                onChange={(e: any) => handleInputChange(e)}
                type="text"
                data-cy="elec-meter-input"
                label="Enter Elec Meter Read"
                name={"electricityDayMeterRead"}
              />
            )}
            {
              singleVoid?.electricMeterDetails === null && 
              <TextInput
                value={propertyInfo?.elecMPAN}
                onChange={(e: any) => handleInputChange(e)}
                type="text"
                data-cy="elec-mpan-meter-input"
                label="Enter MPAN Number"
                name={"elecMPAN"}
              />
            }
          </>
        )}
      </div>
      <div>
        <div id="dateContainer">
          {
             singleVoid !== undefined && singleVoid.electricMeterDetails === null && fuelType === "elec" &&
              <small>
                <label id="dateToggle">
                  <span>Is the meter economy7?</span>
                  <ToggleSwitch
                    toggle={() =>
                      setHasAgentConfirmedMeterE7(!hasAgentConfirmedMeterE7)
                    }
                    initVal={hasAgentConfirmedMeterE7}
                  ></ToggleSwitch>
                </label>
              </small>
          }
          <small>
            <label>
              <span>Was the meter read taken today ?</span>      
              <ToggleSwitch
                toggle={() =>
                  setReadTakenToday(!isReadTakenToday)
                }
                initVal={isReadTakenToday}
              ></ToggleSwitch>
            </label>
          </small>
          {!isReadTakenToday && (
            <SteppedInput
              onChange={(e: any) => {
                handleInputtedDate(e, setDay, setMonth, setYear);
              }}
              values={propertyInfoMoveDateMeta || []}
              label="Date of Move Out Read (DD/MM/YYYY)"
              divider="/"
              onFormattedChange={() => {}}
              config={[
                {
                  label: "Day",
                  length: 2,
                  maxVal: 31,
                  minVal: 1,
                },
                {
                  label: "Month",
                  length: 2,
                  maxVal: 12,
                  minVal: 1,
                },
                {
                  label: "Year",
                  length: 4,
                  maxVal: 2050,
                  minVal: new Date().getFullYear() - 1,
                },
              ]}
            />
          )}
        </div>
        
        <div id="meterSubmit">
          {!readSubmitted ? (
              <>

                <> 
                 { voidMove.moveDate === "0001-01-01T00:00:00+00:00" ?
                   <ErrorMessage>
                     Please add a move { isMoveOut ? "out" : "in" } date
                   </ErrorMessage>
                   :
                   (
                    <>
                         
                          {
                            fuelType === "water" && (
                                <Button
                                tabIndex={0}
                                disabled={
                                    +propertyInfo?.waterMeterRead.length < 4
                                    }                                
                                    onClick={(e: any) => handleMoveOutReadSubmit("water")}
                                    >
                                        Submit
                                    </Button>            
                                ) 
                            }              
                        
                            {
                                fuelType === "gas" && (
                                <>
                                    {
                                    singleVoid &&
                                    voidMove &&
                                    voidMove.moveDate !== undefined &&
                                    voidMove.moveDate !==
                                    "0001-01-01T00:00:00+00:00" && (
                                        <ErrorMessage noErrors={
                                            (Boolean(isMoveOutMeterReadInRange && 
                                            singleVoid?.gasMeterDetails === null &&  
                                                propertyInfo?.gasMPRN !== undefined && 
                                                propertyInfo?.gasMPRN.toString().length >= 6 && propertyInfo?.gasMPRN.toString().length <= 11) || 
                                            Boolean( isMoveOutMeterReadInRange && singleVoid?.gasMeterDetails !== null && singleVoid?.gasMeterDetails.mprn))  }>
                                            <ul>
                                                {
                                                    !isMoveOutMeterReadInRange &&
                                                    <li>The meter read must be recorded within {isMoveOut ? "7" : "10" } days either
                                                    side of <br/><strong>
                                                    {format(new Date(voidMove.moveDate), "PPPP")}</strong>
                                                    </li>
                                                }
                                                {
                                                    (singleVoid?.gasMeterDetails === null && 
                                                    (propertyInfo?.gasMPRN === undefined || 
                                                        (propertyInfo?.gasMPRN !== undefined && 
                                                        (propertyInfo?.gasMPRN.toString().length <= 5 || propertyInfo?.gasMPRN.toString().length >= 12)))) &&
                                                    <li>The MPRN number must be between must be between 6 and 11 digits long<br/></li>
                                                }
                                            </ul>
                                        </ErrorMessage>
                                    )}
                                    
                                    <Button
                                        tabIndex={0}
                                        disabled={
                                            !isMoveOutMeterReadInRange ||
                                            +propertyInfo?.gasMeterRead.length < 4 ||
                                            (singleVoid?.gasMeterDetails === null && 
                                            (propertyInfo?.gasMPRN === undefined || 
                                            (propertyInfo?.gasMPRN !== undefined && 
                                            (propertyInfo?.gasMPRN.toString().length <= 5 ||  propertyInfo?.gasMPRN.toString().length >= 12))))                                    
                                        }
                                        onClick={(e: any) => handleMoveOutReadSubmit("gas")}
                                        >
                                        Submit
                                    </Button>
                                </>
                            ) 
                        }
                    
                        {
                            fuelType === "elec" &&      
                            (
                            <>
                                {
                                singleVoid &&
                                voidMove &&
                                voidMove.moveDate !== undefined &&
                                voidMove.moveDate !==
                                "0001-01-01T00:00:00+00:00" && (
                                <ErrorMessage noErrors={
                                    (Boolean(isMoveOutMeterReadInRange && 
                                        singleVoid?.electricMeterDetails === null &&  
                                        propertyInfo?.elecMPAN !== undefined && 
                                            propertyInfo?.elecMPAN.toString().length >= 13 && propertyInfo?.elecMPAN.toString().length <= 21) || 
                                        Boolean( isMoveOutMeterReadInRange && singleVoid?.electricMeterDetails !== null && singleVoid?.electricMeterDetails.mpan))
                                }>
                                    <ul>
                                    {
                                        !isMoveOutMeterReadInRange &&
                                        <li>The meter read must be recorded within {isMoveOut ? "7" : "10" } days either 
                                        side of the Move-{isMoveOut ? "Out" : "In"} Date of{" "}
                                        <strong>{format(new Date(voidMove.moveDate), "PPPP")}</strong>
                                        </li>
                                    }                                    
                                    {
                                        (singleVoid?.electricMeterDetails === null && 
                                        (propertyInfo?.elecMPAN === undefined || 
                                            (propertyInfo?.elecMPAN !== undefined && 
                                            (propertyInfo?.elecMPAN.toString().length <= 12 ||  propertyInfo?.elecMPAN.toString().length >= 22)))) &&
                                        <li>The MPAN number must be between must be between 13 and 21 digits long<br/></li>
                                    }
                                    </ul>
                                </ErrorMessage>
                                )}
                                
                                <Button
                                tabIndex={0}
                                className="meterSubmitButton"
                                disabled={
                                    !isMoveOutMeterReadInRange ||
                                    (singleVoid?.electricMeterDetails?.isEconomy7 ? (
                                    +propertyInfo?.electricityDayMeterRead.length < 4 ||
                                    +propertyInfo?.electricityNightMeterRead.length < 4) : 
                                    +propertyInfo?.electricityDayMeterRead.length < 4) ||
                                    (singleVoid?.electricMeterDetails === null && 
                                    (propertyInfo?.elecMPAN === undefined || 
                                    (propertyInfo?.elecMPAN !== undefined && 
                                    (propertyInfo?.elecMPAN.toString().length <= 12 ||  propertyInfo?.elecMPAN.toString().length >= 22))))                                    
                                }
                                onClick={(e: any) => handleMoveOutReadSubmit("elec")}>
                                Submit
                                </Button>
                            </>
                            )
                        }
                    
                      {
                          fuelType === "" && 
                            <ErrorMessage>
                              Please select a meter type
                            </ErrorMessage>
                        }
                   </>)
                 }
                </>

            </>
          ) : (
            <span id="addAdditionalRead">
              <span>{meterMessage}</span>
              {meterMessage !== "Submitting read..." && (
                <>
                  {fuelType === "gas" && (
                    <Button
                      onClick={() => submitAnotherRead("gas", propertyInfoSubmittedMoveDate)}
                    >
                      Add another read
                    </Button>
                  )}
                  {fuelType === "elec" && (
                    <Button
                      onClick={() => submitAnotherRead("elec", propertyInfoSubmittedMoveDate)}
                    >
                      Add another read
                    </Button>
                  )}
                </>
              )}
            </span>
          )}
        </div>
      </div>
    </>
}  
    </div>
  </MeterReadSectionInner>
  );
};

export default MeterReadSubmitForm;
