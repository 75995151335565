import React from 'react'
import styled from 'styled-components'
import {Colours, FontSizes} from '../../common/styles'

const ErrorContainer = styled.div`
display: flex;
img{
  width: 24px;
  height: 24px;
}
span{
    font-size: ${FontSizes.small};
    color: ${Colours.red};
}
`;

interface Props {
    message: string;
}

const Error: React.FC<Props> = ({
message
}) => {
return (
    <ErrorContainer>
        <img src="/assets/svg/error.svg" alt="error"/>
        <span>{message}</span>
    </ErrorContainer>
)
}

export default Error;