import baseStyled, { ThemedStyledInterface } from "styled-components";
import {Colours} from '../common/styles';

export const vouchLight = {
  name: "main",
  colors:{
    text: "#082842"
  },
  color: {
    primaryActive: Colours.teal,
    secondaryActive: Colours.blue,
    tertiaryActive: Colours.blue,
    primaryDisabled: Colours.darkGrey,
    mainHighlight: Colours.teal,
    alternateBackground: "#f2f2f2",
    mainBodyText: "#082842",
    highlightBodyText: "#ffffff",
    disabledBodyText: "#000000"
  },
};

export const vouchBot = {
  name: "bot",
  color: {
    primaryActive: "#34bebd",
    secondaryActive: "#082842",
    primaryDisabled: "#f2f2f2",
    mainHighlight: "#34bebd",
    alternateBackground: "#f2f2f2",
    mainBodyText: "#082842",
    highlightBodyText: "#ffffff",
    disabledBodyText: "#000000"
  },
};

export const vouchDark = {
  name: "mainDark",
};

export const goodLord = {
  name: "gl",
  color: {
    mainHighlight: "red",
    alternateBackground: "purple",
    mainBodyText: "orange",
  },
};

export const voidDark = {
  accentBackground: Colours.blue,
  accentDetail: Colours.teal,
  accentText: Colours.white,
  background: Colours.lightGrey,
  button: Colours.blue,
  buttonOutline: Colours.white,
  text: Colours.teal,
  noticeAccent: Colours.blue,
}

export const voidLight = {
  accentBackground: Colours.lightGrey,
  accentDetail: Colours.white,
  accentText: Colours.teal,
  background: Colours.white,
  button: Colours.teal,
  buttonOutline: "transparent",
  text: Colours.blue,
  noticeAccent: Colours.orange,
}

export type VouchLightTheme = typeof vouchLight;
export type VouchDarkTheme = typeof vouchDark;
export type GoodLordTheme = typeof goodLord;
export type voidDarkTheme = typeof voidLight;
export type voidLightTheme = typeof voidDark;

export default baseStyled as ThemedStyledInterface<VouchLightTheme>;
