import React from "react";
import styled from "../common/themes";
import { NavLink } from "react-router-dom";
import WarningAlert from "./DesignSystem/Alert/WarningAlert";

interface Props {
  shopType?: string;
}

const Title = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: right;
  span {
    color: ${(props) => props.theme.color.mainHighlight};
  }

  h1 {
    color: ${(props) => props.theme.color.mainHighlight};
  }

  h2 {
    color: ${(props) => props.theme.color.mainBodyText};
    display: flex;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
  }


  @media (min-width: 660px) {
    margin-top: 50px;
  }
`;

const StoreTypeContainer = styled.div`
  position: relative;
  margin: 0;
  span {
    position: absolute;
    top: 0;
    left: 10px;
    transform: rotate(4deg);
  }
`;

const BannerContainer = styled.div`
  margin: 15px 0;
  display: ${(props) => (props.theme.name === "bot" ? "none" : "")};

  #portalTitle {
      &:hover {
      cursor: default;
    }
  }
`;

const PaddedDiv = styled.div`
  padding: 20px;
  width: 100%;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.color.mainHighlight};
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const MainHeader: React.FC<Props> = ({ shopType }) => {
  return (
    <>
    {
      shopType === "portal" ? (
          <BannerContainer>
            <Title id="portalTitle">
              <img src="/assets/svg/vouchlogo.svg" alt="Vouch" />
              <StoreTypeContainer>
                {shopType === "portal" && <h1>Notification Dashboard</h1>}
              </StoreTypeContainer>
              <PaddedDiv>
                <WarningAlert title="Notification Dashboard is being replaced soon">
                  <div>
                    <p>The Notification Dashboard will be replaced at the end of August. <b>From the 6th of August, move outs can only be created in the new Utility Management dashboard.</b> You&apos;ll still be able to complete any in-progress change of occupancies on your Notification Dashboard.</p>
                    <p><StyledLink href="https://vouchstorage.blob.core.windows.net/static-files/2024Q3_Vouch_VoidManagement-flyer_compressed.pdf?sp=r&st=2024-08-01T09:26:27Z&se=2025-08-01T17:26:27Z&spr=https&sv=2022-11-02&sr=b&sig=NGp8nDD5KrLczGjEeRZzgmC1y8JwhR2U5Fy007e3kXs%3D" target="_blank">Click here to find out more</StyledLink> or reach out to your account manager for more information.</p>
                  </div>
                </WarningAlert>
              </PaddedDiv>
            </Title>
          </BannerContainer>
          ) : (
        <NavLink to="/">
          <BannerContainer>
            <Title>
              <img src="/assets/svg/vouchlogo.svg" alt="Vouch" />
              <StoreTypeContainer>
                {shopType === "landlord" && <h1>Landlord Offers</h1>}
                {shopType === "tenant" && <h1>Home Move Assistant</h1>}
                {shopType === "agent" && <h1>Agent Essentials</h1>}
              </StoreTypeContainer>
            </Title>
          </BannerContainer>
        </NavLink>
      )   
    }
    </>

  );
};

export default MainHeader;
