import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";

interface Props {
  alertEvent: () => void,
  message: string;
}

const NoReadMessage = styled.span`
  display: flex;
  align-items: center;
  cursor:pointer;

  svg {
    margin-left:10px;
    circle:nth-child(1) {
      fill: #ED941E;
    }
    path:nth-child(2) {
      fill: #FFF;
    }
`;


const AlertMessage: React.FC<Props> = ({
  alertEvent,
  message,
}) => {
 

  return (
    <NoReadMessage onClick={() => {
      alertEvent();
    }}><strong>{message}</strong> <SVG src="/assets/svg/warn.svg"></SVG></NoReadMessage>
  );
};

export default AlertMessage;
