import React, { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import ctx, { ctxType } from "../../context/ShopContext";
// components
import TextInput from "./TextInput";

interface Props {
  setSelectedAddress: Function;
  selectedAddress: Location;
  indexOfAddress?: number;
}

interface Location {
  id?: string;
  nameOrNumber?: string;
  line1?: string;
  line2?: string;
  line3?: string;
  city?: string;
  county?: string;
  country?: string;
  postcode?: string;
  latitude?: number;
  longitude?: number;
}

const swingIn = keyframes`
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  `;
const Container = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
`;

const FoundAddresses = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
  background: #f2f2f2;
  animation: ${swingIn} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  position: absolute;
  z-index: 100;
  bottom: 38px;
  width: 100%;
`;

const AddressItem = styled.div<{ selected: boolean }>`
  margin: 15px 5px;
  padding: 20px;
  background: ${(props) => (props.selected ? "#34bebd" : "white")};
  color: ${(props) => (props.selected ? "white" : "#082842")};
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }
`;

const GroupedInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  column-gap: 10px;

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const ManualInput = styled.div`
  display: grid;
`;

const PostcodeInput = styled(TextInput)`
  margin: 0 auto;
`;

const AddressSelector: React.FC<Props> = ({
  selectedAddress,
  setSelectedAddress,
  indexOfAddress,
}) => {
  const [searchedPostcode, setSearchedPostcode] = useState("");
  const [addresses, setAddresses] = useState<Array<Location>>([]);
  // const { propertyData }: ctxType = useContext(ctx);

  useEffect(() => {
    const WAIT = 2000; // miliseconds to wait until searching
    if (searchedPostcode.trim() === "") return;
    let timer = setTimeout(() => {
      findAddress(searchedPostcode);
    }, WAIT);

    return () => {
      clearTimeout(timer);
    };
  }, [searchedPostcode]);

  function handleAddressChange(addr: Location) {
    if (indexOfAddress !== undefined) {
      setSelectedAddress(addr, indexOfAddress);
    } else {
      setSelectedAddress(addr);
    }
    setAddresses([]);
  }

  function handleInputChange(e: React.FormEvent<EventTarget>) {
    const { value, name } = e.target as HTMLInputElement;
    const currVals = { ...selectedAddress };
    switch (name) {
      case "usrPostcode":
        setSearchedPostcode(value);
        break;
      case "line1":
        currVals.line1 = value;
        setSelectedAddress(currVals);
        break;
      case "line2":
        currVals.line2 = value;
        setSelectedAddress(currVals);
        break;
      case "city":
        currVals.city = value;
        setSelectedAddress(currVals);
        break;
      case "postcode":
        currVals.postcode = value;
        setSelectedAddress(currVals);
        break;
    }
  }

  async function findAddress(postcode: string) {
    const res = await fetch(
      `${process.env.REACT_APP_LOCATION_API}?postcode=${postcode}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setAddresses(data);
    }
  }

  function formatAddr(addr: Location): string {
    return `${addr.line1}, ${addr.line2 ? addr.line2 + ", " : ""} ${
      addr.postcode
    }`;
  }

  return (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          findAddress(searchedPostcode);
        }}
      >
        <PostcodeInput
          value={searchedPostcode}
          type="text"
          label="Find By Postcode"
          name="usrPostcode"
          onChange={(e: any) => handleInputChange(e)}
        ></PostcodeInput>
      </form>
      {addresses.length > 0 && (
        <FoundAddresses>
          {addresses.map((addr, index) => (
            <AddressItem
              key={addr.id}
              onClick={() => {
                handleAddressChange(addr);
              }}
              selected={addr.id === selectedAddress?.id}
            >
              {formatAddr(addr)}
            </AddressItem>
          ))}
        </FoundAddresses>
      )}
      <ManualInput>
        <TextInput
          value={selectedAddress.line1}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="Line 1"
          name="line1"
        />
        <TextInput
          value={selectedAddress.line2}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="Line 2"
          name="line2"
        />
        <GroupedInput>
          <TextInput
            value={selectedAddress.city}
            onChange={(e: any) => handleInputChange(e)}
            type="text"
            label="City"
            name="city"
          />
          <TextInput
            value={selectedAddress.postcode}
            onChange={(e: any) => handleInputChange(e)}
            type="text"
            label="Postcode"
            name="postcode"
          />
        </GroupedInput>
      </ManualInput>
    </Container>
  );
};

export default AddressSelector;
