import React, { useContext } from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import { Button } from "../../common/styles";
import { ToggleSwitch } from "vouch.ui.react.components";
import ctx, { ctxType } from "../../context/VoidContext";


interface Props {
  toggleMenu: () => void;
  menuOpen: boolean;
}

const Banner = styled.div<{darkModeEnabled:boolean}>`
  z-index: 11;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  left: 0;
  font-size: 0.8rem;
  padding: 5px 20px;
  width: 100%;
  text-align: center;
  background: #082842;
  color: ${props => props.theme.accentDetail};
  align-items: center;
  label {
    display: flex;
    align-items: center;
    >div {
      padding 0 2rem;
    }
  }

  img {
    height: 30px;
  }
  > div:nth-child(2) {
    flex: 1;
  }
  > div:nth-child(3),
  > div:nth-child(1) {
    flex: 0;
    display: flex;
  }

  > div:nth-child(3) {
    cursor: pointer;
  }


  > div:nth-child(1) {
    svg {
      path {
        fill: ${props => props.theme.accentDetail};
      }
    }
  }
`;

const Email = styled.span`
  display: inline-block !important;
  text-decoration: underline;
`;

const MenuContainer = styled.div`
  background: #fff;
  border-radius: 10px;

  span {
    padding: 0 !important;
    font-size: 12px;
  }

  p {
    margin-bottom: 0;
    margin-top: 30px;
  }

  > div {
    width: 100% !important;
    height: auto !important;
    padding: 0 !important;
    button {
      margin-left: 0;
      margin-right: 0;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  z-index: 10000;
  justify-content: center;
  align-items: center;
  top: 40px;
  left: 0;

  button {
    margin: 40px 20px;
  }
  h1,
  h3 {
    margin: 0;
    padding: 0 20px;
  }
  h1 {
    color: #34bebd;
  }
  div {
    background: #fff;
    height: 70%;
    width: 70%;
    max-width: 800px;
    padding: 40px;
    overflow-y: auto;
  }
  span {
    display: block;
    padding: 20px;
    strong {
      text-transform: capitalize;
    }
  }
`;

const PortalMenu: React.FC<Props> = ({ toggleMenu, menuOpen }) => {
  const { darkModeEnabled, setDarkModeEnabled }: ctxType = useContext(ctx);

  return (
    <>
      <Banner darkModeEnabled={darkModeEnabled}>
        <div>
          <SVG src="/assets/svg/vouchlogo.svg" />
        </div>{" "}
        <div></div>{" "}
        <label>
         <p><span id="toggleChoice">{darkModeEnabled ? "Dark Mode On" : "Dark Mode Off"}</span></p>
              <ToggleSwitch
                toggle={() => setDarkModeEnabled(!darkModeEnabled)}
                initVal={darkModeEnabled}>
              </ToggleSwitch>
            </label>

      </Banner>

      {menuOpen && (
        <Menu>
          <MenuContainer>
            <h2>Void Portal Support</h2>

            <p>
              <strong>Contact Us</strong>
            </p>
            <span>
              We have a dedicated team ready to assist you with any queries
              relating to your void. Simply get in touch at{" "}
              <Email>voids@vouch.co.uk</Email> where we can assist with your
              queries. Our normal operational hours are Monday to Friday 9am to
              5.30pm and we aim to respond within 24 hours of your message.
            </span>

            <p>
              <strong>What is the Void Portal?</strong>
            </p>
            <span>
              We confirm to you that your switch is underway and give you an
              exact date that you energy supply will seamlessly switch over to
              ScottishPower. We will also contact your existing fuel suppliers,
              water and local council. Fantastisch!
            </span>

            <p>
              <strong>Tutorial Videos</strong>
              <strong><span>Getting started</span></strong>
            </p>
            <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/2dp9kpuJDq8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>            <div>
              <Button onClick={() => toggleMenu()}>Close</Button>
            </div>
          </MenuContainer>
        </Menu>
      )}
    </>
  );
};

export default PortalMenu;
