import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Button,
  SteppedInput,
  TextInput,
  ToggleSwitch,
} from "vouch.ui.react.components";

import { Colours } from "../../common/styles";
import { ToggleButton } from "../../common/styles";

// types

// components

const Container = styled.div<{ collapse: boolean }>`
  display: ${(props) => (props.collapse ? "none" : "block")};
`;

const InputCheckBoxGroup = styled.div`
  text-align: left;

  label {
    display: flex;
    padding: 10px 0;
    font-size: 0.95rem;

    div {
      padding: 0 20px;
      align-self: center;
    }
  }

  input[type="checkbox"] {
    margin-right: 10px;
    font-size: 13px;
    top: 6px;
    position: relative;
  }
`;

const ButtonGroup = styled.div<{ showList?: boolean }>`
  display: ${(props) => (props.showList ? "flex" : "none")};
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  overflow-y: auto;
  padding: 15px;
  background: #f2f2f2;
  position: absolute;
  z-index: 100;
  border: 1px solid #f2f2f2;
  top: 100%;

  @media (max-width: 450px) {
    top: calc(100% - 10px);

    label {
      margin: 5px 0;
    }
  }
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
`;

const FormSummary = styled.div<{
  arePolicyDetailsComplete: boolean;
}>`
  padding: 0 20px;
  background: #f2f2f2;
  border-radius: 10px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) =>
    props.arePolicyDetailsComplete ? `${Colours.blue}` : `${Colours.red}`};
`;

const Select = styled.select`
  width: 100%;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid rgba(188, 190, 192, 0.5);
  option {
    padding: 10px;
  }
`;

interface Props {
  index: number;
  policyDetailsForm: Function;
}

interface InsuranceInfo {
  currentRentalProtectionSupplier: "";
  existingPolicyEndDate: "";
  addressLineOne: "";
  addressLineTwo: "";
  city: "";
  postcode: "";
  monthlyRent: "";
  preferredStartDate: "";
  policyLength: "";
}

const PropertyDetailsForm: React.FC<Props> = ({ index, policyDetailsForm }) => {
  const [insuranceSignUpInfo, setInsuranceSignUpInfo] = useState({
    currentRentalProtectionSupplier: "",
    existingPolicyEndDate: "",
    addressLineOne: "",
    addressLineTwo: "",
    city: "",
    postcode: "",
    monthlyRent: "",
    preferredStartDate: "",
    policyLength: "",
  });

  const [activeButton, setActiveButton] = useState("");

  const policyDurations = [
    { title: "6 Months", value: 1 },
    { title: "12 Months", value: 2 },
  ];

  const [addresses, setAddresses] = useState<Array<Location>>([]);
  const [chosenAddress, setChosenAddress] = useState<any>();
  const [existingPolicyEndDate, setExistingPolicyEndDate] = useState<string[]>(
    []
  );
  const [showPolicyDetails, setShowPolicyDetails] = useState<boolean>(false);

  const [preferredStartDate, setPreferredStartDate] = useState<string[]>([]);
  const [isApplicantsHaveNoPrevArrears, setIsApplicantsHaveNoPrevArrears] =
    useState<boolean>(false);
  const [
    isApplicantsCircumstancesChanged,
    setIsApplicantsCircumstancesChanged,
  ] = useState<boolean>(false);
  const [isRefRecommendationPass, setIsRefRecommendationPass] =
    useState<boolean>(false);
  const [arePolicyDetailsComplete, setArePolicyDetailsComplete] =
    useState<boolean>(false);

  useEffect(() => {
    const policyDate = [
      existingPolicyEndDate[0],
      existingPolicyEndDate[1],
      existingPolicyEndDate[2],
    ].join("/");
    const preferredStart = [
      preferredStartDate[0],
      preferredStartDate[1],
      preferredStartDate[2],
    ].join("/");

    setInsuranceSignUpInfo({
      ...insuranceSignUpInfo,
      existingPolicyEndDate: policyDate,
      preferredStartDate: preferredStart,
    });
  }, [existingPolicyEndDate, preferredStartDate]);

  useEffect(() => {
    if (
      insuranceSignUpInfo.currentRentalProtectionSupplier !== "" &&
      insuranceSignUpInfo.existingPolicyEndDate !== "" &&
      insuranceSignUpInfo.addressLineOne !== "" &&
      insuranceSignUpInfo.city !== "" &&
      insuranceSignUpInfo.postcode !== "" &&
      insuranceSignUpInfo.monthlyRent !== "" &&
      // insuranceSignUpInfo.policyLength !== "" &&
      insuranceSignUpInfo.preferredStartDate !== "" &&
      isApplicantsHaveNoPrevArrears &&
      isApplicantsCircumstancesChanged &&
      isRefRecommendationPass
    ) {
      setArePolicyDetailsComplete(true);
      policyDetailsForm(insuranceSignUpInfo);
    } else {
      setArePolicyDetailsComplete(false);
    }
  }, [
    insuranceSignUpInfo,
    isRefRecommendationPass,
    isApplicantsCircumstancesChanged,
    isApplicantsHaveNoPrevArrears,
  ]);

  const getDuration = (e: any) => {
    console.log(e.target.value);
    
    // const label = options.filter((item: any) => {
    //   return e.target.id.toString() === item.value.toString();
    // });
    // const name = label[0].title;
    // setDropdownLabel(name);
    // setActiveOccupancyButton(e.target.id);
    // ToggleIt();
    // getGeneralValue(e.target.id, name);
  };

  function chooseAddress(e: any): void {
    addresses.filter((chosenAddress: any) => {
      if (chosenAddress?.id === e.target.value) {
        setChosenAddress(chosenAddress);
        setInsuranceSignUpInfo({
          ...insuranceSignUpInfo,
          addressLineOne: chosenAddress?.line1,
          addressLineTwo: chosenAddress?.line2,
          city: chosenAddress?.city,
          postcode: chosenAddress?.postcode,
        });
        return "";
      }
    });
  }

  const toggleView = (e: any) => {
    setShowPolicyDetails(!showPolicyDetails);
  };

  function handleInputChange(evt: any) {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setInsuranceSignUpInfo({
      ...insuranceSignUpInfo,
      [evt.target.name]: value,
    });
  }
  async function findAddress(postcode: string) {
    const res = await fetch(
      `${process.env.REACT_APP_LOCATION_API}?postcode=${postcode}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setAddresses(data);
    }
  }

  useEffect(() => {
    findAddress(insuranceSignUpInfo.postcode);
  }, [insuranceSignUpInfo.postcode]);

  function formatAddr(addr: any): string {
    return `${addr.line1}, ${addr.line2 ? addr.line2 + ", " : ""} ${
      addr.postcode
    }`;
  }

  return (
    <>
      <FormSummary
        arePolicyDetailsComplete={arePolicyDetailsComplete}
        onClick={toggleView}
      >
        {`${chosenAddress?.line1 || insuranceSignUpInfo.addressLineOne}`
          ? `Policy Address: ${
              chosenAddress?.line1 || insuranceSignUpInfo.addressLineOne
            }, ${chosenAddress?.postcode || insuranceSignUpInfo.postcode}`
          : ""}

        {chosenAddress?.line1 || insuranceSignUpInfo.addressLineOne ? (
          <Button>{showPolicyDetails ? "View" : "Hide"} Details</Button>
        ) : null}
      </FormSummary>
      <Container collapse={showPolicyDetails}>
        <h3>Property {index}</h3>
        <InputCheckBoxGroup>
          <label>
            <span>
              I can confirm all applicants in the property have no previous
              arrears
            </span>
            <ToggleSwitch
              toggle={() =>
                setIsApplicantsHaveNoPrevArrears(!isApplicantsHaveNoPrevArrears)
              }
              initVal={isApplicantsHaveNoPrevArrears}
            ></ToggleSwitch>
          </label>
          <label>
            <span>
              I can confirm initial referencing recommendation was pass
            </span>
            <ToggleSwitch
              toggle={() =>
                setIsRefRecommendationPass(!isRefRecommendationPass)
              }
              initVal={isRefRecommendationPass}
            ></ToggleSwitch>
          </label>
          <label>
            <span>
              I can confirm there are no changes to any applicants circumstance,
              to my knowledge
            </span>
            <ToggleSwitch
              toggle={() =>
                setIsApplicantsCircumstancesChanged(
                  !isApplicantsCircumstancesChanged
                )
              }
              initVal={isApplicantsCircumstancesChanged}
            ></ToggleSwitch>
          </label>
        </InputCheckBoxGroup>
        <InputContainer>
          <TextInput
            type="text"
            name="postcode"
            label="Address Postcode"
            maxLength={50}
            minLength={2}
            regex={
              /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
            }
            value={chosenAddress?.postcode || insuranceSignUpInfo.postcode}
            onChange={(e: any) => handleInputChange(e)}
          />
          <label htmlFor="tenancyAddress">
            <Select
              defaultValue="default"
              name="tenancyAddress"
              id="tenancyAddress"
              onChange={(e) => {
                chooseAddress(e);
              }}
            >
              <option value={"default"} disabled>
                Select your tenancy address...
              </option>
              {addresses.map((addressItem: any, index: number) => {
                return (
                  <option value={addressItem.id} key={index}>
                    {" "}
                    {formatAddr(addressItem)}
                  </option>
                );
              })}
            </Select>
          </label>
        </InputContainer>

        <InputContainer>
          <TextInput
            type="text"
            name="addressLineOne"
            label="Address Line One"
            maxLength={50}
            minLength={2}
            regex={/^[a-zA-Z0-9\s-]+$/}
            value={chosenAddress?.line1 || insuranceSignUpInfo.addressLineOne}
            onChange={(e: any) => handleInputChange(e)}
          />
          <TextInput
            type="text"
            name="addressLineTwo"
            label="Address Line Two"
            maxLength={50}
            minLength={0}
            // regex={/^[a-zA-Z\s-]+$/}
            value={chosenAddress?.line2 || insuranceSignUpInfo.addressLineTwo}
            onChange={(e: any) => handleInputChange(e)}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            type="text"
            name="city"
            label="Address City"
            maxLength={50}
            minLength={2}
            regex={/^[a-zA-Z\s-]+$/}
            value={chosenAddress?.city || insuranceSignUpInfo.city}
            onChange={(e: any) => handleInputChange(e)}
          />

          <SteppedInput
            onChange={setExistingPolicyEndDate}
            values={existingPolicyEndDate}
            label="Policy End Date (YYYY/MM/DD)"
            divider="/"
            config={[
              {
                label: "Year",
                length: 4,
                maxVal: 2050,
                minVal: new Date().getFullYear() - 1,
              },
              {
                label: "Month",
                length: 2,
                maxVal: 12,
                minVal: 1,
              },
              { label: "Day", length: 2, maxVal: 31, minVal: 1 },
            ]}
          />
        </InputContainer>
        <InputContainer>
          <TextInput
            type="number"
            name="monthlyRent"
            label="Monthly Rent"
            maxLength={50}
            minLength={2}
            regex={/^[0-9]+$/}
            value={insuranceSignUpInfo.monthlyRent}
            onChange={(e: any) => handleInputChange(e)}
          />
          <SteppedInput
            onChange={setPreferredStartDate}
            values={preferredStartDate}
            label="Preferred Start Date (YYYY/MM/DD)"
            divider="/"
            config={[
              {
                label: "Year",
                length: 4,
                maxVal: 2050,
                minVal: new Date().getFullYear() - 1,
              },
              {
                label: "Month",
                length: 2,
                maxVal: 12,
                minVal: 1,
              },
              { label: "Day", length: 2, maxVal: 31, minVal: 1 },
            ]}
          />
        </InputContainer>
        <TextInput
          type="text"
          name="currentRentalProtectionSupplier"
          label="Current Protection Supplier"
          maxLength={50}
          minLength={2}
          regex={/^[a-zA-Z'.\s-]+$/}
          value={insuranceSignUpInfo.currentRentalProtectionSupplier}
          onChange={(e: any) => handleInputChange(e)}
        />
        <ButtonGroup>
          {policyDurations.map((roomItem) => {
            return (
              <ToggleButton
                key={roomItem.title}
                isActive={roomItem.value.toString() === activeButton}
              >
                <span className="radio__label">{roomItem.title}</span>
                <input
                  hidden
                  type="radio"
                  id={roomItem.value.toString()}
                  name="Policy Length"
                  onChange={() => {
                    getDuration(roomItem);
                  }}
                />
              </ToggleButton>
            );
          })}
        </ButtonGroup>
      </Container>
    </>
  );
};
export default /* withAITracking(reactPlugin,  */ PropertyDetailsForm;
