import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

interface Props {
  type: string;
  label: string;
  onChange: Function;
  name?: string;
  required?: boolean;
  value: any;
}

const TextInputContainer = styled.div<{ focused: boolean }>`
  margin: 10px 0;
  border-style: solid;
  border-color: rgba(188, 190, 192, 0.5);
  border-width: 1px;
  padding: 18px;
  border-radius: 5px;
  position: relative;
  text-align: left;
  cursor: text;
  border-color: ${(props) => (props.focused ? "#34bebd" : "")};
`;

const TextInputElement = styled.input`
  outline: none;
  border: none;
  background: none;
  width: 100%;
`;

const TextInputLabel = styled.span<{ focused: boolean }>`
  position: absolute;
  color: ${(props) => (props.focused ? "#34bebd" : "#bcbec0")};
  transform: ${(props) =>
    props.focused ? "translateY(-29px) scale(0.71)" : ""};
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  cursor: text;
  white-space: nowrap;
  font-size: 14px;
  background: ${(props) => (props.focused ? "white" : "transparent")};
  padding: 2px 8px;
`;

const TextInput: React.FC<Props> = ({
  label,
  onChange,
  name,
  required,
  type,
  value,
}) => {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);

  function handleBlur() {
    //@ts-ignore
    if (inputRef.current.value.trim() === "") {
      setFocused(false);
    } else {
      setFocused(true);
    }
  }

  function handleFocus() {
    // @ts-ignore
    inputRef.current.focus();
    setFocused(true);
  }

  useEffect(() => {
    //@ts-ignore
    if (inputRef.current.value.trim() !== "") {
      setFocused(true);
    }
  }, [value]);

  return (
    <TextInputContainer focused={focused} onClick={() => handleFocus()}>
      <TextInputLabel
        focused={focused}
        id="text-input"
        onClick={() => setFocused(true)}
        onFocus={() => handleFocus()}
        onBeforeInputCapture={() => handleFocus()}
        onBlur={() => handleBlur()}
      >
        {label}
      </TextInputLabel>
      <TextInputElement
        type={type || "text"}
        name={name}
        required={required}
        value={value}
        ref={inputRef}
        onClick={() => setFocused(true)}
        onBlur={() => handleBlur()}
        onChange={(e) => onChange(e)}
        onFocus={() => handleFocus()}
        onBeforeInputCapture={() => handleFocus()}
        tabIndex={0}
      />
    </TextInputContainer>
  );
};

export default TextInput;
