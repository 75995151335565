
  export const bedroomOptions = [
    { title: "1 Bedroom", value: 1 },
    { title: "2 Bedrooms", value: 2 },
    { title: "3 Bedrooms", value: 3 },
    { title: "4 Bedrooms", value: 4 },
    { title: "5+ Bedrooms", value: 5 }
  ];

  export const occupantOptions = [
    { title: "1 Person", value: 1 },
    { title: "2 People", value: 2 },
    { title: "3 People", value: 3 },
    { title: "4 People", value: 4 },
    { title: "5+ People", value: 5 }
  ];

  export const buildingOptions = [
    { title: "Bungalow", value: 1 },
    { title: "Detached House", value: 2 },
    { title: "Flat or Maisonette", value: 3 },
    { title: "Semi-Detached House", value: 4 },
    { title: "Terraced House", value: 5 },
    { title: "Unknown", value: 6 }
  ];

  export const titleOptions = [
    { title: "Mr", value: 1 },
    { title: "Mx", value: 2 },
    { title: "Mrs", value: 3 },
    { title: "Miss", value: 4 },
    { title: "Ms", value: 5 },
    { title: "Dr", value: 6 }
  ];