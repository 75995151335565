import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ToggleSwitch } from "vouch.ui.react.components";

interface Props {
  step: number;
  setValidInput: Function;
  setNotificationDetails: Function;
}
const Container = styled.div`
  padding: 50px 0;
`;

const AlternateProgBar = styled.p`
  padding: 30px 0;
  span {
    color: rgba(128, 128, 128, 0.5);
  }
  color: #082842;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-weight: 700;
`;
const CheckBoxContainer = styled.div`
  .optionText {
    justify-content: end;
  }

  .utilityChoice {
    margin-left: auto;
    font-size: 0.8rem;
    line-height: 18px;
    align-items: center;
    padding: 10px 0;
  }

  label {
    display: flex;
    align-items: baseline;
    .notice {
      padding: 0 1rem 0 0;
    }
    div {
      padding: 0 1rem 0 0;
    }
  }
`;

const AddNotificationsStep: React.FC<Props> = ({
  step,
  setValidInput,
  setNotificationDetails,
}) => {
  const [notifyWater, setNotifyWater] = useState<boolean>(true);
  const [notifyGas, setNotifyGas] = useState<boolean>(true);
  const [notifyElectricity, setNotifyElectricity] = useState<boolean>(true);
  const [notifyCouncilTax, setNotifyCouncilTax] = useState<boolean>(true);

  const [notifications, setNotifications] = useState({
    water: true,
    councilTax: true,
    gas: true,
    electric: true,
  });
  useEffect(() => {
    setNotifications({
      ...notifications,
      water: notifyWater,
      gas: notifyGas,
      electric: notifyElectricity,
      councilTax: notifyCouncilTax,
    });
  }, [notifyGas, notifyWater, notifyCouncilTax, notifyElectricity]);

  useEffect(() => {
    if (!notifyGas && !notifyWater && !notifyCouncilTax && !notifyElectricity) {
      setValidInput(false);
    } else {
      setNotificationDetails(notifications);
      setValidInput(true);
    }
  }, [
    notifications,
    notifyGas,
    notifyWater,
    notifyCouncilTax,
    notifyElectricity,
  ]);

  if (step !== 4) {
    return null;
  }
  return (
    <>
      <AlternateProgBar>
        <span>Add Property / Add Landlord / Add Lead Tenant / </span>Choose
        Notifications / <span>Summary</span>
      </AlternateProgBar>
      <Container>
        <h3>Add Notifications</h3>
        <CheckBoxContainer>
          <label className="utilityChoice">
            <ToggleSwitch
              toggle={() => setNotifyWater(!notifyWater)}
              initVal={notifyWater}
            ></ToggleSwitch>
            <span>Notify Water Board about the void for this property</span>
          </label>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <label className="utilityChoice">
            <ToggleSwitch
              toggle={() => setNotifyCouncilTax(!notifyCouncilTax)}
              initVal={notifyCouncilTax}
            ></ToggleSwitch>
            <span>
              Notify Council regarding Council Tax during the void for this
              property
            </span>
          </label>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <label className="utilityChoice">
            <ToggleSwitch
              toggle={() => setNotifyElectricity(!notifyElectricity)}
              initVal={notifyElectricity}
            ></ToggleSwitch>
            <span>
              Notify Electricity Supplier about the void for this property
            </span>
          </label>
        </CheckBoxContainer>
        <CheckBoxContainer>
          <label className="utilityChoice">
            <ToggleSwitch
              toggle={() => setNotifyGas(!notifyGas)}
              initVal={notifyGas}
            ></ToggleSwitch>
            <span>Notify Gas Supplier about the void for this property</span>
          </label>
        </CheckBoxContainer>
      </Container>
    </>
  );
};

export default AddNotificationsStep;
