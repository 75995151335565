import { addDays, endOfDay, isBefore, isDate, isSameDay, isWithinInterval, startOfDay, subDays, format, formatISO } from "date-fns";
import { MoveIn, MoveOut, UtilityDetails, Void, DateRange, PropertyInfo } from "../types/Void";

export function formatVoidPortalEnums(state: string, type: string, provider?:string) {
    
  switch(state){
    case "notSent":
        return "Preparing to send";
    case "notRequired":
        return "Not Required";
    case "sentAndConfirmed":
        return "Confirmed";
    case "supportedNotYetSent":
        return "In Progress";
}
  
  if(type === "Switch") {
    switch(state){
      case "failedToProcess":
      case "successful":
      case "notified":
      case "notifiedByPodio":
      case "rejected":
      case "live":
          return "Notification Sent";
      case "notSent":
          return "Preparing to send";
      case "cancelled":
          return "Notification Cancelled";
      case "processing":
      case "pending":
          return "Processing Notification";
      default:
          return "";
  }  
}

if(type === "notifications") {
    switch(state){
      case "sent":
        return `Sent ${provider ? (`to ${provider}`):("")}`;
      case "alreadySupplied":
        return `${provider ? (`Fuel Supplied by ${provider}`):("Not Supported")}`;
      case "notSupported":
        return `${provider ? (`Not supported by ${provider}`):("Not Supported")}`;
      case "failed":
        return `${provider ? (`${provider} unable to supply`):("Unable to supply")}`;
      case "newlySupplied":
        return `${provider ? (`Recently supplied by ${provider}`):("Recently supplied")}`
      case "pending":
        return `${provider ? (`Awaiting confirmation from ${provider}`):("Awaiting confirmation from supplier")}`
      case "notified":
      case "notifiedByPodio":
        return `${provider ? (`Submitted to, and confirmed by ${provider}`):("Submitted to, and confirmed by supplier")}`;
      default:
          return "";
    }  
  }  
}

export function getProgressColour(status: string): string {
  switch(status){
      case "notSent":
      case "pending":
      case "processing":
          return "#082842";
      case "notified":
      case "notifiedByPodio":
      case "successful":
      case "live":
          return "#34bebd"; 
      case "rejected":
      case "failedToProcess":
      case "cancelled":
          return "#ED1C24"; 
      case "Action Required":
          return "#ED941E"; 
      default:
          return "#BCBEC0";
  }
}

export function getSVGIcon(status: string): string {
  switch(status){
      case "notifiedByPodio":
      case "notified":
      case "processing":
          return "/assets/svg/circle-tick.svg";
      case "pending":
          return "/assets/svg/test.svg"; 
      default:
          return "";
  }
}

export function isNotNullOrUndefined(value:any) {
  if(value !== undefined && value !== null ) {
    return true
  }
  return false
}

export function hasNotificationBeenSent (notificationObj: any,fuel:any ) {
  if(notificationObj !== null && 
    notificationObj[fuel] !== undefined && 
    notificationObj[fuel] !== null && 
    notificationObj[fuel].sentToPodioDate !== undefined && 
    notificationObj[fuel].sentToPodioDate !== "0001-01-01T00:00:00+00:00" ) {
    return true
  }
  return false
}

  
export function fuelNotificationSent(propertyMove:Void['moveIn' | 'moveOut']) {
  if((propertyMove.internalScottishPowerElectricityStatus && 
      propertyMove.internalScottishPowerElectricityStatus.sentDate && 
      propertyMove.internalScottishPowerElectricityStatus.sentDate !== "0001-01-01T00:00:00+00:00") || 
      (propertyMove.internalScottishPowerGasStatus && 
      propertyMove.internalScottishPowerGasStatus.sentDate && 
      propertyMove.internalScottishPowerGasStatus.sentDate !== "0001-01-01T00:00:00+00:00") ||
      (propertyMove.podioNotifications.gas.sentToPodioDate && 
       propertyMove.podioNotifications.gas.sentToPodioDate !== "0001-01-01T00:00:00+00:00") || 
      (propertyMove.podioNotifications.electricity.sentToPodioDate && 
       propertyMove.podioNotifications.electricity.sentToPodioDate !== "0001-01-01T00:00:00+00:00")
      ) {
        return true
      }
        return false
}


export function moveHasBeenSentOrHasReading(notificationObj: any, readingType:any, fuel:any, providerStatusObj?: any) {
  if
  ((providerStatusObj !== null && providerStatusObj !== undefined && providerStatusObj.sentDate !== undefined && providerStatusObj.sentDate !== "0001-01-01T00:00:00+00:00") || 
  (notificationObj !== null && notificationObj[fuel] !== null && notificationObj[fuel] !== undefined && notificationObj[fuel].sentToPodioDate !== "0001-01-01T00:00:00+00:00") || 
  (readingType !== null && readingType.value)) {
    return true
  }
  return false
}

export function updateDateFNSMonth(month: string): number {
  if(month === "01") {
    return 0;
  }

  const newMonth = +month - 1;
  return newMonth;
}

export function determineMoveDisplayStatus(moveUtility: UtilityDetails, statuses: string[]):boolean {
  if(moveUtility === null || moveUtility === undefined ) return false
  return statuses.some((statusString) => {
    return moveUtility.status === statusString ? true : false
  })
}

export function checkReadIsInRange(moveDate:string, submittedMoveYear:string, submittedMoveMonth:string, submittedMoveDay:string, setIsReadInRange: (isReadInRange:boolean)=> void, rangeWindow:number, isReadTakenToday:boolean) {
  let submittedDate: Date;
  const tomorrow = startOfDay(addDays(new Date(), 1));
  const today = new Date();

  if(isReadTakenToday) {
    submittedDate = today;
  } else {
    if(submittedMoveYear === "" || submittedMoveMonth === "" ||  submittedMoveDay === "") {
      return 
    }
    
    const updatedMonth = updateDateFNSMonth(submittedMoveMonth)
    submittedDate = startOfDay(new Date(+submittedMoveYear, updatedMonth, +submittedMoveDay, 0,0,0,0));  
  }
  
  if(isDate(submittedDate)) {
    const isDateWithinRange = isWithinInterval(
      submittedDate, { 
        start:startOfDay(subDays(new Date(moveDate), rangeWindow)),
        end: endOfDay(addDays(new Date(moveDate), rangeWindow))
    })
    
  const isDateBeforeTomorrow = isBefore(submittedDate, tomorrow)
  
  if(isDateWithinRange && isDateBeforeTomorrow) {
    setIsReadInRange(true);
    return;
  }
  setIsReadInRange(false);
  return;

}

}

export function handleInputtedDate(
  e: Array<string>,
  setTheDay: (day: string) => void,
  setTheMonth: (month: string) => void,
  setTheYear: (year: string) => void
) {
  if (e[0] !== undefined && e[0].length === 2) {
    const inputtedDay = e[0];
    setTheDay(inputtedDay);
  }
  if (e[1] !== undefined && e[1].length === 2) {
    const inputtedMonth = e[1];
    setTheMonth(inputtedMonth);
  }
  if (e[2] !== undefined && e[2].length === 4) {
    const inputtedYear = e[2];
    setTheYear(inputtedYear);
  }
}

export function editDateWithinRange(
  currentMoveDate: string | undefined,
  updatedMoveDate: string | undefined,
  voidMove: MoveIn | MoveOut | undefined,
  moveErrorMessage: (message: string) => void,
  moveWithinRangeMessage: (rangeMessage: DateRange) => void,
  moveOutSelectedNotMoveIn: boolean,
) {
  let moveDate;
  let newMoveDate;

  if(voidMove === undefined || voidMove === null) {
    return
  }

  if (
    voidMove.podioNotifications.electricity.sentToPodioDate !== "0001-01-01T00:00:00+00:00" ||
    voidMove.podioNotifications.gas.sentToPodioDate !== "0001-01-01T00:00:00+00:00" ||
    voidMove.podioNotifications.water.sentToPodioDate !== "0001-01-01T00:00:00+00:00" ||
    voidMove.podioNotifications.council.sentToPodioDate !== "0001-01-01T00:00:00+00:00" ||
    voidMove.internalScottishPowerElectricityStatus.sentDate !== "0001-01-01T00:00:00+00:00" ||
    voidMove.internalScottishPowerGasStatus.sentDate !== "0001-01-01T00:00:00+00:00"
  ) {
    moveErrorMessage(`Your move ${moveOutSelectedNotMoveIn ? "out" : "in"} date can't be updated as the property notifications may have already been sent`);
    moveWithinRangeMessage("WONT_HAPPEN");
  } else {
    moveWithinRangeMessage("IN_TIME");
    moveErrorMessage("");
  }

  if (updatedMoveDate === undefined || updatedMoveDate === "") {
    return;
  }

  if(!currentMoveDate || currentMoveDate === "0001-01-01T00:00:00+00:00") {
    return;
  }
  
  moveDate = new Date(currentMoveDate);   
  newMoveDate = new Date(updatedMoveDate);

  if(moveDate !== undefined && (isSameDay(newMoveDate, moveDate) || moveOutSelectedNotMoveIn ? isBefore(newMoveDate, moveDate) : isBefore(moveDate, newMoveDate))) {
    moveErrorMessage("");
    moveWithinRangeMessage("IN_TIME");
    
  } else {
    moveWithinRangeMessage("WONT_HAPPEN");
    moveErrorMessage(`Your move ${moveOutSelectedNotMoveIn ? "out" : "in"} date must be the same day as, or ${moveOutSelectedNotMoveIn ? "before" : "after"} your move ${moveOutSelectedNotMoveIn ? "in" : "out"} date ${format(moveDate, "PPPP")}`);
    return
  }
}


export function returnMeterIdentifier(meterDetails: Void['gasMeterDetails'] | Void['electricMeterDetails'], propertyInfo: PropertyInfo, meterType: string): number| undefined {
 switch(meterType) {
  case "gas":
    if(meterDetails !== null && "mprn" in meterDetails ) {
      return meterDetails.mprn;
    }
    
    if(propertyInfo !== undefined && propertyInfo?.gasMPRN) {
      return +propertyInfo?.gasMPRN;
    }
    break;
  case "electricity":
    if(meterDetails !== null && "mpan" in meterDetails ) {
      return meterDetails.mpan;
    }
      
    if(propertyInfo !== undefined && propertyInfo?.elecMPAN) {
      return +propertyInfo?.elecMPAN;
    }
    break;
 }
}

export function returnNewMeterReading(submittedMeterRead: string, isReadTakenToday: boolean, submittedMoveDate: string|undefined) {
  if(submittedMoveDate === undefined) {
    return
  }

  const readingDate = isReadTakenToday ? formatISO(new Date()) :
    formatISO(new Date(submittedMoveDate));

  const updatedMeterReading = {
    value: submittedMeterRead,
    readingDate: readingDate,
    addedDate: formatISO(new Date()),
  }
  return updatedMeterReading
}