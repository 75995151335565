import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import ctx, { ctxType } from "../../context/ShopContext";
import { Button, InputWrapper, AlternateButton } from "../../common/styles";
import TextInput from "../Input/TextInput";
import AddressSelector from "../Input/AddressSelector";
import Error from "../Input/Error";
import { noHookCreateLead } from "../../hooks/hooks";
import { Deal } from "../../types/Store";
import { SteppedInput } from "vouch.ui.react.components";

interface Props {
  deal?: Deal;
  integration: string;
  setOpen: Function;
  goBack: Function;
  emailProp: string;
  storeName: string;
  dealType: "CALLBACK" | "CALLNOW" | "GETNOW" | null;
  phoneNumber?: string | null;
  setRevealNumber?: Function;
}

interface Location {
  id: string;
  nameOrNumber?: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  county?: string;
  country?: string;
  postcode: string;
  latitude?: number;
  longitude?: number;
}

const NewUserContainer = styled.div`
  height: 100%;
  width: 100%;

  background-color: white;
  text-align: left;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 70vh;
  margin: 0 auto;
  padding: 10px;
`;

const SignUpBlurb = styled.div``;

const SuccessBlurb = styled(SignUpBlurb)`
  text-align: center;

  #deal-highlight {
    color: #34bebd;
    font-weight: bold;
  }
`;

const TitleContainer = styled.h2``;

const DescriptionContainer = styled.p``;

const ControlsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CodeToQuote = styled.div`
  background: #f2f2f2;
  width: fit-content;
  padding: 15px;
  margin: 0 auto;
  font-weight: bold;
  border-radius: 10px;
  font-size: 1.5rem;
`;

const PhoneNumber = styled.div`
  margin: 0 auto;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GroupedInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0 10px;
`;

const ErrorList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NewUser: React.FC<Props> = ({
  deal,
  integration,
  setOpen,
  goBack,
  emailProp,
  storeName,
  dealType,
  phoneNumber,
  setRevealNumber,
}) => {
  const [email, setEmail] = useState(emailProp);
  const [firstName, setFirstName] = useState("");
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");
  const shopCtx: ctxType = useContext(ctx);
  const [success, setSuccess] = useState(false);
  const [posting, setPosting] = useState(false);
  const [validForm, setValidForm] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<Location>({
    city: "",
    line1: "",
    postcode: "",
    id: "",
  });
  /* 
    TCLI specific fields
  */
  const [dob, setDOB] = useState<string[]>([]);
  const [dobErrors, setDobErrors] = useState<string[]>([]);
  const [tenancyLength, setTenancyLength] = useState(12);
  const [startDateErrors, setStartDateErrors] = useState<string[]>([]);
  const [tenancyStartDate, setTenancyStartDate] = useState<string[]>([]);
  useEffect(() => {
    if (
      email &&
      email.includes("@") &&
      email.includes(".") &&
      selectedAddress.postcode.trim() !== "" &&
      selectedAddress.line1.trim() !== "" &&
      firstName.trim() !== "" &&
      lastName.trim() !== ""
    ) {
      if (integration === "tcli") {
        if (
          +tenancyLength !== 0 &&
          startDateErrors.length === 0 &&
          dobErrors.length === 0
        ) {
          setValidForm(true);
          return;
        } else {
          setValidForm(false);
          return;
        }
      }
      setValidForm(true);
      return;
    }
    setValidForm(false);
  }, [
    email,
    selectedAddress,
    firstName,
    lastName,
    dob,
    tenancyLength,
    tenancyStartDate,
  ]);

  useEffect(() => {
    return () => {
      if (phoneNumber) {
        if (setRevealNumber) {
          setRevealNumber(true);
        }
      }
    };
  }, []);

  function handleInputChange(e: React.FormEvent<EventTarget>) {
    const { value, name } = e.target as HTMLInputElement;
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
    }
  }

  async function handlePost() {
    setPosting(true);

    let customTracks: any = {};

    if (dealType === "CALLNOW") {
      customTracks.servicesId = "Vouch Inbound";
    }

    if (integration === "tcli") {
      const dobCopy = [...dob];
      const moveInCpy = [...tenancyStartDate];

      customTracks = {
        // format as MM DD YYYY for those bloody americans
        dob: new Date(
          [dobCopy[1], dobCopy[0], dobCopy[2]].join("-")
        ).toISOString(),
        moveIn: new Date(
          [moveInCpy[1], moveInCpy[0], moveInCpy[2]].join("-")
        ).toISOString(),
        duration: `${tenancyLength}`,
      };
    }

    const res = await noHookCreateLead(
      integration,
      buildCustomer(),
      {
        store: storeName,
        deal: deal?.dealName || "<unable to link deal>",
      },
      customTracks
    );

    console.log(res);

    if (res.status === 201 || 200) {
      shopCtx.setEmailAddr(email);
      setSuccess(true);
      if (deal?.url && dealType == "GETNOW") {
        window.open(deal.url);
      }
      if (!phoneNumber) {
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
      setPosting(false);
    } else {
      setPosting(false);
    }
  }

  function buildCustomer() {
    return {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      email: email,
      address: selectedAddress,
    };
  }

  if (success) {
    return (
      <NewUserContainer>
        <SuccessBlurb>
          <TitleContainer>Fantastic.</TitleContainer>
          <img
            src="/assets/svg/success.svg"
            style={{ maxWidth: 300, margin: "0 auto" }}
          />
          {deal?.url && !phoneNumber ? (
            <p>Just a moment, we are sending you through to {storeName} now.</p>
          ) : !phoneNumber ? (
            <p>Thank you, we have registered your interest with {storeName}</p>
          ) : null}
          {phoneNumber && (
            <div>
              <h3>
                To secure this exclusive offer, please call the number below and
                quote the code when prompted.
              </h3>
              {deal?.dealCode && <CodeToQuote>{deal?.dealCode}</CodeToQuote>}
              {phoneNumber && (
                <PhoneNumber>
                  <a href={`tel:${phoneNumber}`}>
                    <Button
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <img src="/assets/svg/phone.svg" /> {phoneNumber}
                    </Button>
                  </a>
                </PhoneNumber>
              )}
            </div>
          )}
        </SuccessBlurb>
      </NewUserContainer>
    );
  }

  return (
    <>
      <NewUserContainer>
        <SignUpBlurb>
          <TitleContainer>We just need a few details</TitleContainer>
          <DescriptionContainer>
            This is your first time here so we just need some extra details
          </DescriptionContainer>
        </SignUpBlurb>
        {integration === "tcli" && (
          <>
            <h3>Tenancy Details</h3>
            <GroupedInput>
              <SteppedInput
                config={[
                  { label: "Day", length: 2, maxVal: 31, minVal: 1 },
                  {
                    label: "Month",
                    length: 2,
                    maxVal: 12,
                    minVal: 1,
                  },
                  {
                    label: "Year",
                    length: 4,
                    maxVal: 3000,
                    minVal: new Date().getFullYear() - 1,
                  },
                ]}
                isDate
                onError={setStartDateErrors}
                label="Start Date"
                onChange={setTenancyStartDate}
                values={tenancyStartDate}
                divider="/"
              />
              <TextInput
                label="Tenancy Length (Months)"
                onChange={(e: any) => setTenancyLength(e.target.value)}
                type="number"
                value={tenancyLength}
                name="TenancyLength"
              />
            </GroupedInput>
            <ErrorList>
              {startDateErrors.map((err, index) => (
                <Error message={err} key={index} />
              ))}
            </ErrorList>
          </>
        )}
        <h4>Your Details</h4>
        <GroupedInput>
          <TextInput
            onChange={(e: any) => handleInputChange(e)}
            label="First Name*"
            type="text"
            name="firstName"
            value={firstName}
          />
          <TextInput
            onChange={(e: any) => handleInputChange(e)}
            label="Last Name*"
            type="text"
            name="lastName"
            value={lastName}
          />
          <InputWrapper>
            <TextInput
              onChange={(e: any) => handleInputChange(e)}
              required
              label="Mobile Number*"
              type="text"
              name="phone"
              value={phone}
            />
          </InputWrapper>
          <InputWrapper>
            <TextInput
              onChange={(e: any) => handleInputChange(e)}
              required
              label="Email Address*"
              type="email"
              name="email"
              value={email}
            />
          </InputWrapper>
          <InputWrapper>
            <SteppedInput
              config={[
                { label: "Day", length: 2, maxVal: 31, minVal: 1 },
                {
                  label: "Month",
                  length: 2,
                  maxVal: 12,
                  minVal: 1,
                },
                {
                  label: "Year",
                  length: 4,
                  maxVal: new Date().getFullYear() - 18,
                  minVal: 1900,
                },
              ]}
              isDate
              onError={setDobErrors}
              label="Date of birth"
              onChange={setDOB}
              values={dob}
              divider="/"
            />
            <ErrorList>
              {dobErrors.map((err, index) => (
                <Error message={err} key={`dob${index}`} />
              ))}
            </ErrorList>
          </InputWrapper>
        </GroupedInput>
        <h4>Address</h4>
        <AddressSelector
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
        />
      </NewUserContainer>
      <ControlsContainer>
        <AlternateButton onClick={() => goBack()}>Back</AlternateButton>
        <Button onClick={() => handlePost()} disabled={!validForm || posting}>
          Submit
        </Button>
      </ControlsContainer>
    </>
  );
};

export default NewUser;
