import React from "react";
import styled from "../common/themes";
import { Store } from "../types/Store";
import { fadeIn, Button, Colours } from "../common/styles";
import { Link } from "react-router-dom";
import { Mixpanel} from "../insights/mixpanel";

interface Props {
  store: Store;
  fullWidth: boolean;
}

const ShopCardContainer = styled.div<{ fullWidth: boolean }>`
  background: white;
  display: flex;
  flex-direction: ${(p) => (p.fullWidth ? "row" : "column")};
  height: 100%;
  min-height: 200px;
  border-radius: 10px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  animation: ${fadeIn} 0.25s ease-in-out 0s forwards 1;

  &:hover {
    cursor: pointer;
    background: #fff2;
  }
`;

const BodyWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  min-width: 200px;
`;

const ShopLogoLogo = styled.img`
  max-height: 80px;
  max-width: 100px;
  background: none;
`;

const ServicesText = styled.h2`
  color: ${Colours.blue};
`;

const TitleAndCentre = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`;

interface IBannerContainer {
  departmentBanner?: string;
  fullWidth: boolean;
}

const BannerContainer = styled.div<IBannerContainer>`
  background-image: url(${(p: IBannerContainer) =>
    p.departmentBanner ? p.departmentBanner : ""});
  min-height: ${(p: IBannerContainer) =>
    p.departmentBanner ? "150px" : "auto"};
  background-position: center;
  border-radius: ${(p) => (p.fullWidth ? " 0 10px 10px 0" : "0 0 10px 10px")};
  background-size: cover;
  width: 100%;
`;

const ShopCard: React.FC<Props> = ({ store, fullWidth }) => {
  function trackEvent(){
    Mixpanel.track('assistant.store.visit',{
      storeName: store.storeName,
      department: store.departmentName
    })
  }

  return (
    <Link to={`/${store.id}/${store.departmentName.toLowerCase()}`} onClick={()=> trackEvent()}>
      <ShopCardContainer fullWidth={fullWidth}>
        <BodyWrapper>
          <TitleAndCentre>
            <ShopLogoLogo src={store.logoUrl} alt={`${store.storeName} logo`} />
          </TitleAndCentre>
          <div style={{ flex: 1 }}>
            <ServicesText>{store.departmentShortDesc}</ServicesText>
            <p>{store.departmentDesc}</p>
          </div>
          <div className="flex-end">
            <Button alternate>
              {store.customDepartment
                ? store.customDepartment.actionText
                : "View Deals"}
            </Button>
          </div>
        </BodyWrapper>
        <BannerContainer
          departmentBanner={store.smallBanner}
          fullWidth={fullWidth}
        ></BannerContainer>
      </ShopCardContainer>
    </Link>
  );
};

export default ShopCard;
