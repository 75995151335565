import React, { useEffect, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Mixpanel } from "./insights/mixpanel";
import { vouchLight, vouchBot, voidDark, voidLight } from "./common/themes";
import { Router, Switch, Route, Redirect, useParams } from "react-router-dom";
import { useShopApi } from "./hooks/hooks";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./insights/ApplicationInsights";
import Loading from "./components/Loading";
// types
import { PassportUser } from "./types/Person";
import { Cache } from "./types/Misc";
import ShopContext from "./context/ShopContext";
import History from "./context/History";
// generic stores
import Shop from "./views/Shop";
import StoreDeals from "./views/StoreDeals";
import DealPage from "./views/Deal";
// custom stores
import ScottishPower from "./views/CustomStores/ScottishPower";
import Reposit from "./views/CustomStores/Reposit";
import TCLI from "./views/CustomStores/TCLI";
import LandlordRentGuarantee from "./views/CustomStores/LandlordRentGuarantee";
import LBCI from "./views/CustomStores/LBCI";
import GoodlordRPI from "./views/CustomStores/GoodlordRPI";
import ZeroDeposit from "./views/CustomStores/ZeroDeposit";
import ScottishPowerBody from "./components/ScottishPower/ScottishPowerBody";
// custom components
import Sidebar from "./components/Sidebar";
import BurgerMenu from "./components/BurgerMenu";
import UnsubscribeScreen from "./views/unsubscribeScreen";
import VoidPortal from "./views/CustomStores/VoidPortal";
import LandlordConsentScreen from "./LandlordConsentScreen";
import VoidContext from "./context/VoidContext";
import VoidAddressDetailScreen from "./components/VoidPortal/VoidAddressDetailScreen";
import VoidTokenPage from "./components/VoidPortal/VoidTokenPage";

const ShopContainer = styled.div`
  height: -webkit-fill-available;
  min-height: 100vh;
`;
const AppContainer = styled.div`
  position: relative;
  min-height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  height: auto;
  background: ${(props) =>
    props.theme.name === "bot"
      ? "none"
      : "linear-gradient(168deg, #fff 33%, #e0ecf5)"};
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50%;
  /* overflow-x: hidden; */
`;
function App() {
  const [emailAddr, setEmailAddr] = useState<string>("");
  const [cached, setCached] = useState<Cache>({});
  const [agencyData, setAgencyData] = useState(null);
  const [propertyData, setPropertyData] = useState(null);
  const [additionalUsrInfo, setAdditionalUsrInfo] = useState({
    firstName: null,
    lastName: null,
  });
  const [passportUser, setPassportUser] = useState<PassportUser | null>(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [demo, setDemo] = useState<boolean | null>(null);
  const [source, setSource] = useState(""); // used for theming, E.g bot
  const params = new URLSearchParams(window.location.search);
  const [stores, config] = useShopApi([cached, window.location], cached);
  const [voidData, setVoidData] = useState(null);
  const [moveIn, setMoveIn] = useState("");
  const [moveOut, setMoveOut] = useState("");
  const [displayDetail, setDisplayDetail] = useState("");
  const [darkModeEnabled, setDarkModeEnabled] = useState(false);

  function scrollIntoView(id: string) {
    const divElement = document.getElementById(id);
    divElement?.scrollIntoView({ behavior: "smooth" });
  }

  function handleLogout() {
    setPassportUser(null);
    // TODO: handle clear localStorage
  }

  useEffect(() => {
    const storageString = localStorage.getItem("vchStore") || "";
    const cache: Cache = {};
    if (storageString.trim() !== "") {
      Object.assign(cache, JSON.parse(storageString));
    }
    cache.vch = params.get("vch") || cache.vch;
    cache.cId = params.get("cId") || cache.cId;
    cache.token = params.get("token") || cache.token;
    if (cache.vch) {
      Mixpanel.identify(cache.vch);
    }
    Mixpanel.track("assistant.landing", {
      vch: cache.vch,
      cid: cache.cId,
    });
    setCached(cache);

    if (params.get("demo")) {
      setDemo(true);
    }

    if (params.get("src")) {
      setSource(params.get("src") || "");
    }

    localStorage.setItem("vchStore", JSON.stringify(cache));
  }, []);

  function getVch(): string {
    if (demo) {
      return "VCH599-21759";
    }
    return cached.vch || "";
  }

  if (!stores) {
    return <Loading />;
  }
  return (
    <ShopContext.Provider
      value={{
        customerId: cached.cId || "",
        emailAddr: emailAddr,
        stores: stores,
        vchNumber: getVch(),
        config: config,
        setEmailAddr: setEmailAddr,
        additionalUsrInfo: additionalUsrInfo,
        setAdditionalUsrInfo: setAdditionalUsrInfo,
        agencyData: agencyData,
        propertyData: propertyData,
        setAgencyData: setAgencyData,
        setPropertyData: setPropertyData,
      }}
    >
      <ThemeProvider theme={source === "bot" ? vouchBot : vouchLight}>
        <AppContainer>
          <Router history={History}>
            {false && (
              <BurgerMenu setOpen={setSidebarOpen} open={sidebarOpen} />
            )}
            <Sidebar
              setOpen={setSidebarOpen}
              open={sidebarOpen}
              passportUser={passportUser}
              handleLogout={handleLogout}
            />
            <ShopContainer>
              <Switch>
                <Route path="/" exact>
                  {demo ? (
                    <Redirect to="/demo" />
                  ) : (
                    <Shop stores={stores} config={config} type="tenant" />
                  )}
                </Route>
                <Route path="/landlord/void-consent/:token/:optDecision" exact>
                  <LandlordConsentScreen />
                </Route>
                <Route path="/landlord" exact>
                  <Shop stores={stores} config={config} type="landlord" />
                </Route>
                <Route path="/rpi/insurance/:token" exact>
                  <GoodlordRPI
                    store={stores.filter(({ id }) => {
                      return id.toLowerCase() === "rpi";
                    })}
                  />
                </Route>
                <Route path="/agent/:token" exact>
                  <Shop stores={stores} config={config} type="agent" />
                </Route>
                <Route path="/tenant" exact>
                  <Shop stores={stores} config={config} type="tenant" />
                </Route>

                <Route path="/demo" exact>
                  <Shop
                    stores={stores}
                    config={Object.assign({}, config || {}, {
                      applicant: {
                        firstname: "Demo",
                        lastname: "Account",
                        email: "demo@vouch.co.uk",
                        applicationStatus: "accepted",
                        hasVisitedBefore: false,
                        address: {
                          nameOrNumber: " 8",
                          line1: "10 Broomhall Rd",
                          line2: "Vouch",
                          city: "Sheffield",
                          postcode: "S10 2DR",
                        },
                      },
                    })}
                  />
                </Route>
                <Route path="/scottish-power/energy/quote" exact>
                  <Redirect to="/" />
                </Route>
                <Route path="/scottish-power/energy" exact>
                  <Redirect to="/" />
                </Route>
                <Route path="/unsubscribe" exact>
                  <UnsubscribeScreen />
                </Route>
                <Route path="/reposit/deposit protection" exact>
                  <Reposit
                    store={stores.filter(({ id }) => {
                      return id.toLowerCase() === "reposit";
                    })}
                  />
                </Route>
                <Route path="/landlord-building-insurance/insurance" exact>
                  <LBCI
                    stores={stores.filter(({ id }) => {
                      return id.toLowerCase() === "landlord-building-insurance";
                    })}
                  />
                </Route>
                <Route path="/tenant-contents-insurance/insurance" exact>
                  <TCLI
                    stores={stores.filter(({ id }) => {
                      return id.toLowerCase() === "tenant-contents-insurance";
                    })}
                  />
                </Route>
                <Route path="/zero-deposit/deposit protection" exact>
                  <ZeroDeposit
                    store={stores.filter(({ id }) => {
                      return id.toLowerCase() === "zero-deposit";
                    })}
                  />
                </Route>

                <Route path="/landlord-rent-guarantee/insurance" exact>
                  <LandlordRentGuarantee
                    store={stores.find(({ id }) => {
                      return id.toLowerCase() === "landlord-rent-guarantee";
                    })}
                  />
                </Route>
                <Route path="/:store" exact>
                  <Redirect to="/" />
                </Route>
                <Route path="/:store/:department" exact>
                  <StoreDeals stores={stores} />
                </Route>
                <Route path="/:store/:department/:deal" exact>
                  <DealPage stores={stores} />
                </Route>

                <VoidContext.Provider
                  value={{
                    voidData: voidData,
                    setVoidData: setVoidData,
                    setMoveIn: setMoveIn,
                    moveIn: moveIn,
                    setMoveOut: setMoveOut,
                    moveOut: moveOut,
                    scrollIntoView: scrollIntoView,
                    setDisplayDetail: setDisplayDetail,
                    displayDetail: displayDetail,
                    setDarkModeEnabled: setDarkModeEnabled,
                    darkModeEnabled: darkModeEnabled,
                  }}
                >
                  <ThemeProvider theme={darkModeEnabled ? voidDark : voidLight}>
                    <Route path="/utility/voids/agent/dashboard" exact>
                      <VoidPortal store={stores} />
                    </Route>
                    <Route path="/utility/voids/property/:voidId" exact>
                      <VoidAddressDetailScreen />
                    </Route>
                    <Route path="/utility/voids/dashboard/:token" exact>
                      <VoidTokenPage />
                    </Route>
                  </ThemeProvider>
                </VoidContext.Provider>
              </Switch>
            </ShopContainer>
          </Router>
        </AppContainer>
      </ThemeProvider>
    </ShopContext.Provider>
  );
}
export default withAITracking(reactPlugin, App);
