import React from "react";
import styled from "styled-components";
import SVG from "react-inlinesvg";
import {
  formatVoidPortalEnums,
  getProgressColour,
  getSVGIcon,
} from "../../helpers/voidPortalHelpers";
import { format } from "date-fns";
import { Void } from "../../types/Void";

interface Props {
  notificationsFuel: Void['moveOut' | 'moveIn']['notificationsToDisplay']['council' | 'gas' | 'water' | 'electricity'];
  type: string;
  img: string;
}

const UtilityIcon = styled.div<{ status: string; type: string }>`
  svg {
    background: ${(props) => props.theme.background};
    height: ${(props) => (props.type === "notification" ? "64px" : "36px")};
    width: ${(props) => (props.type === "notification" ? "64px" : "36px")};
    path {
      fill: ${(props) => getProgressColour(props.status)};
    }
  }
`;

const UtilityStatus = styled.div<{ status: string }>`
  svg {
    width: 16px;
    height: 16px;
  }
`;

const AlertMessage: React.FC<Props> = ({ notificationsFuel, type, img }) => {
  return (
    <span>
      <UtilityIcon
        type={type}
        status={
          notificationsFuel?.status !== null &&
          notificationsFuel?.status !== undefined
            ? notificationsFuel?.status
            : ""
        }
      >
        <SVG src={img} />
      </UtilityIcon>
      {notificationsFuel?.status && (
        <UtilityStatus
          status={
            notificationsFuel?.status !== null &&
            notificationsFuel?.status !== undefined
              ? notificationsFuel?.status
              : ""
          }
        >
          {<SVG src={getSVGIcon(notificationsFuel.status)} />}
        </UtilityStatus>
      )}

      {notificationsFuel?.status !== null &&
      notificationsFuel?.status !== undefined &&
      type === "notification" ? (
        <span>
          {formatVoidPortalEnums(
            notificationsFuel?.status,
            "notifications",
            notificationsFuel.provider
          )}
        </span>
      ) : null}

      {notificationsFuel?.lastUpdatedDateTime !== undefined &&
      notificationsFuel?.lastUpdatedDateTime !== "1970-01-01T00:00:00+00:00" &&
      notificationsFuel?.lastUpdatedDateTime !== "0001-01-01T00:00:00+00:00" ? (
        <span className="timeStamp">
          (Last updated on:{" "}
          {format(
            new Date(notificationsFuel?.lastUpdatedDateTime),
            "dd/MM/yyyy"
          )}
          )
        </span>
      ) : null}
    </span>
  );
};

export default AlertMessage;
