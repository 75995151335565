import React, { useState, useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { BannerBackground, PageWrapper, Button } from "../../common/styles";
// types
import { Store } from "../../types/Store";
import Person from "../../types/Person";
// components
import Footer from "../../components/Footer";
import StoreHeader from "../../components/StoreHeader";
//import { reactPlugin } from "../../insights/ApplicationInsights";
//import { withAITracking } from "@microsoft/applicationinsights-react-js";
// components
import RepositCard from "./reposit/RepositCard";
import DealBreakdown from "./reposit/DealBreakdown";
import ShopContext from "../../context/ShopContext";

interface Props {
  store: Store[];
}

const StoreContainer = styled(BannerBackground)`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  h1 {
    color: white;
    padding: 20px;
    font-size: 3rem;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

const OptionContainer = styled.div`
  display: grid;
  border-radius: 5px;
  grid-gap: 25px;
  background: none;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
`;

const BlurbContainer = styled.div``;

const MainBodyContainer = styled.div`
  max-width: 1200px;
`;

const LoadingQuoteContainer = styled.div`
  margin-top: 25px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  max-width: 1200px;
  background: white;
  display: grid;
  place-items: center;

  @media (max-width: 450px) {
    h1,
    h2 {
      font-size: 1.2rem;
    }
  }
`;

interface KeyAndValue {
  key: string;
  value: string;
}

export interface Quote {
  tenantFee: number | null;
  totalFee: number | null;
  headCount: number | null;
  ppm: number | null;
  savings: number | null;
}

export interface QuotePricing {
  charges: KeyAndValue[];
  finalPrice: string;
}

const RepositPage: React.FC<Props> = ({ store }) => {
  const shopContext = useContext(ctx);
  const [quote, setQuote] = useState<Quote>();
  const [loading, setLoading] = useState(true); // show/hide loading indiactor
  const [quotePricing, setQuotePricing] = useState<any>();
  const [applicant, setApplicant] = useState<Person | null>({
    firstname: "",
    lastname: "",
    agencyName: "",
    email: "",
    hasVisitedBefore: false,
    applicationStatus: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      nameOrNumber: "",
      postcode: "",
    },
  }); // store applicant meta
  const [option, setOption] = useState<"1" | "2">("1"); // toggles selected

  useEffect(() => {
    if (shopContext.config && shopContext.config?.applicant) {
      setApplicant(shopContext.config?.applicant);
    }

    const reqBody = {
      query: `query{
        getRepositQuote(vch: "${shopContext.vchNumber}"){
          tenantFee
          totalFee
          ppm
          headCount
        }
      }`,
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        const {
          ppm,
          tenantFee,
          totalFee,
          headCount,
        } = resData.data.getRepositQuote;
        const pricing = { quote1: {}, quote2: {} };

        function penceToPound(price: number): string {
          return (price / 100).toFixed(2);
        }

        function getTotalPrice(charges: any = []) {
          let total = 0;
          charges.forEach((charge: any) => {
            total += Number(charge);
          });
          return total.toFixed(2);
        }

        const quote1TotalPrice = getTotalPrice([
          ppm / 100,
          +totalFee / 100,
          -172.48,
        ]);

        const quote2TotalPrice = getTotalPrice([
          ppm / 100,
          (ppm + ppm / 4) / 100,
          -172.48,
        ]);

        pricing.quote1 = {
          charges: [
            { key: "Advance Rent", value: penceToPound(ppm) },
            {
              key: "Reposit Charge",
              value: penceToPound(+totalFee),
            },
            {
              key: "Holding Deposit",
              value: penceToPound(-17248),
            },
          ],
          finalPrice: quote1TotalPrice,
        };
        pricing.quote2 = {
          charges: [
            { key: "Advance Rent", value: penceToPound(ppm) },
            {
              key: "Tenancy Deposit",
              value: penceToPound(ppm + ppm / 4),
            },
            {
              key: "Holding Deposit",
              value: penceToPound(-17248),
            },
          ],
          finalPrice: quote2TotalPrice,
        };
        setQuote(
          Object.assign(resData.data.getRepositQuote, {
            savings: +quote2TotalPrice - +quote1TotalPrice,
          })
        );
        setLoading(false);
        setQuotePricing(pricing);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (!store) {
    return <div>Loading...</div>;
  }
  const reposit = store[0];

  if (!reposit) {
    return <div>Loading...</div>;
  }

  const depositProtection = reposit.departments.filter(({ departmentName }) => {
    return departmentName.toLowerCase() === "deposit protection";
  })[0];

  console.log(reposit);

  return (
    <PageWrapper center>
      <StoreContainer bannerUrl={depositProtection.departmentBanner || ""}>
        <MainStoreBanner>
          <StoreHeader
            storeLogo={reposit.logoUrl}
            department={depositProtection.departmentName}
          />
        </MainStoreBanner>
        {loading && !quote?.totalFee && (
          <LoadingQuoteContainer>
            Loading Tailored Quote...
          </LoadingQuoteContainer>
        )}
        {!loading && quote?.totalFee && (
          <BlurbContainer>
            <MainBodyContainer>
              <OptionContainer>
                {quotePricing && quotePricing.quote1 && (
                  <RepositCard
                    setOption={setOption}
                    option={option}
                    bill={quotePricing.quote1}
                    optionNum="1"
                    optionHeader="Reposit & Advance Rent"
                  />
                )}
                {quotePricing && quotePricing.quote2 && (
                  <RepositCard
                    setOption={setOption}
                    option={option}
                    bill={quotePricing.quote2}
                    optionNum="2"
                    optionHeader="Traditional Deposit & Advance Rent"
                  />
                )}
              </OptionContainer>

              {applicant && (
                <DealBreakdown
                  option={option}
                  applicant={applicant}
                  quote={quote}
                />
              )}
            </MainBodyContainer>
          </BlurbContainer>
        )}
        <Footer>
          {reposit.storeTermsAndCond && (
            <SmallPrint>{reposit.storeTermsAndCond}</SmallPrint>
          )}
        </Footer>
      </StoreContainer>
    </PageWrapper>
  );
};

export default /* withAITracking(reactPlugin,  */ RepositPage;
