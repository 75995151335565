import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Void } from "../../types/Void";
import VoidMoveStatusComponent from "./VoidMoveStatusComponent";
import ctx from "../../context/VoidContext";
import { useHistory } from "react-router-dom";
import { determineMoveDisplayStatus } from "../../helpers/voidPortalHelpers";


interface Props {
  property: Void;
  voidAddressOpen: boolean;
  setSingleVoid: (TheProperty: Void) => void;
  setVoidAddressOpen: (isOpenOrNot: boolean) => void;
  setSelectedOption: (input: boolean) => void;
                  }

const VoidCardContainer = styled.div<{ voidAddressOpen: boolean }>`
  width: 100%;
  background: ${(props) => props.theme.background};
  margin-bottom: 40px;
  border-radius: 10px;
  display: flex;
  flex-flow: column;
  display: ${(props) => (props.voidAddressOpen ? "none" : "flex")};
  box-shadow: 0 0 8px 2px rgb(100 100 100 / 25%);
`;

const NotificationSummary = styled.div`
  display: flex;
  align-items: normal;
  padding: 0 20px;
  font-size: 0.8rem;
  justify-content: space-evenly;
  color: #082842;
  padding: 0 40px;
  background: ${(props) => props.theme.background};

  .moveDate {
    display: flex;
    flex-flow: column;
    text-align: center;
    background: ${(props) => props.theme.background};
    div {
      display: block;
    }
  }
`;

const MoveOutContainer = styled.div`
  flex: 1;
  margin: 10px 0 20px 0;
  display: flex;
  cursor: pointer;
  position: relative;
  > div:nth-child(1) {
    z-index: 10;
    flex: 1;
    display: flex;
    justify-content: space-between;
    > span {
      > div:nth-child(2) {
        height: 24px;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
      }
    }
  }

  > div:nth-child(2) {
    hr {
      border: none;
      border-top: 3px dashed #34bebd;
      color: #fff;
      opacity: 0.3;
      background-color: #fff;
      height: 10px;
      width: 100%;
    }
    position: absolute;
    z-index: 0;
    top: 25%;
    width: 100%;
  }
`;
const MoveInContainer = styled(MoveOutContainer)``;

const Title = styled.div`
  display: flex;
  align-items: baseline;
  text-align: right;
  text-transform: capitalize;
  h2 {
    margin: 0;
    flex: 1 1 50%;
    font-size: 1.2rem;
  }

  div {
    flex: 1 1 50%;
  }
`;

const Divider = styled.div`
    margin: 0 40px;
    border-right: 3px solid #34bebd;
    width: 10px;
    top: 5px;
    color: #34bebd;
    z-index: 1;
    position: relative;
    height: 70px;
`;

const PropertyContent = styled.div`
  color: #082842;
  padding: 20px 20px 0 20px;
  border-radius: 0 10px 10px 0;
  flex: 1;
  margin-left: 40px;
  display: flex;
  flex-flow: column;

  > div {
    > span {
      flex: 1 1 50%;
    }
  }
`;

const UtilityStatusPill = styled.div<{ switchStatus: string }>`
cursor:pointer;
  margin-left: auto;
  max-width: 120px;
  border-radius: 5px;
  background: #34bebd;
  color: #fff;
  text-align: center;
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  background: ${(props) =>
    props.switchStatus === "Complete"
      ? "#34bebd"
      : props.switchStatus === "Action Required"
      ? "#ed941e"
      : props.switchStatus === "In Progress"
      ? "#082842"
      : props.switchStatus === "Cancelled"
      ? "#ED1C24"
      : "#BCBEC0"};
`;

const VoidPropertyCard: React.FC<Props> = ({
  setSingleVoid,
  setVoidAddressOpen,
  voidAddressOpen,
  property,
  setSelectedOption
}) => {

  const { setMoveIn, setMoveOut } = useContext(ctx);
  const history = useHistory();
  
  const [switchStatus, setSwitchStatus ] = useState("");
  const [moveOutStatus, setMoveOutStatus ] = useState("");
  const [moveInStatus, setMoveInStatus ] = useState("");

  function openPropertyDetail(
    e: React.MouseEvent<HTMLElement>,
    property: Void,
    selected: void
  ) {
    e.stopPropagation();
    setVoidAddressOpen(true);
    setSingleVoid(property);
    setMoveIn(moveInStatus);
    setMoveOut(moveOutStatus); 
    history.push(`/utility/voids/property/${property.id}`);
  }

  useEffect(() => {

    setSwitchStatus("In Progress");
     
      if (
        (property?.moveOut !== null &&
        property?.moveOut !== undefined &&
        property?.moveOut?.moveDate === "0001-01-01T00:00:00+00:00") 
        ||
        (property?.moveIn !== null &&
        property?.moveIn !== undefined &&
        property?.moveIn?.moveDate === "0001-01-01T00:00:00+00:00") 
        ||
        ((property?.landlordContactDetails === undefined || property?.landlordContactDetails === null) && 
        (property?.agentContactDetails === undefined || property?.agentContactDetails === null))
        ||
        (property?.moveIn !== null && property?.moveIn !== undefined && 
        (property?.moveIn.electricityDayMeterReading === null || property?.moveIn.electricityDayMeterReading === undefined) &&
        property?.moveIn.notificationsToDisplay.electricity.status !== "notRequired" && 
        property?.moveIn.notificationsToDisplay.electricity.status !== "cancelled" &&
        property?.moveIn.internalScottishPowerElectricityStatus.sentDate === "0001-01-01T00:00:00+00:00" && 
        property?.moveIn.podioNotifications.electricity.sentToPodioDate === "0001-01-01T00:00:00+00:00")
        ||
        (property?.moveOut !== null && property?.moveOut !== undefined && 
        (property?.moveOut.electricityDayMeterReading === null || property?.moveOut.electricityDayMeterReading === undefined) &&
        property?.moveOut.notificationsToDisplay.electricity.status !== "notRequired" && 
        property?.moveOut.notificationsToDisplay.electricity.status !== "cancelled" &&
        property?.moveOut.internalScottishPowerElectricityStatus.sentDate === "0001-01-01T00:00:00+00:00" && 
        property?.moveOut.podioNotifications.electricity.sentToPodioDate === "0001-01-01T00:00:00+00:00")
        ||
        (property?.moveIn !== null && property?.moveIn !== undefined && 
        (property?.moveIn.gasMeterReading === null || property?.moveIn.gasMeterReading === undefined) &&
        property?.moveIn.notificationsToDisplay.gas.status !== "notRequired" && 
        property?.moveIn.notificationsToDisplay.gas.status !== "cancelled" &&
        property?.moveIn.internalScottishPowerGasStatus.sentDate === "0001-01-01T00:00:00+00:00" && 
        property?.moveIn.podioNotifications.gas.sentToPodioDate === "0001-01-01T00:00:00+00:00")
        ||
        (property?.moveOut !== null && property?.moveOut !== undefined && 
        (property?.moveOut.gasMeterReading === null || property?.moveOut.gasMeterReading === undefined) &&
        property?.moveOut.notificationsToDisplay.gas.status !== "notRequired" && 
        property?.moveOut.notificationsToDisplay.gas.status !== "cancelled" &&
        property?.moveOut.internalScottishPowerGasStatus.sentDate === "0001-01-01T00:00:00+00:00" && 
        property?.moveOut.podioNotifications.gas.sentToPodioDate === "0001-01-01T00:00:00+00:00")
        ||
        (property?.agentContactDetails !== undefined &&
        property?.agentContactDetails !== null &&
        property?.landlordContactDetails !== undefined &&
        property?.landlordContactDetails !== null &&
       (property?.agentContactDetails?.forwardingAddress === null || property?.agentContactDetails?.forwardingAddress === undefined) &&
       (property?.landlordContactDetails?.forwardingAddress === null || property?.landlordContactDetails?.forwardingAddress === undefined)))
        {
          setSwitchStatus("Action Required");
        }

        if 
        (determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.council, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.water, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.gas, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.electricity, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.council, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.water, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.gas, ["cancelled"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.electricity, ["cancelled"])) 
        {
          setSwitchStatus("Cancelled");
        } 

      if 
        (determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.council, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.water, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.gas, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.electricity, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.council, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.water, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.gas, ["notified", "notRequired", "notifiedByPodio"]) &&
        determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.electricity, ["notified", "notRequired", "notifiedByPodio"])) 
        {
          setSwitchStatus("Complete");
        } 

  }, [property?.moveIn, property?.moveOut, property?.electricMeterDetails , property?.gasMeterDetails, property?.propertyAddress, property.matchedAddressState]);
  
  
  
  useEffect(() => {

    if ((property?.moveOut !== undefined &&
      property?.moveOut !== null &&
      property?.moveOut?.moveDate !== "1970-01-01T00:00:00+00:00" &&
      property?.moveOut?.moveDate !== "0001-01-01T00:00:00+00:00"
      ))
     {
      setMoveOutStatus("In Progress");
      

      if (
        property?.moveOut !== null &&
        property?.moveOut !== undefined &&
        property?.moveOut?.notificationsToDisplay !== null &&
        property?.moveOut?.notificationsToDisplay !== undefined &&
        property?.moveOut?.moveDate !== "1970-01-01T00:00:00+00:00" &&
        property?.moveOut?.moveDate !== "0001-01-01T00:00:00+00:00") {
          
        setMoveOutStatus("In Progress");

        if (
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.council, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.water, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.gas, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.electricity, ["cancelled"])) 
          {
            setMoveOutStatus("Cancelled");
          }

          if 
          (determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.council, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.water, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.gas, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveOut?.notificationsToDisplay.electricity, ["notified", "notRequired", "notifiedByPodio"]))         
          {
            setMoveOutStatus("Complete");
          } 
      }
    } 
    else {
      setMoveOutStatus("Action Required");
    }
  }, [property?.moveOut, property?.electricMeterDetails , property?.gasMeterDetails, property?.propertyAddress]);
  
  useEffect(() => {

    if ((property?.moveIn !== undefined &&
      property?.moveIn !== null &&
      property?.moveIn?.moveDate !== "1970-01-01T00:00:00+00:00" &&
      property?.moveIn?.moveDate !== "0001-01-01T00:00:00+00:00"
      ))
     {
      setMoveInStatus("In Progress");

      
      if (
        property?.moveIn !== null &&
        property?.moveIn !== undefined &&
        property?.moveIn?.notificationsToDisplay !== null &&
        property?.moveIn?.notificationsToDisplay !== undefined &&
        property?.moveIn?.moveDate !== "1970-01-01T00:00:00+00:00" &&
        property?.moveIn?.moveDate !== "0001-01-01T00:00:00+00:00" 
        ) {
          
        setMoveInStatus("In Progress");
        
        if (
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.council, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.water, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.gas, ["cancelled"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.electricity, ["cancelled"]))  
          {
            setMoveInStatus("Cancelled");
          }
          
          if 
          (determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.council, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.water, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.gas, ["notified", "notRequired", "notifiedByPodio"]) &&
          determineMoveDisplayStatus(property?.moveIn?.notificationsToDisplay.electricity, ["notified", "notRequired", "notifiedByPodio"])) 
          {
            setSwitchStatus("Complete");
          }
      }
    } 
    else {
      setMoveInStatus("Action Required");
    }
  }, [property?.moveIn, property?.electricMeterDetails , property?.gasMeterDetails, property?.propertyAddress]);


  return (
    <>

    <VoidCardContainer voidAddressOpen={voidAddressOpen}>
      <PropertyContent>
        <Title>
          <h2>
            {property?.propertyAddress?.line1
              ? `${property?.propertyAddress?.line1.toLowerCase()},`
              : ""}{" "}
            {property?.propertyAddress?.city
              ? `${property?.propertyAddress?.city.toLowerCase()},`
              : ""}{" "}
            {property?.propertyAddress?.postcode
              ? property?.propertyAddress?.postcode.toUpperCase()
              : ""}
          </h2>
          <UtilityStatusPill data-testid="switchStatus" switchStatus={switchStatus}  onClick={(e: React.MouseEvent<HTMLElement>) =>
            openPropertyDetail(e, property, setSelectedOption(true))
          }>
            {switchStatus}
          </UtilityStatusPill>
        </Title>
      </PropertyContent>
      <NotificationSummary>
        <MoveOutContainer data-testid="moveOutIconsContainer"
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            openPropertyDetail(e, property, setSelectedOption(true))
          }
        >
          <VoidMoveStatusComponent
            showDate={true}
            type={""}
            isMoveOut={true}
            propertyMove={property.moveOut}
          ></VoidMoveStatusComponent>
        </MoveOutContainer>
        <Divider></Divider>
        <MoveInContainer data-testid="moveInIconsContainer"
          onClick={(e: React.MouseEvent<HTMLElement>) =>
            openPropertyDetail(e, property, setSelectedOption(false))
          }
        >
          <VoidMoveStatusComponent
            showDate={true}
            type={""}
            isMoveOut={false}
            propertyMove={property.moveIn}
          ></VoidMoveStatusComponent>
        </MoveInContainer>
      </NotificationSummary>
    </VoidCardContainer>
    </>
  );
};

export default VoidPropertyCard;
