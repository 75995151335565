import React, { useState, useContext, useMemo, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../common/styles";
import ctx, { ctxType } from "../../context/VoidContext";

import { Void } from "../../types/Void";
import VoidPropertyCard from "./VoidPropertyCard";
import VoidPropertyCreator from "./VoidPropertyCreator";
import PortalMenu from "./PortalMenu";


const Title = styled.div`
  display: flex;
  justify-content: space-between;
  color: #082842;
`;

const VoidsPropertyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  padding: 0 20px;
  align-items: center;

  h3 {
    text-align: left;
    width: 100%;
  }
  > div:nth-child(1) {
    flex: 0 1 800px;
    align-self: flex-start;
    flex: 1;
  }
  > div:nth-child(2) {
    flex: 0 1 300px;
    flex: 1;
  }
`;

const Disclaimer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;

  button {
    margin: 40px 20px;
  }
  h1,
  h3 {
    margin: 0;
    padding: 0 20px;
  }
  h1 {
    color: #34bebd;
  }
  div {
    background: #fff;
    height: 70%;
    border-radius: 10px;
    width: 70%;
    max-width: 800px;
    padding: 40px;
    overflow-y: scroll;
  }
  span {
    display: block;
    padding: 20px;
    strong {
      text-transform: capitalize;
    }
  }
`;

const NoDataMessage = styled.div`
  font-size: 2rem;
  color: #34bebd;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-bottom: 20%;
`;

const LoadingMessage = styled(NoDataMessage)`
  align-items: flex-start;
  height: 100%;
  margin-top: 100px;
`;




const AddressesScreen: React.FC = () => {
  const { voidData }: ctxType = useContext(ctx);

  const [voidPropertyList, setVoidPropertyList] = useState<Void[]>();
  const [aSingleVoid, setSingleVoid] = useState<Void | undefined>();
  const [voidAddressOpen, setVoidAddressOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<boolean>(true);
  const [isAddVoidOpen, setIsAddVoidOpen] = useState(false);

  const agentHasAcceptedDisclaimer: boolean =
    localStorage.getItem("VoidPortalDisclaimer") === "Accepted";
  

  const [agentAgreedDisclaimer, setAgentDisclaimer] = useState(
    agentHasAcceptedDisclaimer
  );

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  async function fetchAllAgentVoids(customerId: string) {
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/${customerId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
          "content-type": "application/json",
          
        },
        method: "GET",
      }
    );

    if(res.status === 200) {
      const data = await res.json();
      setVoidPropertyList(data);
    }
    setLoading(false);
  }

  function setSelectedOption(input: boolean) {
    setSelected(input);
  }

  function handleAcceptTerms() {
    localStorage.setItem("VoidPortalDisclaimer", "Accepted");
    setAgentDisclaimer(true);
  }


  const customerIdentifier: string|null = sessionStorage.getItem("voidCustomerIdentifier");


  useEffect(() => {
    if (agentAgreedDisclaimer &&
      voidData?.customerIdentifier !== undefined &&
      voidData?.customerIdentifier !== "") {
      fetchAllAgentVoids(voidData?.customerIdentifier);
    } else {
      if (agentAgreedDisclaimer && customerIdentifier !== null) {
        fetchAllAgentVoids(customerIdentifier);
      }
    }
 
  }, [agentAgreedDisclaimer, voidData?.customerIdentifier, customerIdentifier]);


  const loadingMessage = [
    "Loading your notifications portal...",
    "...Organising properties",
    "Getting latest notifications...",
    "Almost there...",
  ];

  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < loadingMessage.length - 1) {
        setSeconds((seconds) => seconds + 1);
      }
    }, 4500);
    return () => clearInterval(interval);
  }, [loading, seconds]);

  return (
    <>
      {agentAgreedDisclaimer ? (
        loading ? (
          <LoadingMessage>{loadingMessage[seconds]}</LoadingMessage>
        ) : (
          <>
            <VoidsPropertyContainer>
              {isAddVoidOpen ? (
                <VoidPropertyCreator
                  setIsAddVoidOpen={setIsAddVoidOpen}
                ></VoidPropertyCreator>
              ) : (
                <>
       
                  <PortalMenu toggleMenu={toggleMenu} menuOpen={menuOpen}></PortalMenu>
                  <Title></Title>
                  {voidPropertyList && voidPropertyList.length > 0 ? (
                    voidPropertyList.map((property: Void, index: number) => {
                      return (
                        <VoidPropertyCard
                          data-cy="property-card"
                          key={`prop-card-${index}`}
                          setSingleVoid={setSingleVoid}
                          setVoidAddressOpen={setVoidAddressOpen}
                          voidAddressOpen={voidAddressOpen}
                          property={property}
                          setSelectedOption={setSelectedOption}
                        ></VoidPropertyCard>
                      );
                    })
                  ) : (
                    <>
                      <NoDataMessage>
                        No properties in your portal
                      </NoDataMessage>
                    </>
                  )}
                </>
              )}
            </VoidsPropertyContainer>
          </>
        )
      ) : (
        <Disclaimer>
          <div>
            <h1>Notification Portal</h1>
            <h3>Agent Disclaimer</h3>

            <strong>
              <span>
                As an agent I agree I have inserted the below statement into my
                landlords terms of business;
              </span>
            </strong>
            <span>
              SCHEDULE 1 – LANDLORD ADDENDUM - To be included in Agent’s terms
              of business with Landlord Vouch Online LTD (“VOUCH”) has been
              engaged by ​<strong>{voidData?.name}</strong> to provide energy
              supply management services to the Landlord’s Property.
            </span>

            <span>
              The Landlord hereby authorises <strong>{voidData?.name}</strong>{" "}
              to use VOUCH to manage the energy supply during the void period of
              their property(s). This may result in VOUCH changing the
              electricity and/or gas supplier for the Property; however, this
              will not prevent the Landlord from changing to a different energy
              provider if desired.
            </span>

            <span>
              The Landlord agrees that <strong>{voidData?.name}</strong> may
              pass the Landlord’s name and contact details to VOUCH for the
              purposes of: (a) entering into contracts with VOUCH’s preferred
              energy supplier in connection with the supply of gas and/or
              electricity to each Property while that Property is vacant (b)
              engaging with relevant energy suppliers in connection with the
              management and administration of any energy supply contract(s) in
              connection with each Property (c) registering the Landlord with
              the relevant local authority for the payment of council tax; and
              (d) registering the Landlord with the incumbent water supplier to
              the property. The water supplier may contact the Landlord in order
              to provide further information about its services and products and
              conclude an agreement with the Landlord for those services and
              products.
            </span>
            <Button data-cy="disclaimer-button" onClick={handleAcceptTerms}>
              {agentAgreedDisclaimer ? "Accept Terms" : "Close"}
            </Button>
          </div>
        </Disclaimer>
      )}
    </>
  );
};

export default AddressesScreen;
