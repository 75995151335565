import { useState, useEffect, useContext } from "react";
import { Store } from "../types/Store";
import Config from "../types/Config";
import ctx from "../context/ShopContext";
import { PropertyDetails, Fuel, ProductRequestData, ProductResponseData } from "../types/Quote";
import { Mixpanel } from "../insights/mixpanel";
import { Cache } from "../types/Misc";
import { checkIsMeterSupplied } from "../helpers/scottishPowerHelpers";

export const useAuthenticated = () => {
  const shopCtx = useContext(ctx);
  if (shopCtx.customerId !== "") {
    return "customerId";
  }
  if (shopCtx.vchNumber !== "") {
    return "vch";
  }

  return "email";
};

// TODO: replace with regex or something more substancial
export const useValidEmail = (email: string = "") => {
  const shopCtx = useContext(ctx);
  if (!email) {
    email = shopCtx.emailAddr || "";
  }
  email = email.trim();
  if (
    email.includes("@") &&
    email.includes(".") &&
    email.split("").length > 5
  ) {
    return true;
  } else {
    return false;
  }
};

export const useFindShop = (nameOfStore: string) => {
  const shopCtx = useContext(ctx);
  const foundShop = shopCtx.stores.filter(({ id }) => {
    return id.toLowerCase() === nameOfStore.toLowerCase();
  });
  return foundShop[0];
};

export const useFindAddress = async (postcode: string) => {
  const [addresses, setAddresses] = useState<Array<Location>>([]);
  const res = await fetch(
    `${process.env.REACT_APP_LOCATION_API}?postcode=${postcode}`,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
      },
    }
  );

  if (res.status === 200) {
    const data = await res.json();
    console.log(data);
    setAddresses(data);
  }
  return [addresses];
};
export const useFindDeal = (
  strName: string,
  depName: string,
  dealID: string
) => {
  const shop = useFindShop(strName);

  if (!shop) return;

  const department = shop.departments.filter(({ departmentName }) => {
    return departmentName.toLowerCase() === depName.toLowerCase();
  });

  if (!department) return;

  const deal = department[0].deals.filter(({ id }) => {
    return id.toLowerCase() === dealID.toLowerCase();
  });

  return deal[0];
};

export const useHttp = (url: string, dependencies = []) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);

  //   fetch('https://swapi.co/api/people')
  useEffect(() => {
    setIsLoading(true);
    console.log("Sending Http request to URL: " + url);
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch.");
        }
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setFetchedData(data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, dependencies);

  return [isLoading, fetchedData];
};

export const useCreateLead = async (
  deps = [],
  integration: string,
  email: string = "",
  storeMeta?: { store: string; deal: string }
) => {
  const { vchNumber } = useContext(ctx);
  const [data, setData] = useState(null);

  const reqBody = {
    query: `mutation($storeMeta: StoreMeta){
      createLead(vchNumber: "${vchNumber}" integration: "${integration}" storeMeta: $storeMeta emailAddress: "${email}"){
        status
        code
      }
    }`,
    variables: { storeMeta: { ...storeMeta } },
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        if (res.status === 201 || 200) {
          Mixpanel.track("assistant.store.purchase", {
            storeName: storeMeta?.store,
            deal: storeMeta?.deal,
            userId: vchNumber,
            email: email,
          });
          return res.json();
        }
      })
      .then((data) => {
        setData(data);
        console.log(data);
      })
      .catch((err) => {
        Mixpanel.track("assistant.store.error", {
          storeName: storeMeta?.store,
          deal: storeMeta?.deal,
          context: {
            userId: vchNumber,
            email: email,
          },
          ex: err,
        });
      });
  }, deps);
  return data;
};

export const graphQLQuery = async (query: string, params: Object[] = []) => {
  return await fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      query: query,
      variables: [...params],
    }),
  });
};

export const useShopApi = (dependencies: any[], cached: Cache) => {
  const params = new URLSearchParams(window.location.search);

  const { customerId, vchNumber } = useContext(ctx);
  const [stores, setStores] = useState<Array<Store>>([]);
  const [config, setConfig] = useState<Config>();

  let vch: string = cached.vch || "";
  if (params.get("vch") !== null) {
    vch = params.get("vch") ?? "";
  }

  let cId: string = "";
  if (params.get("cid") !== null) {
    cId = params.get("cid") ?? "";
  }

  const reqBody = {
    query: `query{
      getStoreConfig(vchNumber: "${vchNumber || vch}", cId: "${customerId}"){
        center_categories
        v2Customer {
          name
          alanBoswellCode
        }
        vchCustomer{
          firstname
          lastname
          dob
          phone
          email
          address{
            nameOrNumber
            line1
            line2
            city
            postcode
            moveIn
          }
          applicationStatus
          hasVisitedBefore
        }
      }
          getStoreItems(vchNumber: "${vch}", cId: "${cId}"){
            id
            type
            ${params.get("demo") ? "" : "locked"}
            logoUrl
            smallBanner
            storeTermsAndCond
            storeName
            description
            departments{
              departmentName
              departmentDesc
              departmentShortDesc
              departmentBanner
              departmentIcon
              callbackUrl
              customDepartment{
                actionText
              },
              deals{
                id
                phone
                dealName
                dealCode
                tags
                description
                smallPrint
                price{
                  amount
                	currency
                  term
                }
                icon
                url
                startDate
                endDate
              }
            }
          }
        }`,
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        setStores(resData.data.getStoreItems);
        const conf: Config = {
          categories: resData.data.getStoreConfig.center_categories,
          applicant: resData.data.getStoreConfig.vchCustomer,
          customer: resData.data.getStoreConfig.v2Customer,
          agencyData: resData.data.getStoreConfig.agencyData,
          propertyData: resData.data.getStoreConfig.propertyData,
        };
        setConfig(conf);
      })
      .catch((err) => {
        console.log(err);
      });
  }, dependencies || []);
  return [stores, config] as const;
};

export async function returningUser(email: string) {
  const reqBody = {
    query: `query{
      getReturningUser(email: "${email}"){
        email
      }
    }`,
  };

  let data = await fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(reqBody),
  });

  if (data.status === 200 || 201) {
    const decodedData = data.json();
    return decodedData;
  } else {
    return { data: "Nope" };
  }
}

/* hookless version of create lead */
export async function noHookCreateLead(
  integration: string,
  newCustomer: any,
  storeMeta?: { store: string; deal: string },
  customTrackablePoints?: object,
  emailAddress?: string,
  vchNumber?: string
) {
  let trackablePoints = "";
  if (customTrackablePoints) {
    trackablePoints = JSON.stringify(customTrackablePoints);
  }
  let reqBody;
  Mixpanel.track("assistant.store.purchase", {
    email: emailAddress,
    id: vchNumber,
    storeName: storeMeta?.store,
    deal: storeMeta?.deal,
    trackablePoints: trackablePoints,
  });
  if (emailAddress) {
    reqBody = {
      query: `mutation($storeMeta: StoreMeta, $trackPoints: String, $email: String){
        createLead(integration: "${integration}" customTrackablePoints: $trackPoints emailAddress: $email storeMeta: $storeMeta ){
          status
          code
        }
      }`,
      variables: {
        storeMeta: { ...storeMeta },
        trackPoints: trackablePoints,
        email: emailAddress,
      },
    };
  } else if (vchNumber) {
    reqBody = {
      query: `mutation($storeMeta: StoreMeta, $trackPoints: String, $vch: String){
        createLead(integration: "${integration}" customTrackablePoints: $trackPoints vchNumber: $vch storeMeta: $storeMeta ){
          status
          code
        }
      }`,
      variables: {
        storeMeta: { ...storeMeta },
        trackPoints: trackablePoints,
        vch: vchNumber,
      },
    };
  } else {
    const { address } = newCustomer;
    reqBody = {
      query: `mutation($storeMeta: StoreMeta, $trackPoints: String){
        createLead(integration: "${integration}" customTrackablePoints: $trackPoints storeMeta: $storeMeta newCustomer: {
        firstName: "${newCustomer.firstName}"
        lastName: "${newCustomer.lastName}"
        email: "${newCustomer.email}"
        phone: "${newCustomer.phone}"
        address: {
          nameOrNumber: "${address.nameOrNumber}"
          line1: "${address.line1}"
          line2: "${address.line2}"
          city: "${address.city}"
          county: "${address.county}"
          country: "${address.country}"
          postcode: "${address.postcode}"
          latitude: "${address.latitude || ""}"
          longitude: "${address.longitude || ""}"
        }
        }){
          status
          code
        }
      }`,
      variables: { storeMeta: { ...storeMeta }, trackPoints: trackablePoints },
    };
  }

  let data = await fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
    headers: {
      "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
      "content-type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(reqBody),
  });

  if (data.status === 200 || 201) {
    return data.json();
  }

  return data;
}

export const useDisplayMeterList = (mpan?: string, mprn?: string) => {
  const [gasMeterList, setGasMeterList] = useState();
  const [elecMeterList, setElecMeterList] = useState();
  const meterDetails = {
    electricity: [{ mpan: mpan }],
    gas: [{ mprn: mprn }],
  };
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SP_REGISTERS_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(meterDetails),
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject(
            "Hmm, something went wrong. For now we're unable to complete the request, please try again later"
          );
        }
        return res.json();
      })
      .then((json) => {
        if (json.electricity[0]) {
          setElecMeterList(json.electricity[0]);
        }
        if (json.gas[0]) {
          setGasMeterList(json.gas[0]);
        }
      })
      .catch((err) => {});
  }, [mpan, mprn]);

  return {
    elecMeterList,
    gasMeterList,
  };
};

export const useFindScottishPowerProducts = (details: PropertyDetails) => {
  const [products, setProducts] = useState<ProductResponseData[]>([]);
  const [gasMPRN, setGasMPRN] = useState("");
  const [electricityMPAN, setElectricityMPAN] = useState("");
  const [isGasSmartFeatureLost, setIsGasSmartFeatureLost] = useState(false);
  const [isElecSmartFeatureLost, setIslecSmartFeatureLost] = useState(false);
  const [elecErrorMessage, setElecErrorMessage] = useState("");
  const [gasErrorMessage, setGasErrorMessage] = useState("");
  const [productsErrorMessage, setProductsErrorMessage] = useState("");
  const [isElecPrepaymentMeter, setIsElecPrepaymentMeter] = useState(false);
  const [isGasPrepaymentMeter, setIsGasPrepaymentMeter] = useState(false);
  const [isMeterE7, setIsMeterE7] = useState(false);
  const [addressCounty, setAddressCounty] = useState("");
  const [isElecSupplied, setIsElecSupplied] = useState<boolean | undefined>();
  const [isGasSupplied, setIsGasSupplied] = useState<boolean | undefined>();

  useEffect(() => {
    var retrievedMPAN: string;
    var retrievedMPRN: string;
    var addressCounty: string;
    

    if (details.addressId === "") {
      return;
    }

    fetch(`${process.env.REACT_APP_SP_ADDRESS_ENDPOINT}/${details.addressId}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      method: "GET",
    })
      .then((res) => {
        if (res.status !== 200) {
          setProductsErrorMessage(
            "The request to ScottishPower for a quote took too long to respond, or they may not be able to provide a supply to this property as they don't have industry information on this residence, but please try again later."
          );
          return Promise.reject(
            "Hmm, something went wrong. For now we're unable to complete the request, please try again later"
          );
        } else {
          return res.json();
        }
      })
      .then((json) => {
        const fuel = json as Fuel;
        const electricity = fuel.electricity[0];
        const gas = fuel.gas[0];

        let isElecSmart;
        let isGasSmart;
        let isElecPrepayment = false;
        let isGasPrepayment = false;
        let isGasSmartFunctionLost = false;
        let isElecSmartFunctionLost = false;
        let isMeterE7 = false;
        if (electricity) {
          if (electricity.isSupported) {
            retrievedMPAN = electricity.mpan;
            isElecSmart = electricity.isSmart;

            addressCounty = electricity.address.county || "";
            isElecPrepayment = electricity.isPrepayment;
            isMeterE7 = electricity.isEconomy7;
            if (isElecSmart) {
              isElecSmartFunctionLost = electricity.lossOfSmartFunctionality;
            }
            setElectricityMPAN(retrievedMPAN);

            const elecResponse = checkIsMeterSupplied("mpan", {
              electricity: [{ mpan: retrievedMPAN }],
            })
  
            elecResponse.then((res) => {
              setIsElecSupplied(res);          
            })
          }
          setElecErrorMessage(electricity.unsupportedDescription ?? "");
        }

        if (gas) {
          if(gas.isSupported) {
            retrievedMPRN = gas.mprn;
            isGasSmart = gas.isSmart;
            isGasPrepayment = gas.isPrepayment;
            addressCounty = gas.address.county || "";
            if (isGasSmart) {
              isGasSmartFunctionLost = gas.lossOfSmartFunctionality;
            }
            setGasMPRN(retrievedMPRN);
      
            const gasResponse = checkIsMeterSupplied("mprn", {
              gas: [{ mprn: retrievedMPRN }],
            })
  
            gasResponse.then((res) => {
              setIsGasSupplied(res);    
            })
          }
          setGasErrorMessage(gas.unsupportedDescription ?? "");
        }

        if (!electricity && !gas) {
          setProductsErrorMessage(
            `Unable to provide a quote for this property as we can't retrieve meter details for gas or electricity.`
          );
          return Promise.reject(
            "Unfortunately, we are unable to provide a quote for this property as we can't retrieve meter details for either your gas or electricity supply. Please try again later."
          );
        }

        setAddressCounty(addressCounty);
        setIslecSmartFeatureLost(isElecSmartFunctionLost);
        setIsGasSmartFeatureLost(isGasSmartFunctionLost);
        setIsGasPrepaymentMeter(isGasPrepayment);
        setIsMeterE7(isMeterE7);
        setIsElecPrepaymentMeter(isElecPrepayment);

        const bedroomNum = details.bedrooms;
        const buildingType = details.building;
        const occupants = details.occupants;
        const productRequest: ProductRequestData = {
          caller: "GOODLORD_TD",
          mpan: retrievedMPAN,
          mprn: retrievedMPRN,
          numberOfBedrooms: bedroomNum,
          propertyType: Number(buildingType),
          numberOfOccupiers: occupants,
        };

        if (productRequest.mprn === undefined) {
          delete productRequest.mprn;
        }

        if (productRequest.mpan === undefined) {
          delete productRequest.mpan;
        }
        Mixpanel.track("ScottishPower.BeginLoadProducts", {
          context: details,
        });
        fetch(`${process.env.REACT_APP_SP_PRODUCTS_ENDPOINT}`, {
          headers: {
            "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          body: JSON.stringify(productRequest),
        })
          .then((res) => {
            if (!res.ok) {
              Mixpanel.track("assistant.store.error", {
                storeName: "Scottish-Power",
                deal: "Find Products",
                context: details,
                ex: res,
              });
              if (res.status === 500) {
                setProductsErrorMessage(
                  "The request to ScottishPower for a quote took too long to respond, or they may not be able to provide a supply to this property as they don't have industry information on this residence, but please try again later."
                );
              }
              setProductsErrorMessage(
                "Hmm, something went wrong. For now we're unable to complete the request, please try again later"
              );
              return Promise.reject(
                "Hmm, something went wrong. For now we're unable to complete the request, please try again later"
              );
            }
            return res.json();
          })
          .then((json) => {
            Mixpanel.track("ScottishPower.EndLoadProducts", {
              response: json,
              context: details,
            });
            if (json.products < 1) {
              const productErrorMsg = `Hmm, we had a problem finding products for your property. For now we're unable to complete the request, but please try again later`;
              setProductsErrorMessage(productErrorMsg);
              return Promise.reject(
                `Hmm, we had a problem finding products for your property. For now we're unable to complete the request, but please try again later`
              );
            }
            const returnedProducts = json.products;
            setProducts(returnedProducts);
          });
      })
      .catch((err) => {
        setProductsErrorMessage(err)
        Mixpanel.track("assistant.store.error", {
          storeName: "Scottish-Power",
          deal: "Find Products",
          context: details,
          ex: err,
        });
        return err;
      });
  }, [details.addressId, details.building, details.bedrooms]);
  return {
    products,
    isMeterE7,
    isElecSmartFeatureLost,
    isGasSmartFeatureLost,
    elecErrorMessage,
    gasErrorMessage,
    productsErrorMessage,
    gasMPRN,
    electricityMPAN,
    isElecPrepaymentMeter,
    isGasPrepaymentMeter,
    addressCounty,
    isElecSupplied,
    isGasSupplied,
  };
};
