import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colours } from "../../common/styles";

interface Props {
  title: string;
  desc?: string;
  type?: "warn" | "success" | "error";
  onClick?: Function;
  showForSeconds?: number;
}

const AlertCardContainer = styled.div<{ type?: "warn" | "success" | "error" }>`
  background: ${(props) => getColour(props.type)};
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  min-height: 100px;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 15px;

  h5 {
    font-weight: bold;
    color: ${Colours.blue};
  }

  h3 {
    color: white;
    font-family: "Montserrat", sans-serif;
  }
`;

function getColour(type: string | undefined) {
  switch (type) {
    case "warn":
      return "#ED941E";
    case "success":
      return "#34BEBD";
    case "error":
      return "#ED1C24";
    default:
      return "#34BEBD";
  }
}

function doNothing() {}

const AlertCard: React.FC<Props> = ({
  title,
  desc,
  type,
  onClick = doNothing,
  showForSeconds = 3,
}) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (title.trim() === "") return;

    setVisible(true);
    let timer = setTimeout(() => {
      setVisible(false);
    }, 1000 * showForSeconds);

    return () => {
      clearTimeout(timer);
    };
  }, [title, desc, type]);

  if (visible) {
    return (
      <AlertCardContainer onClick={() => onClick()} type={type}>
        <h3>{title}</h3>
        {desc && <h5>{desc}</h5>}
      </AlertCardContainer>
    );
  }
  return null;
};

export default AlertCard;
