import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import React from "react";

const Alert = styled.div`
  background: white;
  color: black;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 20px;
  border: 1px solid rgb(234 179 8);
  display: flex; 
  justify-content: space-between; 
  gap: 1rem; 
  align-items: center; 
  text-align: left;
`;

const PaddedDiv = styled.div`
  padding-right: 10px;
  width: 100%;
`;

const AlertIcon = styled.div`
  padding: 10px;
  width: 40px;
  height: 40px;
  color: rgb(234 179 8);
`;

interface Props {
    title: string;
    children: React.ReactNode;
}

const WarningAlert = ({ title, children }: Props) => {
    return (
        <Alert>
            <AlertIcon>
                <FontAwesomeIcon size="xl" icon={faExclamationCircle} />
            </AlertIcon>
            <PaddedDiv>
                <h3>{title}</h3>
                {children}
            </PaddedDiv>
        </Alert>
    )
}

export default WarningAlert;