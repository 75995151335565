import React, { useState, useEffect } from "react";

import { TextInput } from "vouch.ui.react.components";
import styled from "styled-components";
import { Tenant } from "../../types/Void";
import DropdownSelector from "../ScottishPower/DropdownSelector";
import { titleOptions } from "../../helpers/ScottishPowerConstants";

interface Props {
  step: number;
  users: Tenant[];
  item: any;
  inputtedForm: any;
  setTenantForm: Function;
  setIsListUpdated: (isListUpdated:boolean) => void;
  setFormError: (isFormWithoutErrors:boolean) => void;
  formError: boolean;
}

const PanelContainer = styled.div`
display: flex;
text-align: left;
flex-flow:row;
grid-gap: 10px;
align-items: flex-start;
justify-content: space-between;
> div:nth-child(2) {
    flex: 0
    min-width: 100px;
} 
> div:nth-child(1) {
    flex: 1;
} 

`;
const RecordComponent = styled.div`
  ul {
    > div:nth-child(1) {
      > div:nth-child(1) {
        margin: 0 5px;
      }
    }
  }
`;

const PreviewPanel = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
  grid-area: preview;
  height: fit-content;
  flex: 0 1 300px;
  color: #082842;
  max-height: 700px;
  margin: 0 5px 30px;
  margin-top: 29px;

  #preview-content {
    display: grid;
    grid-auto-columns: 50% 50%;
    grid-auto-flow: column;
  }

  h4 {
    margin: 0;
  }
`;
const Label = styled.div`
  font-size: 12px !important;
  font-weight: bold;
  display: block;
  padding: 20px 0 0 0;
`;

const DataEntryPanel = styled.div`
  > div {
    display: flex;
    grid-gap: 20px;
    align-items: end;
  }
  grid-area: details;
  option {
    border-style: solid;
    border-width: 1px;
    padding: 18px;
    border-radius: 10px;
    position: relative;
    text-align: left;
    cursor: text;
    width: 100%;
  }
`;

const ErrorMessage = styled.span`
  color: #ED1C24;
  font-weight: 700;
`;

const SingleTenant: React.FC<Props> = ({
  step,
  item,
  inputtedForm,
  setTenantForm,
  users,
  setIsListUpdated,
  setFormError,
  formError
}) => {

  const [title, setTitle] = useState("");
  const [updatedForm, setUpdatedForm] = useState({
    title: users[step].contactDetails.title,
    forename: users[step].contactDetails.forename,
    surname: users[step].contactDetails.surname,
    email: users[step].contactDetails.email,
    phone: users[step].contactDetails.mobilePhone || users[step].contactDetails.housePhone ,
  });


  const [tenantForm, setMyFullForm] = useState({
    id: users[step].id,
    vchNumber: users[step].vchNumber,
    isLeadTenant: users[step].isLeadTenant,
    contactDetails: updatedForm,
  });



  function handleInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setIsListUpdated(false);
    const name = evt.target.name;
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setUpdatedForm({
      ...updatedForm,
      [name]: value,
    });
  }

  
  function getTitle(inputNumber: number, actualTitle: string): void {
    setUpdatedForm({ ...updatedForm, title:actualTitle });
  }


  useEffect(() => {
    if(item?.contactDetails && item?.contactDetails.title) {
      setTitle(item?.contactDetails.title);
    };
  }, []);

  useEffect(() => {
    setMyFullForm({ ...tenantForm, contactDetails: updatedForm });
  }, [updatedForm]);

  useEffect(() => {
    if(updatedForm.forename !== "" && updatedForm.surname !== "") {
      setFormError(false)
    } else {
      setFormError(true)
    }
  }, [updatedForm]);

  useEffect(() => {
    setTenantForm({ ...inputtedForm, contactDetails: tenantForm.contactDetails
      , vchNumber:tenantForm.vchNumber, id: tenantForm.id, isLeadTenant:tenantForm.isLeadTenant
     });
  }, [tenantForm]);


  if (!inputtedForm) return <div>Loading...</div>;

  return (
    <RecordComponent>
      <PanelContainer>
        <DataEntryPanel>
          <div>
            <ul>
              <DropdownSelector
                introText={""}
                listType={"Title"}
                getGeneralValue={getTitle}
                options={titleOptions}
                preSelectedTitleLabel={updatedForm.title}
              ></DropdownSelector>
              <TextInput
                type="text"
                name="forename"
                label="Tenant Forename"
                maxLength={50}
                minLength={2}
                regex={/^[a-zA-Z0-9\s-]+$/}
                value={
                  updatedForm.forename ?? (item?.contactDetails?.forename || "")
                }
                onChange={(e: any) => handleInputChange(e)}
              />
              <TextInput
                      type="text"
                      name="surname"
                      label="Tenant Surname"
                      maxLength={50}
                      minLength={2}
                      regex={/[^0-9/]/}
                      value={
                        updatedForm.surname ?? (item?.contactDetails?.surname || "")
                      }
                      onChange={(e: any) =>
                        handleInputChange(e)
                      }
                    />
                  </ul>
                  <ul>
                    <li>
                      <TextInput
                        type="text"
                        name="email"
                        label="Tenant Email"
                        maxLength={50}
                        minLength={2}
                        regex={/\S+@\S+\.\S+/}
                        value={
                        updatedForm.email ?? (item?.contactDetails?.email || "")
                        }
                        onChange={(e: any) =>
                          handleInputChange(e)
                        }
                      />

                      <TextInput
                        type="text"
                        name="phone"
                        label="Tenant Phone Number"
                        maxLength={11}
                        minLength={11}
                        regex={/^[0-9\s ]+$/}
                        value={
                            updatedForm.phone ?? (item?.contactDetails?.mobilePhone || item?.contactDetails?.housePhone || "")
                        }
                        onChange={(e: any) =>
                          handleInputChange(e)
                        }
                      />
                    </li>
            </ul>
          </div>
          {
            formError && (
              <ErrorMessage>Please add a forename and surname for this tenant</ErrorMessage>
            )
          }
        </DataEntryPanel>
        <PreviewPanel>
          <div id="preview-content">
            <div>
              <h4>Updated Tenant Details</h4>
              <Label>Name</Label>
              <span>{updatedForm.title || item?.contactDetails?.title} {updatedForm.forename} {updatedForm.surname}</span>
              <Label>Email</Label>
              <span>{updatedForm.email}</span>
              <Label>Phone</Label>
              <span>{updatedForm.phone}</span>
            </div>
          </div>
        </PreviewPanel>
      </PanelContainer>
    </RecordComponent>
  );
};

export default SingleTenant;
