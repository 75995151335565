import React, { useState, useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { PageWrapper, Button, Colours } from "../../common/styles";
// types
import { Store, Department } from "../../types/Store";
import Person from "../../types/Person";
// hooks
import { useAuthenticated } from "../../hooks/hooks";
// components
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import UserHandler from "../../components/modals/UserHandler";
import ExistingUser from "../../components/modals/ExistingUser";
import StoreHeader from "../../components/StoreHeader";

interface Props {
  store?: Store;
}

const StoreContainer = styled.div<{ bannerUrl: string }>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &:after {
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    position: fixed;
    background-position: center;

    @media (max-width: 320px) {
      height: 300px;
    }
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

const TenantsInsurancePageWrapper = styled(PageWrapper)`
  padding: 0;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  margin-bottom: 100px;

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

interface KeyAndValue {
  key: string;
  value: string;
}

export interface Quote {
  tenantFee: number | null;
  totalFee: number | null;
  headCount: number | null;
  ppm: number | null;
  savings: number | null;
}

export interface QuotePricing {
  charges: KeyAndValue[];
  finalPrice: string;
}

const TitleContainer = styled.div`
  justify-content: stretch;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #storeName {
    background: ${Colours.teal};
    color: white;
    padding: 20px;
    max-width: 80%;
    margin-bottom: 20px;
  }
  #departmentDescription {
    padding: 20px;
    background-color: ${Colours.blue};
    max-width: 80%;

    h2 {
      color: ${Colours.white};
    }
  }
`;

const Blurb = styled.div`
  h1 {
    font-weight: bold;
    color: white;
    font-family: "Montserrat", sans-serif;
    padding: 0px 30px;
  }
`;

const CTAWrapper = styled.div`
  margin: 25px auto;

  img {
    cursor: pointer;
    height: auto;
    padding: 0 20px;
  }
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 10px;
`;

const BlurbContainer = styled.div`
  padding: 40px;
  border-radius: 20px;
  background: ${Colours.blue};
  display: grid;
  align-items: flex-end;
  justify-content: center;

  img {
    max-width: 100%;
  }
  @media (min-width: 375px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const CheckMarkPoint = styled.div`
  display: flex;
  align-items: flex-start;
  color: ${Colours.white};

  img {
    padding: 5px;
    margin: 1em 5px 0 0;
  }

  span {
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
`;

const LandlordRentGuarantee: React.FC<Props> = ({ store }) => {
  //const shopContext = useContext(ctx);
  const [open, setOpen] = useState(false);
  const [applicant, setApplicant] = useState<Person | null>({
    firstname: "",
    lastname: "",
    agencyName: "",
    email: "",
    hasVisitedBefore: false,
    applicationStatus: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      nameOrNumber: "",
      postcode: "",
    },
  }); // store applicant meta
  const typeOfAuth = useAuthenticated();
  let history = useHistory();
  const shopContext = useContext(ctx);

  const [updatedStore, setUpdatedStore] = useState<Store | null>(null);
  const [updatedDepartment, setUpdatedDepartment] = useState<Department | null>(
    null
  );
  useEffect(() => {
    if (!store) return;

    const department = store.departments.filter(({ departmentName }) => {
      return departmentName.toLowerCase() === "insurance";
    })[0];
    const newCallBackUrl = department.callbackUrl.replace(
      "{AGENT_ID}",
      shopContext.config?.customer?.alanBoswellCode || "2716"
    );

    store.departments[0].callbackUrl = newCallBackUrl;

    console.log(newCallBackUrl);

    setUpdatedStore({ ...store });
    setUpdatedDepartment(department);
  }, [store]);

  if (!store) {
    return <div>Loading...</div>;
  }

  if (!updatedStore || !updatedDepartment) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {open && (
        <Modal setOpen={setOpen} open={open}>
          {typeOfAuth === "vch" ? (
            <ExistingUser
              integration="store"
              setOpen={setOpen}
              deal={{
                id: "landlord-rent-guarantee",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Landlord Rent Guarantee",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: updatedDepartment.callbackUrl,
              }}
              email=""
              storeName="Landlord Rent Guarantee"
              dealType="GETNOW"
            />
          ) : (
            <UserHandler
              deal={{
                id: "landlord-rent-guarantee",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Landlord Rent Guarantee",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: updatedDepartment.callbackUrl,
              }}
              integration="store"
              setOpen={setOpen}
              storeName="Landlord Rent Guarantee"
              dealType="GETNOW"
            />
          )}
        </Modal>
      )}
      <StoreContainer bannerUrl={store.smallBanner}>
        <MainStoreBanner>
          <StoreHeader storeLogo={updatedStore.logoUrl} />
          <TitleContainer>
            <div id="storeName">
              <h1>{updatedStore.storeName}</h1>
            </div>
            <div id="departmentDescription">
              <h2>{updatedDepartment.departmentShortDesc}</h2>
            </div>
          </TitleContainer>
        </MainStoreBanner>
        <TenantsInsurancePageWrapper center>
          <BlurbContainer>
            <h1>What&apos;s included?</h1>
            <TextContainer>
              <Blurb>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>Cover up to £2,500 in rental income per month.</p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>Up to 12 months&apos; cover</p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    Full landlord legal expenses up to £100,000 (including
                    eviction costs).
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>Landlord Legal expenses helpline available 24/7.</p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>All tenant types considered.</p>
                </CheckMarkPoint>
              </Blurb>
            </TextContainer>
            <CTAWrapper>
              <Button onClick={() => setOpen(true)}>Get Now</Button>
            </CTAWrapper>
          </BlurbContainer>
          <Footer>
            {store.storeTermsAndCond && (
              <SmallPrint>{store.storeTermsAndCond}</SmallPrint>
            )}
          </Footer>
        </TenantsInsurancePageWrapper>
      </StoreContainer>
    </>
  );
};

export default /* withAITracking(reactPlugin,  */ LandlordRentGuarantee;
