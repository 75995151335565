import React, { useState, useEffect, useRef, useContext } from "react";
import styled from "../common/themes";
// types
import { Store } from "../types/Store";
// components
import Footer from "../components/Footer";
import MainHeader from "../components/MainHeader";
import CardPanel from "../components/CardPanel";
import Loading from "../components/Loading";
import ctx from "../context/ShopContext";

import FullWidthBanner from "../components/FullWidthBanner";
import { PageWrapper, TertiaryButton } from "../common/styles";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../insights/ApplicationInsights";
import { useParams } from "react-router-dom";
import { AgencyData, PropertyData } from "../types/Config";
// helpers
import { updateFilteredStores } from "../helpers/helpers";
interface Props {
  stores: Store[];
  config: any;
  type?: string;
}

const ShopContainer = styled.div`
  display: grid;
  grid-template-rows: min-content min-content 1fr min-content;
  height: 100%;
  min-height: 100vh;
`;

const Stores = styled.div`
  display: grid;
`;

const Menu = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
`;

const FilterTagContainer = styled.ul`
  list-style: none;
  display: inline-grid;
  margin: 0;
  max-width: 1200px;
  grid-auto-flow: column;
  padding: 10px 0;
  grid-gap: 10px;
  max-width: 100%;
  max-height: 52px;
  text-align: center;
  overflow-x: auto;
  justify-items: flex-end;
  justify-content: start;
  ::-webkit-scrollbar {
    display: none;
  }
  width: 100%;
`;

interface IFilterTag {
  active: boolean;
}

const FilterTag = styled.li<IFilterTag>`
  display: flex;
  justify-content: space-evenly;
  padding: 7px;
  background: ${(p) => (p.active ? p.theme.color.mainHighlight : "")};
  color: ${(p: IFilterTag) => (p.active ? "white" : "#082842")};
  border-radius: 5px;
  border: ${(p) =>
    p.active
      ? `solid 1px ${p.theme.color.mainHighlight}`
      : "solid 1px #082842"};
  width: 100px;
  font-weight: 500;
  font-size: 0.8rem;
  max-height: 32px;
  text-align: center;
  align-items: center;
  transition: background-color 59ms linear;

  img {
    max-width: 24px;
    height: auto;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Shop: React.FC<Props> = ({ stores, config, type = undefined }) => {
  const [filterTerm, setFilterTerm] = useState<string>("all");
  const [filteredStores, setFilteredStores] = useState([]);
  const { setAgencyData, setPropertyData } = useContext(ctx);
  const { token } = useParams<{ token?: string }>();

  useEffect(() => {
    if (!token) return;
    const reqBody = {
      query: `query($token: String!){
          getStoreConfig(token: $token){
            agencyData{
              customerResourceId
              name
              email
              phone
            }
            propertyData{
              name
              line1
              line2
              city
              postcode
              moveIn
              monthlyRent
            } 
              }
            }`,
      variables: {
        token,
      },
    };

    fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(reqBody),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        const { agencyData, propertyData } = resData.data.getStoreConfig;

        if (agencyData) {
          setAgencyData(agencyData);
        }

        if (propertyData) {
          setPropertyData(propertyData);
        }
      })
      .catch((err) => {
        console.log(err);
        window.location.href = "/";
      });
  }, [token]);

  const shopRef = useRef<HTMLDivElement>(document.createElement("div"));

  const executeScroll = () =>
    shopRef.current.scrollIntoView({ behavior: "smooth" });

  function updateFilter(e: React.MouseEvent<HTMLLIElement>) {
    e.stopPropagation();
    let filterTag = e.target as HTMLInputElement;
    if (filterTag.id.trim() !== "") setFilterTerm(filterTag.id);
  }

  function switchStore() {
    window.location.href = type !== "landlord" ? `/landlord` : `/`;
  }

  useEffect(() => {
    if (!stores || stores.length === 0) return;

    updateFilteredStores(stores, setFilteredStores, filterTerm, type);
  }, [stores, filterTerm, type]);

  if (!filteredStores || filteredStores.length === 0) {
    return <Loading />;
  }

  return (
    <ShopContainer>
      <PageWrapper center>
        <MainHeader shopType={type} />
      </PageWrapper>

      <FullWidthBanner
        vchMeta={config.applicant}
        customerMeta={config.customer}
        executeScroll={executeScroll}
      ></FullWidthBanner>
      <PageWrapper center>
        <Stores id="Stores" ref={shopRef}>
          <Menu>
            <FilterTagContainer>
              {config.categories && config.categories.length > 0 && (
                <FilterTag
                  id="all"
                  onClick={(e) => updateFilter(e)}
                  active={filterTerm.toLowerCase() === "all"}
                >
                  All
                </FilterTag>
              )}
              {config.categories &&
                config.categories.map((filter: string, index: number) => (
                  <FilterTag
                    active={filterTerm.toLowerCase() === filter.toLowerCase()}
                    id={filter.toLowerCase()}
                    key={index}
                    onClick={(e) => {
                      updateFilter(e);
                    }}
                  >
                    {filter}
                  </FilterTag>
                ))}
            </FilterTagContainer>
            <TertiaryButton
              id="switchStore"
              key="switchStore"
              onClick={(e) => {
                switchStore();
              }}
            >
              {type !== "landlord" ? `Landlords` : `Tenants`}
            </TertiaryButton>
          </Menu>

          {Object.entries(filteredStores)
            .sort(function (a: any[], b: any[]) {
              return (
                config.categories.indexOf(a[0]) -
                config.categories.indexOf(b[0])
              );
            })
            .map(([key, subStores]: [string, Store[]], index: number) => (
              <CardPanel
                stores={subStores}
                key={index}
                category={key}
                isDealBreakdown={filterTerm !== "all"}
              />
            ))}
        </Stores>
      </PageWrapper>
      <PageWrapper center>
        <Footer />
      </PageWrapper>
    </ShopContainer>
  );
};

export default withAITracking(reactPlugin, Shop);
