import React from "react";
import styled from "styled-components";
import { Button } from "../../../common/styles";
import Person from "../../../types/Person";
import { Quote } from "../Reposit";

interface Props {
  option: string;
  applicant?: Person;
  quote: Quote;
}

const DealBreakdownContainer = styled.div`
  margin-top: 25px;
  box-shadow: 0 0 3px rgb(0 0 0 / 12%), 0 0 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  max-width: 1200px;
  background: white;
`;

const DealTitle = styled.h1`
  color: #0075dc;
`;

const DealBlurb = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  align-items: flex-start;
  padding: 15px;
  grid-gap: 5px;
`;

const BreakdownItem = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
`;

const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const OptionSelected = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  h3 {
    margin-right: 5px;
  }
`;

const DealBreakdown: React.FC<Props> = ({ option, applicant, quote }) => {
  function formatAddress(): string {
    if (!applicant || !applicant.address) return "";
    const { nameOrNumber, line1, city, postcode } = applicant.address;
    return `${nameOrNumber} ${line1}, ${city} ${postcode}`.trim();
  }

  if (!applicant || !quote) {
    return null;
  }
  return (
    <DealBreakdownContainer>
      <DealBlurb>
        <div>
          <DealTitle>
            Lets crunch the numbers for you {applicant.firstname}.
          </DealTitle>
          <BreakdownItem>
            <img alt="home" src="/assets/svg/home.svg" />
            {applicant.address && <h3>{formatAddress()}</h3>}
          </BreakdownItem>
          {quote.ppm && (
            <BreakdownItem>
              <img alt="money" src="/assets/svg/money.svg" />
              <h3>Monthly Rent £{(quote.ppm / 100).toFixed(2)}</h3>
            </BreakdownItem>
          )}
          {quote.tenantFee && (
            <BreakdownItem>
              <img alt="user" src="/assets/svg/user.svg" />
              <h3>Each tenant pays £{(quote.tenantFee / 100).toFixed(2)}</h3>
            </BreakdownItem>
          )}
          <h2 style={{ padding: "10px" }}>
            Saves{" "}
            <span style={{ color: "#0075dc", textDecoration: "underline" }}>
              £{quote.savings}
            </span>{" "}
            in up front costs
          </h2>
        </div>
        <div>
          <h1>We notify your agency immediately.</h1>
          <p style={{ textAlign: "justify" }}>
            When you have purchased Reposit, your letting agency will be
            notified immedaitely, removing the requirement for a traditional
            cash deposit.
          </p>
          <h1>What next?</h1>
          <p style={{ textAlign: "justify" }}>
            After you have purchased Reposit we will send you your rent invoice
            for the first month&apos;s rent only. When you have paid your letting
            agency directly, please come back to your dashboard and confirm that
            you have made your payment in the Option 1 Section.
          </p>
        </div>
      </DealBlurb>
      <Controls>
        <OptionSelected>
          <h3>Option {option} Selected</h3>
          <img alt="selected" src="/assets/svg/circle-tick.svg" />
        </OptionSelected>
        <Button>Get Now</Button>
      </Controls>
    </DealBreakdownContainer>
  );
};

export default DealBreakdown;
