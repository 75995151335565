import { createContext, useState } from "react";
// types
import { Store } from "../types/Store";
import Config, { AgencyData, PropertyData } from "../types/Config";

const ctx: ctxType = {
  vchNumber: null,
  customerId: null,
  emailAddr: null,
  dob: null,
  phone: null,
  setEmailAddr: () => {},
  setAdditionalUsrInfo: () => {},
  additionalUsrInfo: {
    firstName: null,
    lastName: null,
  },
  stores: [],
  config: null,
  agencyData: null,
  propertyData: null,
  setPropertyData: () => {},
  setAgencyData: () => {},
};

interface AdditionalUsrInfo {
  firstName?: string | null;
  lastName?: string | null;
}

export interface ctxType {
  vchNumber?: string | null;
  customerId?: string | null;
  emailAddr: null | string;
  dob?: null | string;
  phone?: null | string;
  stores: Store[];
  config: Config | null | undefined;
  setEmailAddr: Function;
  additionalUsrInfo: AdditionalUsrInfo;
  setAdditionalUsrInfo: Function;
  agencyData: AgencyData | null;
  setAgencyData: Function;
  propertyData: PropertyData | null;
  setPropertyData: Function;
}

const ShopContext = createContext(ctx);

export default ShopContext;
