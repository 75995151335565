import React, { useState, useContext, useEffect } from "react";
import ctx from "../../context/ShopContext";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { PageWrapper, Colours, Button } from "../../common/styles";
// types
import { Store } from "../../types/Store";
import Person from "../../types/Person";
import { useAuthenticated } from "../../hooks/hooks";
// components
import Footer from "../../components/Footer";
import Modal from "../../components/Modal";
import UserHandler from "../../components/modals/UserHandler";
import ExistingUser from "../../components/modals/ExistingUser";
import StoreHeader from "../../components/StoreHeader";

interface Props {
  stores: Store[];
}

const StoreContainer = styled.div<{ bannerUrl: string }>`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;

  &:after {
    content: "";
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      url("${(p) => (p.bannerUrl ? p.bannerUrl : "")}");
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    position: fixed;
    background-position: center;
  }

  & > * {
    position: relative;
    z-index: 5;
    margin: 0 auto;
    width: 100%;
    display: block;
  }
  & > #footer {
    z-index: 0;
  }
`;

const TenantsInsurancePageWrapper = styled(PageWrapper)`
  padding: 0;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  margin-bottom: 100px;

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }

  @media (max-width: 550px) {
    h1,
    h4 {
      max-width: 80%;
    }
  }
`;

interface KeyAndValue {
  key: string;
  value: string;
}

export interface Quote {
  tenantFee: number | null;
  totalFee: number | null;
  headCount: number | null;
  ppm: number | null;
  savings: number | null;
}

export interface QuotePricing {
  charges: KeyAndValue[];
  finalPrice: string;
}

const TitleContainer = styled.div`
  justify-content: stretch;
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #storeName {
    background: ${Colours.teal};
    color: white;
    padding: 20px;
    max-width: 80%;
    margin-bottom: 20px;
  }
  #departmentDescription{
    padding: 20px;
    background-color: ${Colours.blue};
    max-width: 80%;
   h2{
    color: ${Colours.white};
   } 
  }
`;

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
  padding: 10px;
`;

const Blurb = styled.div`
  h1 {
    color: white;
  }
`;

const BlurbContainer = styled.div`
  border-radius: 20px;
  padding: 40px;
  color: ${Colours.white};
  background: ${Colours.blue};
  display: grid;
  align-items: flex-end;
  justify-content: center;

  img {
    max-width: 100%;
  }
  @media (min-width: 375px){
    padding: 20px;
    margin: 0 20px;
  }
`;

const CTAWrapper = styled.div`
  margin: 0 auto;
  padding: 20px 0;
`;

const CheckMarkPoint = styled.div`
  display: flex;
  align-items: flex-start;

  img {
    padding: 5px;
    margin: 1em 5px 0 0;
  }

  span {
    font-weight: bold;
    font-family: "Montserrat", sans-serif;
  }
`;

const TCLIPage: React.FC<Props> = ({ stores }) => {
  const shopContext = useContext(ctx);
  const typeOfAuth = useAuthenticated();
  const [open, setOpen] = useState(false);
  const [applicant, setApplicant] = useState<Person | null>({
    firstname: "",
    lastname: "",
    agencyName: "",
    email: "",
    hasVisitedBefore: false,
    applicationStatus: "",
    address: {
      line1: "",
      line2: "",
      city: "",
      nameOrNumber: "",
      postcode: "",
    },
  }); // store applicant meta
  let history = useHistory();
  if (!stores) {
    return <div>Loading...</div>;
  }
  const tcli = stores[0];

  if (!tcli) {
    return <div>Loading...</div>;
  }

  const tenantInsurance = tcli.departments.filter(({ departmentName }) => {
    return departmentName.toLowerCase() === "insurance";
  })[0];

  return (
    <>
      {open && (
        <Modal setOpen={setOpen} open={open}>
          {typeOfAuth === "vch" ? (
            <ExistingUser
              integration="tcli"
              setOpen={setOpen}
              deal={{
                id: "tenants-contents-insurance",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Tenants Contents Insurance",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: "",
              }}
              email=""
              storeName="tcli"
              dealType="CALLBACK"
            />
          ) : (
            <UserHandler
              deal={{
                id: "tenants-contents-insurance",
                callback: "",
                description: "",
                smallPrint: "",
                price: {
                  amount: "",
                  currency: "GBP",
                  term: "",
                },
                dealName: "Tenants Contents Insurance",
                icon: "",
                endDate: "",
                startDate: "",
                tags: [],
                url: "",
              }}
              integration="tcli"
              setOpen={setOpen}
              storeName={tcli.storeName}
              dealType="CALLBACK"
            />
          )}
        </Modal>
      )}
      <StoreContainer bannerUrl={tenantInsurance.departmentBanner}>
        <MainStoreBanner>
          <StoreHeader storeLogo={tcli.logoUrl} />
          <TitleContainer>
            <div id="storeName"><h1>{tcli.storeName}</h1></div>
            <div id="departmentDescription"><h2>{tenantInsurance.departmentShortDesc}</h2></div>
            <div style={{ gridArea: "blank" }}>&nbsp;</div>
          </TitleContainer>
        </MainStoreBanner>
        <TenantsInsurancePageWrapper center>
          <BlurbContainer>
            <TextContainer>
              <Blurb>
                <h1>What&apos;s covered?</h1>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Contents cover</span> - for damage caused by fire,
                    theft, malicious damage, vandalism, subsidence, heave or
                    landslip, storm, flood and escape of water or oil.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Visitors&apos; belongings</span> - up to £1000.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Standard accidental damage cover</span> - for
                    electronics and more.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Alternative accomodation expenses</span> - up to a
                    maximum of £10,000.
                  </p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    <span>Tenants liability cover</span> - of up to £10,000 per
                    incident.
                  </p>
                </CheckMarkPoint>
              </Blurb>
              <Blurb>
                <h1>Optional extras</h1>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>Full accidental damage cover.</p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>Legal expenses cover up to £100.000</p>
                </CheckMarkPoint>
                <CheckMarkPoint>
                  <img src="/assets/svg/small-tick.svg" alt="tick" />
                  <p>
                    Contents outside the home for up to 90 consecutive days.
                  </p>
                </CheckMarkPoint>
              </Blurb>
            </TextContainer>
            <CTAWrapper>
              <Button onClick={() => setOpen(true)}>Get a Callback</Button>
            </CTAWrapper>
          </BlurbContainer>
          <Footer>
            {tcli.storeTermsAndCond && (
              <SmallPrint>{tcli.storeTermsAndCond}</SmallPrint>
            )}
          </Footer>
        </TenantsInsurancePageWrapper>
      </StoreContainer>
    </>
  );
};

export default /* withAITracking(reactPlugin,  */ TCLIPage;
