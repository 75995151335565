import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

interface Props {
  storeLogo: string;
  department?: string;
}

const StoreLogo = styled.img`
  max-width: 150px;
  grid-area: logo;
  justify-self: center;
  align-self: center;
`;

const BackArrow = styled.img`
  max-width: 25px;
  min-width: 25px;
  margin: 0 20px 0 0;
`;

const Title = styled.div`
  grid-area: department;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 200px auto;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    "controls logo blank"
    "department department department";
  justify-content: center;
  width: 100%;
  min-width: 300px;
`;

const ControlsContainer = styled.div`
  grid-area: controls;
  display: flex;
  justify-content: space-between;
  grid- img {
    max-width: 24px;
    height: auto;
  }

  img:hover {
    cursor: pointer;
  }
`;

const BannerContainer = styled.div`
  margin: 15px 0;
`;

const MainHeader: React.FC<Props> = ({ storeLogo, department = "" }) => {
  let history = useHistory();

  return (
    <BannerContainer>
      <TitleContainer>
        <ControlsContainer>
          {window.location.pathname !== "/" && (
            <BackArrow
              src="/assets/svg/back.svg"
              onClick={() => history.goBack()}
            />
          )}
        </ControlsContainer>
        <div style={{display:"flex"}}>
        <StoreLogo src={storeLogo} />
        </div>
        <div style={{ gridArea: "blank" }}>&nbsp;</div>
        {department && (
          <Title onClick={(e) => (window.location.href = "/")}>
            <h1>{department}</h1>
          </Title>
        )}
      </TitleContainer>
    </BannerContainer>
  );
};

export default MainHeader;
