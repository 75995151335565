import mixpanel from "mixpanel-browser";

class MixpanelWrapper {
    private envCheck: boolean;

    constructor() {
        this.envCheck = process.env.NODE_ENV !== 'development';
        console.log(process.env.REACT_APP_MIXPANEL_KEY);
        mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY as string);
    }

    public identify(id: string) {
        mixpanel.identify(id);
    }

    public alias(id: string) {
        mixpanel.alias(id);
    }

    public track(name: string, props?: object) {
        mixpanel.track(name, props);
    }

    public set people(props: object) {
        mixpanel.people.set(props);
    }
}

export const Mixpanel = new MixpanelWrapper();