import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextInput } from "vouch.ui.react.components";
import { AlternateButton, Button } from "../../common/styles";
import { Void } from "../../types/Void";
import SPAddressSelector from "../Input/SPAddressSelector";
import Loading from "../Loading";


interface Props {
  singleVoid: Void | undefined;
  isEditFormOpen: boolean;
  toggleEdit: () => void;
  moveToggle: (MoveInOrOut:boolean) => void;
  moveOutSelectedNotMoveIn: boolean;
  setSelectedOption: (input: boolean) => void;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  > div {
    width: 100%;
  }

  .grouped {
    display: flex;
  }
`;



const VoidAddressDetailContainer = styled.div`
  display: flexbox;
  flex-flow: row wrap;
  justify-content: space-between;
  display: flex;
  grid-gap: 5%;
`;



const LandlordSection = styled.div`

  cursor: pointer;
  min-width: 50%;
  width: 100%;

  #forwardingAddressSection {
margin-top: 50px;
  }

  .addressPeople {
    display: flex;
    align-items: baseline;
    grid-gap: 20px;
    width: 100%;
    ul {
      > div {
        min-width: 400px;
      }
    }
    li {
      padding: 5px 0;
      div {
        min-width: 400px;

      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const Address = styled.div`
  text-transform: capitalize;
`;


const AddressMetaSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-flow: column;
    flex: 0 1 65%;

    span {
      display: inline;
      line-height: 60px;
      font-family: "Montserrat";
      font-weight: 700;
      border-radius: 10px;
    }
  }
`;


const TenancyDetailsSection = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 0 0 10px 10px;
  border-top:none; 
  padding: 40px;
  align-self: baseline;
  flex: 1 1 20%;

  .dateFeedback {
    display: flex;
  }
  #addressCollection {
    display: flex;
  }

  #voidPeriod,
  #forwardingAddressEntry {
    > div {
      grid-gap: 50px;
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: left;
      div {
        width: 100%;
      }
      #addressSelector {
        div {
          max-width: 100%;
        }
      }
    }
  }
  #ownerInfo {
    display: flex;
    flex-flow: column;
  }

  #moveDates {
    display: flex;
    max-width: 100%;

    .editedDates {
      display: flex;
      h4 {
        margin-bottom: 0;
      }
    }
    div {
      flex: 1;
      width: 100%;
    }
  }
  #addressSelector {
    > div {
      form {
        > div {
          margin: 10px 0;
        }
      }
    }
  }
  #moveoutDate {
    margin: 10px 0px;
    div {
      margin: 0;
    }
  }
  .side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 220px;
    width: 100%;
    div {
      padding: 0;
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .introHeading {
    margin-top: 18px;
    font-size: 16px;
    span {
      font-size: 16px;
      color: grey;
      /* font-size: 1.17rem; */
    }
  }

  #voidOwnerTitle {
    border-top: 1px solid #f2f2f2;
    padding-top: 30px;

    span {
      color: grey;
      font-size: 1.17rem;
    }
  }
  small {
    display: block;
    padding-top: 60px;
  }
  span {
    font-size: 0.8rem;
  }

  > div:nth-child(1) {
    display: flex;
    flex-flow: column;
    max-width: 400px;
    column-gap: 20px;

    > div {
      flex: 1 1 100px;
    }
  }
  .addressPeople {
    display: flex;
    align-items: baseline;
    grid-gap: 20px;
    width: 100%;
    li {
      padding: 5px 0;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .utilityList {
    img {
      width: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      max-width: 220px;
      padding: 5px 0;
    }
  }
`;



const Back = styled.div`
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  z-index: 10;
  font-size: 1rem;
`;

const HeadingContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  margin-left: 20px;

  align-items: center;
`;



const LandlordEditPanel: React.FC<Props> = ({
  singleVoid,
  isEditFormOpen,
  toggleEdit,
  moveOutSelectedNotMoveIn,
  moveToggle,
  setSelectedOption
}) => {

 // CONSTS 
 const [sendingData, setSendingData] = useState(false);

 const [landlordAddress, setLandlordAddress] = useState({
   city: "",
   houseNumber: "",
   postCode: "",
   street: "",
   id: "",
   houseName: "",
   flatNumber: "",
   formattedAddress: "",
   line1: "",
   line2:"",
 });

 const [voidLandlord, setVoidLandlord] = useState({
   companyName: "",
   email: "",
   title: "",
   forename: "",
   surname: "",
   phone: "",
   forwardingAddress: {
     city: landlordAddress.city,
     houseNumber: landlordAddress.houseNumber,
     postCode: landlordAddress.postCode,
     street: landlordAddress.street,
     id: landlordAddress.id,
     houseName: landlordAddress.houseName,
     flatNumber: landlordAddress.flatNumber,
     formattedAddress: landlordAddress.formattedAddress,
     line1: `${landlordAddress.flatNumber ? landlordAddress.flatNumber : ""} ${landlordAddress.houseNumber} ${landlordAddress.street}`,
     line2:landlordAddress.city,
   }
 });
 

  function handleEditedFields(
    evt: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    if (type === "landlord") {
      setVoidLandlord({
        ...voidLandlord,
        [evt.target.name]: evt.target.value,
      });
    }
 
  }
  


// THE NEW WAY OF UPDATING LANDLORD DETAILS

  async function updateVoid(voidDetails: any, id: string) {
    setSendingData(true);
    
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/new/${id}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(voidDetails),
      }
    );
    setSendingData(true);

    if (res.status === 200) {
      console.log("yeah edit!");      
    }
    
    window.location.reload();
    setSendingData(false);
    toggleEdit();
  }

 
  function handleUpdatedSingleVoid() {
    if(singleVoid !== undefined) {
    let updatedVoid = {};
    let forwardingAddressObj: any;
    if(singleVoid.landlordContactDetails !== undefined && 
     singleVoid.landlordContactDetails.forwardingAddress !== undefined &&
     singleVoid.landlordContactDetails.forwardingAddress !== null ) {
     
      updatedVoid = {
      voidId: singleVoid.id,
      customerIdentifier: singleVoid.customerIdentifier,
      landlordContactDetails: {
              title:   voidLandlord.title || singleVoid.landlordContactDetails.title,
              email:   voidLandlord.email || singleVoid.landlordContactDetails.email,
              surname:   voidLandlord.surname || singleVoid.landlordContactDetails.surname,
              forename:   voidLandlord.forename || singleVoid.landlordContactDetails.forename,
              phone:   voidLandlord.phone || singleVoid.landlordContactDetails.mobilePhone || singleVoid.landlordContactDetails.housePhone,
              companyName:   voidLandlord.companyName || singleVoid.landlordContactDetails.companyName,
              address:{
                city: landlordAddress.city || singleVoid.landlordContactDetails.forwardingAddress.city ,
                postcode: landlordAddress.postCode || singleVoid.landlordContactDetails.forwardingAddress.postcode ,
                udprn: landlordAddress.id || singleVoid.landlordContactDetails.forwardingAddress.id ,
                name: landlordAddress.houseName || singleVoid.landlordContactDetails.forwardingAddress.houseName ,
                line1: `${landlordAddress.flatNumber ? landlordAddress.flatNumber : ""} ${landlordAddress.houseNumber} ${landlordAddress.street}` || `${singleVoid.landlordContactDetails.forwardingAddress.flatNumber ? singleVoid.landlordContactDetails.forwardingAddress.flatNumber : ""} ${singleVoid.landlordContactDetails.forwardingAddress.houseNumber} ${singleVoid.landlordContactDetails.forwardingAddress.street}`,
                line2: landlordAddress.houseName || singleVoid.landlordContactDetails.forwardingAddress.houseName ,
              }
    }
  } 
  

} else {
  forwardingAddressObj = {
    city:landlordAddress.city  ,
    postcode:landlordAddress.postCode  ,
    udprn:landlordAddress.id  ,
    name:landlordAddress.houseName,
    line1:landlordAddress.line1,
    line2:landlordAddress.line2 
  };

const landlordContactDetailsObj = {
    title: voidLandlord.title || singleVoid?.landlordContactDetails?.title,
    email: voidLandlord.email || singleVoid?.landlordContactDetails?.email,
    surname: voidLandlord.surname || singleVoid?.landlordContactDetails?.surname,
    forename: voidLandlord.forename || singleVoid?.landlordContactDetails?.forename,
    phone: voidLandlord.phone || singleVoid?.landlordContactDetails?.mobilePhone  || singleVoid?.landlordContactDetails?.housePhone,
    companyName: voidLandlord.companyName || singleVoid?.landlordContactDetails?.companyName,
    address: forwardingAddressObj
}

updatedVoid = {
  landlordContactDetails:landlordContactDetailsObj,
  voidId: singleVoid.id,
  customerIdentifier: singleVoid.customerIdentifier,
}

if(landlordContactDetailsObj.address.city === null || landlordContactDetailsObj.address.city === "") {
  delete landlordContactDetailsObj.address.city
}
}



if(singleVoid.id !== undefined) {
  updateVoid(updatedVoid, singleVoid.id)
  }
}
   

  }

  

 


 
  
  useEffect(() => {   
    setVoidLandlord({...voidLandlord, forwardingAddress:landlordAddress}) 
  },[landlordAddress.postCode])
 


  return (
    <Container id="voidEditContainer">
      {isEditFormOpen && !sendingData ? (
        <div>
                
          <HeadingContainer>
            <Back onClick={() => toggleEdit()}>
              <img
                src="/assets/svg/back.svg"
                alt="a black arrow pointing leftwards"
              />
            </Back>
            <AddressMetaSection>
              <div>
                <span>
                  {singleVoid?.propertyAddress?.line1
                    ? `${singleVoid?.propertyAddress?.line1.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.line2
                    ? `${singleVoid?.propertyAddress?.line2.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.city
                    ? `${singleVoid?.propertyAddress?.city.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.postcode
                    ? `${singleVoid?.propertyAddress?.postcode.toUpperCase()}`
                    : ""}
                </span>
              </div>
            </AddressMetaSection>
          </HeadingContainer>
          <div id="editButtonsContainer">
            <div>
              {isEditFormOpen && (
                <Button
               
                  onClick={() => handleUpdatedSingleVoid()}
                >
                  Submit
                </Button>
              )}

              <AlternateButton onClick={() => toggleEdit()}>
                {isEditFormOpen ? (
                  "Cancel"
                ) : (
                  <div className="editButton">
                    Edit
                    <img src="/assets/svg/edit.svg" alt="icon of a pen" />
                  </div>
                )}
              </AlternateButton>
            </div>

 
          </div>
         
          <VoidAddressDetailContainer>
            <TenancyDetailsSection>
              <div id="ownerInfo">
       
       <LandlordSection>
         <h3 id="voidOwnerTitle">
           Landlord
         </h3>
         {singleVoid?.landlordContactDetails !== null ? (
           <div className="addressPeople">
             <ul>
               <TextInput
                 type="text"
                 name="title"
                 label="Landlord Title"
                 maxLength={50}
                 
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.title ||
                   singleVoid?.landlordContactDetails?.title
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
               <TextInput
                 type="text"
                 name="forename"
                 label="Landlord Forename"
                 maxLength={50}
                 
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.forename ||
                   singleVoid?.landlordContactDetails?.forename
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
               <TextInput
                 type="text"
                 name="surname"
                 label="Landlord Surname"
                 maxLength={50}
                 
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.surname ||
                   singleVoid?.landlordContactDetails?.surname
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
             </ul>
             <ul>
               <li>
                 <TextInput
                   type="text"
                   name="email"
                   label="Landlord Email"
                   maxLength={50}
                   
                   regex={/\S+@\S+\.\S+/}
                   value={
                     voidLandlord.email ||
                     singleVoid?.landlordContactDetails?.email
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />

                 <TextInput
                   type="text"
                   name="phone"
                   label="Landlord Phone"
                   maxLength={11}
                   
                   regex={/^[0-9\s ]+$/}
                   value={
                     voidLandlord.phone ||
                     singleVoid?.landlordContactDetails?.mobilePhone || singleVoid?.landlordContactDetails?.housePhone
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />
                 
                 <TextInput
                   type="text"
                   name="companyName"
                   label="Landlord Company Name"
                   value={
                     voidLandlord.companyName ||
                     singleVoid?.landlordContactDetails?.companyName
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />
                 {
                   singleVoid?.billOwner !== "agency" && (
                 <div id="forwardingAddressSection">
                    <SPAddressSelector
     selectedAddress={landlordAddress}
     setSelectedAddress={setLandlordAddress}
     />
     <h3>Forwarding Address</h3>
   {landlordAddress !== null && landlordAddress !== undefined && (<Address>{landlordAddress.flatNumber ? <>{landlordAddress.flatNumber},</> : ""} {landlordAddress.houseNumber ? <>{landlordAddress.houseNumber}</> : ""} {landlordAddress.street ? <>{landlordAddress.street.toLowerCase()},</> : ""} {landlordAddress.city ? <>{landlordAddress.city.toLowerCase()},</> : ""} {landlordAddress.postCode ? <>{landlordAddress.postCode.toUpperCase()}</> : ""}</Address>)}
                 </div>
                   )
                 }
               </li>
             </ul>
           </div>
         ) : (
           <div className="addressPeople">
             <ul>
               <TextInput
                 type="text"
                 name="title"
                 label="Landlord Title"
                 maxLength={50}
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.title ||
                   ""
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
               <TextInput
                 type="text"
                 name="forename"
                 label="Landlord Forename"
                 maxLength={50}
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.forename ||
                   ""
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
               <TextInput
                 type="text"
                 name="surname"
                 label="Landlord Surname"
                 maxLength={50}
                 regex={/^[a-zA-Z0-9\s-]+$/}
                 value={
                   voidLandlord.surname ||
                   ""
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "landlord")
                 }
               />
             </ul>
             <ul>
               <li>
                 <TextInput
                   type="text"
                   name="email"
                   label="Landlord Email"
                   maxLength={50}
                   
                   regex={/\S+@\S+\.\S+/}
                   value={
                     voidLandlord.email ||
                     ""
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />

                 <TextInput
                   type="text"
                   name="phone"
                   label="Landlord Phone"
                   maxLength={11}
                   
                   regex={/^[0-9\s ]+$/}
                   value={
                     voidLandlord.phone ||
                    ""
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />
                 
                 <TextInput
                   type="text"
                   name="companyName"
                   label="Landlord Company Name"
                   maxLength={31}
                   // regex={/^[0-9\s ]+$/}
                   value={
                     voidLandlord.companyName ||
                    ""
                   }
                   onChange={(e: any) =>
                     handleEditedFields(e, "landlord")
                   }
                 />
                             Forwarding Address
                           <div>
                    <SPAddressSelector
     selectedAddress={landlordAddress}
     setSelectedAddress={setLandlordAddress}
   />
                 </div>
               </li>
             </ul>
           </div>
         )}
       </LandlordSection>
       
     </div>
   
   
      
            </TenancyDetailsSection>
            
          </VoidAddressDetailContainer>
          
        </div>
        
      ): <Loading/>}

    </Container>
  );
};

export default LandlordEditPanel;
