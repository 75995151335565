import React, { useContext } from "react";
import styled from "styled-components";
import { MetaMeterInfo, MeterRetryDetails, PodioFuelNotification, Void, VoidMeterSubmission } from "../../types/Void";
import VoidUtilityStatus from "./VoidUtilityStatus";
import { format, addDays } from "date-fns";
import AlertMessage from "./AlertMessage";
import ctx, { ctxType } from "../../context/VoidContext";


interface Props {
  voidMove: Void['moveIn'] | Void['moveOut'] ;
  voidMeterDetails:Void['gasMeterDetails' | 'electricMeterDetails'] | null;
  voidAddress: Void['propertyAddress'];
  isMeterReadOpen: boolean;
  isReadRequired: boolean;
  meterReading:VoidMeterSubmission | undefined;
  meterNightReading:VoidMeterSubmission | undefined;
  toggleMeterReadEntryPanel: () => void;
  toggleMeterRead: () => void;
  voidMoveNotifications?:Void['moveOut' | 'moveIn']['notificationsToDisplay'] | undefined;
  voidMoveNotificationsFuel:Void['moveOut' | 'moveIn']['notificationsToDisplay']['council' | 'gas' | 'water' | 'electricity'] | undefined;
  voidMoveInternalSPFuelStatus:Void['moveOut' | 'moveIn']['internalScottishPowerGasStatus' | 'internalScottishPowerElectricityStatus'] | undefined;
  meterType: MetaMeterInfo;
  voidPodioNotificationFuel: PodioFuelNotification['water'] | PodioFuelNotification['council'] | PodioFuelNotification['gas'] | PodioFuelNotification['electricity'] | undefined;
  findMeter:(MeterDetails:MeterRetryDetails) => void; 
  meterDetails:MeterRetryDetails;
  moveOutSelectedNotMoveIn: boolean;
}

const PropertyMeter = styled.div<{ isMeterReadOpen: boolean }>`

.logoContainer {
    display: flex;
    align-items: center;
    img {
        width: 40px;
    }
}
  .miniPill {
    display: ${(props) => (props.isMeterReadOpen ? "block" : "none")};
    div {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
  .unsupportedMessage {
    padding: 30px;
    border: 2px solid orange;
    border-radius: 10px;
    flex: 1;
    display: block;
    flex-flow: row wrap;
    text-align: left;
    align-content: center;
    margin-bottom: 10px;
    display: inline-flex;
    height: fit-content;
    line-height:1.8rem;
}
  }

  .titleContainer {
    margin-left: 20px;
    margin-bottom: 20px;
  }

  

  display: flex;
  flex-flow: column;
  > div:nth-child(1) {
    display: flex;
    padding: 0 20px 0 10px;
    justify-content: space-between;
  }
  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
  }
  background: ${(props) => props.theme.background};
  padding: 20px;
  border-radius: 10px;

  .altTitle {
    color: #34bebd;
    font-size: 1.2rem;
    display: block;
  }
  .meterId {
    display: flex;
    flex-flow: column;
    max-width: 260px;
    
  }
  .switchSection,
  .readingsSection {
    display: ${(props) => (props.isMeterReadOpen ? "none" : "block")};
  }

  .readingsSection {
    margin-right: 25px;
  }

  .groupOut {
    display: flex;
    column-gap: 20px;
    > div {
      flex: 1;
      width: ${(props) => (props.isMeterReadOpen ? "100%" : "auto")};
    }
    span {
      min-width: 75px;
      }
    }
  }
  .title {
    font-size: 1rem;
    font-weight: 700;
  }

  small {
    padding-top: 0px;
  }

  margin-bottom: 30px;
`;

const MeterNotice = styled.div<{ isMeterReadOpen?: boolean }>`
  border: 2px solid ${props => props.theme.noticeAccent};
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
  span {
    display: inline !important;
    font-size: 1rem !important;
    text-transform: capitalize;
  }

  display: ${(props) => (props.isMeterReadOpen ? "none" : "block")};
`;


const MeterInfoContainer = styled.div`
  display:flex;
  justify-content: space-between;
`;

const SubmitReadPill = styled.div`
  margin-left: auto;
  cursor: pointer;
  width: 120px;
  border-radius: 10px;
  background: ${(props) => props.theme.button};
  color: #fff;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 10px;
  padding: 5px 10px;
  margin-bottom: 20px;
`;


const VoidPropertyMeter: React.FC<Props> = ({
  moveOutSelectedNotMoveIn,
  voidMove,
  isMeterReadOpen,
  isReadRequired,
  meterReading,
  meterNightReading,
  voidMeterDetails,
  voidAddress,
  voidMoveNotifications,
  voidMoveNotificationsFuel,
  voidMoveInternalSPFuelStatus,
  toggleMeterReadEntryPanel,
  toggleMeterRead,
  meterType,
  voidPodioNotificationFuel,
  findMeter,
  meterDetails
}) => {
  
  const { darkModeEnabled }: ctxType = useContext(ctx);


  return (
    <PropertyMeter id="elecMeterMoveIn" isMeterReadOpen={isMeterReadOpen}>
      <div>
        <span className="logoContainer">
          <img src={meterType.img} alt={meterType.imageDesc} />
          <span className="altTitle">{meterType.title}</span>
        </span>
        <VoidUtilityStatus 
        isReadRequired={isReadRequired} 
        toggleMeterReadEntryPanel={toggleMeterReadEntryPanel} 
        voidMove={voidMove} 
        voidMoveNotifications={voidMoveNotifications} 
        voidMoveNotificationsFuel={voidMoveNotificationsFuel}>
        </VoidUtilityStatus>
        
      </div>
      <div className="groupOut">
        {
          <MeterInfoContainer>
          

          <>
 
          <div className="meterId">
          <span className="title">Meter Details</span>
            <span className="groupOut">
              {
                <span>{
                  meterType.title.toLowerCase() === "gas" ? "MPRN:" : 
                  meterType.title.toLowerCase() === "electricity" ? "MPAN:" : 
                  meterType.title.toLowerCase() === "water" ? null : 
                  voidMeterDetails ? "mpan" in voidMeterDetails ? voidMeterDetails.mpan : voidMeterDetails?.mprn : "Unknown"}
                </span>
                }
              {
                <span>{
                  meterType.title.toLowerCase() === "gas" ? (voidMeterDetails ? "mpan" in voidMeterDetails ? voidMeterDetails.mpan : voidMeterDetails?.mprn : "Unknown") : 
                  meterType.title.toLowerCase() === "electricity" ? (voidMeterDetails ? "mpan" in voidMeterDetails ? voidMeterDetails.mpan : voidMeterDetails?.mprn : "Unknown") : 
                  meterType.title.toLowerCase() === "water" ? null : ""
                  }
                </span>
                }
            </span>

            <span className="groupOut">
              <span>Serial:</span>{" "}
              <span>{voidMeterDetails?.serialNumber ?? "Unknown"}</span>
            </span>
            <span className="groupOut">
              <span>Meter Type:</span>{" "}
              <span>
                {voidMeterDetails ? voidMeterDetails.isPrepayment ? "Prepayment"
                  : "isEconomy7" in voidMeterDetails && voidMeterDetails.isEconomy7 ? "Economy 7" : "Standard" : "Unknown"}
              </span>
            </span>
            {
              voidPodioNotificationFuel !== null &&
              voidPodioNotificationFuel?.sentToPodioDate !==
                undefined &&
                voidPodioNotificationFuel?.sentToPodioDate !==
                "1970-01-01T00:00:00+00:00" &&
                voidPodioNotificationFuel?.sentToPodioDate !==
                "0001-01-01T00:00:00+00:00" ? null : 
            <div className="miniPill">
              <SubmitReadPill
                data-cy="gas-submit-read"
                onClick={() => {
                  toggleMeterRead();
                }}
              >
                {isMeterReadOpen ? "View Meter Info" : "Submit Reading"}
              </SubmitReadPill>
            </div>
            }
          </div>
        <div className="meterId switchSection">
          <span className="title">Supplier</span>
          <span className="groupOut">
            <span>Current:</span>
            <span>
              {voidMove !== null &&
              voidMoveNotificationsFuel !== null &&
              voidMoveNotificationsFuel?.provider
                ? voidMoveNotificationsFuel.provider
                : "Supplier Unknown"}
            </span>
          </span>
          <span className="groupOut">
            <span>Notified on:</span>
            <span>
              {voidMoveInternalSPFuelStatus !== null &&
              voidMoveInternalSPFuelStatus !== undefined &&
              voidMoveInternalSPFuelStatus.sentDate &&
              voidMoveInternalSPFuelStatus !==
                undefined &&
              voidMoveInternalSPFuelStatus?.sentDate !==
                "1970-01-01T00:00:00+00:00" &&
              voidMoveInternalSPFuelStatus.sentDate !==
                "0001-01-01T00:00:00+00:00"
                ? <>{format(new Date(voidMoveInternalSPFuelStatus.sentDate), "PPPP")}</>
                : voidPodioNotificationFuel !== null &&
                voidPodioNotificationFuel?.sentToPodioDate !==
                  undefined &&
                  voidPodioNotificationFuel?.sentToPodioDate !==
                  "1970-01-01T00:00:00+00:00" &&
                  voidPodioNotificationFuel?.sentToPodioDate !==
                  "0001-01-01T00:00:00+00:00" ? format(new Date(voidPodioNotificationFuel?.sentToPodioDate), "PPPP") 
                  : "Notification not sent"}
            </span>
          </span>
        </div>
        <div className="meterId readingsSection">
          <span className="title">Reading:</span>
          {voidMove !== null &&
          voidMoveNotifications !== null &&
          voidMoveNotificationsFuel?.status ===
            "cancelled" ? (
            <div>
              <span>No read submission required</span>
            </div>
          ) : (
            <div>
              {meterNightReading !== undefined &&
               <span id="readUpdate">
               <span>Electricity Night reading: </span>
               <span>
                 <strong>
                   {meterNightReading?.["value"] || "Not found"}
                 </strong>
               </span>
               <br/>
             </span>
              }

              {meterReading !== undefined ? (
                <>       
                   <span id="readUpdate">
                    <span>{meterType.title}{voidMeterDetails !== undefined && voidMeterDetails !== null && "isEconomy7" in voidMeterDetails && voidMeterDetails.isEconomy7 ? " Day " : " "}reading: </span>
                    <span>
                      <strong>
                        {meterReading?.["value"] || "Not found"}
                      </strong>
                    </span>
                  </span>
                  <br />                
                </>
              ) 
              
               : (
                 <>
                   {isReadRequired ? (
                    <AlertMessage alertEvent={toggleMeterRead} message={"No reading provided"}></AlertMessage>
                  ) : (
                    <span>
                      {voidMove !== null &&
                      ((voidMoveInternalSPFuelStatus !==
                        null &&
                      voidMoveInternalSPFuelStatus !==
                        undefined &&
                      voidMoveInternalSPFuelStatus
                        ?.sentDate &&
                      voidMoveInternalSPFuelStatus !==
                        undefined &&
                      voidMoveInternalSPFuelStatus
                        ?.sentDate !== "1970-01-01T00:00:00+00:00" &&
                      voidMoveInternalSPFuelStatus
                        .sentDate !== "0001-01-01T00:00:00+00:00") ||
                        (voidPodioNotificationFuel !== null &&
                          voidPodioNotificationFuel?.sentToPodioDate !==
                            undefined &&
                            voidPodioNotificationFuel?.sentToPodioDate !==
                            "1970-01-01T00:00:00+00:00" &&
                            voidPodioNotificationFuel?.sentToPodioDate !==
                            "0001-01-01T00:00:00+00:00"))
  
                        ? (
                        "No meter readings supplied, an estimated read may be generated"
                      ) : (
                        <>
                          No meter readings supplied
                          <SubmitReadPill
                        data-cy="gas-submit-read"
                        onClick={() => {
                          toggleMeterRead();
                        }}
                      >
                        {isMeterReadOpen ? "View Meter Info" : "Submit Reading"}
                      </SubmitReadPill>
                  
                        </>
                      )}
                    </span>
                  )}
               </>
              ) 
              }

              {meterReading !== undefined && (
                <>
                  <span id="readUpdate">
                    Recorded on:{" "}<strong>
                    {format(new Date(
                      meterReading?.[
                        "readingDate"
                      ]),
                      "dd/MM/yyyy"
                    ) || "No date found"}{" "}
                    </strong>
                  </span><br/>
                  <span id="readUpdate">
                    Submitted on:{" "}<strong>
                    {format(new Date
                      (meterReading?.[
                        "addedDate"
                      ]),
                      "dd/MM/yyyy"
                    ) || "No date found"}{" "}
                    </strong>
                  </span>

                </>
              )}

              {
                voidMove !== null && 
                ((voidMoveInternalSPFuelStatus !==
                  null &&
                voidMoveInternalSPFuelStatus !==
                  undefined &&
                voidMoveInternalSPFuelStatus
                  ?.sentDate &&
                voidMoveInternalSPFuelStatus !==
                  undefined &&
                voidMoveInternalSPFuelStatus
                  .sentDate === "0001-01-01T00:00:00+00:00") ||
                  (meterType.title.toLowerCase() === "water")) && 
                voidPodioNotificationFuel !== null &&
                voidPodioNotificationFuel?.sentToPodioDate !==
                  undefined &&
                voidPodioNotificationFuel?.sentToPodioDate ===
                  "0001-01-01T00:00:00+00:00" &&
                  <SubmitReadPill
                    data-cy="gas-submit-read"
                    onClick={() => {
                      toggleMeterRead();
                    }}
                  >
                  {isMeterReadOpen ? "View Meter Info" : "Submit Reading"}
                  </SubmitReadPill>
                }
            </div>
            
          )}
        </div>
          </>
          </MeterInfoContainer>
        }

      </div>

      {meterReading === undefined && (
        <>
          {voidMove !== null &&
            voidMove?.moveDate &&
            voidMove?.moveDate !== "1970-01-01T00:00:00+00:00" &&
            voidMove?.moveDate !== "0001-01-01T00:00:00+00:00" &&
            voidMoveNotificationsFuel?.status !==
              "cancelled" &&
              meterType.title !== "Water" &&
            isReadRequired && (
              <MeterNotice isMeterReadOpen={isMeterReadOpen}>
                The meter reading for{" "}
                <span>{voidAddress?.line1?.toLowerCase()}</span>{" "}
                {voidAddress?.postcode?.toUpperCase()} may be
                estimated if a meter read isn&apos;t submitted by{" "}
                <strong>
                  {format(addDays(new Date(voidMove?.moveDate), moveOutSelectedNotMoveIn ? 7 : 10), "PPPP")}
                </strong>. {voidMeterDetails === null && (`Please provide a valid ${meterType.title.toLowerCase() === "gas" ? "MPRN" : "MPAN"} number to allow us to submit a meter reading successfully.`)}   
              </MeterNotice>
            )}
        </>
      )}
    </PropertyMeter>
  );
};

export default VoidPropertyMeter;
