import React from "react";
import styled from "styled-components";
import { useParams } from "react-router";
import { BannerBackground } from "../common/styles";
// types
import { Store, Deal } from "../types/Store";
import { useFindShop, useFindDeal } from "../hooks/hooks";
// components
import Footer from "../components/Footer";
import StoreHeader from "../components/StoreHeader";
import Loading from "../components/Loading";
import { reactPlugin } from "../insights/ApplicationInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

// components
import DealCard from "../components/DealCard";

interface Props {
  stores: Store[];
}

const DepartmentContainer = styled(BannerBackground)`
  min-height: 100vh;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;

  h1 {
    color: white;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }
`;

const BlurbContainer = styled.div``;

const DealsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  align-items: flex-start;
  margin: 25px 0;
  //place-items: center;
  //justify-items: center;
`;

const DealPage: React.FC<Props> = () => {
  let { store, department, deal } = useParams<{
    store: string;
    department: string;
    deal: string;
  }>();
  const foundStore: Store = useFindShop(store);
  const foundDeal: Deal | undefined = useFindDeal(store, department, deal);

  console.log(foundStore, foundDeal);

  if (!foundStore || !foundDeal) {
    return <Loading />;
  }

  return (
    <DepartmentContainer
      id="DepartmentContainer"
      bannerUrl={foundStore.departmentBanner}
    >
      <MainStoreBanner>
        <StoreHeader storeLogo={foundStore.logoUrl} department={department} />
      </MainStoreBanner>
      <BlurbContainer>
        <DealsContainer>
          <DealCard
            singleDeal
            deal={foundDeal}
            store={foundStore.storeName}
            department={department}
            storeId={foundStore.id}
            quote
          />
        </DealsContainer>
      </BlurbContainer>
      <Footer>
        {foundStore.storeTermsAndCond && (
          <SmallPrint>{foundStore.storeTermsAndCond}</SmallPrint>
        )}
      </Footer>
    </DepartmentContainer>
  );
};

export default withAITracking(reactPlugin, DealPage);
