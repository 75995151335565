import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { Mixpanel } from "../../insights/mixpanel";
import styled from "styled-components";
import {
  SteppedInput,
  TextInput,
  ToggleSwitch,
} from "vouch.ui.react.components";
import ScottishPowerProgressBar from "../ScottishPower/spProgressBar";

interface Props {
  step: number;
  setValidInput: Function;
  getTenantStepDetails: Function;
}

const AlternateProgBar = styled.p`
  padding: 30px 0;
  span {
    color: rgba(128, 128, 128, 0.5);
  }
  color: #082842;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  font-weight: 700;
`;
const AddTenants: React.FC<Props> = ({
  step,
  setValidInput,
  getTenantStepDetails,
}) => {
  const [isTenantMovingOut, setIsTenantMovingOut] = useState(true);
  const [moveInDate, setMoveInDate] = useState<string[]>([]);
  const [moveOutDate, setMoveOutDate] = useState<string[]>([]);

  const [tenant, setTenant] = useState({
    firstName: "",
    lastName: "",
    email: "",
    isLeadTenant: true,
    moveInDate: "",
    moveOutDate: "",
  });

  function handleTenantInfo(evt: any) {
    setTenant({
      ...tenant,
      [evt.target.name]: evt.target.value,
    });
  }

  useEffect(() => {
    if (
      tenant.firstName !== "" &&
      tenant.lastName !== "" &&
      tenant.email !== "" &&
      (tenant.moveOutDate !== "" || tenant.moveInDate !== "")
    ) {
      setValidInput(true);
      getTenantStepDetails(tenant)
    } else {
      setValidInput(false);
    }
  }, [tenant]);


  useEffect(() => {
    const moveIn = [moveInDate[0], moveInDate[1], moveInDate[2]].join("/");
    const moveOut = [moveOutDate[0], moveOutDate[1], moveOutDate[2]].join("/");
    if (moveOut.length === 10) {
      let selectedMoveOut = new Date(moveOut);
      const formattedMoveOut = selectedMoveOut.toISOString().slice(0,19);
      setTenant({ ...tenant, moveOutDate: formattedMoveOut });
    }
    if (moveIn.length === 10) {
      let selectedMoveIn = new Date(moveIn);
      const formattedMoveIn = selectedMoveIn.toISOString().slice(0,19);
      setTenant({ ...tenant, moveInDate: formattedMoveIn });
    }
  }, [moveInDate, moveOutDate]);


  if (step !== 3) {
    return null;
  }
  return (
    <>
      <AlternateProgBar>
        <span>Add Property / Add Landlord / </span>Add Lead Tenant /{" "}
        <span>Choose Notifications / Summary</span>
      </AlternateProgBar>

      <h3>Add Lead Tenant</h3>
      <TextInput
        type="text"
        name="firstName"
        label="Tenant's First Name"
        maxLength={15}
        minLength={2}
        regex={/^[a-zA-Z\s-]+$/}
        value={tenant.firstName}
        onChange={(e: any) => handleTenantInfo(e)}
      />
      <TextInput
        type="text"
        name="lastName"
        label="Tenant's Last Name"
        maxLength={15}
        minLength={2}
        regex={/^[a-zA-Z\s-]+$/}
        value={tenant.lastName}
        onChange={(e: any) => handleTenantInfo(e)}
      />
      <TextInput
        type="text"
        name="email"
        label="Tenant's Email Address"
        maxLength={50}
        minLength={2}
        regex={/\S+@\S+\.\S+/}
        value={tenant.email}
        onChange={(e: any) => handleTenantInfo(e)}
      />
      {isTenantMovingOut ? (
        <SteppedInput
          onChange={setMoveOutDate}
          values={moveOutDate}
          label="Move-Out Date (DD/MM/YYYY)"
          divider="/"
          config={[
            { label: "Day", length: 2, maxVal: 31, minVal: 1 },
            {
              label: "Month",
              length: 2,
              maxVal: 12,
              minVal: 1,
            },
            {
              label: "Year",
              length: 4,
              maxVal: 2005,
              minVal: new Date().getFullYear() - 1,
            },
          ]}
        />
      ) : (
        <SteppedInput
          onChange={setMoveInDate}
          values={moveInDate}
          label="Move-In Date (DD/MM/YYYY)"
          divider="/"
          config={[
            { label: "Day", length: 2, maxVal: 31, minVal: 1 },
            {
              label: "Month",
              length: 2,
              maxVal: 12,
              minVal: 1,
            },
            {
              label: "Year",
              length: 4,
              maxVal: 2005,
              minVal: new Date().getFullYear() - 1,
            },
          ]}
        />
      )}
    </>
  );
};

export default AddTenants;
