import React, { useState, useContext, useMemo, useEffect } from "react";
import ctx, { ctxType } from "../../context/ShopContext";
import styled from "styled-components";
import { Colours, Button, AlternateButton } from "../../common/styles";
import {
  SteppedInput,
  TextInput,
  ToggleSwitch,
} from "vouch.ui.react.components";

// types
import AddressSelector from "../../components/Input/AddressSelector";
// components


interface InsuranceInfo {
  [key: string]: any;
  line1?: string;
  line2?: string;
  city?: string;
  postcode?: string;
  currentRentalProtectionSupplier?: string;
  monthlyRent?: string;
  preferredStartDate?: string;
  policyPrice: string;
  policyLength: number;
  startDateMeta: string[];
  endDateMeta: string[];
  areNoArrearsPresent: boolean;
  areDetailsValid?: boolean;
}

const FinalSection = styled.div`
  margin-top: 70px;
`;

const CTAWrapper = styled.div`
  margin: 0 auto;
  padding: 20px 0;
  text-align: center;
  h3 {
    color: ${Colours.red};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  > div {
    flex: 1 1 300px;
  }

  #email {
    margin-bottom: 30px;
  }

  small {
    margin-left: auto;
    font-weight: 700;
    line-height: 1.5rem;
    width: 300px;
  }
`;

const BlurbContainer = styled.div<{ formPostSuccess: boolean }>`
  border-radius: 20px;
  padding: 40px;
  background: ${Colours.white};
  display: ${(props) => (props.formPostSuccess ? "block" : "grid")};
  align-items: flex-end;
  justify-content: center;
  .addressFormat {
    text-transform: capitalize;
  }

  .summaryComplete {
    display: flex;
    justify-content: space-between;
    flex-flow: row-wrap;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    padding: 50px 0 20px 0;
    font-size: 0.9rem;

    div {
      display: flex;
      justify-content: space-between;
      flex-flow: row-wrap;
    }
  }

  .SummaryContent {
    font-weight: 700;
    border-top: 1px solid #f2f2f2;
    padding: 30px 0;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    font-size: 0.9rem;
  }

  .policyLengthContainer {
    p {
      display: inline-block;
      margin: 20px 20px 20px 0;
    }
  }

  #loginHelp {
    text-align: right;
    a {
      text-decoration: underline;
    }
  }

  h1 {
    color: ${Colours.blue};
  }
  img {
    max-width: 100%;
  }
  @media (min-width: 375px) {
    padding: 20px;
    margin: 0 20px;
  }
`;

const FormContent = styled.div`
  button {
    margin-left: 0;
    margin-right: 0;
  }

  #flexContainer {
    display: flex;
    grid-gap: 20px;
    padding-top: 40px;
    flex-flow: row wrap;
  }

  #column1 {
    width: 100%;
    flex: 1 1 400px;
  }

  #column2 {
    min-width: 400px;
    align-self: flex-start;
    display: flex;
    flex-flow: column wrap;
    align-self: stretch;
    padding-top: 163px;
  }

  #insideColumn2 {
    position: sticky;
    top: 5px;
  }

  .summary {
    background: #f2f2f2;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    font-size: 0.8rem;
    h3 {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      span {
        display: block;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    #propertySummary {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
`;

const Policy = styled.div`
  display: flex;
  flex: 1;
  align-items: baseline;
  grid-gap: 30px;
  flex-flow: row wrap;
  > div:nth-child(1) {
    flex: 1 1 60%;
    margin-bottom: 50px;

    h3 {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        font-size: 0.8rem;
      }
    }
  }
  > div:nth-child(2) {
    flex: 1 1 300px;
  }

  label {
    padding: 10px;
  }
`;

const InputCheckBoxGroup = styled.div`
  text-align: left;

  a {
    text-decoration: underline;
  }

  label {
    display: flex;
    padding: 10px 0;
    font-size: 0.95rem;

    div {
      padding: 0 20px;
      align-self: center;
    }
  }

  input[type="checkbox"] {
    margin-right: 10px;
    font-size: 13px;
    top: 6px;
    position: relative;
  }
`;
const PolicyContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  #protectionSupplier {
    align-items: center;
    display: flex;
    div {
      padding: 0 1rem 0 0;
    }
  }

  #policyStartDate,
  #monthlyRent,
  #currentRentalProtectionSupplier {
    > div {
      margin: 10px 0px;
    }
  }
`;

const ConfirmMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  background: ${Colours.blue};
  color: ${Colours.white};
  height: 200px;

  span {
    font-size: 2.5rem;
    font-family: "Montserrat", sans-serif;
  }
`;

const SendingMessage = styled(ConfirmMessage)`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

const ContactDetailsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  grid-gap: 30px;
  div {
    flex: 1 1 300px;
  }
`;

const CheckBoxContainer = styled.div`
  .defaultCompanyAddress {
    max-width: 300px;
    margin-left: auto;
    font-size: 0.8rem;
    line-height: 18px;
    align-items: center;
  }

  label {
    display: flex;
    align-items: baseline;
    div {
      padding: 0 1rem 0 0;
    }
  }
`;

const RPIForm: React.FC = () => {
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const [isPolicyEligibilityAgreed, setIsPolicyEligibilityAgreed] =
    useState<boolean>(false);
  const [isInsuranceTermsAgreed, setIsInsuranceTermsAgreed] =
    useState<boolean>(false);
  const [isDefaultAddress, setIsDefaultAddress] = useState<boolean>(false);
  const [isReferencingPass, setIsReferencingPass] = useState<boolean>(false);
  const [areNoArrearsPresent, setAreNoArrearsPresent] =
    useState<boolean>(false);
  const [areNoCircumstancesChanged, setAreNoCircumstancesChanged] =
    useState<boolean>(false);
  const [submitAttempt, setSubmitAttempt] = useState<boolean>(false);

  const { agencyData, propertyData }: ctxType = useContext(ctx);
  const [startDay, setStartDay] = useState("");
  const [startMonth, setStartMonth] = useState("");
  const [startYear, setStartYear] = useState("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [formPostSuccess, setFormPostSuccess] = useState<boolean>(false);
  const [monthlyRent, setMonthlyRent] = useState("");
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [displayProtectionSupplier, setDisplayProtectionSupplier] =
    useState<boolean>(false);
  const [policyIsMonthly, setPolicyIsMonthly] = useState<boolean>(false);

  let timeStamp;
  if (propertyData && propertyData?.moveIn) {
    timeStamp = new Date(+propertyData?.moveIn * 1000);
  }

  let theYear = timeStamp ? timeStamp?.getFullYear().toString() : "";
  let theMonth = timeStamp
    ? (timeStamp?.getUTCMonth() + 1).toString().padStart(2, "0")
    : "";
  let theDay = timeStamp
    ? timeStamp?.getUTCDate().toString().padStart(2, "0")
    : "";

  const setPolicyDuration = () => {
    let policyDuration;
    if (policyIsMonthly) {
      policyDuration = 1;
    } else {
      policyDuration = 12;
    }
    return policyDuration;
  };

  const showPolicyPrice = (
    monthlyRent: string | undefined,
    duration: number | undefined
  ) => {
    let price = "** - Unable to insure at this rental amount - **";
    if (monthlyRent === undefined || duration === undefined) return price;
    if (duration === 1) {
      if (+monthlyRent <= 10000) {
        price = "48.75";
      }
      if (+monthlyRent >= 5001 && +monthlyRent <= 7500) {
        price = "32.00";
      }
      if (+monthlyRent >= 2501 && +monthlyRent <= 5000) {
        price = "26.25";
      }
      if (+monthlyRent <= 2500) {
        price = "19.50";
      }
    }

    if (duration === 12) {
      if (+monthlyRent <= 10000) {
        price = "549.00";
      }
      if (+monthlyRent >= 5001 && +monthlyRent <= 7500) {
        price = "349.00";
      }
      if (+monthlyRent >= 2501 && +monthlyRent <= 5000) {
        price = "279.00";
      }
      if (+monthlyRent <= 2500) {
        price = "198.00";
      }
    }

    return price;
  };

  const [companyInfo, setCompanyInfo] = useState({
    companyName: agencyData?.name || "",
    email: agencyData?.email || "",
    companyAddressLineOne: "",
    companyAddressLineTwo: "",
    companyAddressCity: "",
    companyAddressPostcode: "",
    termsAgreed: isInsuranceTermsAgreed,
    eligibilityAgreed: isPolicyEligibilityAgreed,
    firstName: "",
    lastName: "",
    isDefaultAddress: isDefaultAddress,
  });

  const [addresses, setAddresses] = useState<InsuranceInfo[]>([
    {
      city: propertyData?.city || "",
      line1: propertyData?.line1 || "",
      line2: propertyData?.line2 || "",
      postcode: propertyData?.postcode || "",
      currentRentalProtectionSupplier: "",
      endDateMeta: [],
      monthlyRent:
        propertyData && propertyData?.monthlyRent
          ? propertyData?.monthlyRent
          : "",
      preferredStartDate: [theDay, theMonth, theYear].join("/") || "",
      existingPolicyEndDate:"",
      startDateMeta:
        theDay && theMonth && theYear ? [theDay, theMonth, theYear] : [],
      policyLength: 12,
      policyPrice: showPolicyPrice(
        propertyData && propertyData?.monthlyRent
          ? propertyData?.monthlyRent
          : monthlyRent,
        setPolicyDuration()
      ),
      areNoArrearsPresent: areNoArrearsPresent,
      isReferencingPass: isReferencingPass,
      areNoCircumstancesChanged: areNoCircumstancesChanged,
      policyIsMonthly: policyIsMonthly,
    },
  ]);

  useEffect(() => {
    if (!propertyData) return;
    setCompanyInfo({
      companyName: agencyData?.name || "",
      email: agencyData?.email || "",
      companyAddressLineOne: "",
      companyAddressLineTwo: "",
      companyAddressCity: "",
      companyAddressPostcode: "",
      termsAgreed: isInsuranceTermsAgreed,
      eligibilityAgreed: isPolicyEligibilityAgreed,
      firstName: "",
      lastName: "",
      isDefaultAddress: isDefaultAddress,
    });
    setAddresses([
      {
        city: propertyData?.city || "",
        line1: propertyData?.line1 || "",
        line2: propertyData?.line2 || "",
        postcode: propertyData?.postcode || "",
        currentRentalProtectionSupplier: "",
        endDateMeta: [],
        monthlyRent:
          propertyData && propertyData?.monthlyRent
            ? propertyData?.monthlyRent
            : "",
        preferredStartDate: [theDay, theMonth, theYear].join("/") || "",
        existingPolicyEndDate:"",
        startDateMeta: [theDay, theMonth, theYear],
        policyLength: 12,
        policyPrice: showPolicyPrice(
          propertyData && propertyData?.monthlyRent
            ? propertyData?.monthlyRent
            : monthlyRent,
          setPolicyDuration()
        ),
        areNoArrearsPresent: areNoArrearsPresent,
        isReferencingPass: isReferencingPass,
        areNoCircumstancesChanged: areNoCircumstancesChanged,
        policyIsMonthly: policyIsMonthly,
      },
    ]);
  }, [propertyData]);

  const addPolicyAddress = () => {
    const addressCopy = [...addresses];
    addressCopy.push({
      city: "",
      line1: "",
      line2: "",
      postcode: "",
      currentRentalProtectionSupplier: "",
      monthlyRent: "",
      startDateMeta: [],
      endDateMeta: [],
      preferredStartDate: "",
      existingPolicyEndDate:"",
      policyLength: 12,
      policyPrice: "",
      areNoArrearsPresent: false,
      isReferencingPass: false,
      areNoCircumstancesChanged: false,
      policyIsMonthly: false,
    });
    setAddresses([...addressCopy]);
  };

  const handleAddressUpdate = (item: InsuranceInfo, index: number) => {
    setCurrentIndex(index);
    const addressesCopy = [...addresses];
    addressesCopy[index] = {
      ...addressesCopy[index],
      city: item.city,
      line1: item.line1,
      line2: item.line2,
      postcode: item.postcode,
    };
    setAddresses(addressesCopy);
  };

  function handleCheckboxUpdate(
    setState: Function,
    state: boolean,
    index: number,
    name: string
  ) {
    setCurrentIndex(index);
    setState(state);
    const copy = [...addresses];
    copy[index][`${name}`] = state;
    setAddresses(copy);
  }

  const removePolicyAddress = (index: number) => {
    const newAddressList = [...addresses];
    newAddressList.splice(index, 1);
    setAddresses(newAddressList);
  };

  function handleCompanyInputChange(evt: React.ChangeEvent<HTMLInputElement>) {
    setCompanyInfo({
      ...companyInfo,
      [evt.target.name]: evt.target.value,
    });
  }
  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) {
    setCurrentIndex(index);
    const copy: any = [...addresses];
    if (e.target.name === "monthlyRent") {
      setMonthlyRent(e.target.value);
    }
    copy[index][e.target.name] = e.target.value;
    setAddresses(copy);
  }

  function handleStartDateChange(
    index: number,
    date: string,
    realIndex: number
  ) {
    setCurrentIndex(realIndex);

    if (index.toString().length === 2 && !index.toString().startsWith("/")) {
      const inputtedDay = index.toString();
      setStartDay(inputtedDay);
    }
    if (index.toString().length === 3 && !index.toString().startsWith("//")) {
      const inputtedMonth = index.toString();
      setStartMonth(inputtedMonth);
    }
    if (index.toString().length === 6 && index.toString().startsWith("/")) {
      const inputtedYear = index.toString();
      setStartYear(inputtedYear);
    }
  }

  useEffect(() => {
    setCompanyInfo({
      ...companyInfo,
      isDefaultAddress: isDefaultAddress,
      termsAgreed: isInsuranceTermsAgreed,
      eligibilityAgreed: isPolicyEligibilityAgreed,
    });
  }, [isInsuranceTermsAgreed, isPolicyEligibilityAgreed, isDefaultAddress]);

  useEffect(() => {
    if (addresses[currentIndex]?.monthlyRent !== undefined) {
      const copy = [...addresses];
      copy[currentIndex].policyPrice = showPolicyPrice(
        copy[currentIndex]?.monthlyRent,
        setPolicyDuration()
      );
      setAddresses(copy);
    }
  }, [monthlyRent, policyIsMonthly]);

  useEffect(() => {
    if (addresses[currentIndex]?.policyLength !== undefined) {
      const copy = [...addresses];
      copy[currentIndex].policyLength = setPolicyDuration();
      setAddresses(copy);
    }
  }, [monthlyRent, policyIsMonthly]);

  const formatDate = (dateString: string) => {
    const dateRegex =
      /(^(((0[1-9]|1[0-9]|2[0-8])[/](0[1-9]|1[012]))|((29|30|31)[/](0[13578]|1[02]))|((29|30)[/](0[4,6,9]|11)))[/](19|[2-9][0-9])\d\d$)|(^29[/]02[/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
    if (!dateRegex.test(dateString)) {
      return;
    }
    const year = dateString.substr(6);
    const month = dateString.substr(3, 2);
    const day = dateString.substr(0, 2);
    const reformattedDate = `${month}/${day}/${year}`;
    const dateObj = new Date(reformattedDate);
    const dateIsoFormat = dateObj
      .toISOString()
      .toString()
      .replace("T", " ")
      .substr(0, 19);
    return dateIsoFormat;
  };

  useMemo(() => {
    return setCurrentIndex(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (startDay !== "" && startMonth !== "" && startYear !== "") {
      const copy = [...addresses];
      copy[currentIndex].preferredStartDate = [
        startDay,
        startMonth,
        startYear.substr(1),
      ].join("");
      setAddresses(copy);
    }
  }, [startDay, startMonth, startYear, currentIndex]);

  let sanitisedAddresses = addresses.map((singleAddress) => {
    let copyObj = JSON.parse(JSON.stringify(singleAddress));
    copyObj["preferredStartDate"].length === 10
      ? (copyObj["preferredStartDate"] = formatDate(
          copyObj["preferredStartDate"]
        ))
      : (copyObj["preferredStartDate"] = "");
    delete copyObj["endDateMeta"];
    delete copyObj["startDateMeta"];
    delete copyObj["policyIsMonthly"];
    delete copyObj["isPolicyStartDateToday"];
    delete copyObj["displayProtectionSupplier"];
    if (copyObj["existingPolicyEndDate"] === "") {
      copyObj["existingPolicyEndDate"] = copyObj["preferredStartDate"];
    }
    if (copyObj["currentRentalProtectionSupplier"] === "") {
      copyObj["currentRentalProtectionSupplier"] = "n/a";
    }
    if (copyObj["line2"] === "") {
      copyObj["line2"] = " ";
    }
    if (companyInfo.companyAddressLineTwo === "") {
      companyInfo.companyAddressLineTwo = " ";
    }
    return copyObj;
  });

  let formDataWhole = Object.assign(
    {},
    { policies: sanitisedAddresses, companyInfo }
  );

  let checker = sanitisedAddresses.find((address: InsuranceInfo) => {
    let sliderInputs = Object.values(address).includes(false);
    let textInputs = Object.values(address).includes("");
    return sliderInputs || textInputs;
  });

  useEffect(() => {
    if (
      companyInfo.companyName !== "" &&
      companyInfo.companyAddressLineOne !== "" &&
      companyInfo.companyAddressCity !== "" &&
      companyInfo.companyAddressPostcode !== "" &&
      companyInfo.termsAgreed &&
      companyInfo.eligibilityAgreed &&
      companyInfo.firstName !== "" &&
      companyInfo.lastName !== "" &&
      companyInfo.email !== "" &&
      checker === undefined
    ) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [companyInfo, checker]);

  async function handleSubmit() {
    setSubmitAttempt(true);
    if (!isFormComplete) {
      console.log("form aint done tho");
      console.log(formDataWhole);
      
      return;
    }
    setSendingForm(true);
    fetch(`${process.env.REACT_APP_GL_PODIO_ENDPOINT}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
        "content-type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(formDataWhole),
    })
      .then((res) => {
        if (!res.ok) {
          return Promise.reject("Uh oh!");
        }
        setSendingForm(false);
        setFormPostSuccess(true);
        return res.text();
        // return res.json();
      })
      .then((json) => {
        console.log(json);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {formPostSuccess && (
        <ConfirmMessage>
          <span>Form Successfully Sent!</span>
        </ConfirmMessage>
      )}
      {sendingForm ? (
        <SendingMessage>
          <span>
            Submitting your{" "}
            {sanitisedAddresses && sanitisedAddresses?.length > 1
              ? "Policies"
              : "Policy"}
            ...
          </span>
        </SendingMessage>
      ) : (
        <BlurbContainer formPostSuccess={formPostSuccess}>
          {formPostSuccess ? (
            <div>
              <>
                <h1>{`${companyInfo.companyName}`} Insurance Policy Summary</h1>
                <span>
                  Thank you{` ${companyInfo.firstName}`}, your contact details
                  have been successfully sent to Goodlord and will be processed
                  along with the policy details below.
                </span>
                <div className="summaryComplete">
                  <span>Purchased</span>
                  <span>Current RPI Provider</span>
                  <span>Property Address</span>
                  <span>Policy Length</span>
                  <span>Policy Start Date</span>
                  <span>Price</span>
                  <span>Rent</span>
                </div>

                {addresses && addresses.length > 0
                  ? sanitisedAddresses.map(
                      (address: InsuranceInfo, index: number) => (
                        <div key={index}>
                          <div className="SummaryContent">
                            <span>
                              {new Date(Date.now()).toLocaleDateString()}
                            </span>
                            <span>
                              {address.currentRentalProtectionSupplier
                                ? address.currentRentalProtectionSupplier
                                : "Not Provided"}
                            </span>
                            <span className="addressFormat">
                              {address.line1
                                ? `${address.line1?.toLowerCase()}, `
                                : ""}
                              {address.postcode
                                ? `${address.postcode?.toUpperCase()}`
                                : ""}
                            </span>
                            <span>{address.policyLength} months</span>
                            <span>{address.preferredStartDate}</span>
                            <span>£{address.policyPrice} {address.policyLength === 12 ? `annually` :' per month' }</span>
                            <span>£{address.monthlyRent} pcm</span>
                          </div>
                        </div>
                      )
                    )
                  : null}
              </>
            </div>
          ) : (
            <>
              <p id="loginHelp">
                Have a Goodlord login already?{" "}
                <a href="https://app.goodlord.co/#/login">Sign in here</a>
              </p>
              <h1>Contact Details</h1>
              <FormContent>
                <ContactDetailsContainer>
                  <div>
                    <TextInput
                      type="text"
                      name="firstName"
                      label="First Name"
                      maxLength={50}
                      minLength={2}
                      regex={/^[a-zA-Z\s-]+$/}
                      value={companyInfo.firstName}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />
                    <TextInput
                      type="text"
                      name="lastName"
                      label="Last Name"
                      maxLength={50}
                      minLength={2}
                      regex={/^[a-zA-Z\s-]+$/}
                      value={companyInfo.lastName}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />

                    <div id="email">
                      <TextInput
                        type="text"
                        name="email"
                        label="Email"
                        maxLength={50}
                        minLength={2}
                        regex={/\S+@\S+\.\S+/}
                        value={agencyData?.email || companyInfo.email}
                        onChange={(e: any) => handleCompanyInputChange(e)}
                      />
                    </div>
                  </div>

                  <div>
                    <div id="companyName">
                      <TextInput
                        type="text"
                        name="companyName"
                        label="Company Name"
                        maxLength={50}
                        minLength={2}
                        regex={/^[a-zA-Z0-9'.\s-]+$/}
                        value={agencyData?.name || companyInfo.companyName}
                        onChange={(e: any) => handleCompanyInputChange(e)}
                      />
                    </div>
                    <TextInput
                      type="text"
                      name="companyAddressLineOne"
                      label="Company Line One"
                      maxLength={50}
                      minLength={2}
                      regex={/^[a-zA-Z0-9\s-]+$/}
                      value={companyInfo.companyAddressLineOne}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />
                    <TextInput
                      type="text"
                      name="companyAddressLineTwo"
                      label="Company Line Two"
                      maxLength={50}
                      minLength={0}
                      regex={/^[a-zA-Z0-9\s.-]+$/}
                      value={companyInfo.companyAddressLineTwo}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />

                    <TextInput
                      type="text"
                      name="companyAddressCity"
                      label="Company City"
                      maxLength={50}
                      minLength={2}
                      regex={/^[a-zA-Z\s-]+$/}
                      value={companyInfo.companyAddressCity}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />

                    <TextInput
                      type="text"
                      name="companyAddressPostcode"
                      label="Company Postcode"
                      maxLength={50}
                      minLength={2}
                      regex={
                        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/
                      }
                      value={companyInfo?.companyAddressPostcode}
                      onChange={(e: any) => handleCompanyInputChange(e)}
                    />
                  </div>
                </ContactDetailsContainer>

                <CheckBoxContainer>
                  <label className="defaultCompanyAddress">
                    <ToggleSwitch
                      toggle={() => setIsDefaultAddress(!isDefaultAddress)}
                      initVal={isDefaultAddress}
                    ></ToggleSwitch>
                    <span>
                      Set this address as the default company address{" "}
                      {`for ${
                        agencyData?.name ? agencyData?.name : "my company"
                      }`}
                    </span>
                  </label>
                </CheckBoxContainer>
                <div id="flexContainer">
                  <div id="column1">
                    <h1>Policy Details</h1>
                    {addresses && addresses.length > 0 ? (
                      addresses.map((item, index: number) => (
                        <PolicyContainer key={index}>
                          <Policy>
                            <div>
                              <h3>
                                Policy {index + 1}
                                {index !== 0 && (
                                  <AlternateButton
                                    onClick={() => {
                                      removePolicyAddress(index);
                                    }}
                                  >
                                    Remove this policy
                                  </AlternateButton>
                                )}
                              </h3>
                              <AddressSelector
                                selectedAddress={item}
                                indexOfAddress={index}
                                setSelectedAddress={handleAddressUpdate}
                              />
                              <InputContainer id="policyStartDate">
                                <SteppedInput
                                  onChange={() => {}}
                                  values={addresses[index].startDateMeta}
                                  label="Preferred Start Date (DD/MM/YYYY)"
                                  divider="/"
                                  onFormattedChange={(e: any) =>
                                    handleStartDateChange(e, "_", index)
                                  }
                                  config={[
                                    {
                                      label: "Day",
                                      length: 2,
                                      maxVal: 31,
                                      minVal: 1,
                                    },
                                    {
                                      label: "Month",
                                      length: 2,
                                      maxVal: 12,
                                      minVal: 1,
                                    },
                                    {
                                      label: "Year",
                                      length: 4,
                                      maxVal: 2050,
                                      minVal: new Date().getFullYear() - 1,
                                    },
                                  ]}
                                />
                              </InputContainer>
                              <InputContainer id="monthlyRent">
                                <TextInput
                                  type="number"
                                  name="monthlyRent"
                                  label="Monthly Rent"
                                  maxLength={50}
                                  minLength={0}
                                  regex={/^[0-9]+$/}
                                  value={
                                    index === 0 &&
                                    propertyData &&
                                    propertyData?.monthlyRent
                                      ? propertyData?.monthlyRent
                                      : addresses[index]?.monthlyRent
                                      ? addresses[index]?.monthlyRent
                                      : ""
                                  }
                                  onChange={(e: any) =>
                                    handleInputChange(e, index)
                                  }
                                />
                              </InputContainer>
                              <label id="protectionSupplier">
                                <ToggleSwitch
                                  toggle={() =>
                                    handleCheckboxUpdate(
                                      setDisplayProtectionSupplier,
                                      !displayProtectionSupplier,
                                      index,
                                      "displayProtectionSupplier"
                                    )
                                  }
                                  initVal={
                                    addresses[index]?.displayProtectionSupplier
                                  }
                                ></ToggleSwitch>
                                <span>
                                  I am renewing an existing rent protection
                                  policy
                                </span>
                              </label>

                              {addresses[index]?.displayProtectionSupplier && (
                                <div id="currentRentalProtectionSupplier">
                                  <TextInput
                                    type="text"
                                    name="currentRentalProtectionSupplier"
                                    label="Current Protection Supplier"
                                    maxLength={50}
                                    minLength={2}
                                    regex={/^[a-zA-Z'.\s-]+$/}
                                    value={
                                      addresses[index]
                                        ?.currentRentalProtectionSupplier
                                    }
                                    onChange={(e: any) =>
                                      handleInputChange(e, index)
                                    }
                                  />
                                </div>
                              )}
                              <label id="protectionSupplier">
                                <ToggleSwitch
                                  toggle={() =>
                                    handleCheckboxUpdate(
                                      setPolicyIsMonthly,
                                      !policyIsMonthly,
                                      index,
                                      "policyIsMonthly"
                                    )
                                  }
                                  initVal={addresses[index]?.policyIsMonthly}
                                ></ToggleSwitch>
                                <span>
                                  {addresses[index]?.policyLength} Month Policy
                                </span>
                              </label>

                              <div className="policyLengthContainer">
                                <h3>
                                  {addresses[index]?.policyLength} Month Policy
                                  at £{addresses[index]?.policyPrice}{" "}
                                  {policyIsMonthly ? "" : "annually"}
                                </h3>
                              </div>
                              <CheckBoxContainer>
                                <label>
                                  <ToggleSwitch
                                    toggle={() =>
                                      handleCheckboxUpdate(
                                        setAreNoArrearsPresent,
                                        !areNoArrearsPresent,
                                        index,
                                        "areNoArrearsPresent"
                                      )
                                    }
                                    initVal={
                                      addresses[index]?.areNoArrearsPresent
                                    }
                                  ></ToggleSwitch>
                                  <span>
                                    I can confirm all applicants in the property
                                    have no previous arrears.
                                  </span>
                                </label>
                                <label>
                                  <ToggleSwitch
                                    toggle={() =>
                                      handleCheckboxUpdate(
                                        setIsReferencingPass,
                                        !isReferencingPass,
                                        index,
                                        "isReferencingPass"
                                      )
                                    }
                                    initVal={
                                      addresses[index]?.isReferencingPass
                                    }
                                  ></ToggleSwitch>
                                  <span>
                                    I can confirm that all applicable tenants or
                                    guarantors passed referencing conducted by
                                    Vouch, that they have no adverse credit
                                    history, that they individually /
                                    collectively meet the affordability criteria
                                    of 2.5x the amount of rent (3x the amount of
                                    rent for guarantors) and that there was no
                                    adverse landlord reference, if applicable.
                                  </span>
                                </label>
                                <label>
                                  <ToggleSwitch
                                    toggle={() =>
                                      handleCheckboxUpdate(
                                        setAreNoCircumstancesChanged,
                                        !areNoCircumstancesChanged,
                                        index,
                                        "areNoCircumstancesChanged"
                                      )
                                    }
                                    initVal={
                                      addresses[index]
                                        ?.areNoCircumstancesChanged
                                    }
                                  ></ToggleSwitch>
                                  <span>
                                    I can confirm there are no changes to any
                                    applicants circumstance, to my knowledge.
                                  </span>
                                </label>
                              </CheckBoxContainer>
                            </div>
                          </Policy>
                        </PolicyContainer>
                      ))
                    ) : (
                      <div></div>
                    )}
                    <Button
                      onClick={() => {
                        addPolicyAddress();
                      }}
                    >
                      Add a rental policy
                    </Button>
                  </div>
                  <div id="column2">
                    <div id="insideColumn2">
                      {addresses && addresses.length > 0
                        ? addresses.map(
                            (address: InsuranceInfo, index: number) => (
                              <div className="summary" key={index}>
                                <h3>Policy {index + 1}</h3>
                                {addresses[index]?.areNoArrearsPresent && (
                                  <>
                                    <div id="propertySummary">
                                      <div>
                                        {addresses[index]?.line1 ? (
                                          <>
                                            {addresses[index]?.line1}, <br />
                                          </>
                                        ) : null}
                                        {addresses[index]?.line2 ? (
                                          <>
                                            {addresses[index]?.line2}, <br />
                                          </>
                                        ) : null}
                                        {addresses[index]?.city ? (
                                          <>
                                            {addresses[index]?.city}, <br />
                                          </>
                                        ) : null}
                                        {addresses[index]?.postcode ? (
                                          <>
                                            {addresses[index]?.postcode} <br />
                                          </>
                                        ) : null}
                                      </div>
                                      <div>
                                        <div>
                                          {addresses[index]?.preferredStartDate
                                            ? `Policy Start Date: ${addresses[index]?.preferredStartDate}`
                                            : ""}
                                        </div>
                                        <div>
                                          {addresses[index]?.monthlyRent
                                            ? `Rent: £${addresses[index]?.monthlyRent} pcm`
                                            : ""}
                                        </div>
                                      </div>
                                    </div>
                                    <br />

                                    <div>
                                      {addresses[index]
                                        ?.currentRentalProtectionSupplier
                                        ? `Current Protection Supplier: ${addresses[index]?.currentRentalProtectionSupplier}`
                                        : ""}
                                    </div>
                                    <div>
                                      <strong>
                                        {addresses[index]?.policyPrice ? (
                                          <span>
                                            {addresses[index]?.policyLength} Month
                                            Policy at £
                                            {addresses[index]?.policyPrice}{" "}
                                            {addresses[index]?.policyLength === 1 ? "" : "annually"}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </strong>
                                    </div>
                                  </>
                                )}
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>
                </div>
              </FormContent>
              <FinalSection>
                <InputCheckBoxGroup>
                  <label>
                    <ToggleSwitch
                      toggle={() =>
                        setIsPolicyEligibilityAgreed(!isPolicyEligibilityAgreed)
                      }
                      initVal={isPolicyEligibilityAgreed}
                    ></ToggleSwitch>
                    <span>
                      I confirm that I have read and accept to the{" "}
                      <a
                        href="https://goodlord-co.s3.amazonaws.com/docs/rpi/FINAL_Agent_Goodlord_Rent_Protection_Policy_Wording_15_11_19.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Policy Wording
                      </a>
                      ,{" "}
                      <a
                        href="https://goodlord-co.s3.amazonaws.com/docs/rpi/FINAL_Goodlord_Eligibility_Criteria_-_DAS_RPI_15_11_2019.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Eligibility Criteria
                      </a>{" "}
                      and{" "}
                      <a
                        href="https://goodlord-co.s3.amazonaws.com/docs/rpi/FINAL_Goodlord_Agent_IPID_15_11_19.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Insurance Product Information Document
                      </a>
                    </span>
                  </label>

                  <label>
                    <ToggleSwitch
                      toggle={() =>
                        setIsInsuranceTermsAgreed(!isInsuranceTermsAgreed)
                      }
                      initVal={isInsuranceTermsAgreed}
                    ></ToggleSwitch>
                    <span>
                      I confirm I have read and accept our{" "}
                      <a
                        href="https://goodlord-co.s3.amazonaws.com/docs/rpi/FINAL_Goodlord_Insurance_Terms_of_Business_15_11_19.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Insurance Terms of Business
                      </a>
                    </span>
                  </label>
                </InputCheckBoxGroup>
              </FinalSection>
              <CTAWrapper>
                {!isFormComplete && submitAttempt && (
                  <h3>
                    Please ensure you have filled in all sections of the form
                  </h3>
                )}
                <Button onClick={(e) => handleSubmit()}>Submit</Button>
              </CTAWrapper>
            </>
          )}
        </BlurbContainer>
      )}
    </>
  );
};

export default /* withAITracking(reactPlugin,  */ RPIForm;
