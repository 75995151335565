import React, { useState, useEffect } from "react";
import { FormGuide, ToggleButton } from "../../common/styles";
import styled, { keyframes } from "styled-components";

interface Props {
  options: any;
  listType: string;
  introText: string;
  getGeneralValue: Function;
  preSelectedTitleIndex?: string;
  preSelectedTitleLabel?: string;
}


const Default = styled.span`
 position: relative;
 width: 100%;
 > span {
   position: absolute;
    background: #fff;
    padding: 5px 10px;
    top: -20px;
    font-size: 12px;
 }
    padding: 20px;
    /* margin: 5px 0px; */
    border: 1px solid #f2f2f2;
    border-radius: 5px;
    border: 1px solid #34bebd;

    @media (max-width: 450px) {
      margin: 10px 5px;
      font-size: .8rem;
      line-height: normal;
  }

`;

const FormContainer = styled.div`
  text-align: left;

  > div {
    > p {
      margin-bottom: 10px;      
    }
  }

  label {
    padding: 20px;
    margin: 5px 0px;
    border: 1px solid ${(props) => props.theme.color.alternateBackground};
    border-radius: 5px;
    
    input {
      margin-left: 10px;
    }
  }
  
  button {
    border-radius: 10px;
    display:flex;
    flex-flow: row;
    
    span {
      margin: auto;
    }
  }

  @media (max-width: 450px) {
    label {
      margin: 10px 5px;
      font-size: .8rem;
      line-height: normal;
    }

    p {
      margin-bottom: 5px;
    }
  }

  `;

const DropDown = styled.div`
  display:flex;
  flex-flow: column;
  align-items: center;
  position: relative;


`;
const swingIn = keyframes`
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  `;


const ButtonGroup = styled.div<{ showList?: boolean }>`
display:${(props) => props.showList ? "flex" : "none"};
flex-flow: column;
justify-content: center;
margin: 0 auto;
width: 100%; 
overflow-y: auto;
padding: 15px;
background: #f2f2f2;
position: absolute;
z-index: 100;
border: 1px solid #F2F2F2;
top: 100%;
animation: ${swingIn} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;

@media(max-width:450px){
      top: calc(100% - 10px);

label {
  margin: 5px 0;
}
}
`
  ;

const DropdownSelector: React.FC<Props> = ({ getGeneralValue, introText, listType, options ,  preSelectedTitleIndex,preSelectedTitleLabel}) => {
  const [activeOccupancyButton, setActiveOccupancyButton] = useState("");
  const [dropdownLabel, setDropdownLabel] = useState("");
  const [showList, setShowList] = useState<boolean>(false)

  const getValue = (e: any) => {
    const label = options.filter((item: any) => {
      return e.target.id.toString() === item.value.toString();
    })
    const name = label[0].title;
    setDropdownLabel(name)
    setActiveOccupancyButton(e.target.id)
    ToggleIt();
    getGeneralValue(e.target.id, name)
  }

  function ToggleIt() {
    setShowList(!showList)
  }

  useEffect(() => {
    if(preSelectedTitleLabel !== undefined && 
      preSelectedTitleLabel !== "") {
      setDropdownLabel(preSelectedTitleLabel)
      setActiveOccupancyButton(options.find((selectedOption:any) => {
        return selectedOption.title === preSelectedTitleLabel
      })?.value.toString())
    }

    else if(preSelectedTitleIndex !== undefined && preSelectedTitleIndex !== "") {
        setActiveOccupancyButton(preSelectedTitleIndex)
        setDropdownLabel(options.find((selectedOption:any) => {
          return selectedOption.value === preSelectedTitleIndex
        })?.title)
      }
  },[]) 

  return (
    <>
      <FormContainer>
        <DropDown>
          <FormGuide>{introText}</FormGuide>
          <Default onClick={(e) => ToggleIt()}>
            <span>{listType}</span>
            {dropdownLabel ? dropdownLabel : `Please select...`}</Default>
          <ButtonGroup showList={showList}>
            {options.map((choice: any) => {
              return <ToggleButton key={choice.title} isActive={choice.value.toString() === activeOccupancyButton}><span>{choice.title}</span>
                <input
                  hidden
                  type="radio"
                  id={choice.value.toString()}
                  name={listType}
                  onChange={getValue}
                />
              </ToggleButton>
            })
            }
          </ButtonGroup>
        </DropDown>
      </FormContainer>
    </>
  )
};
export default DropdownSelector;






