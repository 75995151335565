import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextInput, ToggleSwitch } from "vouch.ui.react.components";
import { AlternateButton, Button } from "../../common/styles";
import { Void } from "../../types/Void";
import SPAddressSelector from "../Input/SPAddressSelector";
import Loading from "../Loading";

interface Props {
  singleVoid: Void | undefined;
  isEditFormOpen: boolean;
  toggleEdit: () => void;
  isActionRequired: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  > div {
    width: 100%;
  }

  .grouped {
    display: flex;
  }
`;



const VoidAddressDetailContainer = styled.div`
  display: flexbox;
  flex-flow: row wrap;
  justify-content: space-between;
  display: flex;
  grid-gap: 5%;
`;



const LandlordSection = styled.div`
  cursor: pointer;
  min-width: 50%;
  width: 100%;

  #toggleChoice {
    color: #34bebd;
    font-weight: 700;
    font-size:16px;
  }

  label {
    display: flex;
    align-items: center;
  }

  .addressPeople {
    display: flex;
    align-items: baseline;
    grid-gap: 20px;
    width: 100%;
    ul {
      > div {
        min-width: 400px;
      }
    }
    li {
      padding: 5px 0;
      div {
        min-width: 400px;

      }
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
`;

const Address = styled.div`
  text-transform: capitalize;
`;


const AddressMetaSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-flow: column;
    flex: 0 1 65%;

    span {
      display: inline;
      line-height: 60px;
      font-family: "Montserrat";
      font-weight: 700;
      border-radius: 10px;
    }
  }
`;



const ActionNotice = styled.div`
  border: 2px solid #ed941e;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0 ;
  max-width: 100% !important;
  display:block;

  span {
    /* display: inline !important; */
    font-size: 1rem !important;
    text-transform: capitalize;
  }

`;


const TenancyDetailsSection = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 0 0 10px 10px;
  border-top:none; 
  padding: 40px;
  align-self: baseline;
  flex: 1 1 20%;

  .dateFeedback {
    display: flex;
  }
  #addressCollection {
    display: flex;
  }

  #voidPeriod,
  #forwardingAddressEntry {
    > div {
      grid-gap: 50px;
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: left;
      div {
        width: 100%;
      }
      #addressSelector {
        div {
          max-width: 100%;
        }
      }
    }
  }
  #ownerInfo {
    display: flex;
    flex-flow: column;
  }

  #moveDates {
    display: flex;
    max-width: 100%;

    .editedDates {
      display: flex;
      h4 {
        margin-bottom: 0;
      }
    }
    div {
      flex: 1;
      width: 100%;
    }
  }
  #addressSelector {
    > div {
      form {
        > div {
          margin: 10px 0;
        }
      }
    }
  }
  #moveoutDate {
    margin: 10px 0px;
    div {
      margin: 0;
    }
  }
  .side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 220px;
    width: 100%;
    div {
      padding: 0;
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .introHeading {
    margin-top: 18px;
    font-size: 16px;
    span {
      font-size: 16px;
      color: grey;
    }
  }

  #voidOwnerTitle {
    border-top: 1px solid #f2f2f2;
    padding-top: 30px;

    span {
      color: grey;
      font-size: 1.17rem;
    }
  }
  small {
    display: block;
    padding-top: 60px;
  }
  span {
    font-size: 0.8rem;
  }

  > div:nth-child(1) {
    display: flex;
    flex-flow: column;
    max-width: 400px;
    column-gap: 20px;

    > div {
      flex: 1 1 100px;
    }
  }
  .addressPeople {
    display: flex;
    align-items: baseline;
    grid-gap: 20px;
    width: 100%;
    li {
      padding: 5px 0;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .utilityList {
    img {
      width: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      max-width: 220px;
      padding: 5px 0;
    }
  }
`;



const Back = styled.div`
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  z-index: 10;
  font-size: 1rem;
`;

const HeadingContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  margin-left: 20px;

  align-items: center;
`;



const BillPayerEditPanel: React.FC<Props> = ({
  singleVoid,
  isEditFormOpen,
  toggleEdit,
  isActionRequired,
}) => {


  
  // CONSTS 
  

  const [sendingData, setSendingData] = useState(false);

  const [billPayerAddress, setBillPayerAddress] = useState({
    city: "",
    houseNumber: "",
    postCode: "",
    street: "",
    id: "",
    houseName: "",
    flatNumber: "",
    line1: "",
    line2:"",
  });
  
      const [voidBillPayer, setVoidBillPayer] = useState({
        agencyName: "",
        companyName: "",
        email: "",
        title: "",
        forename: "",
        surname: "",
        mobilePhone: "",
        forwardingAddress: {
          city: billPayerAddress.city,
          houseNumber: billPayerAddress.houseNumber,
          postCode: billPayerAddress.postCode,
          street: billPayerAddress.street,
          id: billPayerAddress.id,
          houseName: billPayerAddress.houseName,
          flatNumber: billPayerAddress.flatNumber,
          line1: `${billPayerAddress.flatNumber !== null ? billPayerAddress.flatNumber : ""} ${billPayerAddress.houseNumber} ${billPayerAddress.street !== null ? billPayerAddress.street : ""}`,
          line2:billPayerAddress.city,
        }
      });

  function handleEditedFields(
    evt: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) {
    if (type === "voidBillPayer") {
      setVoidBillPayer({
        ...voidBillPayer,
        [evt.target.name]: evt.target.value,
      });
    }
 
  }
  

  useEffect(() => {
    if(billPayerAddress.postCode !== "") {
      setBillPayerAddress({
        ...billPayerAddress, line1: `${billPayerAddress.flatNumber} ${billPayerAddress.houseNumber} ${billPayerAddress.street}`, line2:billPayerAddress.city 
      })
    }
  },[billPayerAddress.postCode])
  
 
  
  async function updateVoid(voidDetails: any, id: string) {
    setSendingData(true)
    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/new/${id}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        body: JSON.stringify(voidDetails),
      }
    );

    if (res.ok) {
      console.log(res);
      window.location.reload();
      
    }
    setSendingData(false)
    toggleEdit();
  }

  const [voidBillPayerIsAgency, setVoidBillPayerIsAgency] = useState(true);
 
  function handleUpdatedSingleVoid() {
    let dataTransferObject: any;
    let forwardingAddressObj: any;
    if(singleVoid !== undefined) {

    }
    if(singleVoid !== undefined && singleVoid.agentContactDetails !== null && singleVoid.agentContactDetails !== undefined && voidBillPayerIsAgency) {
      if(singleVoid.agentContactDetails.forwardingAddress !== null && 
        singleVoid.agentContactDetails.forwardingAddress !== undefined) {
         
            dataTransferObject = {
              voidId: singleVoid.id,
              customerIdentifier: singleVoid.customerIdentifier,
              billOwner: "agency",
              agentContactDetails: {
                title:   voidBillPayer.title || singleVoid.agentContactDetails.title || "Mr",
                email:   voidBillPayer.email || singleVoid.agentContactDetails.email,
                surname:   voidBillPayer.surname || singleVoid.agentContactDetails.surname,
                forename:   voidBillPayer.forename || singleVoid.agentContactDetails.forename,
                phone:   voidBillPayer.mobilePhone || singleVoid.agentContactDetails.mobilePhone,
                agencyName:   voidBillPayer.agencyName || singleVoid.agentContactDetails.agencyName,
                address:{
                  city: billPayerAddress.city || singleVoid.agentContactDetails.forwardingAddress.city ,
                  houseNumber: billPayerAddress.houseNumber || singleVoid.agentContactDetails.forwardingAddress.houseNumber || "" ,
                  postcode: billPayerAddress.postCode || singleVoid.agentContactDetails.forwardingAddress.postcode ,
                  street: billPayerAddress.street || singleVoid.agentContactDetails.forwardingAddress.street ,
                  updrn: billPayerAddress.id || singleVoid.agentContactDetails.forwardingAddress.id ,
                  houseName: billPayerAddress.houseName || singleVoid.agentContactDetails.forwardingAddress.houseName ,
                  flatNumber: billPayerAddress.flatNumber || singleVoid.agentContactDetails.forwardingAddress.flatNumber || "",
                  line1: billPayerAddress.line1 || `${singleVoid.agentContactDetails.forwardingAddress.flatNumber !== null ? singleVoid.agentContactDetails.forwardingAddress.flatNumber : ""} ${singleVoid.agentContactDetails.forwardingAddress.houseNumber} ${singleVoid.agentContactDetails.forwardingAddress.street !== null ? singleVoid.agentContactDetails.forwardingAddress.street : ""}` ,
                  line2: billPayerAddress.line2 || singleVoid.agentContactDetails.forwardingAddress.city ,
                }
              }
            };
   
      } else {
         forwardingAddressObj = {
          city:billPayerAddress.city  ,
          houseNumber:billPayerAddress.houseNumber  ,
          postcode:billPayerAddress.postCode  ,
          street:billPayerAddress.street  ,
          udprn:billPayerAddress.id  ,
          houseName:billPayerAddress.houseName,
          flatNumber:billPayerAddress.flatNumber,
          line1:billPayerAddress.line1,
          line2:billPayerAddress.line2 
        }
        dataTransferObject = {
          voidId: singleVoid.id,
          customerIdentifier: singleVoid.customerIdentifier,
          billOwner: "agency",
          agentContactDetails: {
            title:   voidBillPayer.title || singleVoid?.agentContactDetails.title || "Mr",
            email:   voidBillPayer.email || singleVoid?.agentContactDetails.email,
            surname:   voidBillPayer.surname || singleVoid?.agentContactDetails.surname,
            forename:   voidBillPayer.forename || singleVoid?.agentContactDetails.forename,
            phone:   voidBillPayer.mobilePhone || singleVoid?.agentContactDetails.mobilePhone || singleVoid?.agentContactDetails.mobilePhone,
            agencyName:   voidBillPayer.agencyName || singleVoid?.agentContactDetails.agencyName,
            address: forwardingAddressObj
          }
        }
      }
    
      if(singleVoid !== undefined) {
        updateVoid(dataTransferObject, singleVoid.id);
      }
    }
    if(singleVoid !== undefined && singleVoid.landlordContactDetails !== null && singleVoid.landlordContactDetails !== undefined && !voidBillPayerIsAgency) {
      if(singleVoid.landlordContactDetails.forwardingAddress !== null && 
        singleVoid.landlordContactDetails.forwardingAddress !== undefined) {
         
            dataTransferObject = {
              voidId: singleVoid.id,
              customerIdentifier: singleVoid.customerIdentifier,
              billOwner: "landlord",
              landlordContactDetails: {
                title:   voidBillPayer.title || singleVoid.landlordContactDetails.title || "Mr",
                email:   voidBillPayer.email || singleVoid.landlordContactDetails.email,
                surname:   voidBillPayer.surname || singleVoid.landlordContactDetails.surname,
                forename:   voidBillPayer.forename || singleVoid.landlordContactDetails.forename,
                phone:   voidBillPayer.mobilePhone || singleVoid.landlordContactDetails.mobilePhone,
                companyName:   voidBillPayer.companyName || singleVoid.landlordContactDetails.companyName,
                address:{
                  city: billPayerAddress.city || singleVoid.landlordContactDetails.forwardingAddress.city ,
                  houseNumber: billPayerAddress.houseNumber || singleVoid.landlordContactDetails.forwardingAddress.houseNumber || "" ,
                  postcode: billPayerAddress.postCode || singleVoid.landlordContactDetails.forwardingAddress.postcode ,
                  street: billPayerAddress.street || singleVoid.landlordContactDetails.forwardingAddress.street ,
                  updrn: billPayerAddress.id || singleVoid.landlordContactDetails.forwardingAddress.id ,
                  houseName: billPayerAddress.houseName || singleVoid.landlordContactDetails.forwardingAddress.houseName ,
                  flatNumber: billPayerAddress.flatNumber || singleVoid.landlordContactDetails.forwardingAddress.flatNumber || "",
                  line1: billPayerAddress.line1 || `${singleVoid.landlordContactDetails.forwardingAddress.flatNumber !== null ? singleVoid.landlordContactDetails.forwardingAddress.flatNumber:""} ${singleVoid.landlordContactDetails.forwardingAddress.houseNumber} ${singleVoid.landlordContactDetails.forwardingAddress.street !== null ? singleVoid.landlordContactDetails.forwardingAddress.street : ""}` ,
                  line2: billPayerAddress.line2 || singleVoid.landlordContactDetails.forwardingAddress.city ,
                }
              }
            };
   
      } else {
         forwardingAddressObj = {
          city:billPayerAddress.city  ,
          houseNumber:billPayerAddress.houseNumber  ,
          postcode:billPayerAddress.postCode  ,
          street:billPayerAddress.street  ,
          udprn:billPayerAddress.id  ,
          houseName:billPayerAddress.houseName,
          flatNumber:billPayerAddress.flatNumber,
          line1:billPayerAddress.line1,
          line2:billPayerAddress.line2 
        }
        dataTransferObject = {
          voidId: singleVoid.id,
          customerIdentifier: singleVoid.customerIdentifier,
          billOwner: "landlord",
          landlordContactDetails: {
            title:   voidBillPayer.title || singleVoid?.landlordContactDetails.title || "Mr",
            email:   voidBillPayer.email || singleVoid?.landlordContactDetails.email,
            surname:   voidBillPayer.surname || singleVoid?.landlordContactDetails.surname,
            forename:   voidBillPayer.forename || singleVoid?.landlordContactDetails.forename,
            phone:   voidBillPayer.mobilePhone || singleVoid?.landlordContactDetails.mobilePhone || singleVoid?.landlordContactDetails.mobilePhone,
            companyName:   voidBillPayer.companyName || singleVoid?.landlordContactDetails.companyName,
            address: forwardingAddressObj
          }
        }
      }
    
      if(singleVoid !== undefined) {
        updateVoid(dataTransferObject, singleVoid.id);
      }
    }
  }
 

  useEffect(() => {
    setVoidBillPayer({...voidBillPayer, forwardingAddress:billPayerAddress})
  },[billPayerAddress.postCode])

useEffect(() => {
  if(singleVoid?.billOwner === "agency") {
    setVoidBillPayerIsAgency(true)
  } else {
    setVoidBillPayerIsAgency(false)
  }
},[])

  return (
    <Container id="voidEditContainer">
      {isEditFormOpen && !sendingData ? (
        <div>
                
          <HeadingContainer>
            <Back onClick={() => toggleEdit()}>
              <img
                src="/assets/svg/back.svg"
                alt="a black arrow pointing leftwards"
              />
            </Back>
            <AddressMetaSection>
              <div>
                <span>
                  {singleVoid?.propertyAddress?.line1
                    ? `${singleVoid?.propertyAddress?.line1.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.line2
                    ? `${singleVoid?.propertyAddress?.line2.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.city
                    ? `${singleVoid?.propertyAddress?.city.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.postcode
                    ? `${singleVoid?.propertyAddress?.postcode.toUpperCase()}`
                    : ""}
                </span>
              </div>
            </AddressMetaSection>
          </HeadingContainer>
          <div id="editButtonsContainer">
            <div>
              {isEditFormOpen && (
                <Button
               
                  onClick={() => handleUpdatedSingleVoid()}
                >
                  Submit
                </Button>
              )}

              <AlternateButton onClick={() => toggleEdit()}>
                {isEditFormOpen ? (
                  "Cancel"
                ) : (
                  <div className="editButton">
                    Edit
                    <img src="/assets/svg/edit.svg" alt="icon of a pen" />
                  </div>
                )}
              </AlternateButton>
            </div>

 
          </div>
         
          <VoidAddressDetailContainer>
            <TenancyDetailsSection>
           {
             isActionRequired && (
<ActionNotice>
  Please update your notification request with a forwarding address for the bill payer
</ActionNotice>
             )
           }   
              
          
              <div id="ownerInfo">
       
       <LandlordSection>

         <label>
         <p>Bill Payer is <span id="toggleChoice">{voidBillPayerIsAgency ? "an Agency" : "a Landlord"}</span></p>
              <ToggleSwitch
                toggle={() => setVoidBillPayerIsAgency(!voidBillPayerIsAgency)}
                initVal={voidBillPayerIsAgency}>
              </ToggleSwitch>
            </label>
         <h3 id="voidOwnerTitle">
           Bill Payer
         </h3>
         {!voidBillPayerIsAgency ? (<>{singleVoid?.landlordContactDetails !== null && (
           <div className="addressPeople">
           <ul>
             <TextInput
               type="text"
               name="title"
               label="Bill Payer Title"
               maxLength={50}
               
               regex={/^[a-zA-Z0-9\s-]+$/}
               value={
                 voidBillPayer.title ||
                 singleVoid?.landlordContactDetails?.title
               }
               onChange={(e: any) =>
                 handleEditedFields(e, "voidBillPayer")
               }
             />
             <TextInput
               type="text"
               name="forename"
               label="Bill Payer Forename"
               maxLength={50}
               
               regex={/^[a-zA-Z0-9\s-]+$/}
               value={
                 voidBillPayer.forename ||
                 singleVoid?.landlordContactDetails?.forename
               }
               onChange={(e: any) =>
                 handleEditedFields(e, "voidBillPayer")
               }
             />
             <TextInput
               type="text"
               name="surname"
               label="Bill Payer Surname"
               maxLength={50}
               
               regex={/^[a-zA-Z0-9\s-]+$/}
               value={
                 voidBillPayer.surname ||
                 singleVoid?.landlordContactDetails?.surname
               }
               onChange={(e: any) =>
                 handleEditedFields(e, "voidBillPayer")
               }
             />
           </ul>
           <ul>
             <li>
               <TextInput
                 type="text"
                 name="email"
                 label="Bill Payer Email"
                 maxLength={50}
                 
                 regex={/\S+@\S+\.\S+/}
                 value={
                   voidBillPayer.email ||
                   singleVoid?.landlordContactDetails?.email
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "voidBillPayer")
                 }
               />
           
               <TextInput
                 type="text"
                 name="mobilePhone"
                 label="Bill Payer Phone"
                 maxLength={11}
    
                 value={
                   voidBillPayer.mobilePhone ||
                   singleVoid?.landlordContactDetails?.mobilePhone
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "voidBillPayer")
                 }
               />
 
               <TextInput
                 type="text"
                 name="companyName"
                 label="Bill Payer Company Name"
                 maxLength={50}
    
                 value={
                   voidBillPayer.companyName ||
                   singleVoid?.landlordContactDetails?.companyName
                 }
                 onChange={(e: any) =>
                   handleEditedFields(e, "voidBillPayer")
                 }
               />
               
                    <div id="forwardingAddressSection">
                      <SPAddressSelector
                        selectedAddress={billPayerAddress}
                        setSelectedAddress={setBillPayerAddress}/>
                        {
                           billPayerAddress.postCode && (
                             <>
                             
                      <h3>New Forwarding Address</h3>
                      <Address>{billPayerAddress.flatNumber ? <>{billPayerAddress.flatNumber},</> : ""} {billPayerAddress.houseNumber ? <>{billPayerAddress.houseNumber}</> : ""} {billPayerAddress.street ? <>{billPayerAddress.street.toLowerCase()},</> : ""} {billPayerAddress.city ? <>{billPayerAddress.city.toLowerCase()},</> : ""} {billPayerAddress.postCode ? <>{billPayerAddress.postCode.toUpperCase()}</> : ""}</Address>
                             </>
                      )}
                    </div>
                    <h3>Current Forwarding Address</h3>
                    {singleVoid?.landlordContactDetails?.forwardingAddress !== null ? 
                      <Address>{singleVoid?.landlordContactDetails?.forwardingAddress?.line1 ? `${singleVoid?.landlordContactDetails?.forwardingAddress?.line1.toLowerCase()},` : ""} {singleVoid?.landlordContactDetails?.forwardingAddress?.line2 ? `${singleVoid?.landlordContactDetails?.forwardingAddress?.line2.toLowerCase()},` : ""} {singleVoid?.landlordContactDetails?.forwardingAddress?.postcode ? singleVoid?.landlordContactDetails?.forwardingAddress?.postcode : ""}</Address>
                  :
                  "No address provided"
                  }
             </li>
           </ul>
           </div>
         )}</>) : (
           <>
           {singleVoid?.agentContactDetails !== null ? (

<div className="addressPeople">
<ul>
  <TextInput
    type="text"
    name="title"
    label="Bill Payer Title"
    maxLength={50}
    
    regex={/^[a-zA-Z0-9\s-]+$/}
    value={
      voidBillPayer.title ||
      singleVoid?.agentContactDetails?.title
    }
    onChange={(e: any) =>
      handleEditedFields(e, "voidBillPayer")
    }
  />
  <TextInput
    type="text"
    name="forename"
    label="Bill Payer Forename"
    maxLength={50}
    
    regex={/^[a-zA-Z0-9\s-]+$/}
    value={
      voidBillPayer.forename ||
      singleVoid?.agentContactDetails?.forename
    }
    onChange={(e: any) =>
      handleEditedFields(e, "voidBillPayer")
    }
  />
  <TextInput
    type="text"
    name="surname"
    label="Bill Payer Surname"
    maxLength={50}
    
    regex={/^[a-zA-Z0-9\s-]+$/}
    value={
      voidBillPayer.surname ||
      singleVoid?.agentContactDetails?.surname
    }
    onChange={(e: any) =>
      handleEditedFields(e, "voidBillPayer")
    }
  />
</ul>
<ul>
  <li>
    <TextInput
      type="text"
      name="email"
      label="Bill Payer Email"
      maxLength={50}
      
      regex={/\S+@\S+\.\S+/}
      value={
        voidBillPayer.email ||
        singleVoid?.agentContactDetails?.email
      }
      onChange={(e: any) =>
        handleEditedFields(e, "voidBillPayer")
      }
    />

    <TextInput
      type="text"
      name="mobilePhone"
      label="Bill Payer Phone"
      maxLength={11}
      value={
        voidBillPayer.mobilePhone ||
        singleVoid?.agentContactDetails?.mobilePhone
      }
      onChange={(e: any) =>
        handleEditedFields(e, "voidBillPayer")
      }
    />
    
    <div id="forwardingAddressSection">
       <SPAddressSelector
        selectedAddress={billPayerAddress}
        setSelectedAddress={setBillPayerAddress}/>
        {
          billPayerAddress.postCode && (
        <>
      <h3>New Forwarding Address</h3>
      <Address>{billPayerAddress.flatNumber ? <>{billPayerAddress.flatNumber},</> : ""} {billPayerAddress.houseNumber ? <>{billPayerAddress.houseNumber}</> : ""} {billPayerAddress.street ? <>{billPayerAddress.street.toLowerCase()},</> : ""} {billPayerAddress.city ? <>{billPayerAddress.city.toLowerCase()},</> : ""} {billPayerAddress.postCode ? <>{billPayerAddress.postCode.toUpperCase()}</> : ""}</Address>
        </>
          )}
                          <h3>Current Forwarding Address</h3>
                          {singleVoid?.agentContactDetails?.forwardingAddress !== null ? 
<Address>{singleVoid?.agentContactDetails?.forwardingAddress?.line1 ? `${singleVoid?.agentContactDetails?.forwardingAddress?.line1.toLowerCase()},` : ""} {singleVoid?.agentContactDetails?.forwardingAddress?.line2 ? `${singleVoid?.agentContactDetails?.forwardingAddress?.line2.toLowerCase()},` : ""} {singleVoid?.agentContactDetails?.forwardingAddress?.postcode ? `${singleVoid?.agentContactDetails?.forwardingAddress?.postcode}` : ""}</Address>
                  :
                  "No address provided"
                  }
    </div>

  </li>
</ul>
</div>
           ): (<span>No bill payer details provided</span>)}
           </>
         )}
         
       </LandlordSection>
       
     </div>
   
   
      
            </TenancyDetailsSection>
            
          </VoidAddressDetailContainer>
          
        </div>
        
      ): <Loading/>}

      
    </Container>
  );
};

export default BillPayerEditPanel;
