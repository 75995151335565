import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SteppedInput } from "vouch.ui.react.components";
import { AlternateButton, Button } from "../../common/styles";
import { Void, PropertyInfo, TenantContactDetails, DateRange } from "../../types/Void";
import TenantStepper from "./TenantStepper";
import Loading from "../Loading";
import {format, formatISO, isValid } from "date-fns"; 
import { handleInputtedDate, editDateWithinRange } from "../../helpers/voidPortalHelpers";

interface Props {
  singleVoid: Void | undefined;
  isEditFormOpen: boolean;
  toggleEdit: () => void;
  moveToggle: (MoveInOrOut: boolean) => void;
  moveOutSelectedNotMoveIn: boolean;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  > div {
    width: 100%;
  }

  .grouped {
    display: flex;
    font-size: 0.8rem;
  }
`;

const TenantsIncomingSection = styled.div<{ moveOutSelectedNotMoveIn: boolean }>`
  display: ${(props) => (props.moveOutSelectedNotMoveIn ? "block" : "none")};
  cursor: pointer;
  min-width: 50%;
  width: 100%;
`;

const TenantsOutgoingSection = styled.div<{ moveOutSelectedNotMoveIn: boolean }>`
  display: ${(props) => (!props.moveOutSelectedNotMoveIn ? "block" : "none")};
  cursor: pointer;
  min-width: 50%;
  width: 100%;
`;

const VoidContacts = styled.div``;

const VoidAddressDetailContainer = styled.div`
  display: flexbox;
  flex-flow: row wrap;
  justify-content: space-between;
  display: flex;
  grid-gap: 5%;
`;

const MoveInInput = styled.div<{ moveOutSelectedNotMoveIn: boolean }>`
  display: ${(props) => (!props.moveOutSelectedNotMoveIn ? "flex" : "none")};
  cursor: pointer;
  min-width: 50%;
  #moveinDate {
    margin-bottom: 20px;
  }
  grid-gap: 50px;
  .moveOutMessages {
    align-items: center;
    display: flex;
    align-items: end;
    margin-bottom: 10px;
  }
`;

const MoveOutInput = styled.div<{ moveOutSelectedNotMoveIn: boolean }>`
  .moveOutMessages {
    align-items: end;
    margin-bottom: 10px;
    display: flex;
  }
  #moveoutDateDate {
    margin-bottom: 20px;
  }
  display: ${(props) => (props.moveOutSelectedNotMoveIn ? "flex" : "none")};
  cursor: pointer;
  min-width: 50%;
  grid-gap: 50px;
`;

const DisplayInDates = styled.div<{ moveOutSelectedNotMoveIn?: boolean }>`
  display: ${(props) => (!props.moveOutSelectedNotMoveIn ? "flex" : "none")};
  cursor: pointer;
  min-width: 50%;
`;

const DisplayOutDates = styled.div<{ moveOutSelectedNotMoveIn?: boolean }>`
  display: ${(props) => (props.moveOutSelectedNotMoveIn ? "flex" : "none")};
  cursor: pointer;
  min-width: 50%;
`;
const TenancyDetailsSection = styled.div`
  border: 2px solid #f2f2f2;
  border-radius: 0 0 10px 10px;
  border-top: none;
  padding: 40px;
  align-self: baseline;
  flex: 1 1 20%;

  .dateFeedback {
    display: flex;
  }
  #addressCollection {
    display: flex;
  }

  #voidPeriod,
  #forwardingAddressEntry {
    > div {
      grid-gap: 50px;
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: left;
      div {
        width: 100%;
      }
      #addressSelector {
        div {
          max-width: 100%;
        }
      }
    }
  }
  #ownerInfo {
    display: flex;
    flex-flow: column;
  }

  #moveDates {
    display: flex;
    max-width: 100%;

    .editedDates {
      display: flex;
      h4 {
        margin-bottom: 0;
      }
    }
    div {
      flex: 1;
      width: 100%;
    }
  }
  #addressSelector {
    > div {
      form {
        > div {
          margin: 10px 0;
        }
      }
    }
  }
  #moveoutDate {
    margin: 10px 0px;
    div {
      margin: 0;
    }
  }
  .side-by-side {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 220px;
    width: 100%;
    div {
      padding: 0;
    }
  }
  h3 {
    margin-bottom: 0;
  }
  .introHeading {
    margin-top: 18px;
    font-size: 16px;
    span {
      font-size: 16px;
      color: grey;
    }
  }

  #voidOwnerTitle {
    border-top: 1px solid #f2f2f2;
    padding-top: 30px;

    span {
      color: grey;
      font-size: 1.17rem;
    }
  }
  small {
    display: block;
    padding-top: 60px;
  }
  span {
    font-size: 0.8rem;
  }

  > div:nth-child(1) {
    display: flex;
    flex-flow: column;
    max-width: 400px;
    column-gap: 20px;

    > div {
      flex: 1 1 100px;
    }
  }
  .addressPeople {
    display: flex;
    align-items: baseline;
    grid-gap: 20px;
    width: 100%;
    li {
      padding: 5px 0;
    }
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .utilityList {
    img {
      width: 15px;
    }
    li {
      display: flex;
      justify-content: space-between;
      max-width: 220px;
      padding: 5px 0;
    }
  }
`;

const AddressMetaSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.6rem;
  text-transform: capitalize;
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-flow: column;
    flex: 0 1 65%;

    span {
      display: inline;
      line-height: 60px;
      font-family: "Montserrat";
      font-weight: 700;
      border-radius: 10px;
    }
  }
`;

const MoveOutDate = styled.div<{ selected: boolean; moveOutSelectedNotMoveIn: boolean }>`
  display: flex;
  align-items: center;
  grid-gap: 20px;
  background: ${(props) => (!props.moveOutSelectedNotMoveIn ? "#f2f2f2" : "#fff")};
  color: ${(props) => (!props.moveOutSelectedNotMoveIn ? "#000" : "#34bebd")};
  padding: 20px 40px;
  border-radius: 10px 10px 0 0;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  border: 2px solid #f2f2f2;
  border-bottom: none;
  margin-top: 40px;
`;

const MoveInDate = styled(MoveOutDate)<{
  selected: boolean;
  moveOutSelectedNotMoveIn: boolean;
}>`
  background: ${(props) => (!props.moveOutSelectedNotMoveIn ? "#fff" : "#f2f2f2")};
  color: ${(props) => (!props.moveOutSelectedNotMoveIn ? "#34bebd" : "#000")};
`;

const MoveOutDateInfo = styled.div<{
  updatedMoveOutDateInRange: string;
  moveOutSelectedNotMoveIn: boolean;
}>`
   border: 2px solid ${(props) =>
     props.updatedMoveOutDateInRange === "MIGHT_NOT_BE_ON_TIME"
       ? "#ed941e"
       : props.updatedMoveOutDateInRange === "WONT_HAPPEN"
       ? "red"
       : "none"};
  padding: 20px;
  border-radius: 10px;
  display: ${(props) =>
    props.updatedMoveOutDateInRange === "IN_TIME" ? "none" : "block"};
  margin: 20px 0;
  font-weight: 700;
  div {
    span {
      color: red;

    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
  `;

const MoveInDateInfo = styled.div<{
  updatedMoveInDateInRange: string;
  moveOutSelectedNotMoveIn: boolean;
}>`
font-weight: 700;
  border: 2px solid ${(props) =>
    props.updatedMoveInDateInRange === "MIGHT_NOT_BE_ON_TIME"
      ? "#ed941e"
      : props.updatedMoveInDateInRange === "WONT_HAPPEN"
      ? "red"
      : "none"};
  padding: 20px;
  border-radius: 10px;
  display: ${(props) =>
    props.updatedMoveInDateInRange === "IN_TIME" ? "none" : "block"}
  margin: 20px 0;
`;

const Back = styled.div`
  font-weight: 500;
  text-align: right;
  cursor: pointer;
  display: flex;
  column-gap: 10px;
  z-index: 10;
  font-size: 1rem;
`;

const HeadingContainer = styled.div`
  display: flex;
  grid-gap: 20px;
  margin-left: 20px;

  align-items: center;
`;

const MoveInEditPanel: React.FC<Props> = ({
  singleVoid,
  isEditFormOpen,
  toggleEdit,
  moveOutSelectedNotMoveIn,
  moveToggle,
  
}) => {



  const [removeAllOutgoingTenants, setRemoveAllOutgoingTenants] = useState(false)
  const [removeAllIncomingTenants, setRemoveAllIncomingTenants] = useState(false)
  const [formError, setFormError] = useState(false);

  interface updatedVoidDto {

      voidId:string,
      customerIdentifier:string,
      moveIn?:{
        moveDate:string
        notifyCouncil?: boolean,
        notifyWater?: boolean
      },
      moveOut?: {
        moveDate:string,
        notifyCouncil?: boolean,
        notifyWater?: boolean
      },
      tenantsMovingOut?:Array<TenantContactDetails>,
      tenantsMovingIn?:Array<TenantContactDetails>
  }

  async function updateVoid(voidDetails: updatedVoidDto, id: string) {    
    setSendingData(true);

    const res = await fetch(
      `${process.env.REACT_APP_VOID_PORTAL_ENDPOINT}/new/${id}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(voidDetails),
      }
    );
    setSendingData(true);

    if (res.status === 200) {
      const data = await res.text;
      console.log(data);
      window.location.reload();
    }
    toggleEdit();
    setSendingData(false);
  }

  function handleUpdatedSingleVoid() {

    let updatedVoid:updatedVoidDto = {
      voidId:"",
      customerIdentifier:"",
      moveIn:{
        moveDate:"",
        notifyCouncil:true,
        notifyWater: true
      },
      moveOut: {
        moveDate:"",
        notifyCouncil:true,
        notifyWater: true
      },
      tenantsMovingOut:[],
      tenantsMovingIn:[]

      
    };
let updatedInDate = ""
let updatedOutDate = ""


    if (singleVoid !== undefined) {

      if(propertyInfo?.submittedMoveInDate &&
        propertyInfo?.submittedMoveInDate.length === 10) {
          updatedInDate = formatISO(new Date(propertyInfo?.submittedMoveInDate))
        } else if(singleVoid?.moveIn !== null && singleVoid?.moveIn?.moveDate !==
            "1970-01-01T00:00:00+00:00" &&
          singleVoid?.moveIn?.moveDate !==
            "0001-01-01T00:00:00+00:00" && singleVoid?.moveIn?.moveDate !== ""){
              updatedInDate = singleVoid?.moveIn?.moveDate
        } else {
          updatedInDate =  ""
        }

        if(propertyInfo?.submittedMoveOutDate &&
          propertyInfo?.submittedMoveOutDate.length === 10) {
            updatedOutDate = formatISO(new Date(propertyInfo?.submittedMoveOutDate))
          } else if(singleVoid?.moveOut !== null && singleVoid?.moveOut?.moveDate !==
              "1970-01-01T00:00:00+00:00" &&
            singleVoid?.moveOut?.moveDate !==
              "0001-01-01T00:00:00+00:00" && singleVoid?.moveOut?.moveDate !== ""){
                updatedOutDate = singleVoid?.moveOut?.moveDate
          } else {
            updatedOutDate =  ""
          }
        
      updatedVoid = {
        voidId: singleVoid.id,
        customerIdentifier: singleVoid.customerIdentifier,
        moveIn: {
          moveDate: updatedInDate,
          notifyCouncil: true,
          notifyWater: true
        },
        moveOut: {
          moveDate: updatedOutDate,
          notifyCouncil: true,
          notifyWater: true
        },
        tenantsMovingIn: editedIncomingTenantsList,
        tenantsMovingOut: editedOutgoingTenantsList
      }

      if (updatedVoid.tenantsMovingIn !== undefined && updatedVoid.tenantsMovingIn.length === 0 && !removeAllIncomingTenants) {
        delete updatedVoid.tenantsMovingIn
      }

      if (updatedVoid.tenantsMovingOut !== undefined && updatedVoid.tenantsMovingOut.length === 0 && !removeAllOutgoingTenants) {
        delete updatedVoid.tenantsMovingOut
      }
  
      if ((updatedVoid.moveIn !== undefined && updatedVoid.moveIn.moveDate === "") || (updatedVoid.moveIn !== undefined && singleVoid.moveIn !== null && updatedVoid.moveIn.moveDate === singleVoid.moveIn.moveDate)) {
        delete updatedVoid.moveIn
      }
  
      if ((updatedVoid.moveOut !== undefined && updatedVoid.moveOut.moveDate === "") || (updatedVoid.moveOut !== undefined && singleVoid.moveOut !== null && updatedVoid.moveOut.moveDate === singleVoid.moveOut.moveDate)) {
        delete updatedVoid.moveOut
      }

      if(singleVoid?.moveIn !== null) {
        delete updatedVoid.moveIn?.notifyWater
        delete updatedVoid.moveIn?.notifyCouncil
      }

      if(singleVoid?.moveOut !== null) {
        delete updatedVoid.moveOut?.notifyWater
        delete updatedVoid.moveOut?.notifyCouncil
      }
      
      updateVoid(updatedVoid, singleVoid.id);
    }
  }

  // CONSTS

  const [submitDay, setDay] = useState("");
  const [submitMonth, setMonth] = useState("");
  const [submitYear, setYear] = useState("");
  const [submitEndDay, setEndDay] = useState("");
  const [submitEndMonth, setEndMonth] = useState("");
  const [submitEndYear, setEndYear] = useState("");
  const [sendingData, setSendingData] = useState(false);
  const [editedIncomingTenantsList, setEditedIncomingTenantsList] = useState<
    TenantContactDetails[]
  >([]);
  const [editedOutgoingTenantsList, setEditedOutgoingTenantsList] = useState<
  TenantContactDetails[]
  >([]);

  const [propertyInfo, setPropertyInfo] = useState<PropertyInfo>({
    gasMeterRead: "",
    waterMeterRead: "",
    electricityDayMeterRead: "",
    electricityNightMeterRead: "",
    submittedMoveOutDate: "",
    submittedMoveOutDateMeta: [],
    submittedMoveInDate: "",
    submittedMoveInDateMeta: [],
    voidActive: "Active",
  });

  const [selectedAddress, setSelectedAddress] = useState({
    city: "",
    houseNumber: "",
    postCode: "",
    street: "",
    id: "",
    houseName: "",
    flatNumber: "",
    formattedAddress: "",
  });

  const [forwardingAddress, setForwardingAddress] = useState({
    city: selectedAddress.city,
    houseNumber: selectedAddress.houseNumber,
    postCode: selectedAddress.postCode,
    street: selectedAddress.street,
    id: selectedAddress.id,
    houseName: selectedAddress.houseName,
    flatNumber: selectedAddress.flatNumber,
    formattedAddress: selectedAddress.formattedAddress,
  });

  const [landlordAddress, setLandlordAddress] = useState({
    city: "",
    houseNumber: "",
    postCode: "",
    street: "",
    id: "",
    houseName: "",
    flatNumber: "",
    formattedAddress: "",
    line1: "",
    line2: "",
  });

  const updatedForwardingAddress = {
    postcode: forwardingAddress.postCode,
    addressType: "current",
    line1: `${forwardingAddress.flatNumber} ${forwardingAddress.houseNumber} ${forwardingAddress.street}`,
    line2: forwardingAddress.city,
    normalizedLine1: forwardingAddress.formattedAddress,
    latitude: 0,
    longitude: 0,
    city: forwardingAddress.city,
    houseNumber: forwardingAddress.houseNumber,
    id: forwardingAddress.id,
    houseName: forwardingAddress.houseName,
    flatNumber: forwardingAddress.flatNumber,
  };

  const [voidLandlord, setVoidLandlord] = useState({
    companyName: "",
    email: "",
    title: "",
    forename: "",
    surname: "",
    phone: "",
    forwardingAddress: {
      city: landlordAddress.city,
      houseNumber: landlordAddress.houseNumber,
      postCode: landlordAddress.postCode,
      street: landlordAddress.street,
      id: landlordAddress.id,
      houseName: landlordAddress.houseName,
      flatNumber: landlordAddress.flatNumber,
      formattedAddress: landlordAddress.formattedAddress,
      line1: `${landlordAddress.flatNumber} ${landlordAddress.houseNumber} ${landlordAddress.street}`,
      line2: landlordAddress.city,
    },
  });

  const [updatedMoveOutDateInRange, setUpdatedMoveOutDateInRange] = useState<
    "IN_TIME" | "MIGHT_NOT_BE_ON_TIME" | "WONT_HAPPEN"
  >("IN_TIME");
  const [updatedMoveInDateInRange, setUpdatedMoveInDateInRange] = useState<
    "IN_TIME" | "MIGHT_NOT_BE_ON_TIME" | "WONT_HAPPEN"
  >("IN_TIME");

  const [updatedMoveOutErrorMessage, setUpdatedMoveOutErrorMessage] =
    useState("");
  const [updatedMoveInErrorMessage, setUpdatedMoveInErrorMessage] =
    useState("");

  useEffect(() => {
    if (selectedAddress.id !== "") {
      setForwardingAddress(selectedAddress);
    }
  }, [selectedAddress.id]);



  function checkValidDate(day:string, month:string, year:string, submittedMoveDate:string, setMoveErrorMessage: (message:string) => void, setMoveDateRange: (dateRange: DateRange) => void, type:string) {
    if (day === "" || month === "" || year === "") {
      return
    }

    const isDateValid = isValid(new Date(`${year}-${month}-${day}`))
    if(!isDateValid) {
      setMoveErrorMessage(`Please enter a valid ${type} date`);
      setMoveDateRange("WONT_HAPPEN");
      return;
    }
    
    setMoveErrorMessage("");
    setMoveDateRange("IN_TIME")
    setMoveErrorMessage("");
    setPropertyInfo({
      ...propertyInfo,
      [submittedMoveDate]: `${year}-${month}-${day}`,
    });
  }

  useEffect(() => {
    if(moveOutSelectedNotMoveIn) {
      checkValidDate(submitDay, submitMonth, submitYear, "submittedMoveOutDate", setUpdatedMoveOutErrorMessage, setUpdatedMoveOutDateInRange, "move-out")
      return
    }
    checkValidDate(submitEndDay, submitEndMonth, submitEndYear, "submittedMoveInDate", setUpdatedMoveInErrorMessage, setUpdatedMoveInDateInRange, "move-in")
    
  },[submitEndDay, submitEndMonth, submitEndYear, submitDay, submitMonth, submitYear])


  useEffect(() => {
    setVoidLandlord({ ...voidLandlord, forwardingAddress: landlordAddress });
  }, [landlordAddress]);

  useEffect(() => {
    if(moveOutSelectedNotMoveIn) {
      editDateWithinRange(
        singleVoid?.moveOut?.moveDate,
        propertyInfo?.submittedMoveOutDate,
        singleVoid?.moveOut, 
        setUpdatedMoveOutErrorMessage,
        setUpdatedMoveOutDateInRange,
        moveOutSelectedNotMoveIn
      );
      return;
    }
    editDateWithinRange(
      singleVoid?.moveIn?.moveDate,
      propertyInfo?.submittedMoveInDate,
      singleVoid?.moveIn, 
      setUpdatedMoveInErrorMessage,
      setUpdatedMoveInDateInRange,
      moveOutSelectedNotMoveIn
    );
  }, [
    singleVoid?.moveOut?.moveDate,
    singleVoid?.moveIn?.moveDate,
    propertyInfo?.submittedMoveOutDate,
    propertyInfo?.submittedMoveInDate,
  ]);

  function getUpdatedIncomingTenants(tenantList: any) {
    setEditedIncomingTenantsList(tenantList);
  }

  function getUpdatedOutgoingTenants(tenantList: any) {
    setEditedOutgoingTenantsList(tenantList);
  }
  

  return (
    <Container id="voidEditContainer">
      {isEditFormOpen && !sendingData ? (
        <div>
          <HeadingContainer>
            <Back onClick={() => toggleEdit()}>
              <img
                src="/assets/svg/back.svg"
                alt="a black arrow pointing leftwards"
              />
            </Back>
            <AddressMetaSection>
              <div>
                <span>
                  {singleVoid?.propertyAddress?.line1
                    ? `${singleVoid?.propertyAddress?.line1.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.line2
                    ? `${singleVoid?.propertyAddress?.line2.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.city
                    ? `${singleVoid?.propertyAddress?.city.toLowerCase()},`
                    : ""}{" "}
                  {singleVoid?.propertyAddress?.postcode
                    ? `${singleVoid?.propertyAddress?.postcode.toUpperCase()}`
                    : ""}
                </span>
              </div>
            </AddressMetaSection>
          </HeadingContainer>
          <div id="editButtonsContainer">
            <div>
              {isEditFormOpen && (

                <Button
                  disabled={
                    updatedMoveOutDateInRange === "WONT_HAPPEN" ||
                    updatedMoveInDateInRange === "WONT_HAPPEN" ||
                    updatedMoveInErrorMessage !== "" ||
                    updatedMoveOutErrorMessage !== "" ||
                    formError
                  }
                  onClick={() => handleUpdatedSingleVoid()}
                  >
                  Submit
                </Button>
              )}

              <AlternateButton onClick={() => toggleEdit()}>
                {isEditFormOpen ? (
                  "Cancel"
                ) : (
                  <div className="editButton">
                    Edit
                    <img src="/assets/svg/edit.svg" alt="icon of a pen" />
                  </div>
                )}
              </AlternateButton>
            </div>
          </div>

          <div className="grouped" id="voidOwnerTitle">
            <MoveOutDate
              onClick={() => moveToggle(true)}
              selected={moveOutSelectedNotMoveIn}
              moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
            >
              <span>Move-Out Date</span>
            </MoveOutDate>
            <MoveInDate
              onClick={() => moveToggle(false)}
              selected={moveOutSelectedNotMoveIn}
              moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
            >
              <span>Move-In Date</span>
            </MoveInDate>
          </div>
          <VoidAddressDetailContainer>
            <TenancyDetailsSection>
              <div id="moveDates">
                <MoveOutInput moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                  <div>
                    <div>
                      <h3 id="moveoutDateDate">Move-Out Date</h3>
                    </div>
                    <div id="moveoutDate">
                      <SteppedInput
                        onChange={(e: any) => {
                          handleInputtedDate(e, setDay, setMonth, setYear);
                        }}
                        values={propertyInfo?.submittedMoveOutDateMeta || []}
                        label="Move-Out Date (DD/MM/YYYY)"
                        divider="/"
                        onFormattedChange={() => {}}
                        config={[
                          {
                            label: "Day",
                            length: 2,
                            maxVal: 31,
                            minVal: 1,
                          },
                          {
                            label: "Month",
                            length: 2,
                            maxVal: 12,
                            minVal: 1,
                          },
                          {
                            label: "Year",
                            length: 4,
                            maxVal: 2050,
                            minVal: new Date().getFullYear() - 1,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="moveOutMessages">
                    <MoveOutDateInfo
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      updatedMoveOutDateInRange={updatedMoveOutDateInRange}
                    >
                      <span>{updatedMoveOutErrorMessage}</span>
                    </MoveOutDateInfo>
                  </div>
                </MoveOutInput>
                <MoveInInput moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                  <div>
                    <div>
                      <h3>Move-In Date</h3>
                    </div>
                    <div id="moveinDate">
                      <SteppedInput
                        onChange={(e: any) => {
                          handleInputtedDate(e, setEndDay, setEndMonth, setEndYear);
                        }}
                        values={propertyInfo?.submittedMoveInDateMeta || []}
                        label="Move-In Date (DD/MM/YYYY)"
                        divider="/"
                        onFormattedChange={() => {}}
                        config={[
                          {
                            label: "Day",
                            length: 2,
                            maxVal: 31,
                            minVal: 1,
                          },
                          {
                            label: "Month",
                            length: 2,
                            maxVal: 12,
                            minVal: 1,
                          },
                          {
                            label: "Year",
                            length: 4,
                            maxVal: 2050,
                            minVal: new Date().getFullYear() - 1,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="moveOutMessages">
                    <MoveInDateInfo
                      moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}
                      updatedMoveInDateInRange={updatedMoveInDateInRange}
                    >
                      <span>{updatedMoveInErrorMessage}</span>
                    </MoveInDateInfo>
                  </div>
                </MoveInInput>
                <div className="dateFeedback">
                  <div className="editedDates">
                    <DisplayInDates moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                      <div>
                        <h3>Current Move-In Date</h3>
                        <span>
                          {singleVoid?.moveIn?.moveDate &&
                          singleVoid?.moveIn?.moveDate !==
                            "1970-01-01T00:00:00+00:00" &&
                          singleVoid?.moveIn?.moveDate !==
                            "0001-01-01T00:00:00+00:00"
                            ? format(new Date(singleVoid?.moveIn?.moveDate), "PPPP")
                            : "No Move-In Date Provided"}
                        </span>
                      </div>
                      <div>
                        <h3>New Move-In Date</h3>
                        <span>
                          {propertyInfo?.submittedMoveInDate &&
                          propertyInfo?.submittedMoveInDate.length === 10
                            ? format(new Date(propertyInfo?.submittedMoveInDate), "PPPP")
                            : ""}
                        </span>
                      </div>
                    </DisplayInDates>
                    <DisplayOutDates moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                      <div>
                        {" "}
                        <h3>Current Move-Out Date</h3>
                        <span>
                          {singleVoid?.moveOut?.moveDate &&
                          singleVoid?.moveOut?.moveDate !==
                            "1970-01-01T00:00:00+00:00" &&
                          singleVoid?.moveOut?.moveDate !==
                            "0001-01-01T00:00:00+00:00"
                            ? format(
                                new Date(singleVoid?.moveOut?.moveDate), "PPPP"
                                
                              )
                            : "No Move-Out Date Provided"}
                        </span>
                      </div>
                      <div>
                        <h3>New Move-Out Date</h3>
                        <span>
                          {propertyInfo?.submittedMoveOutDate &&
                          propertyInfo?.submittedMoveOutDate.length === 10
                            ? format(
                                new Date(propertyInfo?.submittedMoveOutDate), "PPPP"
                              )
                            : ""}
                        </span>
                      </div>
                    </DisplayOutDates>
                  </div>
                </div>
              </div>

              <TenantsIncomingSection moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                <h3 id="voidOwnerTitle">Outgoing Tenants</h3>
                {singleVoid?.tenantsMovingOut === null ||
                (singleVoid?.tenantsMovingOut !== undefined &&
                  singleVoid?.tenantsMovingOut?.length === 0) ||
                (singleVoid?.tenantsMovingOut !== undefined &&
                  singleVoid?.tenantsMovingOut?.length > 0 &&
                  singleVoid?.tenantsMovingOut?.[0]?.contactDetails ===
                    null) ? (
                  <TenantStepper
                    getUpdatedTenants={getUpdatedOutgoingTenants}
                    moveInOrOut={"outTenant"}
                    tenantList={singleVoid?.tenantsMovingOut}
                    setRemoveAllTenants={setRemoveAllOutgoingTenants}
                    submitTenants={handleUpdatedSingleVoid}
                    formError={formError}
                    setFormError={setFormError}
                    ></TenantStepper>
                    ) : (
                      <VoidContacts>
                    <TenantStepper
                      getUpdatedTenants={getUpdatedOutgoingTenants}
                      moveInOrOut={"outTenant"}
                      tenantList={singleVoid?.tenantsMovingOut}
                      setRemoveAllTenants={setRemoveAllOutgoingTenants}
                      submitTenants={handleUpdatedSingleVoid}
                      formError={formError}
                      setFormError={setFormError}
                    ></TenantStepper>
                  </VoidContacts>
                )}
              </TenantsIncomingSection>
              <TenantsOutgoingSection moveOutSelectedNotMoveIn={moveOutSelectedNotMoveIn}>
                <h3 id="voidOwnerTitle">Incoming Tenants</h3>
                {singleVoid?.tenantsMovingIn === null ||
                (singleVoid?.tenantsMovingIn !== undefined &&
                  singleVoid?.tenantsMovingIn?.length === 0) ||
                (singleVoid?.tenantsMovingIn !== undefined &&
                  singleVoid?.tenantsMovingIn?.length > 0 &&
                  singleVoid?.tenantsMovingIn?.[0]?.contactDetails === null) ? (
                  <TenantStepper
                    getUpdatedTenants={getUpdatedIncomingTenants}
                    moveInOrOut={"inTenant"}
                    tenantList={singleVoid?.tenantsMovingIn}
                    setRemoveAllTenants={setRemoveAllIncomingTenants}
                    submitTenants={handleUpdatedSingleVoid}
                    formError={formError}
                    setFormError={setFormError}
                  ></TenantStepper>
                ) : (
                  <VoidContacts>
                    <TenantStepper
                      getUpdatedTenants={getUpdatedIncomingTenants}
                      moveInOrOut={"inTenant"}
                      tenantList={singleVoid?.tenantsMovingIn}
                      setRemoveAllTenants={setRemoveAllIncomingTenants}
                      submitTenants={handleUpdatedSingleVoid}
                      formError={formError}
                      setFormError={setFormError}
                    ></TenantStepper>
                  </VoidContacts>
                )}
              </TenantsOutgoingSection>
              <div id="ownerInfo"></div>
            </TenancyDetailsSection>
          </VoidAddressDetailContainer>
        </div>
      ) : <Loading/>}

    </Container>
  );
};

export default MoveInEditPanel;
