import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';


const MessageContainer = styled.div`
  background: linear-gradient(168deg, #fff 33%, #e0ecf5);
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  padding: 50px;
  text-align: right;
  overflow-x:hidden;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
 a {
   text-decoration:none;
 }


  
  h1 {
    max-width:600px;
    margin: 0;
    text-align: left;
    padding-top:50px;
    color: #082842;
    font-family: "Lato", sans-serif;
    font-size:1.5rem;
    z-index:1;
  }

  p {
    text-align: left;
    padding-bottom: 50px;
  }
`
  ;

const SuccessImage = styled.img`
  position: fixed;
  right: -50px;
  bottom: 0px;
  box-sizing: border-box;
  overflow-y: hidden;
  opacity: .3;
  width:50%;
  max-width: 800px;
  min-height:300px;
`;


const Emoji = styled.img`
  margin-left: 5px;
  max-width: 100px;
`;

interface Props { }

const Button = styled.button`
  background-color: #34bebd;
  margin: 10px 10px 10px 0;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #34bebd;
  width: 150px;
  height: 60px;
  letter-spacing: 0.23px;
  color: white;
  display:block;
  font: bold 15px Lato, sans-serif;
  outline: none;
  &:disabled {
    background: #f2f2f2;
    border-color: #082842;
    color: #082842;
    pointer-events: none;
  }
`;

const LandlordConsentScreen: React.FC<Props> = () => {
    const [ responseData,  setResponseData] = useState("");
    const [ errorData,  setErrorData] = useState("");
    const { token, optDecision } = useParams<{ token: string;  optDecision: string }>();

    useEffect(() => {
      if (!token) return;
      const reqBody = {
        query: `mutation($token: String!, $optDecision: String!){
          landlordVoidConsent(token: $token, optDecision: $optDecision)
              }`,
        variables: {
          token,
          optDecision
        },
      };
  
      fetch(`${process.env.REACT_APP_SHOP_ENDPOINT}`, {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(reqBody),
      })
        .then((res) => {
          return res.json();
        })
        .then((resData) => {
          console.log(resData);
          if (resData.data && resData.data.landlordVoidConsent !== null) {
            return setResponseData(resData.data.landlordVoidConsent);
          } else {
            return setErrorData(resData.errors[0]?.message)
          }
          
        })
        .catch((err) => console.log(err));
    }, []);
  

  return (
    <MessageContainer>
      <div>
        <h3>
          powered by
          <Emoji src="/assets/svg/vouchlogo.svg" />
        </h3>
      </div>
      <div>
       
        {
            <>
              <h1>{responseData ? responseData : "Oops, something went wrong when updating your void consent. Please refresh this page and try again."}</h1>
              <p>If you have any issues or questions please contact <a href="mailto:hello@vouch.co.uk">hello@vouch.co.uk</a></p>
            </>
        }
      </div>
      <div>
        <SuccessImage src="/assets/svg/consent-update.svg" alt="an illustration of a person with arms outstretched towards a large mobile phone with a tick on the phone's screen"></SuccessImage>
      </div>
      <a href="https://store.vouch.co.uk"><Button>Return to the store</Button></a>
    </MessageContainer>
  )
}

export default LandlordConsentScreen;