import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { fadeInModal, slideInTop } from "../common/styles";

interface Props {
  open: boolean;
  setOpen: Function;
  maxWidth?: string;
}

const ModalBackDrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  animation: ${fadeInModal} 0.35s ease-in-out 0s forwards 1;
  display: grid;
  place-items: center;
`;

const ModalBodyContainer = styled.div<{ maxWidth?: string }>`
  transform: translate(-50%, -50%);
  -webkit-animation: ${slideInTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: ${slideInTop} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background: white;
  padding: 1rem;
  margin: 0 auto;
  border-radius: 15px 15px 0 0;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "650px")};
  position: relative;
  width: 100%;
  min-height: 50vh;
  max-height: 90vh;
  position: fixed;
  left: 0;
  z-index: 100;
  right: 0;
  bottom: 0;
  background-color: rg#f2f2f2(160, 153, 153);
  text-align: center;
  display: grid;
  overflow: hidden;
`;

const Modal: React.FC<Props> = ({ open, setOpen, maxWidth, children }) => {
  const modalRef = useRef(null);
  useOutsideAlerter(modalRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false);
        }
      }

      function handleKeyPressOutside(event: any) {
        if (event.key === "Escape") {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyPressOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleKeyPressOutside);
        setOpen(false);
      };
    }, [ref]);
  }
  return (
    <ModalBackDrop>
      <ModalBodyContainer maxWidth={maxWidth} ref={modalRef}>{children}</ModalBodyContainer>
    </ModalBackDrop>
  );
};

export default Modal;
