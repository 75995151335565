import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TextInput,
} from "vouch.ui.react.components";
import DropdownSelector from "../ScottishPower/DropdownSelector";
import { titleOptions } from "../../helpers/ScottishPowerConstants";

interface Props {
  step: number;
  setValidInput: Function;
  setLandlordDetails: Function;
}

const AlternateProgBar = styled.p`
padding: 30px 0;
  span {
    color: rgba(128, 128, 128, 0.5);
  }
  color: #082842;
  font-family: "Montserrat", sans-serif;
    text-align: left;
    font-weight: 700;

    `
;

const Container = styled.div`

#title {
  > div {
    > div {
      margin: 0 5px;
    }
  } 
}`;

const AddLandlordStep: React.FC<Props> = ({ step, setValidInput, setLandlordDetails }) => {

  const [landlord, setLandlord] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber:"",
    title:"",
    companyName:"",
  });

  function handleLandlordInfo(evt: any) {
    setLandlord({
      ...landlord,
      [evt.target.name]: evt.target.value,
    });
  }
  
  function getTitle(inputTitle: number, actualTitle: string): void {      
    setLandlord({...landlord, title:actualTitle})
  }

  useEffect(() => {
    if (
      landlord.firstName !== "" &&
      landlord.lastName !== "" &&
      landlord.email !== "" &&
      landlord.title !== "" &&
      landlord.companyName !== "" &&
      landlord.phoneNumber !== ""
    ) {
      setValidInput(true);
      setLandlordDetails(landlord)
    } else {
      setValidInput(false);
    }
  }, [landlord]);

  


  if (step !== 2) {
    return null;
  }
  return (
    <Container>
          <AlternateProgBar><span>Add Property / </span>Add Landlord / <span>Add Lead Tenant / Choose Notifications / Summary</span></AlternateProgBar>

      {/* <ScottishPowerProgressBar step={step}></ScottishPowerProgressBar> */}
      <div id="title">
            <DropdownSelector
              getGeneralValue={getTitle}
              introText={""}
              listType={"Title"}
              options={titleOptions}>
            </DropdownSelector>
          </div>
      <TextInput
        type="text"
        name="firstName"
        label="Landlord's First Name"
        maxLength={15}
        minLength={2}
        regex={/^[a-zA-Z\s-]+$/}
        value={landlord.firstName}
        onChange={(e: any) => handleLandlordInfo(e)}
      />
      <TextInput
        type="text"
        name="lastName"
        label="Landlord's Last Name"
        maxLength={15}
        minLength={2}
        regex={/^[a-zA-Z\s-]+$/}
        value={landlord.lastName}
        onChange={(e: any) => handleLandlordInfo(e)}
      />
      <TextInput
        type="text"
        name="email"
        label="Landlord's Email Address"
        maxLength={50}
        minLength={2}
        regex={/\S+@\S+\.\S+/}
        value={landlord.email}
        onChange={(e: any) => handleLandlordInfo(e)}
      />
      <TextInput
        type="text"
        name="companyName"
        label="Landlord's Comapany Name"
        maxLength={15}
        minLength={2}
        regex={/^[a-zA-Z\s-']+$/}
        value={landlord.companyName }
        onChange={(e: any) => handleLandlordInfo(e)}
      />
      <TextInput
        type="text"
        name="phoneNumber"
        label="Landlord's Phone Number"
        maxLength={11}
        minLength={10}
        regex={/^0[0-9]{10}$/}
        value={landlord.phoneNumber}
        onChange={(e: any) => handleLandlordInfo(e)}
      />
    </Container>
  );
};

export default AddLandlordStep;
