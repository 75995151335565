import { createContext } from "react";
// types
import  { VoidData } from "../types/Void";

const ctx: ctxType = {
  voidData: null,
  setVoidData: () => {},
  setMoveIn: () => {},
  moveIn: "",
  moveOut: "",
  setMoveOut: () => {},
  scrollIntoView: () => {},
  setDisplayDetail: () => {},
  displayDetail: "",
  setDarkModeEnabled: (enableDarkMode: boolean) => {},
  darkModeEnabled:false,
};

export interface ctxType {
  voidData: VoidData | null;
  setVoidData: Function;
  setMoveIn: Function;
  scrollIntoView: Function;
  moveIn: string;
  moveOut: string;
  setMoveOut: Function;
  setDisplayDetail: Function;
  displayDetail: string;
  setDarkModeEnabled: Function;
  darkModeEnabled:boolean;
}

const VoidContext = createContext(ctx);

export default VoidContext;
