import React from "react";
import styled from "styled-components";
import { SecondaryButton, Button } from "../common/styles";
import { Deal } from "../types/Store";

const CTAContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding: 15px 15px 0 15px;
  justify-items: center;
  grid-gap: 0 10px;

  @media (max-width: 400px) {
    #sec {
      width: 100%;
    }
  }
`;

const DealButton = styled(SecondaryButton)`
  width: 100%;
  min-width: 150px;
`;

const LongGetNow = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  grid-column: 1 / span 2;

  button {
    min-width: 150px;
    width: 90%;
  }
`;

interface Props {
  setOpen: Function;
  revealNumber: boolean;
  setDealType: Function;
  callbackUrl?: string;
  deal: Deal;
}

export default function CTAButtons({
  setDealType,
  deal,
  revealNumber,
  setOpen,
  callbackUrl,
}: Props) {
  return (
    <>
      <CTAContainer>
        {callbackUrl && (
          <DealButton
            onClick={() => setDealType("CALLBACK")}
            alternate
            id="sec"
          >
            Get a Callback
          </DealButton>
        )}

        {deal.url && !deal.phone && (
          <Button onClick={() => setDealType("GETNOW")}>Get Now</Button>
        )}
      </CTAContainer>
      <LongGetNow>
        {deal.phone && (
          <Button onClick={() => setDealType("GETNOW")}>Get Now</Button>
        )}
      </LongGetNow>
    </>
  );
}
