import React, { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import ctx, { ctxType } from "../../context/ShopContext";
import { AddressData as Location } from "../../types/Quote"
import {Colours} from "../../common/styles";
// components
import { TextInput } from "vouch.ui.react.components";

interface Props {
  setSelectedAddress: Function;
  selectedAddress: Location;
}



const swingIn = keyframes`
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  `;
const Container = styled.div`
  max-width: 400px;
  width: 100%;
  position: relative;
`;

const FoundAddresses = styled.div`
  border:4px dashed yellow;
  max-height: 390px;
  overflow-y: auto;
  padding: 15px;
  background: #f2f2f2;
  animation: ${swingIn} 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  position: absolute;
  z-index: 100;
  top: 75px;
  width: 100%;
  border: 1px solid ${Colours.lightGrey};
  @media(max-width:420px) {
    top: 90px;
    font-size: .8em;
  }
`;

const AddressItem = styled.div<{ selected: boolean }>`
  margin: 15px 5px;
  padding: 20px;
  background: ${(props) => (props.selected ? "#34bebd" : "white")};
  color: ${(props) => (props.selected ? "white" : "#082842")};
  border-radius: 10px;

  &:hover {
    cursor: pointer;
  }

  @media(max-width:420px) {
    margin: 0px 0px 10px 0px;
  padding: 10px;
  text-align: center;
  }
`;

const GroupedInput = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));

  @media (max-width: 420px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const ManualInput = styled.div`
  display: grid;
`;

const PostcodeInput = styled(TextInput)`
  margin: 0 auto;
`;

const SPAddressSelector: React.FC<Props> = ({
  selectedAddress,
  setSelectedAddress,
}) => {
  const [searchedPostcode, setSearchedPostcode] = useState("");
  const [addresses, setAddresses] = useState<Array<Location>>([]);
  const shopCtx: ctxType = useContext(ctx);

  useEffect(() => {
    if (searchedPostcode.trim() === "") return;
    let timer = setTimeout(() => {
      findAddress(searchedPostcode);
    });

    return () => {
      clearTimeout(timer);
    };
  }, [searchedPostcode]);

  function handleInputChange(e: React.FormEvent<EventTarget>) {
    const { value, name } = e.target as HTMLInputElement;
    const currVals = { ...selectedAddress };
    switch (name) {
      case "usrPostcode":
        setSearchedPostcode(value);
        break;
      case "flatNumber":
        currVals.flatNumber = value;
        setSelectedAddress(currVals);
        break;
      case "houseNumber":
        currVals.houseNumber = value;
        setSelectedAddress(currVals);
        break;
      case "houseName":
        currVals.houseName = value;
        setSelectedAddress(currVals);
        break;
      case "street":
        currVals.street = value;
        setSelectedAddress(currVals);
        break;
      case "city":
        currVals.city = value;
        setSelectedAddress(currVals);
        break;
      case "postcode":
        currVals.postCode = value;
        setSelectedAddress(currVals);
        break;
      case "formattedAddress":
        currVals.formattedAddress = value;
        setSelectedAddress(currVals);
        break;
    }
  }

  async function findAddress(postcode: string) {
    const regex = /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;
    let inputtedPostcode;
    if (!regex.test(postcode)) {
      return
    }

    inputtedPostcode = {
      postCode: postcode
    }

    const res = await fetch(
      `${process.env.REACT_APP_SP_ADDRESS_ENDPOINT}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": `${process.env.REACT_APP_SP_API_KEY}`,
          "content-type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(inputtedPostcode)

      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setAddresses(data.results);
    }
  }

  function formatAddr(addr: Location): string {
    return `${addr.formattedAddress}`;
  }

  // const vchPostcode = shopCtx?.config?.applicant?.address?.postcode

  // useEffect(() => {
  //   if (vchPostcode !== undefined) {
  //     findAddress(vchPostcode)
  //   }
  // }, [vchPostcode])

  return (
    <Container>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          findAddress(searchedPostcode);
        }}
      >
        <PostcodeInput
          value={searchedPostcode 
            // || vchPostcode
          }
          type="text"
          label="Find By Postcode"
          name="usrPostcode"
          onChange={(e: any) => handleInputChange(e)}
        ></PostcodeInput>
      </form>
      {addresses.length > 0 && (
        <FoundAddresses>
          {addresses.map((addr) => (
            <AddressItem key={addr.id}
              onClick={() => {
                setSelectedAddress(addr);
                setAddresses([]);
              }}
              selected={addr.id === selectedAddress?.id}
            >
              {formatAddr(addr)}
            </AddressItem>
          ))}
        </FoundAddresses>
      )}
      {/* <ManualInput>
        <TextInput
          value={selectedAddress?.flatNumber}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="Flat Number"
          name="flatNumber"
        />
        <TextInput
          value={selectedAddress?.houseNumber}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="House Number"
          name="houseNumber"
        />
        <TextInput
          value={selectedAddress?.houseName}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="House Name"
          name="houseName"
        />
        <TextInput
          value={selectedAddress?.street}
          onChange={(e: any) => handleInputChange(e)}
          type="text"
          label="Street"
          name="street"
        />
        <GroupedInput>
          <TextInput
            value={selectedAddress?.city}
            onChange={(e: any) => handleInputChange(e)}
            type="text"
            label="City"
            name="city"
          />
        </GroupedInput>
      </ManualInput> */}
    </Container>
  );
};

export default SPAddressSelector;
