import React from 'react';
import styled from "styled-components";
import { BannerBackground, PageWrapper } from "../common/styles";
import { useParams } from "react-router";
// types
import { Store, Deal } from "../types/Store";
import { useFindShop } from "../hooks/hooks";
// components
import DealCard from "../components/DealCard";
import Footer from "../components/Footer";
import Loading from "../components/Loading";

import StoreHeader from "../components/StoreHeader";
import { reactPlugin } from "../insights/ApplicationInsights";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

interface Props {
  stores: Store[];
}

const StoreContainer = styled(BannerBackground)`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
`;

const SmallPrint = styled.p`
  font-size: 10px;
  text-align: justify;
  padding: 20px 10px;
`;

const MainStoreBanner = styled.div`
  padding: 10px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  h1 {
    color: white;
    text-align: center;
  }

  @media (min-width: 320px) {
    margin-bottom: 50px;
  }
`;

const BlurbContainer = styled.div``;

const DealsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  align-items: flex-start;
  margin: 25px 0;
  grid-gap: 20px;

  @media (max-width: 320px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
`;

const StorePage: React.FC<Props> = () => {
  let { store, department } = useParams<{
    store: string;
    department: string;
  }>();
  const foundStore: Store = useFindShop(store);

  if (!foundStore) {
    return <Loading />;
  }

  const filteredDep = foundStore.departments.filter(({ departmentName }) => {
    return departmentName.toLowerCase() === department.toLowerCase();
  })[0];

  console.log(filteredDep);

  return (
    <PageWrapper center>
      <StoreContainer bannerUrl={filteredDep.departmentBanner}>
        <MainStoreBanner>
          <StoreHeader storeLogo={foundStore.logoUrl} department={department} />
        </MainStoreBanner>
        <BlurbContainer>
          <DealsContainer>
            {filteredDep.deals.map((deal: Deal, index: number) => (
              <DealCard key={index}
                deal={deal}
                store={foundStore.storeName}
                department={department}
                departmentObj={filteredDep}
              ></DealCard>
            ))}
          </DealsContainer>
        </BlurbContainer>
        <Footer>
          {foundStore.storeTermsAndCond && (
            <SmallPrint>{foundStore.storeTermsAndCond}</SmallPrint>
          )}
        </Footer>
      </StoreContainer>
    </PageWrapper>
  );
};

export default withAITracking(reactPlugin, StorePage);
