import React from "react";
import styled from "styled-components";

interface Props {}

const Container = styled.div`
  max-width: 400px;
  place-items: center;
  display: grid;
  margin: 0 auto;
`;

const Loading: React.FC<Props> = (props) => {
  return (
    <Container>
      <svg
        version="1.1"
        id="L4"
        x="0px"
        y="0px"
        viewBox="-40 20 150 50"
        enableBackground="new 0 0 0 0"
      >
        <circle fill="#34bebd" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="#34bebd" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="#34bebd" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </Container>
  );
};

export default Loading;
